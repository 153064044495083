import React, { useEffect } from 'react'
import './DropdownSearchbar.css'

const DropdownSearchbar = ({
  value,
  onChange,
  placeholder,
  options,
  onClick,
  isOpen,
  onFocus,
  onClose
}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdownSearchbar')) {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isOpen, onClose])

  return (
    <>
      <div className="dropdownSearchbar">
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          onFocus={onFocus}
        />
      </div>
      {isOpen && (
        <ul className="dropdownSearchbarOptions">
          {options.map((option) => (
            <li
              key={option.name}
              className="dropdownSearchbarOption"
              onClick={() => {
                onClick(option)
                onClose()
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

export default DropdownSearchbar
