import React, { useState, useEffect } from 'react'
import './ResourcesFileModal.css'
import BasicButton from '../buttons/BasicButton'
import Warning from '../alerts/Warning'
import Success from '../alerts/Success'
import DarkSelectInput from '../forms/DarkSelectInput'
import CheckResourcesTable from '../tables/CheckResourcesTable'

const API = process.env.REACT_APP_SETUP_API

const ResourcesFileModal = ({ isOpen, closeModal }) => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Dragging state and errors */
  const [dragging, setDragging] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  /* Check resources hooks */
  const [invalidResources, setInvalidResources] = useState([])
  const [incompleteResources, setIncompleteResources] = useState([])
  const [repeatedResources, setRepeatedResources] = useState([])
  const [repreatedEmails, setRepeatedEmails] = useState([])
  const [originalRepeatedResources, setOriginalRepeatedResources] = useState([])
  const [updatedRepeatedResources, setUpdatedRepeatedResources] = useState(false)
  const [newResources, setNewResources] = useState([])
  const [checkResources, setCheckResources] = useState(false)
  /* Resources uploaded */
  const [resourcesUploaded, setResourcesUploaded] = useState(false)
  /* Counter to close resources modal */
  const [counter, setCounter] = useState(5)

  /* Upload with drag and drop */
  const handleDrop = async (e) => {
    e.preventDefault()
    setDragging(false)

    const file = e.dataTransfer.files[0]
    const reader = new FileReader()

    /* Validation for type files */
    const allowedExtensions = ['xls', 'xlsm', 'csv', 'xlsx']
    const fileExtension = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)

    if (allowedExtensions.includes(fileExtension.toLowerCase())) {
      reader.readAsDataURL(file)
      /* Fetch */
      
      const formData = new FormData()
      formData.append('resources-file', file)
      formData.append('company', currentCompany)
      const filePreviewResponse = await fetch(`${API}/resources/${currentCompany}/file-preview`, {
        method: 'POST',
        body: formData
      })
      const data = await filePreviewResponse.json()
      if (filePreviewResponse.status === 200){
        setCheckResources(true)
        setInvalidResources(data["invalid_emails"])
        setRepeatedResources(data["conflicted_resources"])
        setOriginalRepeatedResources(data["conflicted_resources"])
        setNewResources(data["new_resources"])
        setIncompleteResources(data["incomplete_resources"])
        setRepeatedEmails(data['repeated_emails'])
        setError('')
        setSuccess('Your resources file has been updated')
      } else {
        console.error(data)
      }
    } else {
      setSuccess('')
      setError('Please upload only a XLS, XLSM, CSV or XLSX file')
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    if (!dragging) {
      setDragging(true)
    }
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setDragging(false)
  }

  const setResourcesFile = (e, file) => {
    console.log(file)
    setSuccess('Your resources file has been updated')
    handleDrop({
      preventDefault: () => { },
      dataTransfer: {
        files: [file || e.target.files[0]],
      },
    })
  }

  /* Edit invalid resources */
  const handleEditInvalidResources = (item) => {
    const index = item.index
    setInvalidResources(invalidResources.filter((resource, i) => i !== index))
    setNewResources([...newResources, item])
  }

  const handleEditRepeatedEmails = (item) => {
    const index = item.index
    setRepeatedEmails(repreatedEmails.filter((resource, i) => i !== index))
    setNewResources([...newResources, item])
  }

  /* Edit repeated resources */
  const handleEditRepeatedResources = (item) => {
    const index = item.index
    setRepeatedResources(repeatedResources.filter((resource, i) => i !== index))
    setNewResources([...newResources, item])
  }

  /* Edit new resources */
  const handleEditNewResources = (editedResource) => {
    setNewResources((prev) => {
      const updatedList = prev.map((resource) => {
        if (resource.email === editedResource.email) {
          return editedResource
        }
        return resource
      })

      return updatedList
    })
  }

  const handleEditIncompleteResources = (item) => {
    const index = item.index
    setIncompleteResources(incompleteResources.filter((resource, i) => i !== index))
    setNewResources([...newResources, item])
  }

  // Delete invalid resources
  const handleDeleteInvalidResource = (email) => {
    const updatedInvalidResources = invalidResources.filter(
      (resource) => resource.email !== email
    )
    setInvalidResources(updatedInvalidResources)
  }
  const handleDeleteRepeatedEmails = (email) => {
    const updatedRepeatedEmails = repreatedEmails.filter(
      (resource) => resource.email !== email
    )
    setRepeatedEmails(updatedRepeatedEmails)
  }

  const handleDeleteIncompleteResource = (email) => {
    const updatedIncompleteResources = incompleteResources.filter(
      (resource) => resource.email !== email
    )
    setInvalidResources(updatedIncompleteResources)
  }

  // Delete repeated resources
  const handleDeleteRepeatedResource = (email) => {
    const updatedRepeatedResources = repeatedResources.filter(
      (resource) => resource.new_resource.email !== email
    )
    setRepeatedResources(updatedRepeatedResources)
  }

  // Delete new resource
  const handleDeleteNewResource = (email) => {
    const updatedNewResources = newResources.filter(
      (resource) => resource.email !== email
    )
    setNewResources(updatedNewResources)
  }

  /* Options to handle repeated resources */
  const [selectedOption, setSelectedOption] = useState("Please select an option")
  const options = ['Update repeated resources', 'Discard repeated resources']

  const handleOption = (value) => {
    setSelectedOption(value)
    if (value === 'Discard repeated resources') {
      setRepeatedResources([])
      setUpdatedRepeatedResources(false)
    } else {
      setUpdatedRepeatedResources(true)
      setRepeatedResources(prevItems => {
        return originalRepeatedResources.map(item => {
          return item.new_resource
        })
      })
    }
  }

  /* Upload file */
  const uploadResourcesFile = async () => {
    if (invalidResources.length !== 0) {
      setError("Some resources have invalid emails, please correct or delete them")
    } else if(repreatedEmails.length !== 0){
      setError("Some emails are repeated in file")
    } else if (selectedOption === "Please select an option" && originalRepeatedResources.length !== 0) {
      setError("Please select an option to update or discard repeated resources")
    } else {
      const response = await fetch(`${API}/resources/${currentCompany}/sync`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          'new_resources': newResources,
          'updated_resources': repeatedResources
        })
      })
      if (response.status === 500) {
        setError("Something went wrong")
      } else if (response.status === 200) {
        setResourcesUploaded(true)
        setCheckResources(false)
      }
    }
  }

  /* Cancel upload resources file */
  const cancelUpload = () => {
    closeModal()
    setCheckResources(false)
    setResourcesUploaded(false)
    setError(null)
    setSuccess(null)
    setCounter(5)
  }

  /* Interval to close modal after five seconds */
  useEffect(() => {
    let interval

    if (resourcesUploaded) {
      interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1)
      }, 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [resourcesUploaded])

  useEffect(() => {
    if (counter === 0) {
      cancelUpload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter])


  /* Open modal */
  if (!isOpen) return null

  return (
    <div
      className={
        `resourcesFileModalBack flexContainer alignCenter justifyCenter 
       ${isOpen ? 'modalActive' : 'modalNoActive'}`
      }
    >
      <div className="resourcesFileModalContent flexContainer spaceBetween">
        <div
          className="
            pr1
            grayLineRight noGrayLineRightMobile
            col3
            mb2
            fullTablet flexTablet flexWrap
            fullLandscape flexLandscape spaceBetween
          "
        >
          <div className="resourcesFileStep resourcesFileStepActive">
            <span><i className="fa-solid fa-cloud-arrow-up" /></span>
            <p>Upload Your File</p>
          </div>
          <div
            className={
              `resourcesFileStep ${checkResources || resourcesUploaded ? "resourcesFileStepActive" : ""}`
            }
          >
            <span><i className="fa-solid fa-user-group" /></span>
            <p>Preview Resources</p>
          </div>
          <div className={`resourcesFileStep ${resourcesUploaded ? "resourcesFileStepActive" : ""}`}>
            <span><i className="fa-solid fa-circle-check" /></span>
            <p>Upload Resources</p>
          </div>
          <BasicButton
            color="btnBlack"
            size="btnFull"
            onClick={cancelUpload}
          >
            {resourcesUploaded ? "Close" : "Cancel"}
          </BasicButton>
        </div>
        {/* Step one */}
        {checkResources || resourcesUploaded ? null :
          <div className="col9">
            <h2 className="mb1">Upload Your File Here</h2>
            <p className="regularText fw500 mb1">
              Please upload a single XLS, XLSM, or CSV file containing your company's resources. You can download the sample file below to see the structure that your file should have.
            </p>
            {error && <div className="mb1 tac"><Warning message={error} /></div>}
            {success && <div className="mb1 tac"><Success message={success} /></div>}
            <div
              className={`dragAndDropBox basicBorder mb1 ${dragging ? 'dragging' : ''}`}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
            >
              <p className="bigText mt1">
                <i className="fa-solid fa-cloud-arrow-up lightGray" />{' '}
                Drag and Drop Your File Here
                <br/>
                <small className="lightGray">(Only XLS, XLSM & CSV)</small>
              </p>
              <p className="mtHalf mb1">or</p>
              <input
                id="browseFile"
                method="GET"
                type="file"
                accept=".xls, .xlsm, .csv, .xlsx"
                className="hide"
                formEncType="multipart/form-data"
                name="resourcesFile"
                onChange={(e) => setResourcesFile(e)}
              />
              <label
                htmlFor="browseFile"
                className="btn btnRed btnSmall cursorPointer"
              >
                Browse File <i className="fas fa-search opacity5" />
              </label>
            </div>
            <a
              href="https://dobcon-files.s3.us-east-2.amazonaws.com/exampleFile.csv"
              className="btnGhost btnFull btn"
            >
              Download Sample File <i className="fa-solid fa-download" />
            </a>
          </div>
        }

        {/* Step two */}
        {!checkResources ? null :
          <div className="col9">
            <h2 className="mb1">Check your resources file</h2>
            {invalidResources.length > 0 &&
              <p className="regularText fw500 mb1">
                <strong>Invalid emails: {invalidResources.length}</strong><br />
                Please edit or delete the resources with invalid email to upload your resources file, you can filter the next table to found them quickly.
              </p>
            }
            {repreatedEmails.length > 0 &&
              <p className="regularText fw500 mb1">
              <strong>Repeated emails: {repreatedEmails.length}</strong><br />
                Please edit or delete the resources with repeated email to upload your resources file, you can filter the next table to found them quickly.
              </p>
            }
            {(repeatedResources.length > 0 && !updatedRepeatedResources) &&
              <p className="regularText fw500 mb1">
                <strong>Repeated resources: {repeatedResources.length}</strong><br />
                Please edit or delete the resources repeated, also you can choose quick options to discard or update the existing resources with the dropdown at the bottom of this table.
              </p>
            }
            {(invalidResources.length === 0 && repeatedResources.length === 0 && repreatedEmails === 0) &&
              <p className="regularText fw500 mb1">
                No invalid emails and no repeated resources. Please check your resources before upload it, if you are sure that you want to upload this resources click on “Upload resources”
              </p>
            }
            {error && <div className="mt1 mb2"><Warning message={error} /></div>}
            <CheckResourcesTable
              /* Different type of resources */
              invalid={invalidResources}
              conflicted={repeatedResources}
              repeated={repreatedEmails}
              resources={newResources}
              updated={updatedRepeatedResources}
              incomplete={incompleteResources}
              /* Edit resources */
              handleEditInvalidResources={handleEditInvalidResources}
              handleEditRepeatedResources={handleEditRepeatedResources}
              handleEditNewResources={handleEditNewResources}
              handleEditIncompleteResources={handleEditIncompleteResources}
              handleEditRepeatedEmails={handleEditRepeatedEmails}
              /* Delete resources */
              handleDeleteInvalidResource={handleDeleteInvalidResource}
              handleDeleteRepeatedResource={handleDeleteRepeatedResource}
              handleDeleteNewResource={handleDeleteNewResource}
              handleDeleteIncompleteResource={handleDeleteIncompleteResource}
              handleDeleteRepeatedEmails={handleDeleteRepeatedEmails}
            />
            {originalRepeatedResources.length > 0 &&
              <DarkSelectInput
                className="darkSelectInputMini"
                options={options}
                value={selectedOption}
                onChange={handleOption}
              />
            }
            <BasicButton
              color="btnRed"
              size="btnFull"
              onClick={uploadResourcesFile}
            >
              Upload resources
            </BasicButton>
          </div>
        }


        {/* Step three */}
        {resourcesUploaded &&
          <div className="col9 tac flexContainer alignCenter justifyCenter">
            <div className="col10">
              <i className="fa-regular fa-circle-check green bigTitle" />
              <p className="smallTitle mt1 mb1">
                Your resources file was uploaded successfully. Please close this window or wait for {counter} seconds, and it will close automatically.
              </p>
              <BasicButton
                color="btnRed"
                size="btnFull"
                onClick={cancelUpload}
              >
                Close window
              </BasicButton>
            </div>
          </div>
        }

      </div>
    </div>
  )
}

export default ResourcesFileModal