import './DarkInput.css'

const DarkInput = ({
  label,
  type,
  value,
  onChange,
  placeholder,
  className,
  onKeyDown,
  onKeyUp,
  textarea,
  ...rest
}) => {
  return (
    <div className={`darkInput ${className}`}>
      {label && <label className="smallText">{label}</label>}
      {
        textarea ?
          <textarea
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            onKeyDown={onKeyDown} {...rest}
          /> :
          <input
            type={type}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            onKeyDown={onKeyDown} {...rest}
          />
      }
    </div>
  )
}

export default DarkInput