const generateTest = () => {
  const contributions = ["XX", "XY", "YX", "YY"];
  const contributionsReleaseAvgLeadTimePerDay = [];
  const today = new Date();
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day

  for (let i = 0; i < 30; i++) {
    const date = new Date(today.getTime() - i * oneDay);
    const dateString = date.toISOString().split('T')[0]; // format YYYY-MM-DD

    contributions.forEach(contribution => {
      contributionsReleaseAvgLeadTimePerDay.push({
        date: dateString,
        code: Math.floor(Math.random() * 6),
        test: Math.floor(Math.random() * 6),
        build: Math.floor(Math.random() * 6),
        integration: Math.floor(Math.random() * 6),
        deploy: Math.floor(Math.random() * 6),
        monitor: Math.floor(Math.random() * 6),
        contribution: contribution
      });
    });
  }

  return {
    initiative: "12XY",
    contributions_release_avg_lead_time_per_day: contributionsReleaseAvgLeadTimePerDay
  };
};

export default generateTest;

