import './SearchBar.css'

const SearchBar = ({ onChange, value, placeholder, className }) => {
  return (
    <div className={`searchBar ${className}`}>
      <span className="searchBarIcon">
        <i className="fa-solid fa-magnifying-glass" />
      </span>
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  )
}

export default SearchBar