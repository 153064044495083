import React, { useEffect, useState } from 'react'
import './CompaniesTable.css'
import StatusPill from '../pills/StatusPill'
import BasicButton from '../buttons/BasicButton'
import BasicModal from '../modals/BasicModal'
import BasicOptionsButton from '../buttons/BasicOptionsButton'
import FormModal from '../modals/FormModal'
import BasicInput from '../forms/BasicInput'
import Warning from '../alerts/Warning'
import SelectInput from '../forms/SelectInput'
import '../forms/BasicInput.css'

const LICENSESAPI = process.env.REACT_APP_LICENCES_API
const SETUPAPI = process.env.REACT_APP_SETUP_API

const CompaniesTable = () => {
  const [error, setError] = useState('')

  /* Status options 
  Ok : Active and paid account
  Deleted : Data was already deleted for this company (can be deleted from list)
  Inactive: Last login > 2 months
  Suspended: Payment pending
  Trial :  Companies with trial (3 months)
  Expired trial: Companies with trial expired
  Free : Companies with free licenses (firendly clients)
  */
  const [currentStatus, setCurrentSatus] = useState(null)
  const statusOptions = ['Ok', 'Inactive', 'Suspended', 'Trial', 'Expired Trial', 'Free']
  const handleSelectStatusOption = (new_status) => {
    setCurrentSatus(new_status)
  }
  const [isOpenModalStatus, setIsOpenModalStatus] = useState(false)
  const closeModalStatus = () => {
    setError('')
    setSelectedCompany(null)
    setCurrentSatus(null)
    setIsOpenModalStatus(false)
  }
  const handleChangeCompanyStatus = (company, status) => {
    setSelectedCompany(company)
    setCurrentSatus(status)
    setIsOpenModalStatus(true)
  }
  const confirmChangeCompanyStatus = async (e) => {
    e.preventDefault()
    try{
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        accept: 'application/json',
        body: JSON.stringify({
          status: currentStatus
        })
      }
      const response = await fetch(`${LICENSESAPI}/${selectedCompany}/status`, requestOptions)
      const data = await response.json()
      if (response.status === 200) {
        getCompanies()
        closeModalStatus()
        
      } else {
        console.error(data)
        setError('Error while editing company status')
      } 
    } catch(e){
      setError('Error while editing company status')
    }
     
  }

  
  /* Actions options */
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [openMenuId, setOpenMenuId] = useState(null)
  const createActionsOptions = (status) => {
    if (status === 'Deleted'){
      return ['Delete from List']
    } else {
      return ['Add Licenses', 'Delete Resources', 'Delete Data', 'Change Status']
    }
  }
  const handleSelectActionsOption = (value, company, status) => {
    if (value === 'Add licenses') {
      setSelectedCompany(company)
      openAddLicensesModal()
    } else if (value === 'Delete Data') {
      handleDeleteCompanyData(company)
    } else if (value === 'Delete Resources'){
      handleDeleteCompanyResources(company)
    } else if (value === 'Delete from List'){
      handleDeleteCompanyRecord(company)
    } else if (value === 'Change Status'){
      handleChangeCompanyStatus(company, status)
    }
  }
  const handleMenuToggle = (company) => {
    setOpenMenuId(openMenuId === company ? null : company)
  }
  const handleClickOutside = () => {
    setOpenMenuId(null)
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  /* Get Companies Licenses information */
  const [companies, setCompanies] = useState([])
  const getCompanies = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    const response = await fetch(`${LICENSESAPI}/history`, requestOptions)
    const data = await response.json()
    if (response.status === 200) {
      setCompanies(data)
    }
  }
  useEffect(() => {
    getCompanies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Delete Company Resources */
  const [isOpenModalDeleteResources, setIsOpenModalDeleteResources] = useState(false)
  const closeModalDeleteResources = () => {
    setError('')
    setSelectedCompany(null)
    setIsOpenModalDeleteResources(false)
  }
  const handleDeleteCompanyResources = (company) => {
    setSelectedCompany(company)
    setIsOpenModalDeleteResources(true)
  }
  const confirmDeleteCompanyResources = async (e) => {
    e.preventDefault()
    try{
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        accept: 'application/json',
      }
      const response = await fetch(`${SETUPAPI}/admin/${selectedCompany}/resources`, requestOptions)
      const data = await response.json()
      if (response.status === 200) {
        setIsOpenModalDeleteResources(false)
        closeModalDeleteResources()
        getCompanies()
        setError('')
        console.log(data)
      } else {
        setError('Error while deleting company resources')
        console.error(data)
      } 
    } catch(e){
      console.error('Error while deleting company resoruces')
    }
     
  }

  /* Delete Company Data */
  const [isOpenModalDeleteData, setIsOpenModalDeleteData] = useState(false)
  const closeModalDeleteData = () => {
    setError('')
    setSelectedCompany(null)
    setIsOpenModalDeleteData(false)
  }
  const handleDeleteCompanyData = (company) => {
    setSelectedCompany(company)
    setIsOpenModalDeleteData(true)
  }
  const confirmDeleteCompanyData = async (e) => {
    e.preventDefault()
    try{
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        accept: 'application/json',
      }
      const response = await fetch(`${SETUPAPI}/admin/${selectedCompany}/data`, requestOptions)
      const data = await response.json()
      if (response.status === 200) {
        const response2 = await fetch(`${LICENSESAPI}/${selectedCompany}/delete`, requestOptions)
        const data2 = await response2.json()
        console.log(data)
        if (response2.status === 200) {
          setIsOpenModalDeleteData(false)
          closeModalDeleteData()
          getCompanies()
          setError('')
          console.log(data2)
        } else {
          setError('Error while deleting record')
          console.error(data2)
        } 
      } else {
        console.error(data)
        setError('Error while deleting record')
      } 
    } catch(e){
      console.error(e)
    }
       
  }

  /* Delete Company Purchase Record */
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const closeModalDelete = () => {
    setError('')
    setSelectedCompany(null)
    setIsOpenModalDelete(false)
  }
  const handleDeleteCompanyRecord = (company) => {
    setSelectedCompany(company)
    setIsOpenModalDelete(true)
  }
  const confirmDeleteCompanyRecord = async (e) => {
    e.preventDefault()
    try{
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        accept: 'application/json',
      }
      const response = await fetch(`${LICENSESAPI}/history/${selectedCompany}`, requestOptions)
      const data = await response.json()
      if (response.status === 200) {
        setIsOpenModalDelete(false)
        closeModalDelete()
        getCompanies()
        console.log(data)
      } else {
        console.error('Error while deleting record')
      }   

    } catch{
      console.error('Error while deleting record')
    }
     
  }

  const licenseTypeOptions = ['1-year', '2-year', 'demo']
  /* Create Company Modal */
  const [isOpenCreateCompanyModal, setIsOpenCreateCompanyModal] = useState(false)
  const newCompanyData = {
    client: '',
    contact: '',
    company: '',
    no_licenses: '',
    license_type: 'Select license type',
  }
  const [addCompanyData, setAddCompanyData] = useState(newCompanyData) // New company form
  const handleCreateCompanyInputChange = (field, value) => {
    setAddCompanyData({
      ...addCompanyData,
      [field]: value,
    })
  }
  const closeCreateCompanyModal = () => {
    setError('')
    setIsOpenCreateCompanyModal(false)
    setAddCompanyData(newCompanyData)
  }
  const openCreateCompanyModal = () => {
    setIsOpenCreateCompanyModal(true)
  }
  const handleCreateCompany = async (e) => {
    e.preventDefault()
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i

    if (!addCompanyData.company) {
      setError("Please enter the company name")
    } else if (!addCompanyData.no_licenses) {
      setError("Please enter the number of licenses")
    } else if (!addCompanyData.client) {
      setError("Please enter the client's name")
    } else if (!addCompanyData.contact) {
      setError("Please enter the client's email")
    } else if (!regex.test(addCompanyData.contact)) {
      setError("Please enter a valid email")
    } else if (!addCompanyData.license_type) {
      setError("Please select a license type")
    }  else {
      try{
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          accept: 'application/json',
          body: JSON.stringify({
            client: addCompanyData.client,
            contact: addCompanyData.contact,
            company: addCompanyData.company,
            no_licenses: parseInt(addCompanyData.no_licenses),
            license_type: addCompanyData.license_type,
          }),
        }
        const response = await fetch(`${LICENSESAPI}`, requestOptions)
        const data = await response.json()
        
        if (response.status === 200) {
          console.log(data)
          setIsOpenCreateCompanyModal(false)
          openSuperuserModal()
        } else {
          console.error(data)
          setError("Error while creating company")
        }
      } catch{
        setError("Error while creating company")
      }
    }
  }

  /* Add Licenses to Company modal */
  const [isOpenAddLicensesModal, setIsOpenAddLicensesModal] = useState(false)
  const emptyAddLicensesData = {
    license_type: 'Select license type',
    no_licenses: ''
  }
  const [addLicensesData, setAddLicensesData] = useState(emptyAddLicensesData)
  const closeAddLicensesModal = () => {
    setError('')
    setIsOpenAddLicensesModal(false)
    setAddLicensesData(emptyAddLicensesData)
    setSelectedCompany(null)
  }
  const handleAddLicensesInputChange = (field, value) => {
    setAddLicensesData({
      ...addLicensesData,
      [field]: value,
    })
  }
  const openAddLicensesModal = () => {
    setIsOpenAddLicensesModal(true)
  }

  const handleAddCompanyLicenses = async (e) => {
    e.preventDefault()

    if (!addLicensesData.no_licenses) {
      setError("Please enter the number of licenses")
    } else if (!addLicensesData.license_type || addLicensesData.license_type === "Select license type") {
      setError("Please select a license type")
    } else {
      try{
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          accept: 'application/json',
          body: JSON.stringify({
            no_licenses: parseInt(addLicensesData.no_licenses),
            license_type: addLicensesData.license_type,
          }),
        }
        const response = await fetch(`${LICENSESAPI}/history/${selectedCompany}`, requestOptions)
        const data = await response.json()
        if (response.status === 200) {
          closeAddLicensesModal()
          getCompanies()
        } else {
          setError('Error adding licenses to company')
        }
      } catch{
        setError('Error adding licenses to company')
      }
      
    }  
  }
  const greenStatuses = ['Ok', 'Trial', 'Free']
  const yellowStatuses = ['Inactive', 'Suspended', 'Expired Trial']

  // Assing Super User
  const [superUserData, setSuperUserData] = useState({
    name: '',
    email: '',
    department: ''
  })
  const [isOpenSuperuserModal, setIsOpenSuperuserModal] = useState(false)
  const openSuperuserModal = () => {
    setIsOpenSuperuserModal(true)
  }
  const closeSuperuserModal = () => {
    setSuperUserData({
      name: '',
      email: '',
      department: ''
    })
    setError('')
    setIsOpenSuperuserModal(false)
  }
  const handleSuperuserInputChange = (field, value) => {
    setSuperUserData({
      ...superUserData,
      [field]: value,
    })
  }
  const submitAssignSuperuser = async(e) => {
    e.preventDefault()
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i

    if (!superUserData.name) {
      setError("Please enter the name")
    } else if (!superUserData.department) {
      setError("Please enter a department")
    } else if(!superUserData.email){
      setError("Please enter an email")
    } else if (!regex.test(superUserData.email)) {
      setError("Please enter a valid email")
    } else {
      try{
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          accept: 'application/json',
          body: JSON.stringify({
            name: superUserData.name,
            department: superUserData.department,
            email: superUserData.email
          }),
        }
        const response = await fetch(`${LICENSESAPI}/${addCompanyData.company}/superuser`, requestOptions)
        const data = await response.json()
        if (response.status === 200) {
          console.log(data)
          closeAddLicensesModal()
          closeSuperuserModal()
          getCompanies()
        } else {
          console.error(data)
          setError('Error while assigning super user')
        }
      } catch{
        setError('Error while assigning super user')
      }
      
    }  
  }


  /* Add Licenses to Company modal */
  return (
    <>
      <div className="companiesTable">
        <div className="companiesTableBody">
          <table>
            <thead className="companiesTableHead">
              <tr>
                <th>Company</th>
                <th className="columnMedium">Super User</th>
                <th className="columnMedium">Contact Name</th>
                <th className="columnMedium">Contact Email</th>
                <th className="columnMedium">No. Licenses</th>
                <th className="columnMedium tac">Status</th>
                <th className="columnMedium tac">Actions</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => (
                <tr key={company.company}>
                  <td>{company.company}</td>
                  <td>{company.super_user ? company.super_user : 'Not yet assigned'}</td>
                  <td>{company.client}</td>
                  <td>{company.contact}</td>
                  <td className='columnMedium'>{company.no_licenses}</td>
                  <td>
                    {greenStatuses.includes(company.status) && <StatusPill status="success" message={company.status} />}
                    {company.status === "Deleted" && <StatusPill status="fail" message={company.status} />}
                    {yellowStatuses.includes(company.status) && <StatusPill status="pending" message={company.status} />}
                  </td>
                  <td>
                    <BasicOptionsButton
                      options={createActionsOptions(company.status)}
                      onChange={(value) => handleSelectActionsOption(value, company.company, company.status)}
                      isOpen={openMenuId === company.company}
                      onToggle={() => handleMenuToggle(company.company)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="companiesTableFooter">
          <BasicButton
            color="btnRed"
            size="btnSmall"
            onClick={openCreateCompanyModal}
          >
            Add New Company
          </BasicButton>
        </div>
      </div>

      {/* Prevent purchase history record delete modal */}
      <BasicModal isOpen={isOpenModalDelete} onClose={closeModalDelete}>
        <h2 className="mb1 tac">Are you sure? </h2>
        <p className="mb2 tac">You will not be able to see "{selectedCompany}" information again.</p>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={confirmDeleteCompanyRecord}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={closeModalDelete}
        >
          Cancel
        </BasicButton>
      </BasicModal>
      {/* Prevent company data delete modal */}
      <BasicModal isOpen={isOpenModalDeleteData} onClose={closeModalDeleteData}>
        <h2 className="mb2 tac">Are you sure?</h2>
        <p className="mb2 tac">This action will delete all data assciated with "{selectedCompany}", including:</p>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={confirmDeleteCompanyData}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={closeModalDeleteData}
        >
          Cancel
        </BasicButton>
      </BasicModal>

      {/* Prevent company resources delete modal */}
      <BasicModal isOpen={isOpenModalDeleteResources} onClose={closeModalDeleteResources}>
        <h2 className="mb2 tac">Are you sure that you want to delete resources for {selectedCompany}?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={confirmDeleteCompanyResources}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={closeModalDeleteResources}
        >
          Cancel
        </BasicButton>
      </BasicModal>
      
      {/* Modal for creating new company */}
      <FormModal isOpen={isOpenCreateCompanyModal} onClose={closeCreateCompanyModal}>
        <>
          <h2 className="mb2">Create Company Licenses</h2>
          {error && <div className="mt-1 mb2"><Warning message={error} /></div>}
          <form>
            <div className="flexContainer spaceBetween">
              <div className="col6">
                <BasicInput
                  label="Company*"
                  type="text"
                  value={addCompanyData.company}
                  onChange={(value) => handleCreateCompanyInputChange('company', value)}
                  placeholder="Company name"
                />
              </div>
              <div className="col6">
                <BasicInput
                  label="Licenses to create*"
                  type="number"
                  value={addCompanyData.no_licenses}
                  onChange={(value) => handleCreateCompanyInputChange('no_licenses', value)}
                  placeholder="Number of licenses"
                />
              </div>
            </div>
            <div className="flexContainer spaceBetween">
              <div className="col6">
                <BasicInput
                  label="Client*"
                  type="text"
                  value={addCompanyData.client}
                  onChange={(value) => handleCreateCompanyInputChange('client', value)}
                  placeholder="Client's name"
                />
              </div>
              <div className="col6">
                <BasicInput
                  label="Email*"
                  type="text"
                  value={addCompanyData.contact}
                  onChange={(value) => handleCreateCompanyInputChange('contact', value)}
                  placeholder="Client's Email"
                />
              </div>
            </div>
            <SelectInput
              label="License Type*"
              options={licenseTypeOptions}
              value={addCompanyData.license_type}
              onChange={(value) => handleCreateCompanyInputChange('license_type', value)}
            />
            <p className="lightGray tal mt-1 mb1">
              <small>*Mandatory Fields</small>
            </p>
            <BasicButton
              color="btnRed"
              size="btnFull"
              onClick={handleCreateCompany}
            >
              Create Company
            </BasicButton>
            <div className="mb1" />
            <BasicButton
              color="btnBlack"
              size="btnFull"
              onClick={closeCreateCompanyModal}
            >
              Cancel
            </BasicButton>
          </form>
        </>
      </FormModal>
      <FormModal isOpen={isOpenSuperuserModal} onClose={closeSuperuserModal}>
        <>
          <h2 className="mb2">Create Super User</h2>
          {error && <div className="mt-1 mb2"><Warning message={error} /></div>}
          <form>
            <div className="flexContainer spaceBetween">
              <div className="col6">
                <BasicInput
                  label="Name*"
                  type="text"
                  value={superUserData.name}
                  onChange={(value) => handleSuperuserInputChange('name', value)}
                  placeholder="User's name"
                />
              </div>
              <div className="col6">
                <BasicInput
                  label="Department*"
                  type="text"
                  value={superUserData.department}
                  onChange={(value) => handleSuperuserInputChange('department', value)}
                  placeholder="User's department"
                />
              </div>
            </div>
            <BasicInput
              label="Email address (Case sensitive)*"
              type="text"
              value={superUserData.email}
              onChange={(value) => handleSuperuserInputChange('email', value)}
              placeholder="User's email"
            />
            <p className="lightGray tal mt-1 mb1">
              <small>*Mandatory Fields</small>
            </p>
            <BasicButton
              color="btnRed"
              size="btnFull"
              onClick={submitAssignSuperuser}
            >
              Create Super User
            </BasicButton>
            <div className="mb1" />
            <BasicButton
              color="btnBlack"
              size="btnFull"
              onClick={closeSuperuserModal}
            >
              Cancel
            </BasicButton>
          </form>
        </>
      </FormModal>
      
      {/* Modal for adding licenses to company */}
      <FormModal isOpen={isOpenAddLicensesModal && selectedCompany} onClose={closeAddLicensesModal}>
        <>
          <h2 className="mb2">Add Company Licenses</h2>
          {error && <div className="mt-1 mb2"><Warning message={error} /></div>}
          <form>
            <div className="flexContainer spaceBetween">
              <div className="col6">
                <div className={`basicInput`}>
                  <label className="smallText">Company</label>
                  <input
                    value={selectedCompany}
                    disabled={true}
                  />
                </div>  
              </div>
              <div className="col6">
                <BasicInput
                  label="Licenses to create*"
                  type="number"
                  value={addLicensesData.no_licenses}
                  onChange={(value) => handleAddLicensesInputChange('licenses_no', value)}
                  placeholder="Number of Licenses"
                />
              </div>
            </div>
            <SelectInput
              label="License Type*"
              options={licenseTypeOptions}
              value={addLicensesData.license_type}
              onChange={(value) => handleAddLicensesInputChange('license_type', value)}
            />
            <p className="lightGray tal mt-1 mb1">
              <small>*Mandatory Fields</small>
            </p>
            <BasicButton
              color="btnRed"
              size="btnFull"
              onClick={handleAddCompanyLicenses}
            >
              Add Licenses
            </BasicButton>
            <div className="mb1" />
            <BasicButton
              color="btnBlack"
              size="btnFull"
              onClick={closeAddLicensesModal}
            >
              Cancel
            </BasicButton>
          </form>
        </>
      </FormModal>
      {/* Moda for changing company status */}
      <FormModal isOpen={isOpenModalStatus} onClose={closeModalStatus}>
        <>
          <h2 className="mb2">Change Company Status</h2>
          {error && <div className="mt-1 mb2"><Warning message={error} /></div>}
          <form>
            <SelectInput
              label="New Status*"
              options={statusOptions}
              value={currentStatus}
              onChange={(value) => handleSelectStatusOption(value)}
            />
            <p className="lightGray tal mt-1 mb1">
              <small>*Mandatory Fields</small>
            </p>
            <BasicButton
              color="btnRed"
              size="btnFull"
              onClick={confirmChangeCompanyStatus}
            >
              Save Status
            </BasicButton>
            <div className="mb1" />
            <BasicButton
              color="btnBlack"
              size="btnFull"
              onClick={closeModalStatus}
            >
              Cancel
            </BasicButton>
          </form>
        </>
      </FormModal>
    </>
  )
}

export default CompaniesTable