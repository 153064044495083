import React from 'react'
import CurrentDate from '../../components/resources/CurrentDate'
import BusinessNavFilter from '../../components/nav/BusinessNavFilter'
import BusinessMetricsTable from '../../components/tables/BusinessMetricsTable'
import { useInitiativesContext } from './resources/BusinessInitiativesContext'
import InitiativePill from '../../components/pills/InitiativePill'

const BusinessDashboard = () => {
  /* Initiatives */
  const initiatives = useInitiativesContext()
  
  return (
    <div className="containerToRight">
      <h1>
        <span className="lightGray">DOBCO<span className="bigN-h1">n</span></span> Business Dashboard
      </h1>
      <p className="mediumText mt1 fw500">Unlock Insights into Your Business Initiatives</p>
      <div className="tar">
        <CurrentDate />
        <hr className="grayLine mt1" />
      </div>
      <div className="flexContainer spaceBetween pt1 pb1">
        <div className="col2">
          <BusinessNavFilter
            links={[
              'Initiative Resources',
              'Initiative Start Date',
              'Initiative Status',
              'Location',
              'Total Initiative Cost'
            ]}
            activeSection="Business Metrics"
          />
        </div>
      </div>
      <hr className="grayLine" />
      <div className="flexContainer pt1">
        {initiatives.length !== 0 ? (
          initiatives.map((initiative) => (
            <InitiativePill
              key={initiative.name}
              pillTitle={initiative.name}
              pillLink={initiative.name}
            />
          ))
        ) : (
          <p className="fw500 mb1">No initiatives Found</p>
        )}
      </div>
      <hr className="grayLine mb2" />
      <BusinessMetricsTable initiatives={initiatives} />
    </div>
  )
}

export default BusinessDashboard