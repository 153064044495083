import React, { useState } from "react";
import './IntelligentReportWidget.css';
import IntelligentReportExpanded from "./IntelligentReportExpanded";
import FeedbackModal from "./FeedbackModal";
import InifinityLoader from "./InfinityLoader";

const IntelligentReportWidget = () => {
  const [isWidgetVisible, setIsWidgetVisible] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const openModal = () => {
    setIsLoading(true); // Start the loading process
    setTimeout(() => {
      setIsLoading(false); // Stop loading after 2 seconds
      setIsModalVisible(true); // Show the modal
    }, 2000); // Simulate a loading delay of 2 seconds
    setIsWidgetVisible(false)
  };

  const closeModal = () => {
    setIsModalVisible(true);
    setIsFeedbackOpen(true)
    setIsWidgetVisible(false)
  };

  const closeFeedbackModal = () => {
    setIsFeedbackOpen(false)
    setIsWidgetVisible(true)
  }

  return (
    <div>
      {isWidgetVisible && 
        <div 
          className="intelligent-report-widget"
          onClick={openModal}
          title="Click to view the Intelligent Report"
        >
          <img src="/img/icons/ai-report/devops Health Report-55.png" className="widget-icon"/>
        </div>
      }

        
      
      {isLoading && (
        /* 
        <div className="loading-spinner">
          <InifinityLoader/>
        </div>
        */
        <div className="loading-spinner">
          <img src="/img/icons/ai-report/Comp 1.gif" alt="Loading..." className="gif-loader"/>
        </div>
        
      )}

      {isModalVisible && <IntelligentReportExpanded closeModal={closeModal}/>}

      {isFeedbackOpen && <FeedbackModal closeModal={closeFeedbackModal}/>}

      
    </div>
  );
};

export default IntelligentReportWidget;
