import React, { useEffect, useState } from 'react'
import './InitiativesTable.css'
import StatusPill from '../pills/StatusPill'
import BasicButton from '../buttons/BasicButton'
import BasicModal from '../modals/BasicModal'
import { useNavigate } from 'react-router-dom'
import BasicOptionsButton from '../buttons/BasicOptionsButton'

const API = process.env.REACT_APP_SETUP_API

const InitiativesTable = () => {
  const editOptions = ['Initiative setup', 'Toolchain setup', 'Tools credentials']
  let navigate = useNavigate()
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Initiatives */
  const [initiatives, setInitiatives] = useState([])

  /* Get initiatives */
  const getCompanyInitiatives = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    const response = await fetch(`${API}/initiatives/${currentCompany}/metrics`, requestOptions)
    const data = await response.json()
    if (response.status === 200) {
      setInitiatives(data)
    }

  }

  /* Edit options */
  const [openMenuId, setOpenMenuId] = useState(null)

  const handleSelectEditOption = (value, initiative_id) => {
    if (value === 'Initiative setup') {
      navigate(`/${btoa('initiative-setup')}`, { state: { initiative: initiative_id, mode: 'edit' } })
    } else if (value === 'Tools credentials') {
      navigate(`/${btoa('toolchain-config')}`, { state: { initiative: initiative_id, mode: 'edit' } })
    } else if (value === 'Toolchain setup') {
      navigate(`/${btoa('toolchain-setup')}`, { state: { initiative: initiative_id, mode: 'edit' } })
    }
  }

  const handleMenuToggle = (initiativeId) => {
    setOpenMenuId(openMenuId === initiativeId ? null : initiativeId)
  }

  const handleClickOutside = () => {
    setOpenMenuId(null)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    getCompanyInitiatives()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Delete initiative */
  const [selectedInitiative, setSelectedInitiative] = useState(null)
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)

  const closeModalDelete = () => {
    setSelectedInitiative(null)
    setIsOpenModalDelete(false)
  }

  const handleDeleteInitiative = (initiative_id) => {
    setSelectedInitiative(initiative_id)
    setIsOpenModalDelete(true)
  }

  const confirmDeleteInitiative = async () => {
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      }
      const response = await fetch(
        `${API}/initiatives/${selectedInitiative}`, requestOptions
      )
      if (response.status === 200) {
        setIsOpenModalDelete(false)
        closeModalDelete()
        getCompanyInitiatives()
      }

    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <div className="initiativesTable">
        <div className="initiativesTableBody">
          <table>
            <thead className="initiativesTableHead">
              <tr>
                <th>Initiative Name</th>
                <th className="columnMedium">Leader</th>
                <th className="columnSmall tac">Status</th>
                <th className="columnSmall tac">Delete</th>
                <th className="columnSmall tac">Edit</th>
              </tr>
            </thead>
            <tbody>
              {initiatives.map((initiative) => (
                <tr key={initiative.id}>
                  <td>{initiative.name}</td>
                  <td className="columnMedium">{initiative.leader}</td>
                  <td>
                    {initiative.delayed === "Yes" && <StatusPill status="fail" message="Delayed" />}
                    {initiative.delayed === "No" && <StatusPill status="success" message="On time" />}
                  </td>
                  <td className="columnSmall tac">
                    <button onClick={() => handleDeleteInitiative(initiative.id)}>
                      <i className="fa-solid fa-trash" />
                    </button>
                  </td>
                  <td className="columnSmall tac">
                    {/*<button onClick={() => handleEditCredentials(initiative.name)}>
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                    */}
                    <BasicOptionsButton
                      options={editOptions}
                      onChange={(value) => handleSelectEditOption(value, initiative.id)}
                      isOpen={openMenuId === initiative.id}
                      onToggle={() => handleMenuToggle(initiative.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot className="initiativesTableFoot">
              <tr>
                <td colSpan={5}>
                  <BasicButton
                    color="btnRed"
                    size="btnSmall"
                    as="link"
                    to="initiative-setup"
                  >
                    New Initiative
                  </BasicButton>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      {/* Prevent intiative delete modal */}
      <BasicModal isOpen={isOpenModalDelete} onClose={closeModalDelete}>
        <h2 className="mb2 tac">Are you sure that you want to delete this initiative?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={confirmDeleteInitiative}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={closeModalDelete}
        >
          Cancel
        </BasicButton>
      </BasicModal>
    </>
  )
}

export default InitiativesTable