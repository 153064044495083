import React, { useState } from 'react'
import CurrentDate from '../../components/resources/CurrentDate'
import BusinessNavFilter from '../../components/nav/BusinessNavFilter'
import BusinessInitiativeFilter from '../../components/filters/BusinessInitiativeFilter'
import { useInitiativesContext } from './resources/BusinessInitiativesContext'
import InitiativePill from '../../components/pills/InitiativePill'
import BusinessMetricsTable from '../../components/tables/BusinessMetricsTable'
import BusinessFilterPill from '../../components/pills/BusinessFilterPill'

const BusinessLocation = () => {
  /* Initiatives */
  const initiatives = useInitiativesContext()

  /* Options for filter */
  const [onlyOneLocation, setOnlyOneLocation] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState([])

  /* Extract locations from initiatives and seting the filter options */
  const extractUniqueLocations = (initiatives) => {
    const locations = initiatives.map((initiative) => initiative.location)
    const uniqueLocations = [...new Set(locations)]
    const filteredLocations = uniqueLocations.filter((location) => location.trim() !== '')
    return filteredLocations
  }

  const locations = extractUniqueLocations(initiatives)
  const options = locations.length ? locations : ['No locations found']

  /* Filtering by location */
  const handleOneLocation = (location) => {
    if (onlyOneLocation === location) {
      setOnlyOneLocation(null)
    } else {
      setOnlyOneLocation(location)
    }
  }

  const handleLocationSelect = (location) => {
    if (selectedLocation.includes(location)) {
      setSelectedLocation(selectedLocation.filter((item) => item !== location))
    } else {
      setSelectedLocation([...selectedLocation, location])
    }
  }

  const handleLocationDelete = (location) => {
    setSelectedLocation(selectedLocation.filter((item) => item !== location))
    if(onlyOneLocation === location) {
      setOnlyOneLocation(null)
    }
  }


  const filteredInitiatives = initiatives.filter((initiative) => {
    /* Filtering by location */
    if (selectedLocation.length === 0) {
      return true
    }

    if (onlyOneLocation) {
      return initiative.location === onlyOneLocation
    }

    return selectedLocation.includes(initiative.location)
  })

  return (
    <div className="containerToRight">
      <h1>
        <span className="lightGray">DOBCO<span className="bigN-h1">n</span></span> Business per Location
      </h1>
      <p className="mediumText mt1 fw500">Filter All Your Initiatives by Location</p>
      <div className="tar">
        <CurrentDate />
        <hr className="grayLine mt1" />
      </div>
      <div className="flexContainer spaceBetween pt1">
        <div className="col2 col6Tablet mb1">
          <BusinessNavFilter
            links={[
              'Business Metrics',
              'Initiative Resources',
              'Initiative Start Date',
              'Initiative Status',
              'Total Initiative Cost'
            ]}
            activeSection="Location"
          />
        </div>
        <div className="col2 col6Tablet mb1">
          <BusinessInitiativeFilter
            options={options}
            onChange={handleLocationSelect}
          />
        </div>
        <div className="col8 flexContainer filterContainer">
          {selectedLocation.map((location) => (
            <div key={location}>
              <BusinessFilterPill
                pillTitle={location}
                pillDelete={() => handleLocationDelete(location)}
                pillSelected={() => handleOneLocation(location)}
                pillDisabled={onlyOneLocation && location !== onlyOneLocation}
                isPillSelected={location === onlyOneLocation}
              />
            </div>
          ))}
        </div>
      </div>
      <hr className="grayLine" />
      <div className="flexContainer pt1">
        {filteredInitiatives.length !== 0 ? (
          filteredInitiatives.map((initiative) => (
            <InitiativePill
              key={initiative.name}
              pillTitle={initiative.name}
              pillLink={initiative.name}
            />
          ))
        ) : (
          <p className="fw500 mb1">No initiatives Found</p>
        )}
      </div>
      <hr className="grayLine mb2" />
      <BusinessMetricsTable initiatives={filteredInitiatives} />
    </div>
  )
}

export default BusinessLocation