import axios from 'axios';

const API_URL = '/api/business';

const postTcoData = async (data) => {
  try {
    const response = await axios.post(API_URL, data);
    return response.data;
  } catch (error) {
    console.error('Error posting TCO data', error);
    throw error;
  }
};

export default {
  postTcoData,
};
