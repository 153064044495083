import './DobconAdoption.css'
import AdoptionCard from '../../components/cards/AdoptionCard'
import adoptioncardsjson from './adoptioncards.json'
import { useEffect, useState } from 'react'
import CurrentDate from '../../components/resources/CurrentDate'

const DobconAdoption = () => {
  const [adoptionCards, setAdoptionCards] = useState(adoptioncardsjson)
  const [storedAdoptionCards, setStoredAdoptionCards] = useState(null)
  const [componentMounted, setComponentMounted] = useState(false)

  /* Save video progress */
  const handleSaveVideoProgress = (cardTitle, currentTime) => {
    // Get a copy of the original array
    const updatedAdoptionCards = [...adoptionCards]
    // Search the corresponging name card in JSON file
    const cardToUpdate = updatedAdoptionCards
      .flatMap(section => section.cards)
      .find(card => card.cardTitle === cardTitle)
    // Update the video progress
    if (cardToUpdate) {
      cardToUpdate.videoProgress = currentTime
    }
    // Update the state with the new JSON
    setAdoptionCards(updatedAdoptionCards)

    /* Save the updated state in localStorage as JSON */
    localStorage.setItem('savedAdoptionCards', JSON.stringify(updatedAdoptionCards))
  }

  /* When a video ends */
  const handleVideoEnded = (sectionIndex, cardIndex) => {
    /* Get a copy of the original array */
    const updatedAdoptionCards = [...adoptionCards]
    /* Set the video as ended and update the state in adoptionCards */
    updatedAdoptionCards[sectionIndex].cards[cardIndex].videoEnded = true
    setAdoptionCards(updatedAdoptionCards)
    /* Save the updated state in localStorage as JSON */
    localStorage.setItem('savedAdoptionCards', JSON.stringify(updatedAdoptionCards))
  }

  useEffect(() => {
    try {
      /* Call the item from localStorage */
      const storedAdoptionCardsData = localStorage.getItem('savedAdoptionCards')
      /* Set the new array in adoptionCards and also in storedAdoptionCards */
      if (storedAdoptionCardsData) {
        setAdoptionCards(JSON.parse(storedAdoptionCardsData))
        setStoredAdoptionCards(JSON.parse(storedAdoptionCardsData))
      }
    } catch (error) {
      console.error('Error parsing JSON from localStorage:', error)
    } finally {
      // Mark the component as mounted after the initial render
      setComponentMounted(true)
    }
  }, [])

  const renderSection = (section, index) => (
    <div key={section.title}>
      <h1 className="smallTitle">{section.title}</h1>
      <hr className="grayLine mt1 mb2" />
      <div className="adoptionCardsContainer mb2">
        {section.cards.map((card, cardIndex) => (
          <AdoptionCard
            key={card.cardTitle}
            cardTitle={card.cardTitle}
            cardDescription={card.cardDescription}
            cardVideoSrc={card.cardVideoSrc}
            videoProgress={card.videoProgress}
            videoEnded={card.videoEnded}
            onVideoEnded={() => handleVideoEnded(index, cardIndex)}
            onSaveVideoProgress={handleSaveVideoProgress}
          />
        ))}
      </div>
    </div>
  )

  return (
    <>
      <div className="adoptionBanner">
        <div className="adoptionContainer containerToRight tacMobile">
          <div className="col4 fullMobile">
            <img
              src="/img/others/dobcon-adoption.png"
              alt="Dobcon Adoption"
              className="adoptionImg"
            />
          </div>
          <div className="col4 fullMobile">
            <img
              src="/img/logo/dobcon-adoption.png"
              alt="Dobcon Adoption Logo"
              className="adoptionLogo"
            />
            <p className="bigText fw500">
              A platform of mini-capsules that support continuous learning and facilitate the cultural adoption of DevOps.
            </p>
            
          </div>
          
        </div>
        
        <div className="tar containerToRight">
          <CurrentDate />
        </div>
      </div>
      

      <div className="containerToRight mt-2">
        {componentMounted && (storedAdoptionCards !== null
          ? storedAdoptionCards.map(renderSection)
          : adoptionCards.map(renderSection))}
      </div>
    </>
  )
}

export default DobconAdoption