import { useState, useEffect } from 'react'
import './DotDetails.css'
import DetailsModal from '../modals/DetailsModal'

const DotDetails = ({details, stage, color}) => {
  const [detailsVisible, setDetailsVisible] = useState(false)
  const [detailTitle, setDetailTitle] = useState('')

  const detailsTitleLabels = {
    green : {
      Code : 'Successful Pull Requests',
      Build : 'Successful Builds',
      Test : 'Successful Tests',
      Integration : 'Products',
      Deploy : 'Successful Deployments',
      Operate : 'Applied Changes',
      Monitor : 'Ok Status',
    },
    red : {
      Code: 'Failed Pull Requests',
      Build: 'Failed Builds',
      Test: 'Failed Tests',
      Integration: 'Failed Products',
      Deploy: 'Failed Deployments',
      Operate: 'Unapplied Changes',
      Monitor: 'Alarms',
    }
  }

  useEffect(() => {
    if (color && stage && detailsTitleLabels[color] && detailsTitleLabels[color][stage]) {
      setDetailTitle(detailsTitleLabels[color][stage])
    }
  }, [color, stage])

  const handleOpenDetails = () => {
    setDetailsVisible(true)
  }

  const handleCloseDetails = () => {
    setDetailsVisible(false)
  }

  return (
    <>
    { details.length > 0 ? (
      <span className='dataDetails'>
        <>
          <button onClick={handleOpenDetails}><i className="fa-solid fa-circle-info" /></button>
        </>
      </span>
    ):(
      <span className="dataDetails disabled">
        <i className="fa-solid fa-circle-info" />
      </span>
    )}
    {detailsVisible && (
      <DetailsModal
        details={details}
        detailsTitle={detailTitle || ''}
        isOpen={detailsVisible}
        onClose={handleCloseDetails}
      />
    )}
    </>
  )
}

export default DotDetails