import React, { useEffect, useState } from 'react'
import './BasicTab.css'
import DarkInput from '../forms/DarkInput'
import BasicButton from '../buttons/BasicButton'
import Warning from '../alerts/Warning'
import BasicModal from '../modals/BasicModal'
import Success from '../alerts/Success'

const API = process.env.REACT_APP_SETUP_API

const BasicTab = ({initiative}) => {
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  // Changes detected
  const [changed, setChanged] = useState(false)
  // Modals
  const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false)
  const [isOpenModalRevert, setIsOpenModalRevert] = useState(false)
  /* Credentials */
  const [initiativeCredentials, setInitiativeCredentials] = useState({})
  const [updatedCredentials, setUpdatedCredentials] = useState({})
  const stageOrder = ['CODE', 'BUILD', 'TEST', 'INTEGRATION', 'DEPLOY', 'OPERATE', 'MONITOR']
  /* Get initiative credentials */
  const getInitiativeCredentials = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    const response = await fetch(
      `${API}/initiatives/${initiative}/tools-config`, requestOptions
    )
    const data = await response.json()
    if (response.status === 500) {
      setError(data['Error'])
    } else if (response.status === 200) {
      const sortedData = {};
      stageOrder.forEach(stage => {
        if (data[stage]) {
          sortedData[stage] = data[stage];
        }
      });
      setInitiativeCredentials(sortedData)
      setUpdatedCredentials(sortedData)
    }
  }

  const handleChange = (value, stage, codeTool, attribute) => {
    setChanged(true)
    setUpdatedCredentials((prevCredentials) => {
      return {
        ...prevCredentials,
        [stage]: prevCredentials[stage].map((item) => {
          if (item.tool === codeTool) {
            return {
              ...item,
              [attribute]: value,
            }
          }
          return item // Return the unchanged items
        }),
      }
    })
  }

  const handleSubmitChanges = async (e) => {
    e.preventDefault()
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        'newCredentials': updatedCredentials,
      }),
    }
    const r = await fetch(`${API}/initiatives/${initiative}/tools-config`, requestOptions)
    if (r.status === 200) {
      setError('')
      setSuccess('Changes saved successfully')
      setChanged(false)
      setIsOpenModalUpdate(false)
    } else {
      setError('An error ocurred while submitting your changes')
      setSuccess('')
    }
    setInitiativeCredentials(updatedCredentials)
  }
  const handleSubmitRevert = () => {
    setChanged(false)
    setUpdatedCredentials(initiativeCredentials)
    setIsOpenModalRevert(false)
  }

  const [isActive, setIsActive] = useState(false)
  const [selected, setSelected] = useState("Select production branch")
  const [codeBranches, setCodeBranches] = useState([])
  const [checkError, setCheckError] = useState("")
  async function getBranches(codeTool, codeToken, codeUrl) {
    if (codeToken === '') {
      setCheckError("Please enter token")
    } else if (codeUrl === '') {
      setCheckError("Please enter url")
    } else {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          'tool': codeTool,
          'token': codeToken,
          'url': codeUrl
        }),
      }
      const r = await fetch(`${API}/code-branches`, requestOptions)
      if (r.status === 200) {
        const branches = await r.json()
        console.log(typeof(branches))
        setCodeBranches(branches)
        setCheckError("")
      } else {
        setCodeBranches([])
        setCheckError("Url or token is invalid")
      }
    }
  }
  const handleSelectBranch = (codeTool, selectedBranches) => {
    setChanged(true)
    setUpdatedCredentials((prevCredentials) => {
      return {
        ...prevCredentials,
        CODE: prevCredentials.CODE.map((item) => {
          if (item.tool === codeTool) {
            return {
              ...item,
              production_branches: [selectedBranches],
            }
          }
          return item // Return the unchanged items
        }),
      }
    })
  }

  useEffect(() => {
    getInitiativeCredentials()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiative])

  const [activeStage, setActiveStage] = useState({
    stage: "CODE",
    tab: 0
  })

  

  return (
    <div className="basicTab">
      {error && <div className="mt1 mb2"><Warning message={error} /></div>}
      {success && <div className="mt1 mb2"><Success message={success} /></div>}
      <div className="basicTabHead">
        {Object.keys(updatedCredentials).map((stage, index) => (
          <div
            className={`basicTabHeadTabs ${index === activeStage.tab ? 'activeTab' : ''}`}
            key={stage}
            onClick={() => setActiveStage({ stage: stage, tab: index })}
          >
            {stage}
          </div>
        ))}
      </div>
      <div className="basicTabBody">
        {updatedCredentials && updatedCredentials[activeStage.stage] ? (
          updatedCredentials[activeStage.stage].map((tool, index) => (
            <div key={tool.tool}>
              <h1 className="bigText mb1">
                {tool.tool}
              </h1>
              {checkError && <div className="mt1 mb1"><Warning message={checkError} /></div>}
              {Object.keys(tool).map((credentialKey, credentialIndex) => (
                credentialKey !== "tool" ? (
                  
                  <div key={credentialKey + tool.tool}>
                    {credentialKey !== "production_branches" ? (
                      <DarkInput
                        type="text"
                        label={credentialKey}
                        placeholder={tool[credentialKey]}
                        value={tool[credentialKey]}
                        onChange={(value) => handleChange(value, activeStage.stage, tool.tool, credentialKey)}
                      />
                    ) :
                      <div className="companyResourcesTable">
                        <div className="companyResourcesTableBody">
                          <table>
                            <thead className="companyResourcesTableHead">
                              <tr>
                                <th>{tool.tool}'s production branches</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tool['production_branches'].map((branch, branchIndex) => (
                                <tr key={branch + tool.tool}>
                                  <td>{branch}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    }
                  </div>
                ) : null
              ))}
              <div>
              {activeStage.stage === "CODE" && tool['url'] !== '' && tool['token'] !== '' ?
                <div className="col12 mt1 flexContainer alignCenter">
                  <div className='mr1'>
                    <BasicButton
                      color="btnRed"
                      size="btnSmall"
                      onClick={() => getBranches(tool.tool, tool.token, tool.url)}
                    >
                      Get Branches
                    </BasicButton>
                  </div>
                  {/* Branch Select */}
                  {codeBranches && Array.isArray(codeBranches) && codeBranches.length !== 0 ?
                    <div className="darkSelectInput m0">
                      <div className="darkSelectInputContainer" onClick={(e) =>
                        setIsActive(!isActive)}
                      >
                        <div className="darkSelectInputSelected">
                          {selected}
                          <span className="fas fa-chevron-down"></span>
                        </div>
                      </div>
                      {isActive && (
                        <div className="darkSelectInputOptions">
                          {codeBranches && codeBranches.map((option, index) => (
                            <div key={option}
                              onClick={() => {
                                setSelected(option);
                                setIsActive(false);
                                handleSelectBranch(tool.tool, option);
                              }}
                              className="darkSelectInputOption">
                              {option}
                            </div>
                          ))}
                        </div>
                      )}
                    </div> : null
                  }
                </div> : null}
              <br/>
              </div>

            </div>

          ))
        ) : null}
      </div>

      <div className="col12 mt1 flexContainer spaceBetween">
        <BasicButton
          color="btnRed"
          size="btnMedium"
          onClick={() => setIsOpenModalUpdate(true)}
        >
          Save changes
        </BasicButton>
        { changed && 
        <BasicButton
          color="btnBlack"
          size="btnMedium"
          onClick={() => setIsOpenModalRevert(true)}
        >
          Revert changes
        </BasicButton>
        }
        
      </div>
      {/* Save Changes modal */}
      <BasicModal isOpen={isOpenModalUpdate} onClose={() => setIsOpenModalUpdate(false)}>
        <h2 className="mb2 tac">Are you sure that you want to save changes?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={handleSubmitChanges}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={() => setIsOpenModalUpdate(false)}
        >
          Cancel
        </BasicButton>
      </BasicModal>
      {/* Undo Changes modal */}
      <BasicModal isOpen={isOpenModalRevert} onClose={() => setIsOpenModalRevert(false)}>
        <h2 className="mb2 tac">Changes will be lost, are you sure?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={handleSubmitRevert}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={() => setIsOpenModalUpdate(false)}
        >
          Cancel
        </BasicButton>
      </BasicModal>
    </div>
  )
}

export default BasicTab