import { isMobile } from "react-device-detect";

const getDeviceType = async() => {
  if(isMobile){
    return 'Mobile'
  } else {
    return 'Desktop'
  }
};

export default getDeviceType;

