function generateMockMTTR() {
    const daysBack = 30; // Number of days to generate data
    const initiatives = ['Initiative_1', 'Initiative_2', 'Initiative_3']; // List of possible initiatives
    let mttrData = [];
    let activeOutages = {}; // Active outages with the hours they met the last day
    let totalOutagesUsed = 0; // Counter to generate unique outage IDs
    let outagesThatCannotRepeat = new Set(); // Outages that did not meet 24 hours and cannot repeat

    for (let day = 0; day < daysBack; day++) {
        const date = new Date();
        date.setDate(date.getDate() - daysBack + day + 1);
        const dateString = date.toISOString().split('T')[0];

        let todayOutages = {}; // Outages appearing today

        // Check active outages to decide their appearance and hours for today
        Object.keys(activeOutages).forEach(outageId => {
            // If an outage cannot repeat, skip it
            if (outagesThatCannotRepeat.has(outageId)) {
                return;
            }

            const hours = Math.floor(Math.random() * 25); // Hours between 0 and 24 for today
            const initiative = initiatives[Math.floor(Math.random() * initiatives.length)]; // Random initiative for each outage

            todayOutages[outageId] = hours;

            // Record the outage and its hours for today
            mttrData.push({
                outages: outageId,
                timestamp: dateString,
                hours,
                initiative
            });

            // If it does not meet 24 hours, mark it so it cannot repeat
            if (hours < 24) {
                outagesThatCannotRepeat.add(outageId);
            }
        });

        // Update activeOutages for the next day
        activeOutages = { ...todayOutages };

        // Generate a new outage only if all of today's outages met 24 hours
        const allMet24Hours = Object.values(todayOutages).every(hours => hours >= 24);
        if (allMet24Hours || day === 0) { // Add a new outage on the first day or if all met 24 hours
            totalOutagesUsed++;
            const newOutageId = `outage_${totalOutagesUsed}`;
            activeOutages[newOutageId] = 24; // Assign 24 hours by default to allow its appearance the next day
        }
    }

    return {
        mttr: mttrData,
        initiative: "All"
    };
}

// Example usage
export default generateMockMTTR;
