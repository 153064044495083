import React, { useState, useEffect } from 'react'
import './LoginForm.css'
import { Link, useNavigate } from 'react-router-dom'
import BasicInput from './BasicInput'
import Warning from '../alerts/Warning'
import BasicButton from '../buttons/BasicButton'
import NextField from '../functions/NextField'
import BasicModal from '../modals/BasicModal'
/* Amplify */
import { Auth, Hub } from 'aws-amplify'
/* API url */
const API = process.env.REACT_APP_LICENCES_API

const LoginForm = () => {
  /* Redirection */
  let navigate = useNavigate()
  /* Login step1 */
  /* Login data */
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })
  /* User confirmed */
  const [isConfirmed, setIsConfirmed] = useState(true)

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    })
  }

  /* Errors */
  const [error, setError] = useState('')
  const [errorForToken, setErrorForToken] = useState('')

  /* Submit */
  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!formData.email) {
      setError("Please enter your email or username")
    } else if (!formData.password) {
      setError("Please enter your password")
    } else {
      try {
        const user = await Auth.signIn(formData.email, formData.password)
        if (user.challengeName === "CUSTOM_CHALLENGE") {
          try {
            //const deviceCheckResult = await checkDevice(formData.email)
            await Auth.sendCustomChallengeAnswer(user, 'deviceConfirmed')
          } catch (error) {
            console.log(error)
            setError("Wrong device")
          }
        }
        setError("")
        navigate(`/${btoa('user-home')}`)
      } catch (error) {
        if (error.name === "UserNotConfirmedException") {
          setError("")
          setIsNewUser(true)
          setIsConfirmed(false)
        } else if (error.name === "NotAuthorizedException") {
          setError("Incorrect email or password")
        }
      }
    }
  }

  /* Login step2 */
  const [isNewUser, setIsNewUser] = useState(false)
  /* Login data2 */
  const [firstLoginData, setFirstLoginData] = useState({
    newUsername: '',
    newPassword: '',
    confirmPassword: '',
    emailToken: ''
  })

  const handleInputChangeFirstLogin = (field, value) => {
    setFirstLoginData({
      ...firstLoginData,
      [field]: value,
    })
  }

  /* Password validations */
  const [passChecks, setPassChecks] = useState({
    eight: false,
    lowercase: false,
    uppercase: false,
    hasNumber: false,
    specialCharacter: false,
    passMatch: false,
  })

  const checkPass = (newPass) => {
    const checks = {
      eight: newPass.length > 7,
      lowercase: /[a-z]/.test(newPass),
      uppercase: /[A-Z]/.test(newPass),
      hasNumber: /\d/.test(newPass),
      specialCharacter: /[!@#$%^&*?¿{}></ºª|"'¬()=_~+`¡]/.test(newPass),
    }
    setPassChecks(checks)
  }

  const confirmPass = () => {
    if (firstLoginData.newPassword === firstLoginData.confirmPassword) {
      setPassChecks({
        ...passChecks,
        passMatch: true
      })
    } else {
      setPassChecks({
        ...passChecks,
        passMatch: false
      })
    }
  }

  const preventCopyPaste = (e) => {
    e.preventDefault()
  }

  /* Token Expiration */
  const tokenExpiration = async () => {
    try {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        accept: 'application/json',
        body: JSON.stringify({
          "email": formData.email
        })
      }
      const response = await fetch(`${API}/token`, requestOptions)
      const data = await response.json()
      return data
    } catch (error) {
      console.log("Error Token " + error)
    }
  }

  /* Updated username */
  const updateUsername = async (oldUsername, newUsername) => {
    try {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        accept: 'application/json',
        body: JSON.stringify({
          "email": oldUsername,
          "new_username": newUsername
        })
      }
      const response = await fetch(`${API}/users/username`, requestOptions)
      const data = await response.json()
      console.log(data)
    } catch (error) {
      console.log("Error Newusername " + error)
    }
  }

  // if user is already logged in, redirect to Home
  const checkIsLoggedIn = async() => {
    let isLoggedIn = false
    try{
      const user = await Auth.currentAuthenticatedUser()
      if(user){
        isLoggedIn = true
      }
    } catch(e){
      isLoggedIn = false
    }
    if(isLoggedIn && !isNewUser){
      navigate(`/${btoa('user-home')}`);
    }
  }

  /* Auth listener (for first login) */
  const setAuthListener = async () => {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          break
        case 'signOut':
          console.log('data from event', data)
          break
        default:
          break
      }
    })
  }

  useEffect(() => {
    checkIsLoggedIn()
    setAuthListener()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Submit first login */
  const handleSubmitFirstLogin = async (e) => {
    e.preventDefault();

    if (!firstLoginData.newPassword) {
      setError("Please enter your new password");
      return;
    }

    const passCheckErrors = {
      lowercase: "The password must have one lowercase character",
      uppercase: "The password must have one uppercase character",
      hasNumber: "The password must have one number",
      specialCharacter: "The password must have one special character",
      eight: "The password must have eight characters minimum"
    };

    for (const [check, errorMessage] of Object.entries(passCheckErrors)) {
      if (!passChecks[check]) {
        setError(errorMessage);
        return;
      }
    }

    if (firstLoginData.newPassword !== firstLoginData.confirmPassword) {
      setError("The passwords must match");
      return;
    }

    if (!firstLoginData.emailToken) {
      setError("Please enter your email token");
      return;
    }

    const tokenCheck = await tokenExpiration();
    if (tokenCheck.response === "token is expired") {
      setError("Token is expired");
      return;
    }

    try {
      if (!isConfirmed) {
        await Auth.confirmSignUp(formData.email, firstLoginData.emailToken);
        setIsConfirmed(true);
      }
      const user = await Auth.signIn(formData.email, formData.password);
      if (user.challengeName === "CUSTOM_CHALLENGE") {
        await Auth.sendCustomChallengeAnswer(user, true);
      }
      await Auth.updateUserAttributes(user, {
        'preferred_username': firstLoginData.newUsername
      });
      await Auth.changePassword(user, formData.password, firstLoginData.newPassword);
      await updateUsername(formData.email, firstLoginData.newUsername);
      setError("");
      navigate(`/${btoa('user-home')}`);
    } catch (error) {
      console.log(error);
      switch (error.name) {
        case 'CodeMismatchException':
          setError('Please enter a valid email token');
          break;
        case 'ExpiredCodeException':
          setError('The email token has expired');
          break;
        case 'LimitExceededException':
          setError('Please wait to try again or contact your superuser to reset your account');
          break;
        case 'AliasExistsException':
          setError(`The username ${firstLoginData.newUsername} already exists, please choose another username`);
          break;
        default:
        // Handle other error cases if needed
      }
    }
  };


  /* Token Modal */
  const [isOpenTokenModal, setIsOpenTokenModal] = useState(false)
  const [emailForToken, setEmailForToken] = useState('')
  const [emailTokenSended, setEmailTokenSended] = useState(false)

  const openTokenModal = () => {
    setIsOpenTokenModal(true)
  }

  const closeTokenModal = () => {
    setErrorForToken('')
    setEmailForToken('')
    setIsOpenTokenModal(false)
  }

  /* Submit token */
  const handleSubmitForToken = async (e) => {
    e.preventDefault()
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i

    if (emailForToken === '') {
      setErrorForToken("Please enter your email")
    } else if (!regex.test(emailForToken)) {
      setErrorForToken("Please enter a valid email")
    } else {
      try {
        await Auth.resendSignUp(emailForToken)
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          accept: 'application/json',
          body: JSON.stringify({
            "email": formData.email
          })
        }
        const response = await fetch(`${API}/token/reset`, requestOptions)
        const data = await response.json()
        setEmailTokenSended(true)
        return data
      } catch (error) {
        setEmailTokenSended(false)
      }
    }
  }

  const backToLogin = () => {
    setFormData({
      email: '',
      password: ''
    })
    setIsNewUser(false)
    setFirstLoginData({
      savedEmail: '',
      newUsername: '',
      newPassword: '',
      confirmPassword: '',
      emailToken: '',
    })
    setPassChecks({
      eight: false,
      lowercase: false,
      uppercase: false,
      hasNumber: false,
      specialCharacter: false,
      passMatch: false,
    })
    setIsOpenTokenModal(false)
    setEmailForToken('')
    setEmailTokenSended(false)
    setError('')
    setErrorForToken('')
  }

  return (
    <>
      {!isNewUser ? (
        <div>
          <div>
            <img
              src="/img/logo/logo-dobcon-small.png"
              alt="DevOps Business Control"
              className="col6Mobile mb2 hide showMobile"
            />
            <h1 className="mb2">Welcome!</h1>
          </div>
          {error && <div className="mb2"><Warning message={error} /></div>}
          <form>
            <BasicInput
              label="Email address or username (Case sensitive)*"
              type="text"
              value={formData.email}
              onChange={(value) => handleInputChange('email', value)}
              onKeyDown={NextField}
              placeholder="Type your email or username"
            />
            <BasicInput
              label="Password (Case sensitive)*"
              type="password"
              value={formData.password}
              onChange={(value) => handleInputChange('password', value)}
              placeholder="Type your password"
            />
            <p className="lightGray tar mb2">
              Forgot password?{' '}
              <Link to={`/${btoa('forgot-password')}`}>
                <u className="lighterGray">Click here</u>
              </Link>
              <br />
              <small>*Mandatory Fields</small>
            </p>
            <BasicButton
              as="button"
              color="btnRed"
              size="btnFull"
              onClick={handleSubmit}
            >
              Log In
            </BasicButton>
          </form>
        </div>
      ) : (
        <div>
          <div>
            <img
              src="/img/logo/logo-dobcon-small.png"
              alt="DevOps Business Control"
              className="col6Mobile mb2 hide showMobile"
            />
            <h1 className="mb1">First time here?</h1>
            <p className="mb2 fw500">Please set a new username and a new password before login</p>
          </div>
          {error && <div className="mb2"><Warning message={error} /></div>}
          <form>
            <div className="hide">
              <BasicInput />
            </div>
            <BasicInput
              label="New username (optional)"
              type="text"
              value={firstLoginData.newUsername}
              onChange={(value) => handleInputChangeFirstLogin('newUsername', value)}
              onKeyDown={NextField}
              placeholder="Type your new username"
            />
            <BasicInput
              onCopy={preventCopyPaste}
              onCut={preventCopyPaste}
              label="New password (Case sensitive)*"
              type="password"
              value={firstLoginData.newPassword}
              onChange={(value) => handleInputChangeFirstLogin('newPassword', value)}
              onKeyDown={NextField}
              onKeyUp={(e) => checkPass(e.target.value)}
              placeholder="Type your new password"
            />
            <BasicInput
              label="Confirm password (Case sensitive)*"
              type="password"
              value={firstLoginData.confirmPassword}
              onChange={(value) => handleInputChangeFirstLogin('confirmPassword', value)}
              onKeyDown={NextField}
              onKeyUp={confirmPass}
              onPaste={preventCopyPaste}
              placeholder="Confirm your password"
            />
            <BasicInput
              label="Email token (Only numbers)*"
              type="onlyNumbers"
              value={firstLoginData.emailToken}
              onChange={(value) => handleInputChangeFirstLogin('emailToken', value)}
              placeholder="Type your email token (Valid for 10min)"
            />
            <p className="lightGray tar mb1">
              Do you need a new email token?{' '}
              <u className="lighterGray cursorPointer" onClick={openTokenModal}>Click here</u>
              <br />
              <small>*Mandatory Fields</small>
            </p>
            <div className="flexContainer spaceBetween mb1 tal">
              <div className="col6">
                <div className="passCheckContainer">
                  <span className={`passCheck ${passChecks.lowercase ? 'passCheckActive' : ''}`} />{' '}
                  <span className="fw500">One lowecase character</span>
                </div>
                <div className="passCheckContainer">
                  <span className={`passCheck ${passChecks.uppercase ? 'passCheckActive' : ''}`} />{' '}
                  <span className="fw500">One uppercase character</span>
                </div>
                <div className="passCheckContainer">
                  <span className={`passCheck ${passChecks.hasNumber ? 'passCheckActive' : ''}`} />{' '}
                  <span className="fw500">One number</span>
                </div>
              </div>
              <div className="col6">
                <div className="passCheckContainer">
                  <span className={`passCheck ${passChecks.specialCharacter ? 'passCheckActive' : ''}`} />{' '}
                  <span className="fw500">One special character</span>
                </div>
                <div className="passCheckContainer">
                  <span className={`passCheck ${passChecks.eight ? 'passCheckActive' : ''}`} />{' '}
                  <span className="fw500">8 characters minimum</span>
                </div>
                <div className="passCheckContainer">
                  <span className={`passCheck ${passChecks.passMatch ? 'passCheckActive' : ''}`} />{' '}
                  <span className="fw500">Passwords must match</span>
                </div>
              </div>
            </div>
            <BasicButton
              as="button"
              color="btnRed"
              size="btnFull"
              onClick={handleSubmitFirstLogin}
            >
              Log In
            </BasicButton>
          </form>
        </div>
      )}
      {/* Modal for token */}
      <BasicModal isOpen={isOpenTokenModal} onClose={closeTokenModal}>
        {!emailTokenSended ?
          <>
            <h2 className="mb1">Get a new email token</h2>
            <p className="mb2 fw500">Please enter your email to get a new email token</p>
            {errorForToken && <div className="mb2"><Warning message={errorForToken} /></div>}
            <form>
              <BasicInput
                label="Email (Case sensitive)*"
                type="text"
                value={emailForToken}
                onChange={(value) => setEmailForToken(value)}
                placeholder="Type your email"
              />
              <p className="lighterGray tal mt-1 mb1"><small>*Mandatory fiekds</small></p>
              <BasicButton
                as="button"
                color="btnRed"
                size="btnFull"
                onClick={handleSubmitForToken}
              >
                Submit
              </BasicButton>
            </form>
            <div className="mb1" />
            <BasicButton
              as="button"
              color="btnBlack"
              size="btnFull"
              onClick={closeTokenModal}
            >
              Cancel
            </BasicButton>
          </> :
          <>
            <h2 className="mb1">The Email Token has sended succesfully please check your email.</h2>
            <BasicButton
              as="button"
              color="btnRed"
              size="btnFull"
              onClick={backToLogin}
            >
              Back to Login
            </BasicButton>
          </>
        }
      </BasicModal>
    </>
  )
}

export default LoginForm