import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BasicButton from '../../../components/buttons/BasicButton'
import CurrentDate from '../../../components/resources/CurrentDate'
import BusinessNavFilter from '../../../components/nav/BusinessNavFilter'
import InitiativesInfo from './InitiativesInfo'
import '../../../components/tables/InitiativeResourcesTable'

const API = process.env.REACT_APP_SETUP_API

const InitiativeResources = () => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Initiative Params */
  let { initiativeName } = useParams()
  /* Navigation */
  const links = ['Initiative Metrics', 'Costs']
  const activeSection = 'Resources'
  /* Get resources */
  const [initiativeResources, setInitiativeResources] = useState([{ asd: "ads" }])

  const fetchInitiativeResources = async () => {
    try {
      const response = await fetch(
        `${API}/resources/${currentCompany}/initiatives/${window.atob(initiativeName)}`,
        {
          method: 'GET',
        }
      )
      const data = await response.json()
      setInitiativeResources([...data])
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchInitiativeResources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="containerToRight">
      <h1 className="mb1">
        <span className="lightGray">{window.atob(initiativeName)}</span> Resources
      </h1>
      <BasicButton color="btnBlack" size="btnSmall" as="link" to="dobcon-business">
        Back to Business Dashboard <i className="fa-solid fa-rotate-left" />
      </BasicButton>
      <div className="tar mt-1">
        <CurrentDate />
        <hr className="grayLine mt1" />
      </div>
      <div className="flexContainer spaceBetween pt1 pb1">
        <div className="col2">
          <BusinessNavFilter
            links={links}
            activeSection={activeSection}
            initiativeName={initiativeName}
          />
        </div>
      </div>

      <InitiativesInfo />

      <div className="initiativeResourcesTable mt2">
        <table>
          <thead className="initiativeResourcesTableHead">
            <tr>
              <th className="w15">Departments</th>
              <th className="w35">Responsibles</th>
              <th className="w35">Team / Roles / Location</th>
              <th className="w15">
                <span className="greenDot" /> On Time
                <br />
                <span className="redDot" /> Delay
              </th>
            </tr>
          </thead>
          <tbody className="initiativeResourcesTableBody">
            {initiativeResources.length !== 0 ? (
              initiativeResources.map((item) => (
                <tr key={item.department}>
                  {/* Department */}
                  <td className="w15">
                    <ul>
                      <li>{item.department}</li>
                    </ul>
                  </td>
                  {/* Responsibles */}
                  <td className="w35">
                    <ul>
                      {item.responsibles && item.responsibles.length > 0 ? (
                        item.responsibles.map((responsible) => (
                          <li key={`responsibles_${responsible.name}`}>
                            <strong>{responsible.name}</strong> / {responsible.role} / {responsible.location}
                          </li>
                        ))
                      ) : (
                        <li key="noResponsibles">No responsibles found</li>
                      )}
                    </ul>
                  </td>
                  {/* Resources */}
                  <td className="w35">
                    <ul>
                      {item.resources ? (
                        item.resources.map((resource) => (
                          <li key={`resources_${resource.name}`}>
                            <strong>{resource.name}</strong> / {resource.role} / {resource.location}
                          </li>
                        ))
                      ) : (
                        <li key="noResources">No resources found</li>
                      )}
                    </ul>
                  </td>
                  {/* Delayed */}
                  <td className="w15">
                    <ul>
                      <li key="delayed">
                        <strong className='redText'>000.00%</strong>
                      </li>
                    </ul>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="w100" key="noDepartment">
                <td colSpan="4">
                  <ul>
                    <li>No department found</li>
                  </ul>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default InitiativeResources