import { Link } from 'react-router-dom'
import './BasicButton.css'

const BasicButton = ({ as, to, color, size, children, onClick, isDisabled }) => {
  return (
    as === "link"
      ?
      <Link to={`/${btoa(to)}`} className={`btn ${color} ${size}`}>{children}</Link>
      :
      <button onClick={onClick} disabled={isDisabled} className={`btn ${color} ${size}`}>{children}</button>
  )
}

export default BasicButton