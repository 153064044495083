import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import DarkSelectInput from '../../components/forms/DarkSelectInput';
import './DobconMetricsDashboard.css';

const DobconMetricsDashboard = () => {
  const [activeTab, setActiveTab] = useState('quality');
  const [timeframe, setTimeframe] = useState('month');
  const [selectedInitiative, setSelectedInitiative] = useState('All');
  const [initiatives, setInitiatives] = useState([]);

  const handleTabChange = (tab) => setActiveTab(tab);
  const handleTimeframeChange = (value) => setTimeframe(value);
  const handleInitiativeChange = (value) => setSelectedInitiative(value);

  useEffect(() => {
    setInitiatives([
      { label: 'All', value: 'All' },
      { label: 'Initiative 1', value: 'Initiative 1' },
      { label: 'Initiative 2', value: 'Initiative 2' },
    ]);
  }, []);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months start from 0
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const getChartData = () => {
    switch (activeTab) {
      case 'quality':
        return {
          labels: ['2024-06-01', '2024-06-08', '2024-06-15', '2024-06-22', '2024-06-29'].map(formatDate),
          datasets: [
            {
              label: 'Production Defects',
              data: [3, 7, 5, 8, 6],
              borderColor: 'rgba(119, 5, 19, 1)',
              backgroundColor: 'rgba(119, 5, 19, 1)',
              borderWidth: 1,
            },
            {
              label: 'Pre-production Defects',
              data: [6, 9, 4, 10, 7],
              borderColor: 'rgba(119, 5, 19, 1)',
              backgroundColor: 'rgba(119, 5, 19, 1)',
              borderWidth: 1,
            },
          ],
        };
      case 'costs':
        return {
          labels: ['Q1', 'Q2', 'Q3', 'Q4'],
          datasets: [
            {
              label: 'Total Cost of Ownership (TCO)',
              data: [10000, 40000, 480000],
              borderColor: 'rgba(119, 5, 19, 1)',
              backgroundColor: 'rgba(119, 5, 19, 1)',
              borderWidth: 1,
            },
            {
              label: 'Average Cost per Release',
              data: [1000, 5000, 25000],
              borderColor: 'rgba(119, 5, 19, 1)',
              backgroundColor: 'rgba(119, 5, 19, 1)',
              borderWidth: 1,
            },
          ],
        };
      case 'productivity':
        return {
          meanTime: {
            labels: ['2024-06-01', '2024-06-08', '2024-06-15', '2024-06-22'].map(formatDate),
            datasets: [
              {
                label: 'Mean Time Between Releases',
                data: [7, 14, 10, 5],
                borderColor: 'rgba(119, 5, 19, 1)',
                backgroundColor: 'rgba(119, 5, 19, 1)',
                borderWidth: 1,
              },
            ],
          },
          leadTime: {
            labels: ['2024-06-01', '2024-06-08', '2024-06-15', '2024-06-22'].map(formatDate),
            datasets: [
              {
                label: 'Lead Time for Changes',
                data: [2, 3, 1, 2],
                borderColor: 'rgba(119, 5, 19, 1)',
                backgroundColor: 'rgba(119, 5, 19, 1)',
                borderWidth: 1,
              },
            ],
          },
          changeFailureRate: {
            labels: ['2024-06-01', '2024-06-08', '2024-06-15', '2024-06-22'].map(formatDate),
            datasets: [
              {
                label: 'Change Failure Rate',
                data: [10, 20, 15, 5],
                borderColor: 'rgba(119, 5, 19, 1)',
                backgroundColor: 'rgba(119, 5, 19, 1)',
                borderWidth: 1,
              },
            ],
          },
          deploymentFrequency: {
            labels: ['2024-06-01', '2024-06-08', '2024-06-15', '2024-06-22'].map(formatDate),
            datasets: [
              {
                label: 'Deployment Frequency',
                data: [2, 5, 4, 6],
                borderColor: 'rgba(119, 5, 19, 1)',
                backgroundColor: 'rgba(119, 5, 19, 1)',
                borderWidth: 1,
              },
            ],
          },
        };
      default:
        return { labels: [], datasets: [] };
    }
  };

  const axisLabels = {
    quality: { x: 'Date', y: 'Number of Defects' },
    costs: { x: 'Quarter', y: 'Costs ($)' },
    productivity: { x: 'Date', y: 'Metrics Value' },
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: true },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: { color: '#FFFFFF' },
        grid: { color: 'rgba(255, 255, 255, 0.2)' },
        title: {
          display: true,
          text: axisLabels[activeTab]?.y || 'Y Axis',
          color: '#FFFFFF',
        },
      },
      x: {
        ticks: { color: '#FFFFFF' },
        grid: { color: 'rgba(255, 255, 255, 0.2)' },
        title: {
          display: true,
          text: axisLabels[activeTab]?.x || 'X Axis',
          color: '#FFFFFF',
        },
      },
    },
  };

  const renderDescription = () => {
    switch (activeTab) {
      case 'quality':
        return (
          <div className="chartDescription">
            <h3>Quality Metrics</h3>
            <p>
              <strong>Production Defects:</strong> Number of defects detected after releasing to production.
              Tracks quality issues that occur post-deployment.
            </p>
            <p>
              <strong>Pre-production Defects:</strong> Number of defects detected before releasing to production.
              Indicates issues caught during development or testing stages.
            </p>
            <p className="advice">
              <strong>Advice:</strong> Monitor defect trends to identify potential areas for improvement in
              your development and testing processes.
            </p>
          </div>
        );
      case 'costs':
        return (
          <div className="chartDescription">
            <h3>Cost Metrics</h3>
            <p>
              <strong>Total Cost of Ownership (TCO):</strong> Represents the cumulative costs associated with
              initiatives or teams over time. Includes infrastructure, people, providers, and others.
            </p>
            <p>
              <strong>Average Cost per Release:</strong> Calculates the average cost involved in releasing
              software over a specific period.
            </p>
            <p className="advice">
              <strong>Advice:</strong> Use TCO and average cost metrics to assess financial efficiency and
              make data-driven decisions to optimize costs.
            </p>
          </div>
        );
      case 'productivity':
        return (
          <div className="chartDescription">
            <h3>Productivity Metrics</h3>
            <p>
              <strong>Mean Time Between Releases:</strong> Measures the average time between software releases.
            </p>
            <p>
              <strong>Lead Time for Changes:</strong> Tracks the time it takes for code changes to go from
              commit to production.
            </p>
            <p>
              <strong>Change Failure Rate (CFR):</strong> Indicates the percentage of deployments that lead
              to failures in production.
            </p>
            <p>
              <strong>Deployment Frequency:</strong> Measures how frequently software is deployed to production.
            </p>
            <p className="advice">
              <strong>Advice:</strong> Use productivity metrics to improve deployment processes and minimize
              the time taken to deliver changes.
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  const productivityChartComponents = () => {
    const chartData = getChartData();
    return (
      <div className="productivityChartsContainer">
        <div className="productivityChartItem">
          <Line data={chartData.meanTime} options={chartOptions} />
        </div>
        <div className="productivityChartItem">
          <Line data={chartData.leadTime} options={chartOptions} />
        </div>
        <div className="productivityChartItem">
          <Bar data={chartData.changeFailureRate} options={chartOptions} />
        </div>
        <div className="productivityChartItem">
          <Line data={chartData.deploymentFrequency} options={chartOptions} />
        </div>
      </div>
    );
  };

  return (
    <div className="containerToRight">
      <div className="dobconDashboardContent">
        <h1 className="dashboardTitle">Dobcon Metrics Dashboard</h1>
        <div className="tabsContainer">
          <button
            className={`tabButton ${activeTab === 'quality' ? 'active' : ''}`}
            onClick={() => handleTabChange('quality')}
          >
            Quality
          </button>
          <button
            className={`tabButton ${activeTab === 'costs' ? 'active' : ''}`}
            onClick={() => handleTabChange('costs')}
          >
            Costs
          </button>
          <button
            className={`tabButton ${activeTab === 'productivity' ? 'active' : ''}`}
            onClick={() => handleTabChange('productivity')}
          >
            Productivity
          </button>
        </div>
        <div className="inputsContainer">
          <div className="selectWrapper">
            <DarkSelectInput
              label="Initiative"
              options={initiatives}
              value={selectedInitiative}
              onChange={(e) => handleInitiativeChange(e.target.value)}
            />
            <DarkSelectInput
              label="Timeframe"
              options={[
                { label: 'Week', value: 'week' },
                { label: 'Month', value: 'month' },
                { label: 'Year', value: 'year' },
              ]}
              value={timeframe}
              onChange={(e) => handleTimeframeChange(e.target.value)}
            />
          </div>
        </div>
        <div className="chartContainer">
          {activeTab === 'quality' && <Line data={getChartData()} options={chartOptions} />}
          {activeTab === 'costs' && <Bar data={getChartData()} options={chartOptions} />}
          {activeTab === 'productivity' && productivityChartComponents()}
        </div>
        {renderDescription()}
      </div>
    </div>
  );
};

export default DobconMetricsDashboard;
