import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLaptop, 
  faNetworkWired, 
  faShieldAlt, 
  faDatabase, 
  faFileAlt, 
  faBuilding, 
  faCogs, 
  faUsers, 
  faCloud, 
  faHeadset, 
  faTruck, 
  faWrench, 
  faServer, 
  faPlane, 
  faExclamationCircle, 
  faClipboard, 
  faPercent, 
  faTasks, 
  faUsersCog, 
  faPaperPlane,
  faBoxArchive
} from '@fortawesome/free-solid-svg-icons';
import tcoService from '../../services/tcoServices';
import './TCOForm.css';
import NumberInput from '../../components/forms/NumberInput';
import DarkSelectInput from '../../components/forms/DarkSelectInput';
import DarkInput from '../../components/forms/DarkInput';

const TCOForm = () => {

  const formatNumber = (number) => {
    const numericValue = parseFloat(number)
    if (!isNaN(numericValue)) {
      return numericValue.toLocaleString('en-US', {
        maximumFractionDigits: 2
      })
    } else {
      return number
    }
  }

  const initialFormData = {
    initiativeCost: {
      infrastructure: {
        computers: formatNumber(1000),
        networking: formatNumber(2000),
        security: formatNumber(1500),
        dataCenter: formatNumber(3000),
        softwareLicenses: formatNumber(4000),
        rent: formatNumber(5000),
        fixedAssets: formatNumber(6000),
      },
      people: {
        employeeWages: formatNumber(7000),
      },
      providers: {
        cloudServices: formatNumber(8000),
        supportServices: formatNumber(9000),
        outsourcedServices: formatNumber(10000),
        implementationServices: formatNumber(11000),
        infrastructureServices: formatNumber(12000),
        dataCenterServices: formatNumber(13000),
      },
      others: {
        travelExpenses: formatNumber(14000),
        penalties: formatNumber(15000),
        officeSupplies: formatNumber(16000),
        consumables: formatNumber(17000),
      },
    },
    percentageAssignedToDevOps: formatNumber(20),
    relatedInitiative: '',
    relatedTeam: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [initiatives, setInitiatives] = useState([]);

  useEffect(() => {
    setInitiatives(['Initiative 1', 'Initiative 2', 'Initiative 3']);
  }, []);

  const handleChange = (value, category, subcategory) => {
    setFormData(prevState => ({
      ...prevState,
      initiativeCost: {
        ...prevState.initiativeCost,
        [category]: {
          ...prevState.initiativeCost[category],
          [subcategory]: value,
        },
      },
    }));
  };

  

  const validateForm = () => {
    const errors = {};
    if (!formData.percentageAssignedToDevOps) errors.percentageAssignedToDevOps = 'Percentage Assigned to DevOps is required';
    if (!formData.relatedInitiative) errors.relatedInitiative = 'Related Initiative is required';
    if (!formData.relatedTeam) errors.relatedTeam = 'Related Team is required';

    Object.keys(formData.initiativeCost).forEach(category => {
      Object.keys(formData.initiativeCost[category]).forEach(subcategory => {
        if (!formData.initiativeCost[category][subcategory]) {
          errors[`${category}-${subcategory}`] = `${subcategory} cost is required`;
        }
      });
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!validateForm()) {
      setError('Please fill out all required fields');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      await tcoService.postTcoData(formData);
      alert('Data submitted successfully');
    } catch (err) {
      setError('Failed to submit data');
    } finally {
      setLoading(false);
    }
  };

  // Icon mapping for different subcategories
  const iconLabelMap = {
    computers:{label:"Computers", icon:faLaptop} ,
    networking:{label:"Networking", icon:faNetworkWired} ,
    security:{label:"Security", icon:faShieldAlt} ,
    dataCenter:{label:"Data Center", icon:faDatabase} ,
    softwareLicenses:{label:"Software Licenses", icon:faFileAlt} ,
    rent:{label:"Rent", icon:faBuilding} ,
    fixedAssets:{label:"Fixed Assets", icon:faCogs} ,
    employeeWages:{label:"Employee Wages", icon:faUsers} ,
    cloudServices:{label:"Cloud", icon:faCloud} ,
    supportServices:{label:"Support", icon:faHeadset} ,
    outsourcedServices:{label:"Outsources", icon:faTruck} ,
    implementationServices:{label:"Implementation", icon:faWrench} ,
    infrastructureServices:{label:"Infraestructure", icon:faServer} ,
    dataCenterServices:{label:"Data Center", icon:faDatabase} ,
    travelExpenses:{label:"Travel Expenses", icon:faPlane} ,
    penalties:{label:"Penalties", icon:faExclamationCircle} ,
    officeSupplies:{label:"Office Supplies", icon:faClipboard} ,
    consumables:{label:"Consumables", icon:faBoxArchive} 
  };

  const categoriesInfo = {
    infrastructure: {label: "Infrastructure", description: "Description explaining category (if needed)"},
    people: {label: "People", description: "Description explaining category (if needed)"},
    providers: {label: "Providers", description: "Description explaining category (if needed)"},
    others: {label: "Others", description: "Description explaining category (if needed)"},
  }

  return (
    <div className="containerToRight">
      <h1>
        TCO
      </h1>
      <p className="mediumText mt1 fw500">Enter details to calculate the Total Cost of Ownership</p>
      <form onSubmit={handleSubmit}>
      {Object.keys(formData.initiativeCost).map((category, index) => {
        const subcategories = Object.keys(formData.initiativeCost[category]);
        const midIndex = Math.ceil(subcategories.length / 2);
        const leftSubcategories = subcategories.slice(0, midIndex);
        const rightSubcategories = subcategories.slice(midIndex);

        return (
          <>
          <hr className="grayLine mt2" />
          <span className="stepTag" id={index}>{index + 1}</span>
          
          <div className="flexContainer spaceBetween mt2" key={category}>
            
            <div className="col3 fullTablet fullLandscape mb1">
              <h2 className="bigText mb1">{categoriesInfo[category].label}*</h2>
              <p className="fw500 mb1">
                {categoriesInfo[category].description}
              </p>
              <small className="lightGray">*Mandatory Fields</small>
            </div>
            <div className="col9">
              <div className="financialVariablesTable">
                <div className="financialVariablesTableBody flexContainer">
                  {subcategories.length === 1 ? (
                    <>
                      <ul className="w100 grayLineRight">
                      {subcategories.map((subcategory) => (
                      <li key={subcategory}>
                        <p>
                          <FontAwesomeIcon icon={iconLabelMap[subcategory].icon} />
                          <span> </span>
                          {iconLabelMap[subcategory].label}
                          <span className="variableTooltip">
                            <i className="fa-solid fa-circle-info" />
                            <span className="variableTooltipInfo">
                              The rate of return used to discount future cash flows back to their present value.
                            </span>
                          </span>
                        </p>
                        <NumberInput
                          inputName={subcategory}
                          className="numberInputMedium"
                          inputType="cost"
                          value={formData.initiativeCost[category][subcategory]}
                          onChange={(_, value) => handleChange(value, category, subcategory)}
                        />
                      </li>
                      ))}
                      
                      </ul>
                      
                    </>
                    
                  ):(
                    <>
                      <ul className="w50 grayLineRight">
                        {leftSubcategories.map((subcategory) => (
                        <li key={subcategory}>
                          <p>
                            <FontAwesomeIcon icon={iconLabelMap[subcategory].icon} />
                            <span> </span>
                            {iconLabelMap[subcategory].label}
                            <span className="variableTooltip">
                              <i className="fa-solid fa-circle-info" />
                              <span className="variableTooltipInfo">
                                The rate of return used to discount future cash flows back to their present value.
                              </span>
                            </span>
                          </p>
                          <NumberInput
                            inputName={subcategory}
                            className="numberInputMini"
                            inputType="cost"
                            value={formData.initiativeCost[category][subcategory]}
                            onChange={(_, value) => handleChange(value, category, subcategory)}
                          />
                        </li>
                        ))}
                      
                      </ul>
                      <ul className="w50 grayLineRight">
                        {rightSubcategories.map((subcategory) => (
                        <li key={subcategory}>
                          <p>
                            <FontAwesomeIcon icon={iconLabelMap[subcategory].icon} />
                            <span> </span>
                            {iconLabelMap[subcategory].label}
                            <span className="variableTooltip">
                              <i className="fa-solid fa-circle-info" />
                              <span className="variableTooltipInfo">
                                The rate of return used to discount future cash flows back to their present value.
                              </span>
                            </span>
                          </p>
                          <NumberInput
                            inputName={subcategory}
                            className="numberInputMedium"
                            inputType="cost"
                            value={formData.initiativeCost[category][subcategory]}
                            onChange={(_, value) => handleChange(value, category, subcategory)}
                          />
                        </li>
                        ))}
                        
                      </ul>
                    </>
                    
                  )}
                  
                </div>
              </div>
            </div>
          </div>
        </>
            
        )

      })}

      <div className="settings fullWidth">
          <div className="form-group">
            <label><FontAwesomeIcon icon={faPercent} /> Percentage Assigned to DevOps</label>
            <NumberInput
              inputName="devopsPercent"
              className="numberInputMini"
              inputType="percentage"
              value={formData.percentageAssignedToDevOps}
              onChange={(_,value) => setFormData({ ...formData, percentageAssignedToDevOps: value })}
            />
            {formErrors.percentageAssignedToDevOps && <span className="error">{formErrors.percentageAssignedToDevOps}</span>}
          </div>
          <div className="form-group">
            <label><FontAwesomeIcon icon={faTasks} /> Related initiatives*</label>
            <DarkSelectInput
              options={initiatives}
              value={formData.relatedInitiative}
              onChange={(value) => setFormData({ ...formData, relatedInitiative: value })}
            />
            {formErrors.relatedInitiative && <span className="error">{formErrors.relatedInitiative}</span>}
          </div>
          <div className="form-group">
            <label><FontAwesomeIcon icon={faUsersCog} /> Related Teams</label>
            <DarkInput
              type="text"
              className="darkestInput"
              placeholder="Type here"
              value={formData.relatedTeam}
              onChange={(_,value) => setFormData({ ...formData, relatedTeam: value })}
            />
            {formErrors.relatedTeam && <span className="error">{formErrors.relatedTeam}</span>}
          </div>
        </div>
        <button type="submit" className="submitButton" disabled={loading}>
          <FontAwesomeIcon icon={faPaperPlane} /> {loading ? 'Submitting...' : 'Submit'}
        </button>
        {error && <p className="errorMessage">{error}</p>}
    </form>
  </div>
  );
};

export default TCOForm;
