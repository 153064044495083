import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import DarkSelectInput from '../../../components/forms/DarkSelectInput';
import '../../../components/tables/GraphTable.css';
import './GraphStyles.css';

const ChangeFailureRate = ({ dataset }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Last Month");
  const [selectedInitiative, setSelectedInitiative] = useState('All');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'ascending' });

  const options = ['Today', 'Last Week', 'Last Two Weeks', 'Last Month'];

  // Extract unique initiatives
  const uniqueInitiatives = [...new Set(dataset.changeFailureRate.map(item => item.initiative))];
  uniqueInitiatives.unshift("All");

  let lastIndex = -1;

  const getDistinctColor = () => {
    const numberOfColors = 30; 
    const segmentSize = 360 / numberOfColors; 

    lastIndex = lastIndex + 5;
    
    const hue = Math.floor(lastIndex * segmentSize);
    const pastel = `hsl(${hue}, 100%, 80%)`; 

    return pastel;
  };

  const formatDateString = (dateStr) => {
    const [year, month, day] = dateStr.split('-');
    return `${month}-${day}-${year.slice(-2)}`;
  };

  useEffect(() => {
    if (dataset && dataset.changeFailureRate) {
      let filteredFailures = selectedInitiative !== 'All' 
        ? dataset.changeFailureRate.filter(item => item.initiative === selectedInitiative) 
        : dataset.changeFailureRate;

      const failuresPerDay = {};
      const failureIds = new Set();

      filteredFailures.forEach(({ timestamp, failures, count, initiative }) => {
        if (!failuresPerDay[timestamp]) failuresPerDay[timestamp] = { initiatives: {} };
        if (!failuresPerDay[timestamp].initiatives[initiative]) {
          failuresPerDay[timestamp].initiatives[initiative] = {};
        }
        if (!failuresPerDay[timestamp].initiatives[initiative][failures]) {
          failuresPerDay[timestamp].initiatives[initiative][failures] = 0;
        }
        failuresPerDay[timestamp].initiatives[initiative][failures] += count;
        failureIds.add(failures);
      });

      const sortedDates = Object.keys(failuresPerDay).sort((a, b) => new Date(a) - new Date(b));
      const formattedDates = sortedDates.map(formatDateString);
      const datasets = Array.from(failureIds).map((id) => {
        const backgroundColor = getDistinctColor();
        const data = sortedDates.map(date => {
          let total = 0;
          for (let initiative in failuresPerDay[date].initiatives) {
            total += failuresPerDay[date].initiatives[initiative][id] || 0;
          }
          return total;
        });

        return {
          label: id,
          backgroundColor,
          data,
          stack: 'stack 0',
        };
      });

      let initialTableData = sortedDates.map(date => {
        const initiatives = Object.keys(failuresPerDay[date].initiatives);
        return {
          date: formatDateString(date),
          totalFailures: Object.values(failuresPerDay[date].initiatives).reduce((acc, curr) => acc + Object.values(curr).reduce((a, b) => a + b, 0), 0),
          initiative: initiatives.join(', '),
        };
      });

      initialTableData = sortTableData(initialTableData, sortConfig);

      setFilteredData(initialTableData);
      setTableData(initialTableData);
      setChartData({
        labels: formattedDates,
        datasets,
      });
    }
  }, [dataset, selectedInitiative, sortConfig]);

  const handleTimeframeChange = (value) => {
    setSelectedOption(value);
    let filtered = [];

    switch (value) {
      case 'Today':
        filtered = filteredData.slice(-1);
        break;
      case 'Last Week':
        filtered = filteredData.slice(-7);
        break;
      case 'Last Two Weeks':
        filtered = filteredData.slice(-14);
        break;
      case 'Last Month':
        filtered = filteredData;
        break;
      default:
        filtered = filteredData;
        break;
    }

    const newLabels = filtered.map(item => item.date);
    const newDatasets = chartData.datasets.map(dataset => ({
      ...dataset,
      data: newLabels.map(label => {
        const index = filteredData.findIndex(item => item.date === label);
        return index !== -1 ? dataset.data[index] : 0;
      }),
    }));

    setChartData({
      labels: newLabels,
      datasets: newDatasets,
    });
    setTableData(filtered);
  };

  const handleInitiativeChange = (value) => {
    setSelectedInitiative(value);
  };

  const sortTableData = (data, config) => {
    return data.sort((a, b) => {
      if (a[config.key] < b[config.key]) {
        return config.direction === 'ascending' ? -1 : 1;
      }
      if (a[config.key] > b[config.key]) {
        return config.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortArrow = (column) => {
    if (sortConfig.key !== column) return ' ↑↓';
    return sortConfig.direction === 'ascending' ? ' ↑' : ' ↓';
  };

  const optionsChart = {
    indexAxis: 'x',
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Date',
          color: 'white',
          font: {
            size: 14
          }
        },
        ticks: {
          autoSkip: false,
          color: 'white',
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: 'Failures',
          color: 'white',
          font: {
            size: 14
          }
        },
        ticks: {
          autoSkip: false,
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: 'white'
        }
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const label = tooltipItem.dataset.label || '';
            const value = tooltipItem.raw || 0;
            return `${label}: ${value} failures`;
          }
        },
        titleFont: {
          size: 14, 
        },
        bodyFont: {
          size: 14, 
        },
      }
    },
  };

  const chartContainerStyle = {
    maxWidth: '100%',
    height: '380px',
    overflowX: 'auto',
  };

  const chartStyle = {
    minWidth: `${filteredData.length * 100}px`,
    height: '100%'
  }

  return (
    <div>
      <div style={chartContainerStyle}>
        <div style={chartStyle}>
          <Bar data={chartData} options={optionsChart} className="chartModal" />
        </div> 
      </div>
      <div className="graphModalFooter">
        <div className="graphFilterLabel">Filter by Timeframe:</div>
        <div className="col3">
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={options}
            value={selectedOption}
            onChange={handleTimeframeChange}
          />
        </div>
      </div>
      <div className="graphModalFooter">
        <div className="graphFilterLabel">Filter by Initiative:</div>
        <div className="col3">
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={uniqueInitiatives}
            value={selectedInitiative}
            onChange={handleInitiativeChange}
          />
        </div>
      </div>
      {/* TABLE */}
      { tableData && tableData.length > 0 && (
        <div>
          <br/>
          <h3>Change Failure Rate Summary <i className="fa-solid fa-arrow-down"></i></h3>
          <br/>
          <div className="graphTable">
            <div className="graphTableBody">
              <table>
                <thead className="graphTableHead">
                  <tr>
                    <th onClick={() => handleSort('date')}>Date {getSortArrow('date')}</th>
                    <th onClick={() => handleSort('totalFailures')}>Total Failures {getSortArrow('totalFailures')}</th>
                    <th onClick={() => handleSort('initiative')}>Initiative {getSortArrow('initiative')}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, index) => (
                    <tr key={index}>
                      <td>{row.date}</td>
                      <td>{row.totalFailures}</td>
                      <td>{row.initiative}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangeFailureRate;
