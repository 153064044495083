import { useState, useEffect } from 'react';
import './IntelligentReportWidget.css';
import BasicButton from "../buttons/BasicButton";

// Global variables (unchanged)
let hasFetchedReport = false;
let cachedReportData = null;

const IntelligentReportExpanded = ({ closeModal }) => {
  const [report, setReport] = useState(cachedReportData);
  const [loading, setLoading] = useState(!cachedReportData);
  const [error, setError] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(null);

  useEffect(() => {
    if (hasFetchedReport) {
      if (cachedReportData) {
        setReport(cachedReportData);
        setLoading(false);
      }
      return;
    }

    hasFetchedReport = true;

    fetch('https:/ai.dobcon.com/ai/titanv2/toolchain/report')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        cachedReportData = data;
        setReport(data);
        setLoading(false); // Stop the loader when data is fetched
      })
      .catch(error => {
        console.error('Fetch error:', error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleDownloadReport = () => {
    if (!report) {
      console.error("No report data available to download.");
      setDownloadError(new Error("No report data available to download."));
      return;
    }

    setDownloadLoading(true);
    setDownloadError(null);

    fetch('https://ai.dobcon.com/ai/generate_pdf_report', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(report)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to generate PDF report.');
        }
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        // Optional: Set the desired file name
        link.setAttribute('download', 'DevOps_Toolchain_Health_Report.pdf');
        // Append to the document body
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        // Release the blob URL
        window.URL.revokeObjectURL(url);
        setDownloadLoading(false);
      })
      .catch(error => {
        console.error('Download error:', error);
        setDownloadError(error);
        setDownloadLoading(false);
      });
  };

  // Show the loader if loading state is true
  if (loading) {
    return (
      <div className="loading-spinner">
        <img src="/img/icons/ai-report/Comp 1.gif" alt="Loading..." className="gif-loader" />
      </div>
    );
  }

  if (error) return <p>Error loading report: {error.message}</p>;
  if (!report) return <p>No report data available</p>;

  return (
    <div id="myModal" className="modal">
      <div className="modal-content">
        {/* Report Header */}
        <div className="report-header">
          <h1>DevOps Toolchain Health Report</h1>
          <div className="report-summary">
            <img
              src="/img/icons/ai-report/health-report-health.png"
              alt="DevOps Health Report Icon"
              className="health-icon"
            />
            <div className="vertical-line"></div>
            <div className="report-info">
              <div className="top">
                <p>Initiative: <strong>{report.initiative || 'N/A'}</strong></p>
                <p>Client: <strong>XYZ Corp</strong></p>
                <p>DevOps Health Score: <strong>{report.score || 'N/A'}</strong></p>
              </div>
              <div className="bottom">
                <p className="bottom">{report.health_description || 'No health description available'}</p>
              </div>
              <button
                className="pdf-button"
                type="button"
                onClick={handleDownloadReport}
                disabled={downloadLoading}
              >
                <i className="fas fa-file-download"></i> {downloadLoading ? 'Generating PDF...' : 'Download PDF'}
              </button>
              {downloadError && <p className="error-message">Error: {downloadError.message}</p>}
            </div>
          </div>
        </div>
        
        {/* Report Content */}
        <div className="report-content">
          <hr className="grayLine" />
          <span className="reportStepTag">1</span>
          <div className="fullTablet fullLandscape mb1">
            <h2 className="bigText mb1">Health Diagnosis</h2>
            <div className="section-content">
              {report.health_diagnosis && report.health_diagnosis.length > 0 ? (
                report.health_diagnosis.map((item, index) => (
                  <p key={index}>
                    <strong>{item.title}: </strong>{item.description}
                  </p> 
                ))
              ) : (
                <p>No health diagnosis available</p>
              )}
            </div>
          </div>

          <hr className="grayLine mt2" />
          <span className="reportStepTag">2</span>
          <div className="fullTablet fullLandscape mb1">
            <h2 className="bigText mb1">Areas of Improvement</h2>
            <div className="section-content">
              {report.improvements && report.improvements.length > 0 ? (
                report.improvements.map((item, index) => (
                  <p key={index}>
                    <strong>{item.title}: </strong>{item.description}
                  </p> 
                ))
              ) : (
                <p>No improvements available</p>
              )}
            </div>
          </div>
          
          <hr className="grayLine mt2" />
          <span className="reportStepTag">3</span>
          <div className="fullTablet fullLandscape mb1">
            <h2 className="bigText mb1">Recommendations</h2>
            <div className="section-content">
              {report.recommendations && report.recommendations.length > 0 ? (
                report.recommendations.map((item, index) => (
                  <p key={index}>
                    <strong>{item.title}: </strong>{item.description}
                  </p> 
                ))
              ) : (
                <p>No recommendations available</p>
              )}
            </div>
          </div>
        </div>
        
        {/* Report Footer */}
        <div className="report-footer">
          <div className="report-prediction">
            <img
              src="/img/icons/ai-report/health-report-prediction.png"
              alt="DevOps Health Report Icon"
              className="prediction-icon"
            />
            <div className="desc">
              <h2>Predicted Outcome</h2>
              <p>{report.predicted_outcome || 'No predicted outcome available'}</p>
            </div>
          </div>
          <div className="report-disclaimer">
            <div className="report-disclaimer-content">
              <p>Disclaimer</p>
              <BasicButton onClick={closeModal} color="btnRed">
                Next
              </BasicButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntelligentReportExpanded;
