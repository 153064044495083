import React, { useEffect, useState } from 'react';
import './GraphStyles.css'

/* ChartJS */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import DarkSelectInput from '../../../components/forms/DarkSelectInput';
import './NumberOfEarlyDefects.css'
import { width } from '@fortawesome/free-regular-svg-icons/faAddressBook';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Legend
);

const graphOptions = {
  type: 'bar',
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: 'white' // Set the legend text color to white
      }
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      titleFont: {
        size: 14, // Set font size for the tooltip title
      },
      bodyFont: {
        size: 14, // Set font size for the tooltip body text
      },
    },
  },
  scales: {
    x: {
      stacked: false,
      reverse: false,
      position: 'bottom',
      
      title: {
        display: true,
        text: 'Date',
        color: 'white',
        font: {
          size: 17
        }
      },
      ticks: {
        autoSkip: false, // Show every label
        color: 'white',
        maxRotation: 45,
      },
    },
    y: {
      stacked: false,
      beginAtZero: true,
      title: {
        display: true,
        text: 'Defects',
        color: 'white',
        font: {
          size: 17
        }
      },
      ticks: {
        stepSize: 1,
        color: 'white',
      },
      suggestedMax: function (context) {
        let maxValue = 0;
        context.chart.data.datasets.forEach((dataset) => {
          dataset.data.forEach((value) => {
            if (value > maxValue) maxValue = value;
          });
        });
        return maxValue + 1;
      },
    },
  },
};

const NumberOfEarlyDefects = ({ edd, initiative }) => {
  const [filteredDateData, setFilteredDateData] = useState(edd.All.edd_by_date);
  
  /* INITIATIVE FILTER */
  const [initiativeFilter, setInitiativeFilter] = useState(initiative)
  const initiativeFilterOptions = Object.keys(edd)
  const handleInitiativeFilterChange = (value) => {
    setInitiativeFilter(value)
    setSelectedOption('Last month')
  }

  /* TIMEFRAME FILTER */
  const [selectedOption, setSelectedOption] = useState(
    "Last Month"
  );
  const options = ['Today', 'Last week', 'Last two weeks', 'Last month'];

  const applyTimeframeFilter = () => {
    switch(selectedOption){
      case 'Today':
        setFilteredDateData(edd[initiativeFilter].edd_by_date.slice(-1));
        break;
      case 'Last week':
        setFilteredDateData(edd[initiativeFilter].edd_by_date.slice(-7));
        break;
      case 'Last two weeks':
        setFilteredDateData(edd[initiativeFilter].edd_by_date.slice(-14));
        break;
      case 'Last month':
        setFilteredDateData(edd[initiativeFilter].edd_by_date);
        break;
      default:
        setFilteredDateData(edd[initiativeFilter].edd_by_date);
        break;
    }
  }

  useEffect(() => {
    applyTimeframeFilter()

  }, [selectedOption, initiativeFilter])

  const formatTimestamp = ((timestamp) => {
    const [month, day, year] = timestamp.split('/');
    return `${month}-${day}-${year.slice(-2)}`;
  })

  const data = {
    labels: filteredDateData?.map((entry) => formatTimestamp(entry.timestamp)) || [],
    datasets: [
      {
        label: 'Solved',
        data: filteredDateData?.map((entry) => entry.solved) || [],
        borderColor: '#8CD98C',
        backgroundColor: '#8CD98C',
      },
      {
        label: 'Detected',
        data: filteredDateData?.map((entry) => entry.detected) || [],
        borderColor: '#CE1C1C',
        backgroundColor: '#CE1C1C',
      },
      
    ],
  };

  const chartContainerStyle = {
    maxWidth: '100%',
    height: '400px', // Adjust this value to make the chart taller
    overflowX: 'auto',
  };

  const chartStyle = {
    minWidth: `${filteredDateData.length * 80}px`, // Dynamically calculated width
    height: '100%', // Keeps the height at 100% of the parent container
  };
  

  

  return (
    <div>
      {filteredDateData ? (
        <div className="mb1">
          <div style={chartContainerStyle}>
            <div style={chartStyle}>
              <Bar options={graphOptions} data={data} />
            </div>
          </div>
          <div className="graphModalFooter">
            <div className="graphFilterLabel fw500">Filter by Timeframe:</div>
            <div className="col3">
              <DarkSelectInput
                className="w100 m0 mt0 tal"
                options={options}
                value={selectedOption}
                onChange={(value) => setSelectedOption(value)}
              />
            </div>
          </div>
        <div className="graphModalFooter">
          <div className="graphFilterLabel fw500">Filter by Initiative:</div>
            <div className="col3">
              <DarkSelectInput
                className="w100 m0 mt0 tal"
                options={initiativeFilterOptions}
                value={initiativeFilter}
                onChange={handleInitiativeFilterChange}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p>No data</p>
        </div>
      )}
    </div>
  );
};

export default NumberOfEarlyDefects;
