import React, { useState, useEffect } from 'react';
import './GraphStyles.css';

/* ChartJS */
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar, Pie } from 'react-chartjs-2';

import DarkSelectInput from '../../../components/forms/DarkSelectInput';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend
);

const chartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
        tooltip: {
            mode: 'index',
            intersect: false,
        },
    },
    interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
            beginAtZero: true,
        },
    },
};

const sortDataByDateDescending = (dataArray) => {
    return dataArray.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
};

const DeploymentsToProductionStackedBars = ({ dtp }) => {
    const [deploymentsToProd, setDeploymentsToProd] = useState([]);
    const [filteredDeployments, setFilteredDeployments] = useState([]);
    const [filteredTimeframeDtp, setFilteredTimeframeDtp] = useState({});
    const [selectedOption, setSelectedOption] = useState(<><i className="fa-regular fa-clock " />&nbsp;Timeframe</>);
    const options = ['Today', 'Last Week', 'Last Two Weeks', 'Last Month'];

    useEffect(() => {
        const deployments = dtp?.dtp_per_date !== undefined ? dtp.dtp_per_date : [];
        setDeploymentsToProd(deployments);
        setFilteredDeployments(sortDataByDateDescending(deployments));
        const timeframeData = dtp?.dtp_per_timeframe !== undefined ? dtp.dtp_per_timeframe : [];
        setFilteredTimeframeDtp(timeframeData.find((item) => item.timeframe === 'lastMonth'));
    }, [dtp]);

    const handleTimeframeChange = (value) => {
        const sortedDeploymentsToProd = sortDataByDateDescending(deploymentsToProd);
        setSelectedOption(value);
        let timeframeFilter = 'lastMonth';
        switch (value) {
            case 'Today':
                setFilteredDeployments([sortedDeploymentsToProd.at(-1)])
                timeframeFilter = 'lastDay';
                break;
            case 'Last Week':
                setFilteredDeployments(sortedDeploymentsToProd.slice(-7));
                timeframeFilter = 'lastWeek';
                break;
            case 'Last Two Weeks':
                setFilteredDeployments(sortedDeploymentsToProd.slice(-14));
                timeframeFilter = 'lastTwoWeeks';
                break;
            case 'Last Month':
                setFilteredDeployments(sortedDeploymentsToProd);
                timeframeFilter = 'lastMonth';
                break;
            default:
                setFilteredDeployments(sortedDeploymentsToProd);
                break;
        }
        setFilteredTimeframeDtp(dtp?.dtp_per_timeframe.find((item) => item.timeframe === timeframeFilter));
    };

    const data = {
        labels: filteredDeployments.map(item => item.timestamp),
        datasets: [
            {
                label: 'SUCCESS',
                data: filteredDeployments.map(item => item.g),
                borderColor: '#38AA0A',
                backgroundColor: '#38AA0A',
            },
            {
                label: 'FAILED',
                data: filteredDeployments.map(item => item.r),
                borderColor: '#FF0000',
                backgroundColor: '#FF0000',
            },
        ],
    };

    const pieData = {
        labels: ['Success', 'Failed'],
        datasets: [
            {
                label: 'Deployment Status',
                data: [filteredTimeframeDtp?.g || 0, filteredTimeframeDtp?.r || 0],
                backgroundColor: ['#38AA0A', '#FF0000'],
                borderColor: ['#ffffff', '#ffffff'],
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className="mb1 mt1 ml-2 mr-2">
            {selectedOption === "Today" ? (
                <>
                    <h1 className='displayContents'>Date: {filteredDeployments[0]?.timestamp}</h1>
                    <Pie data={pieData} />
                    <br />
                </>
            ) : (
                <Bar options={chartOptions} data={data} className='chartModal' />)
            }

            <table className="data-table">
                <thead>
                    <tr>
                        <th>Metrics</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Success</td>
                        <td>{filteredTimeframeDtp?.g || 0}</td>
                    </tr>
                    <tr>
                        <td>Failed</td>
                        <td>{filteredTimeframeDtp?.r || 0}</td>
                    </tr>
                </tbody>
            </table>

            <div className="graphModalFooter">
                <div className="col6 fw500">Release averaged lead time</div>
                <div className="col6">
                    <DarkSelectInput
                        className="w100 m0 mt0 tal"
                        options={options}
                        value={selectedOption}
                        onChange={handleTimeframeChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default DeploymentsToProductionStackedBars;
