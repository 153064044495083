import React, { useState, useEffect } from 'react'
import './LoginForm.css'
import { Link, useNavigate } from 'react-router-dom'
import BasicInput from './BasicInput'
import Warning from '../alerts/Warning'
import BasicButton from '../buttons/BasicButton'
import NextField from '../functions/NextField'
import BasicModal from '../modals/BasicModal'
import FirstTimeLoginForm from './FirstTimeLoginForm'
/* Device Fingerprinting */
import DeviceIdentifier from '../identifier/DeviceIdentifier'
/* Amplify */
import { Auth } from 'aws-amplify'
/* API url */
const API = process.env.REACT_APP_LICENCES_API

const LoginFormFp = () => {
  let navigate = useNavigate()

  const [isNewUser, setIsNewUser] = useState(false)
  /* Errors */
  const [error, setError] = useState('')
  const [errorForToken, setErrorForToken] = useState('')

  /* Get device information */
  const [storedBrowserId, setStoredBrowserId] = useState("")
  const [deviceInfo, setDeviceInfo] = useState({})

  const getDeviceInfo = async () => {
    const d = await DeviceIdentifier()
    const bwid = localStorage.getItem('browserID') || ''
    setStoredBrowserId(bwid)
    setDeviceInfo(d)
  }

  const updateStoredBrowserId = () => {
    if (storedBrowserId !== deviceInfo.browserId) {
      localStorage.setItem('browserID', deviceInfo.browserId);
      setStoredBrowserId(deviceInfo.browserId);
    }
  }

  const checkIsLoggedIn = async () => {
    let isLoggedIn = false
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        isLoggedIn = true
      }
    } catch (e) {
      console.error(e)
    }
    // Redirect if logged in already
    if (isLoggedIn && !isNewUser) {
      navigate(`/${btoa('user-home')}`);
    }
  }

  useEffect(() => {
    checkIsLoggedIn()
    getDeviceInfo()
  }, [])

  const printDeviceInfo = () => {
    alert(deviceInfo)
  }

  // Simple login Form Data
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })

  /* Check device (lic service) */
  const checkDevice = async () => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
      body: JSON.stringify({
        email: formData.email,
        storedBrowserId,
        browserId: deviceInfo.browserId,
        browserName: deviceInfo.browserName,
        deviceName: deviceInfo.deviceName,
        deviceId: deviceInfo.deviceId,
        deviceType: deviceInfo.deviceType,
      })
    }
    const deviceCheckResponse = await fetch(`${API}/auth/device-fp`, requestOptions)
    const deviceCheck = await deviceCheckResponse.json()
    
    if (deviceCheckResponse.status !== 200) {
      console.error(deviceCheck)
      return 'deviceNotConfirmed'
    }
    else {
      return 'deviceConfirmed'
    }
  }

  

  const signInUser = async(username, password) => {
    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === "CUSTOM_CHALLENGE" && !isNewUser) {
        try {
          const deviceCheckResult = await checkDevice();
          await Auth.sendCustomChallengeAnswer(user, deviceCheckResult);
          updateStoredBrowserId() 
          setError("");
          navigate(`/${btoa('user-home')}`);
        } catch (error) {
          setError("Wrong device");
        }
      }
    } catch (error) {
      if (error.name === "UserNotConfirmedException") {
        setIsNewUser(true);
      } else if (error.name === "NotAuthorizedException") {
        setError("Incorrect email or password");
      }
    }
    
  }

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    })
  }

  /* Submit */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email) {
      setError("Please enter your email or username");
      return;
    }
    if (!formData.password) {
      setError("Please enter your password");
      return;
    }
    await signInUser(formData.email, formData.password)
  };

  

  /* Token Modal */
  const [isOpenTokenModal, setIsOpenTokenModal] = useState(false)
  const [emailForToken, setEmailForToken] = useState('')
  const [emailTokenSended, setEmailTokenSended] = useState(false)

  const openTokenModal = () => {
    setIsOpenTokenModal(true)
  }

  const closeTokenModal = () => {
    setErrorForToken('')
    setEmailForToken('')
    setIsOpenTokenModal(false)
  }

  /* Submit token */
  const handleSubmitForToken = async (e) => {
    e.preventDefault()
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i

    if (emailForToken === '') {
      setErrorForToken("Please enter your email")
    } else if (!regex.test(emailForToken)) {
      setErrorForToken("Please enter a valid email")
    } else {
      try {
        await Auth.resendSignUp(emailForToken)
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          accept: 'application/json',
          body: JSON.stringify({
            "email": formData.email
          })
        }
        const tokenResetResponse = await fetch(`${API}/token/reset`, requestOptions)
        const data = await tokenResetResponse.json()
        if(tokenResetResponse.status === 200){
          setEmailTokenSended(true)
        } else{
          console.error(data)
          setEmailTokenSended(false)
        }
      } catch (error) {
        setEmailTokenSended(false)
      }
    }
  }

  const backToLogin = () => {
    setFormData({
      email: '',
      password: ''
    })
    setIsNewUser(false)
    setIsOpenTokenModal(false)
    setEmailForToken('')
    setEmailTokenSended(false)
    setError('')
    setErrorForToken('')
    isNewUser(false)
  }

  return (
    <>
      {!isNewUser ? (
        <div>
          <div>
            <img
              src="/img/logo/logo-dobcon-small.png"
              alt="DevOps Business Control"
              className="col6Mobile mb2 hide showMobile"
            />
            <h1 className="mb2" onClick={printDeviceInfo}>Welcome!</h1>
            <br></br>
          </div>
          {error && <div className="mb2"><Warning message={error} /></div>}
          <form>
            <BasicInput
              label="Email address or username (Case sensitive)*"
              type="text"
              value={formData.email}
              onChange={(value) => handleInputChange('email', value)}
              onKeyDown={NextField}
              placeholder="Type your email or username"
            />
            <BasicInput
              label="Password (Case sensitive)*"
              type="password"
              value={formData.password}
              onChange={(value) => handleInputChange('password', value)}
              placeholder="Type your password"
            />
            <p className="lightGray tar mb2">
              <Link to={`/${btoa('forgot-password')}`}>
                <u className="lighterGray">Forgot password?</u>
              </Link>
              <br />
              <small>*Mandatory Fields</small>
            </p>
            <BasicButton
              as="button"
              color="btnRed"
              size="btnFull"
              onClick={handleSubmit}
            >
              Log In
            </BasicButton>
          </form>
        </div>
      ) : (
        <FirstTimeLoginForm 
          email={formData.email} 
          psswd={formData.password}
          checkDevice={checkDevice}
          openTokenModal={openTokenModal}
          updateStoredBrowserId={updateStoredBrowserId}
        />
      )}
      {/* Modal for token */}
      <BasicModal isOpen={isOpenTokenModal} onClose={closeTokenModal}>
        {!emailTokenSended ?
          <>
            <h2 className="mb1">Get a new email token</h2>
            <p className="mb2 fw500">Please enter your email to get a new email token</p>
            {errorForToken && <div className="mb2"><Warning message={errorForToken} /></div>}
            <form>
              <BasicInput
                label="Email (Case sensitive)*"
                type="text"
                value={emailForToken}
                onChange={(value) => setEmailForToken(value)}
                placeholder="Type your email"
              />
              <p className="lighterGray tal mt-1 mb1"><small>*Mandatory fiekds</small></p>
              <BasicButton
                as="button"
                color="btnRed"
                size="btnFull"
                onClick={handleSubmitForToken}
              >
                Submit
              </BasicButton>
            </form>
            <div className="mb1" />
            <BasicButton
              as="button"
              color="btnBlack"
              size="btnFull"
              onClick={closeTokenModal}
            >
              Cancel
            </BasicButton>
          </> :
          <>
            <h2 className="mb1">The Email Token has sended succesfully please check your email.</h2>
            <BasicButton
              as="button"
              color="btnRed"
              size="btnFull"
              onClick={backToLogin}
            >
              Back to Login
            </BasicButton>
          </>
        }
      </BasicModal>
    </>
  )
}

export default LoginFormFp