import React, { useState } from 'react'
import './SearchResponsibleTable.css'
import DropdownSearchbar from '../resources/DropdownSearchbar'

const SearchResponsibleTable = ({
  code,
  build,
  test,
  integration,
  deploy,
  operate,
  monitor,
  currentResponsibles,
  sendResponsibles,
  peopleInvolved
}) => {

  const handleResponsibleChange = (stage, value) => {
    const updatedResponsibles = {
      ...currentResponsibles,
      [stage]: {
        responsible: value,
        required: false
      }
    }
    sendResponsibles(updatedResponsibles)
  }
  // SEARCH VARIABLES
  const emptySearch = {
    CODE: { search: '', filteredResources: [] },
    BUILD: { search: '', filteredResources: [] },
    TEST: { search: '', filteredResources: [] },
    INTEGRATION: { search: '', filteredResources: [] },
    DEPLOY: { search: '', filteredResources: [] },
    OPERATE: { search: '', filteredResources: [] },
    MONITOR: { search: '', filteredResources: [] }
  }
  const [searchData, setSearchData] = useState(emptySearch)

  const handleDelete = (stage) => {
    const updatedResponsibles = {
      ...currentResponsibles,
      [stage]: {
        responsible: '',
        required: true
      },
    }
    setSearchData(emptySearch)
    sendResponsibles(updatedResponsibles)
  }

  const handleSearch = (stage, value) => {
    const filtered = peopleInvolved.filter((resource) =>
      resource.name.toLowerCase().includes(value.toLowerCase())
    )
    setSearchData({
      ...searchData,
      [stage]: {
        ...searchData[stage],
        search: value,
        filteredResources: filtered
      }
    })
  }

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null)

  const handleFocus = (index) => {
    setOpenDropdownIndex(index)
  }

  const handleCloseDropdown = () => {
    setOpenDropdownIndex(null)
  }

  const renderToolRow = (stageData, stage) => {
    if (stageData?.length !== 0) {
      return (
        <tr>
          <td className="columnSmall">{stage}</td>
          <td>
            {currentResponsibles[stage].responsible === "" ?
              <DropdownSearchbar
                value={searchData[stage].search}
                onChange={(value) => handleSearch(stage, value)}
                placeholder="Search resources..."
                options={searchData[stage].filteredResources}
                onClick={(selectedResource) => handleResponsibleChange(stage, selectedResource)}
                isOpen={openDropdownIndex === stage}
                onFocus={() => handleFocus(stage)}
                onClose={handleCloseDropdown}
              /> : currentResponsibles[stage].responsible.name
            }
          </td>
          <td>{currentResponsibles[stage].responsible !== "" && currentResponsibles[stage].responsible.role}</td>
          <td>{currentResponsibles[stage].responsible !== "" && currentResponsibles[stage].responsible.department}</td>
          <td>{currentResponsibles[stage].responsible !== "" && currentResponsibles[stage].responsible.location}</td>
          <td className="columnSmall tac">
            {currentResponsibles[stage].responsible !== "" && (
              <button onClick={() => { handleDelete(stage) }}>
                <i className="fa-solid fa-trash" />
              </button>
            )}
          </td>
        </tr>
      )
    }
    return null
  }

  return (
    <>
      <div className="searchResponsibleTable">
        <div className="searchResponsibleTableBody">
          <table>
            <thead className="searchResponsibleTableHead">
              <tr>
                <th className="columnSmall">Stage</th>
                <th>Name</th>
                <th>Role</th>
                <th>Department</th>
                <th>Location</th>
                <th className="columnSmall tac">Delete</th>
              </tr>
            </thead>
            <tbody>
              {
                code.length === 0 &&
                  build.length === 0 &&
                  test.length === 0 &&
                  integration.length === 0 &&
                  deploy.length === 0 &&
                  operate.length === 0 &&
                  monitor.length === 0 ?
                  <tr>
                    <td colSpan="7">Please select one tool at least to assign the stage responsibles</td>
                  </tr> : null
              }
              {renderToolRow(code, "CODE")}
              {renderToolRow(build, "BUILD")}
              {renderToolRow(test, "TEST")}
              {renderToolRow(integration, "INTEGRATION")}
              {renderToolRow(deploy, "DEPLOY")}
              {renderToolRow(operate, "OPERATE")}
              {renderToolRow(monitor, "MONITOR")}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default SearchResponsibleTable