import React, { useState } from 'react'
import CurrentDate from '../../components/resources/CurrentDate'
import BusinessNavFilter from '../../components/nav/BusinessNavFilter'
import BusinessInitiativeFilter from '../../components/filters/BusinessInitiativeFilter'
import { useInitiativesContext } from './resources/BusinessInitiativesContext'
import InitiativePill from '../../components/pills/InitiativePill'
import BusinessMetricsTable from '../../components/tables/BusinessMetricsTable'
import NumberInput from '../../components/forms/NumberInput'

const BusinessTotalCost = () => {
  /* Initiatives */
  const initiatives = useInitiativesContext()

  /* Options for filter */
  const [selectedOption, setSelectedOption] = useState('')
  const options = ['Greater than', 'Lower than']

  const handleFilter = (value) => {
    setSelectedOption(value)
    setFilter({ ...filter, type: value })
  }

  /* Filtering by ROI */
  const [filter, setFilter] = useState({ type: '', value: '' })

  const applyFilter = (type) => {
    switch (type) {
      case 'Greater than':
        return (item) => item.total_cost > filter.value
      case 'Lower than':
        return (item) => item.total_cost < filter.value
      default:
        return () => true
    }
  }

  const filteredInitiatives = initiatives.filter(applyFilter(filter.type))

  const handleFilterChange = (inputName, value) => {
    if (inputName === 'greater' || inputName === 'lower') {
      const newValue = parseFloat(value.replace(/,/g, ''))
      if (!isNaN(newValue)) {
        setFilter((prev) => ({ ...prev, value: newValue }))
      }
    }
  }

  return (
    <div className="containerToRight">
      <h1>
        <span className="lightGray">DOBCO<span className="bigN-h1">n</span></span> Business per Cost
      </h1>
      <p className="mediumText mt1 fw500">Filter All Your Initiatives by Total Initiative Cost</p>
      <div className="tar">
        <CurrentDate />
        <hr className="grayLine mt1" />
      </div>
      <div className="flexContainer spaceBetween pt1">
        <div className="col2 mb1">
          <BusinessNavFilter
            links={[
              'Business Metrics',
              'Initiative Resources',
              'Initiative Start Date',
              'Initiative Status',
              'Location'
            ]}
            activeSection="Total Initiative Cost"
          />
        </div>
        <div className="col2 mb1">
          <BusinessInitiativeFilter
            options={options}
            onChange={handleFilter}
          />
        </div>
        <div className="col8 flexContainer alignCenter mb1">
          {selectedOption && (
            <>
              <div className="col2">
                {selectedOption === "Greater than" ? "Greater than:" : "Lower than:"}
              </div>
              <div className='col3'>
                <NumberInput
                  className="numberInputDarkest"
                  inputType="cost"
                  inputName={filter.type === 'Greater than' ? "greater" : "lower"}
                  placeholder="Type here"
                  onChange={handleFilterChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <hr className="grayLine" />
      <div className="flexContainer pt1">
        {filteredInitiatives.length !== 0 ? (
          filteredInitiatives.map((initiative) => (
            <InitiativePill
              key={initiative.name}
              pillTitle={initiative.name}
              pillLink={initiative.name}
            />
          ))
        ) : (
          <p className="fw500 mb1">No initiatives Found</p>
        )}
      </div>
      <hr className="grayLine mb2" />
      <BusinessMetricsTable initiatives={filteredInitiatives} />
    </div>
  )
}

export default BusinessTotalCost