import './Warning.css'

const Warning = ({ message }) => {
  return (
    <div className="warning fw500">
      <i className="fa-solid fa-triangle-exclamation" /> {message}
    </div>
  )
}

export default Warning