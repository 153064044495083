import LoginFormFp from '../../components/forms/LoginFormFp' // Version for Fingerprint tests

const UserLoginFp = () => {
  return (
    <>
      <div className="container flexContainer alignCenter h100">
        <div className="col7 col6Tablet">
          <img
            src="/img/logo/logo-dobcon-large.png"
            alt="DevOps Business Control"
            className="col11"
          />
          <p className="mediumText mt1 col11 taf fw500">
            Analyze your DevOps financial and performance metrics, identify and optimize your team performance by measuring the efficiency of your software delivery pipeline, and monitor your DevOps operation to improve your initiatives.
          </p>
        </div>
      </div>
      <div className="sideContainer col5 grayBg flexContainer alignCenter fullLandscape">
        <div className="tac mAuto col10">
          <LoginFormFp />
        </div>
      </div>
    </>
  )
}

export default UserLoginFp