import React, { useState } from 'react'
import './CheckResourcesTable.css'
import BasicButton from '../buttons/BasicButton'
import BasicInput from '../forms/BasicInput'
import NextField from '../functions/NextField'
import FormModal from '../modals/FormModal'
import Warning from '../alerts/Warning'
import BasicModal from '../modals/BasicModal'
import StatusPill from '../pills/StatusPill'

const API = process.env.REACT_APP_SETUP_API

const CheckResourcesTable = ({
  invalid,
  conflicted,
  repeated,
  resources,
  updated,
  incomplete,
  handleEditInvalidResources,
  handleEditRepeatedEmails,
  handleEditRepeatedResources,
  handleEditNewResources,
  handleEditIncompleteResources,
  handleDeleteInvalidResource,
  handleDeleteRepeatedEmails,
  handleDeleteRepeatedResource,
  handleDeleteNewResource,
  handleDeleteIncompleteResources,
  
}) => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Errors */
  const [error, setError] = useState('')
  /* Resources modal */
  const [isOpenResourcesModal, setIsOpenResourcesModal] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [selectedSource, setSelectedSource] = useState(null)

  const closeModal = () => {
    setError('')
    setIsOpenResourcesModal(false)
  }

  /* Edit an exists resource */
  const [editResourceData, setEditResourceData] = useState({
    index: '',
    name: '',
    role: '',
    department: '',
    location: '',
    email: ''
  })

  const handleEditResourceInputChange = (field, value) => {
    setEditResourceData({
      ...editResourceData,
      [field]: value,
    })
  }

  const handleEditResource = (resource, source, index) => {
    setIsOpenResourcesModal(true)
    setSelectedSource(source)

    let editData = {}

    switch (source) {
      case 'invalid':
      case 'repeated':
      case 'incomplete':
      case 'conflicted':
        editData = {
          index: index,
          name: resource.name,
          role: resource.role,
          department: resource.department,
          location: resource.location,
          email: resource.email
        }
        break;
      
      case 'new':
        editData = {
          name: resource.name,
          role: resource.role,
          department: resource.department,
          location: resource.location,
          email: resource.email
        }
        break;
      default:
        break;
    }

    setEditResourceData(editData)
  }

  const checkEmailAvailability = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        email: editResourceData.email
      })
    };
    try{
      const response = await fetch(`${API}/resources/${currentCompany}/email-check`, requestOptions);
      if (response.status === 409) {
        return "The resource's email is already in use, please choose another one";
      }
      return ""
    } catch{
      return "Something got wrong during email availability check";
    }
  }

  // Validate email format
  const validateEmail = async() => {
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
    if (!editResourceData.email) {
      return "Please enter the new resource's email";
    } else if (!regex.test(editResourceData.email)) {
      return "Please enter a valid email";
    }
    return await checkEmailAvailability()
  }

  const checkNewResourceInformation = async() => {
    if (!editResourceData.name) {
      return "Please enter the new resource's name";
    } else if (!editResourceData.role) {
      return "Please enter the new resource's role";
    } else if (!editResourceData.department) {
      return "Please enter the new resource's department";
    } else if (!editResourceData.location) {
      return "Please enter the new resource's location";
    }
    return await validateEmail()
  }

  const resetFormState = () => {
    // Set hooks to their initial state
    setError('')
    setIsOpenResourcesModal(false)
    setSelectedSource(null)
    setEditResourceData({
      name: '',
      role: '',
      department: '',
      location: '',
      email: ''
    })
  }

  const submitEditResource = async (e) => {
    e.preventDefault()
    
    let validationError = ''

    if (selectedSource === 'invalid' || selectedSource === 'conflicted' || selectedSource === 'repeated') {
      validationError = await validateEmail()
    } else {
      validationError = await checkNewResourceInformation()
    }

    if (validationError) {
      setError(validationError)
      resetFormState()
      return
    } 
    // Send data based on selectedSource
    switch (selectedSource) {
      case 'invalid':
        handleEditInvalidResources(editResourceData)
        break;
      case 'repeated':
        handleEditRepeatedEmails(editResourceData)
        break;
      case 'incomplete':
        handleEditIncompleteResources(editResourceData)
        break;
      case 'conflicted':
        handleEditRepeatedResources(editResourceData)
        break;
      default: // new
        handleEditNewResources(editResourceData)
        break;
    }
    resetFormState()
  }

  /* Delete an exists resource */
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const [selectedResourceToDelete, setSelectedResourceToDelete] = useState(null)

  const closeModalDelete = () => {
    setSelectedResourceToDelete(null)
    setIsOpenModalDelete(false)
  }

  const handleDeleteResource = (resource) => {
    setSelectedResourceToDelete(resource)
    setIsOpenModalDelete(true)
  }

  const confirmDeleteResource = async () => {
    if (selectedResourceToDelete) {
      const { email } = selectedResourceToDelete
      if (selectedResourceToDelete.status === 'invalid') {
        handleDeleteInvalidResource(email)
      } else if (selectedResourceToDelete.status === 'repeated') {
        handleDeleteRepeatedEmails(email)
      } else if (selectedResourceToDelete.status === 'conflicted') {
        handleDeleteRepeatedResource(email)
      } else if (selectedResourceToDelete.status === 'new') {
        handleDeleteNewResource(email)
      } else if (selectedResourceToDelete.status === 'incomplete'){
        handleDeleteIncompleteResources(email)
      }

      closeModalDelete()
    }
  }

  return (
    <div className="checkResourcesTable">
      <div className="checkResourcesTableBody">
        <table>
          <thead className="checkResourcesTableHead">
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Department</th>
              <th>Status</th>
              <th className="columnSmall tac">Edit</th>
              <th className="columnSmall tac">Delete</th>
            </tr>
          </thead>
          <tbody>
            {invalid.length !== 0 ?
              invalid.map((resource, index) => (
                <tr key={resource.name}>
                  <td>{resource.name}</td>
                  <td>{resource.role}</td>
                  <td>{resource.department}</td>
                  <td><StatusPill status="pending-mod" message="Invalid email" /></td>
                  <td className="columnSmall tac">
                    <button
                      onClick={() => handleEditResource({ ...resource, index }, 'invalid', index)}
                    >
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                  </td>
                  <td className="columnSmall tac">
                    <button onClick={() => handleDeleteResource({ ...resource, status: 'invalid' })}>
                      <i className="fa-solid fa-trash" />
                    </button>
                  </td>
                </tr>
              )) : null
            }

            {repeated.length !== 0 ?
              repeated.map((resource, index) => (
                <tr key={resource.name}>
                  <td>{resource.name}</td>
                  <td>{resource.role}</td>
                  <td>{resource.department}</td>
                  <td><StatusPill status="pending-mod" message="Repeated email" /></td>
                  <td className="columnSmall tac">
                    <button
                      onClick={() => handleEditResource({ ...resource, index }, 'repeated', index)}
                    >
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                  </td>
                  <td className="columnSmall tac">
                    <button onClick={() => handleDeleteResource({ ...resource, status: 'repeated' })}>
                      <i className="fa-solid fa-trash" />
                    </button>
                  </td>
                </tr>
              )) : null
            }

            {incomplete.length !== 0 ?
              incomplete.map((resource, index) => (
                <tr key={resource.name}>
                  <td>{resource.name}</td>
                  <td>{resource.role}</td>
                  <td>{resource.department}</td>
                  <td><StatusPill status="pending-mod" message="Incomplete data" /></td>
                  <td className="columnSmall tac">
                    <button onClick={() => handleEditResource({ ...resource, index }, 'incomplete', index)}>
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                  </td>
                  <td className="columnSmall tac">
                    <button onClick={() => handleDeleteResource({ ...resource, status: 'incomplete' })}>
                      <i className="fa-solid fa-trash" />
                    </button>
                  </td>
                </tr>
              )) : null
            }   

            {conflicted.length !== 0 ?
              conflicted.map((resource, index) => (
                <tr key={updated ? resource.name : resource.new_resource.name}>
                  <td>{updated ? resource.name : resource.new_resource.name}</td>
                  <td>{updated ? resource.role : resource.new_resource.role}</td>
                  <td>{updated ? resource.department : resource.new_resource.department}</td>
                  <td>
                    <StatusPill
                      status={updated ? "success" : "fail"}
                      message={updated ? "Ready to update" : "Email conflict"}
                    />
                  </td>
                  <td className="columnSmall tac">
                    <button
                      onClick={() => handleEditResource({ ...resource, index }, 'conflicted', index)}
                    >
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                  </td>
                  <td className="columnSmall tac">
                    <button
                      onClick={() => handleDeleteResource(updated ? { ...resource, status: 'conflicted' } : { ...resource.new_resource, status: 'conflicted' })}
                    >
                      <i className="fa-solid fa-trash" />
                    </button>
                  </td>
                </tr>
              )) : null
            }

            {resources.length !== 0 ?
              resources.map((resource) => (
                <tr key={resource.name}>
                  <td>{resource.name}</td>
                  <td>{resource.role}</td>
                  <td>{resource.department}</td>
                  <td><StatusPill status="success" message="Ready to upload" /></td>
                  <td className="columnSmall tac">
                    <button onClick={() => handleEditResource({ ...resource, source: 'new' }, 'new')}>
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                  </td>
                  <td className="columnSmall tac">
                    <button onClick={() => handleDeleteResource({ ...resource, status: 'new' })}>
                      <i className="fa-solid fa-trash" />
                    </button>
                  </td>
                </tr>
              )) : null
            }
          </tbody>
        </table>
      </div>
      {/* Edit resources */}
      <FormModal isOpen={isOpenResourcesModal} onClose={closeModal}>
        <h2 className="mb2">Edit resource</h2>
        {error && <div className="mt-1 mb2"><Warning message={error} /></div>}
        <form>
          <div className="flexContainer spaceBetween">
            {(selectedSource === 'new' || selectedSource === 'incomplete') && (
              <>
                <div className="col6">
                  <BasicInput
                    label="Name*"
                    type="text"
                    value={editResourceData.name}
                    onChange={(value) => handleEditResourceInputChange('name', value)}
                    onKeyDown={NextField}
                    placeholder="New resource's name"
                  />
                </div>
                <div className="col6">
                  <BasicInput
                    label="Role*"
                    type="text"
                    value={editResourceData.role}
                    onChange={(value) => handleEditResourceInputChange('role', value)}
                    onKeyDown={NextField}
                    placeholder="New resource's role"
                  />
                </div>
                <div className="col6">
                  <BasicInput
                    label="Department*"
                    type="text"
                    value={editResourceData.department}
                    onChange={(value) => handleEditResourceInputChange('department', value)}
                    onKeyDown={NextField}
                    placeholder="New resource's department"
                  />
                </div>
                <div className="col6">
                  <BasicInput
                    label="Location*"
                    type="text"
                    value={editResourceData.location}
                    onChange={(value) => handleEditResourceInputChange('location', value)}
                    onKeyDown={NextField}
                    placeholder="New resource's location"
                  />
                </div>
              </>
            )}
            <div className="col12">
              <BasicInput
                label="Email address (Case sensitive)*"
                type="text"
                value={editResourceData.email}
                onChange={(value) => handleEditResourceInputChange('email', value)}
                placeholder={`New resource's email`}
              />
            </div>
          </div>
          <p className="lightGray tal mt-1 mb1">
            <small>*Mandatory Fields</small>
          </p>
          <BasicButton
            color="btnRed"
            size="btnFull"
            onClick={submitEditResource}
          >
            Edit resource
          </BasicButton>
          <div className="mb1" />
          <BasicButton
            color="btnBlack"
            size="btnFull"
            onClick={closeModal}
          >
            Cancel
          </BasicButton>
        </form>
      </FormModal>
      {/* Prevent resource delete modal */}
      <BasicModal isOpen={isOpenModalDelete} onClose={closeModalDelete}>
        <h2 className="mb2 tac">Are you sure that you want to delete this resource?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={confirmDeleteResource}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={closeModalDelete}
        >
          Cancel
        </BasicButton>
      </BasicModal>
    </div>
  )
}

export default CheckResourcesTable