const CurrentDate = () => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec"
  ]

  const dateObject = new Date()
  //const currentDate = `${monthNames[current.getMonth()]}/${current.getDate()}/${current.getFullYear()}`
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const currentDate = dateObject.toLocaleDateString('en-US', options);

  return (
    <>
      {currentDate}
    </>
  )
}

export default CurrentDate
