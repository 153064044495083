function generateMockTotalProductionByDeveloper() {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 29); // Start date is 30 days before the end date

    // Assuming authors can work on multiple projects but keeping a specific repo service for each
    const projects = [
        { name: "initiative_1", repoService: "GitLab", authors: ["John Doe", "Jane Doe", "Dana Bucket"] },
        { name: "initiative_2", repoService: "GitHub", authors: ["Alice Smith", "Bob Johnson"] },
        { name: "initiative_3", repoService: "Bitbucket", authors: ["Charlie Bit", "Dana Bucket", "Bob Johnson"] }
    ];

    let payload = [];

    projects.forEach(project => {
        project.authors.forEach(author => {
            for (let i = 0; i < 30; i++) {
                const date = new Date(startDate);
                date.setDate(startDate.getDate() + i);
                const dateString = date.toISOString().split('T')[0];

                payload.push({
                    date: dateString,
                    author: author,
                    repo_service: project.repoService,
                    commits: Math.floor(Math.random() * 51), // Random number of commits between 0 and 50
                    project: project.name,
                    initiative: project.name // Adding initiative based on project name
                });
            }
        });
    });

    return payload;
}

export default generateMockTotalProductionByDeveloper;
