import './GraphModal.css'

const GraphModal = ({
  isOpen,
  graph,
  graphTitle,
  onClose
}) => {
  if (!isOpen) return null

  return (
    <div
      className={
        `graphModalBack flexContainer alignCenter justifyCenter 
       ${isOpen ? 'modalActive' : 'modalNoActive'}`
      }
    >
      <div className="graphModalContent">
        <div className='graphModalCloseButton'>
          <i className="fa-regular fa-circle-xmark bigText lightGray" onClick={onClose}/>
        </div>
        
        <div className='graphModalTitle'> 
          <h2>{graphTitle}</h2>
          
        </div>
        <br/>
        {graph}
      </div>
    </div>
  )
}

export default GraphModal