import React, { useState, useEffect } from 'react'
import './LicensesTable.css'
import BasicButton from '../buttons/BasicButton'
import FormModal from '../modals/FormModal'
import BasicInput from '../forms/BasicInput'
import NextField from '../functions/NextField'
import Warning from '../alerts/Warning'
import BasicModal from '../modals/BasicModal'
import SelectInput from '../forms/SelectInput'
/* Amplify */
import { Auth } from 'aws-amplify'
import StatusPill from '../pills/StatusPill'

const API = process.env.REACT_APP_LICENCES_API

const LicensesTable = () => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* List of users */
  const [usersList, setUsersList] = useState([])
  /* Available Licenses */
  const [availableLicenses, setAvailableLicenses] = useState(0)
  /* Options for input */
  const [selectedOption, setSelectedOption] = useState("Please select the user's role")
  const options = [
    'CEO',
    'PM',
    'VP/Director Business', 
    'VP/Director Engineering',
    'Business Manager',
    'Engineering Manager', 
    'Engineer/Developer', 
    'Business Analyst'
  ]

  /* Fetch users list */
  const getCompanyLicenses = async() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    const response = await fetch(`${API}/${currentCompany}`, requestOptions)
    if(response.status === 200){
      const data = await response.json()
      setAvailableLicenses(data['available_licenses'])
      setUsersList(data['assigned_licenses'])
    }
  }

  useEffect(() => {
    getCompanyLicenses()
  }, [])

  /* Errors */
  const [error, setError] = useState('')

  /* Modal for add or edit user */
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedLicense, setSelectedLicense] = useState(null)

  const openModal = () => {
    setIsOpenModal(true)
  }

  const closeModal = () => {
    setAddUserData({
      name: '',
      email: '',
      role: '',
      department: ''
    })
    setError('')
    setSelectedUser(null)
    setSelectedLicense(null)
    setIsOpenModal(false)
  }

  /* Add user */
  const [addUserData, setAddUserData] = useState({
    name: '',
    email: '',
    role: '',
    department: ''
  })

  const handleAddUserInputChange = (field, value) => {
    setAddUserData({
      ...addUserData,
      [field]: value,
    })
  }

  const handleAddRole = (value) => {
    setSelectedOption(value)
    handleAddUserInputChange('role', value)
  }

  const submitAddUser = async (e) => {
    e.preventDefault()
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
    const password = "Temppass1*"

    if (!addUserData.name) {
      setError("Please enter the user's name")
    } else if (!addUserData.department) {
      setError("Please enter the user's department")
    } else if (!addUserData.role) {
      setError("Please enter the user's role")
    } else if (!addUserData.email) {
      setError("Please enter the user's email")
    } else if (!regex.test(addUserData.email)) {
      setError("Please enter a valid email")
    } else {
      try {
        await Auth.signUp({
          username: addUserData.email,
          password: password,
          attributes: {
            name: addUserData.name,
            email: addUserData.email,
            'custom:role': addUserData.role,
            'custom:department': addUserData.department,
            'custom:company': currentCompany
          }
        })
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({
            name: addUserData.name,
            username: addUserData.email,
            email: addUserData.email,
            role: addUserData.role,
            department: addUserData.department,
          }),
        }
        await fetch(`${API}/${currentCompany}`, requestOptions)
        setSelectedUser(null)
        setSelectedLicense(null)
        getCompanyLicenses()
        setAddUserData({
          name: '',
          email: '',
          role: '',
          department: ''
        })
        setError('')
        setIsOpenModal(false)
      } catch (err) {
        console.error(err.name)
        if(err.name === 'UsernameExistsException'){
          setError('License already assigned to this email')
        }
        console.error(err)
      }
    }
  }

  /* Edit user */
  /* Warning */
  const [editWarningModal, setEditWarningModal] = useState(false)
  const [editWarningMsg, setEditWarningMsg] = useState(false)

  const [editUserData, setEditUserData] = useState({
    name: '',
    email: '',
    role: '',
    department: '',
    username: ''
  })

  const handleEditUserInputChange = (field, value) => {
    setEditUserData({
      ...editUserData,
      [field]: value,
    })
  }

  const handleEdit = (user, licenseId) => {
    setSelectedLicense(licenseId)
    setSelectedUser(user)
    setEditUserData({
      name: user.name,
      email: user.email,
      role: user.role,
      department: user.department,
      username: user.username
    })
    setIsOpenModal(true)
  }

  const handleEditRole = (value) => {
    setSelectedOption(value)
    handleEditUserInputChange('role', value)
  }

  const submitEditUser = async (e) => {
    e.preventDefault()
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i

    if (!editUserData.name) {
      setError("Please enter the new user's name")
    } else if (!editUserData.department) {
      setError("Please enter the new user's department")
    } else if (!editUserData.role) {
      setError("Please enter the new user's role")
    } else if (!editUserData.email) {
      setError("Please enter the new user's email")
    } else if (!regex.test(editUserData.email)) {
      setError("Please enter a valid email")
    } else {
      /* If the original user's email changed */
      if (selectedUser.email !== editUserData.email) {
        setEditWarningModal(true)
        setError('')
      } else {
        try {
          const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
            body: JSON.stringify({
              name: editUserData.name,
              email: editUserData.email,
              role: editUserData.role,
              department: editUserData.department
            }),
          }
          await fetch(`${API}/users/attributes`, requestOptions)
          setError('')
          setIsOpenModal(false)
          getCompanyLicenses()
          setSelectedUser(null)
          setSelectedLicense(null)
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  const submitReassignedLicense = async (e) => {
    e.preventDefault()
    const password = "Temppass1*"

    try {
      await Auth.signUp({
        username: editUserData.email,
        password: password,
        attributes: {
          name: editUserData.name,
          email: editUserData.email,
          'custom:role': editUserData.role,
          'custom:department': editUserData.department,
          'custom:company': currentCompany
        }
      })
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          name: editUserData.name,
          email: editUserData.email,
          role: editUserData.role,
          department: editUserData.department
        })
      }
      await fetch(`${API}/${selectedLicense}/edit`, requestOptions)
      setEditWarningModal(false)
      setIsOpenModal(false)
      getCompanyLicenses()
    } catch (error) {
      console.error(error)
      setEditWarningMsg(true)
    }
  }


  const submitForgetDevice = async (e) => {
    e.preventDefault()
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
      }
      await fetch(`${API}/devices/${editUserData.username}`, requestOptions)
      setError('')
      setIsOpenModal(false)
      getCompanyLicenses()
      setSelectedUser(null)
      setSelectedLicense(null)
    } catch (error) {
      console.error(error)
      setEditWarningMsg(true)
    }

  }

  /* Delete user */
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)

  const closeModalDelete = () => {
    setSelectedUser(null)
    setSelectedLicense(null)
    setIsOpenModalDelete(false)
  }

  const handleDeleteUser = (email, licenseId) => {
    setSelectedUser(email)
    setSelectedLicense(licenseId)
    setIsOpenModalDelete(true)
  }

  const confirmDeleteUser = async () => {
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
      }
      await fetch(`${API}/${selectedLicense}/edit`, requestOptions)
      getCompanyLicenses()
      closeModalDelete()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <div className="licensesTable">
        <div className="licensesTableHead">
          <h2>Users</h2>
          <p className="lightGray">
            {
              availableLicenses === 0 ?
                "You don't have licenses available" :
                `You have ${availableLicenses} unassigned licenses`
            }
          </p>
        </div>
        <div className="licensesTableBody">
          <table>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Department</th>
                <th className="columnSmall tac">Status</th>
                <th className="columnSmall tac">Edit</th>
                <th className="columnSmall tac">Delete</th>
              </tr>
            </thead>
            <tbody>
              {usersList.map((user) => (
                <tr key={user._id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>{user.department}</td>
                  <td className="columnSmall">
                    {user.status === "active" ? <StatusPill status="success" message={user.status} /> : null}
                    {user.status === "inactive" ? <StatusPill status="fail" message={user.status} /> : null}
                    {user.status === "" ? <StatusPill status="pending" message={user.status} /> : null}
                  </td>
                  <td className="columnSmall tac">
                    <button onClick={() => handleEdit(user, user._id)}>
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                  </td>
                  <td className="columnSmall tac">
                    <button onClick={() => handleDeleteUser(user.email, user._id)}>
                      <i className="fa-solid fa-trash" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="licensesTableFooter">
          <BasicButton
            color={availableLicenses === 0 ? "btnBlack" : "btnRed"}
            size="btnSmall"
            onClick={openModal}
            isDisabled={availableLicenses === 0 ? true : false}
          >
            Create User
          </BasicButton>
        </div>
      </div>
      {/* Modal to add or edit user */}
      <FormModal isOpen={isOpenModal} onClose={closeModal}>
        {selectedUser ? (
          <>
            <h2 className="mb2">Edit User</h2>
            {error && <div className="mt-1 mb2"><Warning message={error} /></div>}
            <form>
              <BasicInput
                label="Full Name*"
                type="text"
                value={editUserData.name}
                onChange={(value) => handleEditUserInputChange('name', value)}
                onKeyDown={NextField}
                placeholder="New user's name"
              />
              <BasicInput
                label="Department*"
                type="text"
                value={editUserData.department}
                onChange={(value) => handleEditUserInputChange('department', value)}
                onKeyDown={NextField}
                placeholder="New user's department"
              />
              <SelectInput
                label="Role*"
                options={options}
                value={editUserData.role}
                onChange={handleEditRole}
              />
              <BasicInput
                label="Email Address (Case sensitive)**"
                type="text"
                value={editUserData.email}
                onChange={(value) => handleEditUserInputChange('email', value)}
                placeholder="New user's email"
              />
              <p className="lightGray tal mt-1 mb1">
                <small>
                  *Mandatory Fields
                  <br />
                  **Chaning the user's email will result in the creation of a new account with the updated email, and the previous account will be deleted
                </small>
              </p>
              <BasicButton
                color="btnRed"
                size="btnFull"
                onClick={submitEditUser}
              >
                Save Changes
              </BasicButton>
              <div className="mb1" />
              <BasicButton
                color="btnBlack"
                size="btnFull"
                onClick={closeModal}
              >
                Cancel
              </BasicButton>
              <div className="mb1" />
              <BasicButton
                color="btnBlack"
                size="btnFull"
                onClick={submitForgetDevice}
              >
                Forget device
              </BasicButton>
            </form>
            {/* Warning edit modal */}
            <BasicModal isOpen={editWarningModal} onClose={() => setEditWarningModal(false)}>
              {
                !editWarningMsg ?
                  <>
                    <h2 className="mb2 tac">
                      If you edit the user's email, this license will be reassigned.
                      <br />
                      Do you want to continue?
                    </h2>
                    <BasicButton
                      color="btnRed"
                      size="btnFull"
                      onClick={submitReassignedLicense}
                    >
                      Confirm
                    </BasicButton>
                  </>
                  :
                  <h2 className="mb2 tac">
                    There's a license assigned whit this email, please try with another one
                  </h2>
              }

              <div className="mb1" />
              <BasicButton
                color="btnBlack"
                size="btnFull"
                onClick={() => { setEditWarningModal(false); setEditWarningMsg(false); }}
              >
                Cancel
              </BasicButton>
            </BasicModal>
          </>
        ) : (
          <>
            <h2 className="mb2">Add User</h2>
            {error && <div className="mt-1 mb2"><Warning message={error} /></div>}
            <form>
              <div className="flexContainer spaceBetween">
                <div className="col6">
                  <BasicInput
                    label="Name*"
                    type="text"
                    value={addUserData.name}
                    onChange={(value) => handleAddUserInputChange('name', value)}
                    onKeyDown={NextField}
                    placeholder="User's name"
                  />
                </div>
                <div className="col6">
                  <BasicInput
                    label="Department*"
                    type="text"
                    value={addUserData.department}
                    onChange={(value) => handleAddUserInputChange('department', value)}
                    onKeyDown={NextField}
                    placeholder="User's department"
                  />
                </div>
              </div>
              <SelectInput
                label="Role*"
                options={options}
                value={selectedOption}
                onChange={handleAddRole}
              />
              <BasicInput
                label="Email address (Case sensitive)*"
                type="text"
                value={addUserData.email}
                onChange={(value) => handleAddUserInputChange('email', value)}
                placeholder="User's email"
              />
              <p className="lightGray tal mt-1 mb1">
                <small>*Mandatory Fields</small>
              </p>
              <BasicButton
                color="btnRed"
                size="btnFull"
                onClick={submitAddUser}
              >
                Add User
              </BasicButton>
              <div className="mb1" />
              <BasicButton
                color="btnBlack"
                size="btnFull"
                onClick={closeModal}
              >
                Cancel
              </BasicButton>
            </form>
          </>
        )}
      </FormModal>
      {/* Prevent user delete modal */}
      <BasicModal isOpen={isOpenModalDelete} onClose={closeModalDelete}>
        <h2 className="mb2 tac">Are you sure that you want to delete this user?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={confirmDeleteUser}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={closeModalDelete}
        >
          Cancel
        </BasicButton>
      </BasicModal>
    </>
  )
}

export default LicensesTable