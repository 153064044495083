import './Success.css'

const Success = ({ message }) => {
  return (
    <div className="success fw500">
      <i className="fa-solid fa-circle-check" /> {message}
    </div>
  )
}

export default Success