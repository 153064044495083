import React, { useEffect, useState } from 'react'
import './SearchServsAppsTable.css'
import SearchBar from '../resources/SearchBar'

const API = process.env.REACT_APP_SETUP_API

const SearchServsAppsTable = ({ updateServApps, currentServApps }) => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Services and applications list */
  const [servApps, setServApps] = useState([])
  /* Fetch services and applications list */
  const getCompanyServApps = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    const response = await fetch(`${API}/servapps/${currentCompany}`, requestOptions)
    const data = await response.json()
    setServApps(data)
  }

  useEffect(() => {
    getCompanyServApps()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Filtering by searchBar */
  const [searchServApp, setSearchServApp] = useState('')
  const [filteredServApps, setFilteredServApps] = useState([])

  const handleSearch = (value) => {
    const searchServApp = value
    setSearchServApp(searchServApp)

    const filtered = servApps.filter(servApp =>
      servApp.name.toLowerCase().includes(searchServApp.toLowerCase())
    )

    setFilteredServApps(filtered)
  }

  const displayServApps = searchServApp === '' ? servApps : filteredServApps
  
  const handleSelectServApp = (servApp) => {
    updateServApps(servApp)
  }

  return (
    <div className="searchServsAppsTable">
      <div className="searchServsAppsTableHead">
        <h2>Services and Applications</h2>
        <SearchBar
          className="searchBarMedium"
          placeholder="Search services and applications"
          onChange={handleSearch}
          value={searchServApp}
        />
      </div>
      <div className="searchServsAppsTableBody">
        <table>
          <thead>
            <tr>
              <th className="columnSmall">Select</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {displayServApps.length === 0 ? (
              <tr><td colSpan="2">No services or applications found</td></tr>
            ) : (
              displayServApps.map((servApp, index) => (
                <tr key={servApp.id}>
                  <td className="tac">
                    <span
                      className={
                        `selectServApp ${currentServApps.some((selectedItem) => selectedItem.id === servApp.id) 
                          ? 'selectedServApp' : ''}`
                      }
                      onClick={() => handleSelectServApp(servApp)}
                    />
                  </td>
                  <td>{servApp.name}</td>
                </tr>
              ))
            )}

          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SearchServsAppsTable