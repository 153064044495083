export const fakeReleaseAvg = {
  "contributions_release_avg_lead_time_per_day": [
    {
      "date": "2024-08-01",
      "code": 5,
      "test": 3,
      "build": 2,
      "integration": 1,
      "deploy": 4,
      "monitor": 2,
      "contribution": "Contrib_A",
      "initiative": "Initiative_1"
    },
    {
      "date": "2024-08-02",
      "code": 3,
      "test": 4,
      "build": 3,
      "integration": 2,
      "deploy": 5,
      "monitor": 3,
      "contribution": "Contrib_B",
      "initiative": "Initiative_2"
    },
    {
      "date": "2024-08-03",
      "code": 6,
      "test": 5,
      "build": 4,
      "integration": 3,
      "deploy": 3,
      "monitor": 2,
      "contribution": "Contrib_A",
      "initiative": "Initiative_1"
    },
    {
      "date": "2024-08-04",
      "code": 4,
      "test": 3,
      "build": 2,
      "integration": 4,
      "deploy": 4,
      "monitor": 5,
      "contribution": "Contrib_C",
      "initiative": "Initiative_3"
    },
    {
      "date": "2024-08-05",
      "code": 2,
      "test": 6,
      "build": 3,
      "integration": 5,
      "deploy": 3,
      "monitor": 2,
      "contribution": "Contrib_B",
      "initiative": "Initiative_2"
    }
  ]
};

export const fakeMttr = {
  mttr: [
      {
          outages: "outage_1",
          timestamp: "2024-07-18",
          hours: 11,
          initiative: "Initiative_3"
      },
      {
          outages: "outage_2",
          timestamp: "2024-07-20",
          hours: 22,
          initiative: "Initiative_1"
      },
      {
          outages: "outage_3",
          timestamp: "2024-07-22",
          hours: 8,
          initiative: "Initiative_2"
      },
      {
          outages: "outage_4",
          timestamp: "2024-07-24",
          hours: 14,
          initiative: "Initiative_3"
      },
      {
          outages: "outage_5",
          timestamp: "2024-07-26",
          hours: 10,
          initiative: "Initiative_2"
      },
      {
          outages: "outage_6",
          timestamp: "2024-07-28",
          hours: 20,
          initiative: "Initiative_1"
      },
      {
          outages: "outage_7",
          timestamp: "2024-07-30",
          hours: 3,
          initiative: "Initiative_2"
      },
      {
          outages: "outage_8",
          timestamp: "2024-08-01",
          hours: 17,
          initiative: "Initiative_1"
      },
      {
          outages: "outage_9",
          timestamp: "2024-08-03",
          hours: 19,
          initiative: "Initiative_1"
      },
      {
          outages: "outage_10",
          timestamp: "2024-08-05",
          hours: 23,
          initiative: "Initiative_1"
      },
      {
        outages: "outage_11",
        timestamp: "2024-08-10",
        hours: 11,
        initiative: "Initiative_3"
      },
      {
        outages: "outage_12",
        timestamp: "2024-08-11",
        hours: 22,
        initiative: "Initiative_1"
      },
      {
        outages: "outage_13",
        timestamp: "2024-08-13",
        hours: 8,
        initiative: "Initiative_2"
      },
      {
        outages: "outage_14",
        timestamp: "2024-08-15",
        hours: 14,
        initiative: "Initiative_3"
      },
      {
        outages: "outage_15",
        timestamp: "2024-08-20",
        hours: 10,
        initiative: "Initiative_2"
      },
      {
        outages: "outage_16",
        timestamp: "2024-08-28",
        hours: 20,
        initiative: "Initiative_1"
      },
      {
          outages: "outage_17",
          timestamp: "2024-09-01",
          hours: 3,
          initiative: "Initiative_2"
      },
      {
          outages: "outage_18",
          timestamp: "2024-09-07",
          hours: 17,
          initiative: "Initiative_1"
      },
      {
          outages: "outage_19",
          timestamp: "2024-09-14",
          hours: 19,
          initiative: "Initiative_1"
      },
      {
          outages: "outage_20",
          timestamp: "2024-09-22",
          hours: 23,
          initiative: "Initiative_1"
      },
      // Add more hardcoded entries as needed
  ]
};

export const sampleDatasetCFR = {
  changeFailureRate: [
    { timestamp: '2023-06-01', failures: 'Deployment Failure', count: 4, initiative: 'initiative_1' },
    { timestamp: '2023-06-02', failures: 'Configuration Error', count: 6, initiative: 'initiative_2' },
    { timestamp: '2023-06-03', failures: 'Script Error', count: 3, initiative: 'initiative_3' },
    { timestamp: '2023-06-04', failures: 'Network Issue', count: 2, initiative: 'initiative_1' },
    { timestamp: '2023-06-05', failures: 'Deployment Failure', count: 5, initiative: 'initiative_2' },
    { timestamp: '2023-06-06', failures: 'Configuration Error', count: 7, initiative: 'initiative_3' },
    { timestamp: '2023-06-07', failures: 'Script Error', count: 1, initiative: 'initiative_1' },
    { timestamp: '2023-06-08', failures: 'Network Issue', count: 4, initiative: 'initiative_2' },
    { timestamp: '2023-06-09', failures: 'Deployment Failure', count: 3, initiative: 'initiative_3' },
    { timestamp: '2023-06-10', failures: 'Configuration Error', count: 8, initiative: 'initiative_1' },
    { timestamp: '2023-06-11', failures: 'Script Error', count: 2, initiative: 'initiative_2' },
    { timestamp: '2023-06-12', failures: 'Network Issue', count: 3, initiative: 'initiative_3' },
    { timestamp: '2023-06-13', failures: 'Deployment Failure', count: 5, initiative: 'initiative_1' },
    { timestamp: '2023-06-14', failures: 'Configuration Error', count: 6, initiative: 'initiative_2' },
    { timestamp: '2023-06-15', failures: 'Script Error', count: 4, initiative: 'initiative_3' },
    { timestamp: '2023-06-16', failures: 'Network Issue', count: 7, initiative: 'initiative_1' },
    { timestamp: '2023-06-17', failures: 'Deployment Failure', count: 2, initiative: 'initiative_2' },
    { timestamp: '2023-06-18', failures: 'Configuration Error', count: 5, initiative: 'initiative_3' },
    { timestamp: '2023-06-19', failures: 'Script Error', count: 6, initiative: 'initiative_1' },
    { timestamp: '2023-06-20', failures: 'Network Issue', count: 4, initiative: 'initiative_2' },
    { timestamp: '2023-06-21', failures: 'Deployment Failure', count: 3, initiative: 'initiative_3' },
    { timestamp: '2023-06-22', failures: 'Configuration Error', count: 2, initiative: 'initiative_1' },
    { timestamp: '2023-06-23', failures: 'Script Error', count: 5, initiative: 'initiative_2' },
    { timestamp: '2023-06-24', failures: 'Network Issue', count: 8, initiative: 'initiative_3' },
    { timestamp: '2023-06-25', failures: 'Deployment Failure', count: 1, initiative: 'initiative_1' },
    { timestamp: '2023-06-26', failures: 'Configuration Error', count: 7, initiative: 'initiative_2' },
    { timestamp: '2023-06-27', failures: 'Script Error', count: 2, initiative: 'initiative_3' },
    { timestamp: '2023-06-28', failures: 'Network Issue', count: 3, initiative: 'initiative_1' },
    { timestamp: '2023-06-29', failures: 'Deployment Failure', count: 4, initiative: 'initiative_2' },
    { timestamp: '2023-06-30', failures: 'Configuration Error', count: 6, initiative: 'initiative_3' },
    { timestamp: '2023-07-01', failures: 'Script Error', count: 3, initiative: 'initiative_1' },
    { timestamp: '2023-07-02', failures: 'Network Issue', count: 5, initiative: 'initiative_2' },
    { timestamp: '2023-07-03', failures: 'Deployment Failure', count: 2, initiative: 'initiative_3' },
    { timestamp: '2023-07-04', failures: 'Configuration Error', count: 7, initiative: 'initiative_1' },
    { timestamp: '2023-07-05', failures: 'Script Error', count: 4, initiative: 'initiative_2' },
    { timestamp: '2023-07-06', failures: 'Network Issue', count: 3, initiative: 'initiative_3' },
    { timestamp: '2023-07-07', failures: 'Deployment Failure', count: 6, initiative: 'initiative_1' },
    { timestamp: '2023-07-08', failures: 'Configuration Error', count: 2, initiative: 'initiative_2' },
    { timestamp: '2023-07-09', failures: 'Script Error', count: 5, initiative: 'initiative_3' },
    { timestamp: '2023-07-10', failures: 'Network Issue', count: 8, initiative: 'initiative_1' },
    { timestamp: '2023-07-11', failures: 'Deployment Failure', count: 1, initiative: 'initiative_2' },
    { timestamp: '2023-07-12', failures: 'Configuration Error', count: 4, initiative: 'initiative_3' },
    { timestamp: '2023-07-13', failures: 'Script Error', count: 3, initiative: 'initiative_1' },
    { timestamp: '2023-07-14', failures: 'Network Issue', count: 6, initiative: 'initiative_2' },
    { timestamp: '2023-07-15', failures: 'Deployment Failure', count: 5, initiative: 'initiative_3' },
    { timestamp: '2023-07-16', failures: 'Configuration Error', count: 7, initiative: 'initiative_1' },
    { timestamp: '2023-07-17', failures: 'Script Error', count: 2, initiative: 'initiative_2' },
    { timestamp: '2023-07-18', failures: 'Network Issue', count: 4, initiative: 'initiative_3' },
    { timestamp: '2023-07-19', failures: 'Deployment Failure', count: 3, initiative: 'initiative_1' },
    { timestamp: '2023-07-20', failures: 'Configuration Error', count: 6, initiative: 'initiative_2' },
  ],
};

export const sampleDatasetS = {
  softwareFailures: [
    { timestamp: '2024-07-15', failures: 'Database Connection', count: 3, initiative: 'initiative_1' },
    { timestamp: '2024-07-16', failures: 'API Timeout', count: 5, initiative: 'initiative_1' },
    { timestamp: '2024-07-17', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
    { timestamp: '2024-07-18', failures: 'Service Crash', count: 2, initiative: 'initiative_1' },
    { timestamp: '2024-07-19', failures: 'Service Crash', count: 3, initiative: 'initiative_3' },
    { timestamp: '2024-07-20', failures: 'Database Connection', count: 4, initiative: 'initiative_2' },
    { timestamp: '2024-07-21', failures: 'API Timeout', count: 2, initiative: 'initiative_1' },
    { timestamp: '2024-07-22', failures: 'Service Crash', count: 6, initiative: 'initiative_3' },
    { timestamp: '2024-07-23', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
    { timestamp: '2024-07-24', failures: 'Database Connection', count: 2, initiative: 'initiative_3' },
    { timestamp: '2024-07-25', failures: 'API Timeout', count: 5, initiative: 'initiative_1' },
    { timestamp: '2024-07-26', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
    { timestamp: '2024-07-27', failures: 'Service Crash', count: 2, initiative: 'initiative_3' },
    { timestamp: '2024-07-28', failures: 'Service Crash', count: 3, initiative: 'initiative_1' },
    { timestamp: '2024-07-29', failures: 'Database Connection', count: 4, initiative: 'initiative_2' },
    { timestamp: '2024-07-30', failures: 'API Timeout', count: 2, initiative: 'initiative_1' },
    { timestamp: '2024-07-31', failures: 'Service Crash', count: 6, initiative: 'initiative_3' },
    { timestamp: '2024-08-01', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
    { timestamp: '2024-08-02', failures: 'Database Connection', count: 2, initiative: 'initiative_3' },
    { timestamp: '2024-08-03', failures: 'API Timeout', count: 5, initiative: 'initiative_1' },
    { timestamp: '2024-08-04', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
    { timestamp: '2024-08-05', failures: 'Service Crash', count: 2, initiative: 'initiative_3' },
    { timestamp: '2024-08-06', failures: 'Service Crash', count: 3, initiative: 'initiative_1' },
    { timestamp: '2024-08-07', failures: 'Database Connection', count: 4, initiative: 'initiative_2' },
    { timestamp: '2024-08-08', failures: 'API Timeout', count: 2, initiative: 'initiative_3' },
    { timestamp: '2024-08-09', failures: 'Service Crash', count: 6, initiative: 'initiative_1' },
    { timestamp: '2024-08-10', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
    { timestamp: '2024-08-11', failures: 'Database Connection', count: 2, initiative: 'initiative_3' },
    { timestamp: '2024-08-12', failures: 'API Timeout', count: 5, initiative: 'initiative_1' },
    { timestamp: '2024-08-13', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
    // Add more sample data as needed
  ],
};