import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import DarkSelectInput from '../../../components/forms/DarkSelectInput';
import '../../../components/tables/GraphTable.css';
import './GraphStyles.css';

const MeanTimeToRecovery = ({ dataset }) => {
  console.log("Received dataset:", dataset);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    "Last Month"
  );
  const [selectedOutage, setSelectedOutage] = useState('All')
  const [selectedInitiative, setSelectedInitiative] = useState('All');

  const options = ['Today', 'Last Week', 'Last Two Weeks', 'Last Month'];

  const getUniqueInitiatives = (data) => {
    const uniqueInitiatives = new Set(data.map(item => item.initiative));
    return Array.from(uniqueInitiatives);
  };

  const getUniqueOutages = (data) => {
    const uniqueOutages = new Set(data.map(item => item.outages));
    return Array.from(uniqueOutages)
  }

  const initiativeOptions = getUniqueInitiatives(dataset.mttr).sort();
  const outagedOptions = getUniqueOutages(dataset.mttr).sort();

  // Function to generate a pastel color
  const getRandomPastelColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const pastel = `hsl(${hue}, 100%, 80%)`;
    return pastel;
  };

  // Function to format date string to MM-DD
  const formatDateString = (dateStr) => {
    const [year, month, day] = dateStr.split('-');
    return `${month}-${day}-${year.slice(-2)}`;
  };

  useEffect(() => {
    if (dataset && dataset.mttr) {
      const filteredInitiativeDataset = selectedInitiative === 'All'
        ? dataset.mttr
        : dataset.mttr.filter(item => item.initiative === selectedInitiative);

      const filteredOutageDataset = selectedOutage === 'All'
        ? filteredInitiativeDataset
        : filteredInitiativeDataset.filter(item => item.outages === selectedOutage);

      const outagesPerDay = {};
      const outageIds = new Set();

      // Process the data to sum hours for each outage ID by day
      filteredOutageDataset.forEach(({ timestamp, outages, hours, initiative }) => {
        if (!outagesPerDay[timestamp]) outagesPerDay[timestamp] = {};
        if (!outagesPerDay[timestamp][outages]) outagesPerDay[timestamp][outages] = 0;
        outagesPerDay[timestamp][outages] += hours;
        outageIds.add(outages);
      });

      const sortedDates = Object.keys(outagesPerDay).sort((a, b) => new Date(a) - new Date(b));
      const formattedDates = sortedDates.map(formatDateString);
      const datasets = Array.from(outageIds).map((id) => {
        const backgroundColor = getRandomPastelColor();
        const data = sortedDates.map(date => outagesPerDay[date][id] || 0);

        return {
          label: id,
          backgroundColor, 
          data,
          stack: 'stack 0',
        };
      });

      setChartData({
        labels: formattedDates,
        datasets,
      });

      console.log(datasets)

      const initialTableData = sortedDates.map((date, i) => ({
        date: formatDateString(date),
        totalHours: Object.values(outagesPerDay[date]).reduce((acc, curr) => acc + curr, 0),
        outage: filteredOutageDataset[i].outages,
        initiative: filteredOutageDataset[i].initiative
      }));

      setFilteredData(initialTableData);
      setTableData(initialTableData);
    }
  }, [dataset, selectedInitiative, selectedOutage]);

  const handleTimeframeChange = (value) => {
    setSelectedOption(value);
    let filtered = [];

    switch (value) {
      case 'Today':
        filtered = filteredData.slice(-1);
        break;
      case 'Last Week':
        filtered = filteredData.slice(-7);
        break;
      case 'Last Two Weeks':
        filtered = filteredData.slice(-14);
        break;
      case 'Last Month':
        filtered = filteredData;
        break;
      default:
        filtered = filteredData;
        break;
    }

    

    const newLabels = filtered.map(item => item.date);
    const newDatasets = chartData.datasets.map(dataset => ({
      ...dataset,
      data: newLabels.map(label => {
        const index = filteredData.findIndex(item => item.date === label);
        return index !== -1 ? dataset.data[index] : 0;
      }),
    }));

    setChartData({
      labels: newLabels,
      datasets: newDatasets,
    });
    setTableData(filtered);
  };

  useEffect(() => {
    console.log(tableData)
  }, [tableData])

  const handleInitiativeChange = (value) => {
    setSelectedInitiative(value);
  };

  const handleOutageChange = (value) => {
    setSelectedOutage(value)
  }

  const optionsChart = {
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: 'Date',
          color: 'white',
          font: {
            size: 14
          }
        },
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          color: 'white',
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Hours',
          color: 'white',
          font: {
            size: 14
          }
        },
        ticks: {
          autoSkip: false,
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: 'white'
        }
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const label = tooltipItem.dataset.label || '';
            const value = tooltipItem.raw || 0;
            const hours = value.toFixed(0);
            return `${label}: ${hours} hours`;
          }
        },
        titleFont: {
          size: 14, // Set font size for the tooltip title
        },
        bodyFont: {
          size: 14, // Set font size for the tooltip body text
        },
      }
    },
  };

  const chartContainerStyle = {
    maxWidth: '100%',
    height: '420px',
    overflowX: 'auto',
  };

  const chartStyle = {
    minWidth: `${tableData.length * 100}px`,
    height: '100%'
  }

  return (
    <div>
      <div style={chartContainerStyle}>
        <div style={chartStyle}>
          <Bar data={chartData} options={optionsChart} className="chartModal"/>
        </div>
      </div>
      <div className="graphModalFooter">
        <div className="graphFilterLabel fw500">Filter by Outage:</div>
        <div className="col3">
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={['All', ...outagedOptions]}
            value={selectedOutage}
            onChange={handleOutageChange}
          />
        </div>
      </div>
      <div className="graphModalFooter">
        <div className="graphFilterLabel fw500">Filter by Timeframe:</div>
        <div className="col3">
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={options}
            value={selectedOption}
            onChange={handleTimeframeChange}
          />
        </div>
      </div>
      <div className="graphModalFooter">
        <div className="graphFilterLabel fw500">Filter by Initiative:</div>
        <div className="col3">
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={['All', ...initiativeOptions]}
            value={selectedInitiative}
            onChange={handleInitiativeChange}
          />
        </div>
      </div>
      { tableData.length > 0 && (
      <div>
        <br/>
        <h3>Outages Summary <i className="fa-solid fa-arrow-down"></i></h3> 
        <br/>
        <div className="graphTable">
          <div className="graphTableBody">
            <table>
              <thead className="graphTableHead">
                <tr>
                  <th>Date</th>
                  <th>Total Hours</th>
                  <th>Outage Name</th>
                  <th>Initiative</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td>{row.date}</td>
                    <td>{row.totalHours}</td>
                    <td>{row.outage}</td>
                    <td>{row.initiative}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default MeanTimeToRecovery;
