import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BasicButton from '../../../components/buttons/BasicButton'
import CurrentDate from '../../../components/resources/CurrentDate'
import BusinessNavFilter from '../../../components/nav/BusinessNavFilter'
import InitiativesInfo from './InitiativesInfo'

const API = process.env.REACT_APP_SETUP_API

const InitiativeCosts = () => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Initiative Params */
  let { initiativeName } = useParams()
  /* Navigation */
  const links = ['Initiative Metrics', 'Resources']
  const activeSection = 'Costs'
  /* Get metrics */
  const [initiativeCosts, setInitiativeCosts] = useState()



  useEffect(() => {
    const fetchInitiativeCosts = async () => {

      try {
        console.log('here')
        console.log(window.atob(initiativeName))
        const response = await fetch(
          `${API}/initiatives/${currentCompany}/${window.atob(initiativeName)}/metrics`,
          {
            method: 'GET',
          }
        )
        const data = await response.json()
        console.log(data)
        setInitiativeCosts(data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchInitiativeCosts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatCost = (label, cost) => {
    return (
      <li>
        <span>{label}</span>
        <span>${cost !== undefined ? cost.toFixed(2) : "0.00"}</span>
      </li>
    );
  };

  return (
    <div className="containerToRight">
      <h1 className="mb1">
        <span className="lightGray">{window.atob(initiativeName)}</span> Costs
      </h1>
      <BasicButton color="btnBlack" size="btnSmall" as="link" to="dobcon-business">
        Back to Business Dashboard <i className="fa-solid fa-rotate-left" />
      </BasicButton>
      <div className="tar mt-1">
        <CurrentDate />
        <hr className="grayLine mt1" />
      </div>
      <div className="flexContainer spaceBetween pt1 pb1">
        <div className="col2">
          <BusinessNavFilter
            links={links}
            activeSection={activeSection}
            initiativeName={initiativeName}
          />
        </div>
      </div>

      <InitiativesInfo />

      <div className="businessMetricsTable mt2">
        {
          initiativeCosts &&
          <div className="businessMetricsTableBody flexContainer">
            <ul className="w50 grayLineRight">

              <p className="mediumText mb1">Costs</p>
              {formatCost("Total Cost per Initiative", initiativeCosts.total_cost)}
              {formatCost("Forecasted Initiative Cost", initiativeCosts.forecasted_cost)}
              {formatCost("Average Cost per Release", initiativeCosts.release_cost)}
              {formatCost("Cost of Initiative SLAs Achievement", initiativeCosts.sla_breaches)}
            </ul>
            <ul className="w50">
              <p className="mediumText mb1">Cost Elements</p>
              {formatCost("Cloud", initiativeCosts.cloud_cost)}
              {formatCost("Tools", initiativeCosts.tools_cost)}
              {formatCost("Hr", initiativeCosts.hr_cost)}
              {formatCost("Cultural Adoption", initiativeCosts.cultural_cost)}
            </ul>
          </div>
        }

      </div>
    </div>
  )
}

export default InitiativeCosts