import React from 'react'
import './ProgressSlaCard.css'
import ProgressBar from '../resources/ProgressBar'
import DarkSelectInput from '../forms/DarkSelectInput'
import DotDetails from '../detailList/DotDetails';

const ProgressSlaCard = ({
  stage,
  success,
  successDetails,
  fail,
  failDetails,
  detailsPosition,
  agreed,
  achieved,
  all,
  options,
  value,
  onChange
}) => (
  <div className="progressSlaCard">
    <div className="progressSlaCardHead">{stage}</div>
    <div className="progressSlaCardBody">
      {!all && (
        <DarkSelectInput
          className="darkSelectInputMini"
          options={options}
          value={value}
          onChange={onChange}
        />
      )}
      <span className="smallText fw500">
        {stage === 'Code' && 'PRs'}
        {stage === 'Build' && 'Builds'}
        {stage === 'Test' && 'Success'}
        {stage === 'Integration' && 'Products'}
        {stage === 'Deploy' && 'Deployments'}
        {stage === 'Operate' && 'Applied Changes'}
        {stage === 'Monitor' && 'Ok Status'}
      </span>
      { successDetails && 
        <DotDetails
          details={successDetails}
          stage={stage}
          color='green'
        />
      }
      <br />
      <span className="mr1"><span className="greenDot" /> {success}</span><br />
      <span className="smallText fw500">
        {stage === 'Code' && 'Failed PRs'}
        {stage === 'Build' && 'Failed Builds'}
        {stage === 'Test' && 'Failed'}
        {stage === 'Integration' && 'Failed Products'}
        {stage === 'Deploy' && 'Undeployed'}
        {stage === 'Operate' && 'Unapplied Changes'}
        {stage === 'Monitor' && 'Alarms'}
      </span>
      { failDetails && 
        <DotDetails
          details={failDetails}
          stage={stage}
          color='red'
        />
      }
      <br />
      <span><span className="redDot" /> {fail}</span>
      
      <div className="flexContainer spaceBetween alignCenter">
        <hr className="progressSlaCardDivision" /> SLAs <hr className="progressSlaCardDivision" />
      </div>
      <span className="smallText fw500">Agreed</span>
      <br />
      <div className="flexContainer spaceBetween tac fw500">
        <ProgressBar progress={agreed} /> <small>{agreed}%</small>
      </div>
      <span className="smallText fw500">Achieved</span>
      <br />
      <div className="flexContainer spaceBetween tac fw500">
        {achieved !== 'ND' ? (
          <>
            <ProgressBar progress={achieved} /><small>{achieved}%</small>
          </>
        ) : (
          <>
            <ProgressBar progress={0} /> <small>{achieved}</small>
          </>
        )}
      </div>
    </div>
  </div>
);


export default ProgressSlaCard