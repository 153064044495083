import './HomeCard.css'
import BasicButton from '../buttons/BasicButton'

const HomeCard = ({
  cardTitle,
  cardIconSrc,
  cardIconAlt,
  cardDescription,
  cardLinkTo,
  cardSize,
  dobconOnTitle = true
}) => {

  return (
    <div className="homeCard basicBorder">
      <div className="homeCardHead">
        <h3 className="mediumText">
          {dobconOnTitle === true ? <>DOBCO<span className="bigN-h3">n</span></> : null} {cardTitle}
        </h3>
        <img src={cardIconSrc} alt={cardIconAlt} className="homeCardIcon" />
      </div>
      <div className="homeCardBody">
        <p className="mb2">{cardDescription}</p>
        <BasicButton
          as="link"
          to={cardLinkTo}
          color="btnRed"
          size="btnMedium"
        >
          Select
        </BasicButton>
      </div>
    </div>
  )
}

export default HomeCard