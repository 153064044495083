import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './TCOCharts.css'; // Ensure this CSS file contains the required styles
import DarkSelectInput from '../../components/forms/DarkSelectInput';

const DobconCostsDashboard = () => {
  const [timeframe, setTimeframe] = useState('month');
  const [selectedInitiative, setSelectedInitiative] = useState('All');

  const initiatives = [
    { label: 'All', value: 'All' },
    { label: 'Initiative 1', value: 'Initiative 1' },
    { label: 'Initiative 2', value: 'Initiative 2' }
  ];

  const initiativeOptions = ['All', 'Initiative 1', 'Initiative 2']

  const timeframes = [
    { label: 'Week', value: 'week' }, 
    { label: 'Month', value: 'month' }, 
    { label: 'Year', value: 'year' }
  ]

  const timeframesOptions = ['Week', 'Month', 'Year']

  const historicalData = {
    'All': { 'week': { tco: 10000, devOpsTco: 5000 }, 'month': { tco: 40000, devOpsTco: 20000 }, 'year': { tco: 480000, devOpsTco: 240000 }},
    'Initiative 1': { 'week': { tco: 5000, devOpsTco: 2500 }, 'month': { tco: 20000, devOpsTco: 10000 }, 'year': { tco: 240000, devOpsTco: 120000 }},
    'Initiative 2': { 'week': { tco: 15000, devOpsTco: 7500 }, 'month': { tco: 60000, devOpsTco: 30000 }, 'year': { tco: 720000, devOpsTco: 360000 }}
  };

  const costData = historicalData[selectedInitiative][timeframe];

  const chartData = {
    labels: ['TCO', 'DevOps TCO'],
    datasets: [{
      label: 'Costs Over Time',
      data: [costData.tco, costData.devOpsTco],
      borderColor: 'rgba(53, 162, 235, 1)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      borderWidth: 1,
    }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: { color: '#FFFFFF' },
        grid: { color: 'rgba(255, 255, 255, 0.2)' }
      },
      x: {
        ticks: { color: '#FFFFFF' },
        grid: { color: 'rgba(255, 255, 255, 0.2)' }
      }
    }
  };

  return (
    <div className="containerToRight">
      <div className="dobconDashboardContent">
        <h1 className="dashboardTitle">Dobcon Costs Dashboard</h1>
        <div className="inputsContainer">
          <DarkSelectInput
            label="Initiative"
            className="darkSelectInputMedium"
            options={initiativeOptions}
            value={selectedInitiative}
            onChange={e => setSelectedInitiative(e.target.value)}
          />
          <DarkSelectInput
            label="Timeframe"
            className="darkSelectInputMedium"
            options={timeframesOptions}
            value={timeframe}
            onChange={e => setTimeframe(e.target.value)}
          />
        </div>
        <div className="chartContainer">
          <Line data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default DobconCostsDashboard;
