import React, { useState, useEffect } from "react"
import './PrivilegesTable.css'
import SwitchToggle from '../pills/SwitchToogle'
import BasicButton from '../buttons/BasicButton'
import BasicModal from "../modals/BasicModal"
import Success from "../alerts/Success"
import Warning from "../alerts/Warning"

const API = process.env.REACT_APP_LICENCES_API

const PrivilegesTable = ({ company }) => {
  // Notifications
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  // Modals
  const [isOpenModalSave, setIsOpenModalSave] = useState(false)
  const [isOpenModalRevert, setIsOpenModalRevert] = useState(false)
  const [isOpenModalDefaults, setIsOpenModalDefaults] = useState(false)
  // Privileges
  //const rolePrivileges = usePrivilegeContext()
  const [newPrivileges, setNewPrivileges] = useState({});
  
  const getCompanyPrivileges = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    try{
      const response = await fetch(`${API}/privileges/${company}`, requestOptions)
      if(response.status === 200){
        const privileges = await response.json()
        setNewPrivileges(privileges)
      } else{
        setError("An error ocurred retrieving privileges configuration")
      }
    } catch (e){
      console.log(e)
    }
  }

  const roles = [
    //{'value':'super_user', 'name': 'SuperUser'}, 
    {'value':'ceo', 'name': 'CEO'},
    {'value':'pm', 'name': 'Project Maneger'},
    {'value':'vp_director_business', 'name': 'VP/Director Business'},
    {'value':'vp_director_engineering', 'name': 'VP/Director Engineering'},
    {'value':'manager_business', 'name': 'Business Manager'},
    {'value':'manager_engineering', 'name': 'Engineering Manager'},
    {'value':'engineer_dev', 'name': 'Engineer/Developer'},
    {'value':'business_analyst', 'name': 'Business Analyst'}
  ]

  const sections = [
    {'value':'company_setup', 'name': 'Company Setup'},
    {'value':'initiative_setup', 'name': 'Initiative Setup'},
    {'value':'business_dashboard', 'name': 'Business Dashboard'},
    {'value':'toolchain_dashboard', 'name': 'Toolchain Dashboard'},
    {'value':'adoption_dashboard', 'name': 'Adoption Dashboard'},
    {'value':'initiative_manager', 'name': 'Manage Initiatives'},
    {'value':'privilege_manager', 'name': 'Manage Privileges'}
  ]

  useEffect(() => {
    getCompanyPrivileges()
  }, [])

  const handleToggleChange1 = (section, role, option, toggleValue) => {
    setError('')
    setSuccess('')
    
    setNewPrivileges(prevPrivileges => {
      const updatedPrivileges = { ...prevPrivileges };
      const options = updatedPrivileges[section][role]
      if (toggleValue) {
        if (!options.includes(option)) {
          updatedPrivileges[section][role] = [...options, option];
        }
      } else {
        updatedPrivileges[section][role] = updatedPrivileges[section][role].filter(item => item !== option);
      }
      return updatedPrivileges;
    });
  }

  const handleToggleChange2 = (section, role, toggleValue) => {
    setError('')
    setSuccess('')
    setNewPrivileges(prevPrivileges => {
      const updatedPrivileges = { ...prevPrivileges };
      updatedPrivileges[section][role] = toggleValue;
      return updatedPrivileges;
    });
  }

  const handleRestoreChanges = () => {
    setError('')
    setSuccess('')
    getCompanyPrivileges()
    setIsOpenModalRevert(false)
  }

  const restoreDefaults = async() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    try{
      const response = await fetch(`${API}/privileges/${company}`, requestOptions)
      if(response.status === 200){
        setError('')
        setSuccess('Correctly restored to defaults')
        await getCompanyPrivileges()
      } else {
        setError('An error ocurred')
        setSuccess('')
      }
    } catch (e){
      setError(e)
    }
    setIsOpenModalDefaults(false)
  }

  const handleSave = async() => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
      body: JSON.stringify({
        'new_privileges': newPrivileges
      })
    }
    try{
      const response = await fetch(`${API}/privileges/${company}`, requestOptions)
      if(response.status === 200){
        const save_response = await response.json()
        console.log(save_response)
        setSuccess('Privileges correctly updated')
        setError('')
        await getCompanyPrivileges()
      } else{
        setError('An error ocurred')
        setSuccess('')
      }
    } catch (e){
      console.log(e)
    }
    setIsOpenModalSave(false)
  };

  function renderToggleList(sectionValue, roleName, roleValue) {
    if (['company_setup', 'initiative_setup', 'initiative_manager'].includes(roleName)) {
      return (
        <>
          <li>
            <SwitchToggle 
              value={newPrivileges[sectionValue][roleValue].includes("view")}
              onChange={(newValue) => handleToggleChange1(sectionValue, roleValue, 'view', newValue)}
            /> View
          </li>
          <li>
            <SwitchToggle 
              value={newPrivileges[sectionValue][roleValue].includes("edit")}
              onChange={(newValue) => handleToggleChange1(sectionValue, roleValue, 'edit', newValue)}
            /> Update/Edit
          </li>
          <li>
            <SwitchToggle 
              value={newPrivileges[sectionValue][roleValue].includes("create")}
              onChange={(newValue) => handleToggleChange1(sectionValue, roleValue, 'create', newValue)}
            /> Create
          </li>
        </>
      )
    }
    else if(['business_dashboard', 'toolchain_dashboard', 'adoption_dashboard'].includes(roleName))
    {
      return (
        <li>
          <SwitchToggle 
            value={newPrivileges[sectionValue][roleValue]}
            onChange={(newValue) => handleToggleChange2(sectionValue, roleValue, newValue)}
          /> View 
        </li>
      )
    }
    else {
      return (
        <li>
          <SwitchToggle 
            value={newPrivileges[sectionValue][roleValue]}
            onChange={(newValue) => handleToggleChange2(sectionValue, roleValue, newValue)}
          /> Edit 
        </li>
      )
    }
  }

  return (
    <>
      <div>
      {error && <div className="mt1 mb2"><Warning message={error} /></div>}
      {success && <div className="mt1 mb2"><Success message={success} /></div>}
      </div>
      <div className="privilegesTable mb1">
        <div className="privilegesTableHead">
          Assign Privileges
        </div>
        
        {}
        <div className="privilegesTableBody">
        { sections.map((section) => (
        <div key={section.value}>
          <p className="pl1 mb1">
            {section.name}
          </p>
          <div className="privilegesTableSection flexContainer spaceBetween mb1">
            { roles.map((role) => (
              <div className="col3" key={role.value}>
                <ul className="privilegesTableList">
                  <p className="fw500">{role.name}</p>
                  {newPrivileges && newPrivileges[section.value] && 
                   renderToggleList(section.value, role.name, role.value)
                  }
                  </ul>
              </div>
              ))}
              <div className="col3" />
            </div>
          </div>
        ))}
        </div>
      </div>
      <BasicButton
        
        color="btnRed"
        size="btnSmall"
        onClick={() => setIsOpenModalSave(true)}
      >
        Save
      </BasicButton>
      {' '}
      <BasicButton
        
        color="btnBlack"
        size="btnSmall"
        onClick={() => setIsOpenModalRevert(true)}
      >
        Undo changes
      </BasicButton>
      {' '}
      <BasicButton
        
        color="btnBlack"
        size="btnSmall"
        onClick={() => setIsOpenModalDefaults(true)}
      >
        Restore defaults
      </BasicButton>
      {/* Save Changes modal */}
      <BasicModal isOpen={isOpenModalSave} onClose={() => setIsOpenModalSave(false)}>
        <h2 className="mb2 tac">Are you sure that you want to save changes?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={handleSave}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={() => setIsOpenModalSave(false)}
        >
          Cancel
        </BasicButton>
      </BasicModal>
      {/* Revert changes modal */}
      <BasicModal isOpen={isOpenModalRevert} onClose={() => setIsOpenModalRevert(false)}>
        <h2 className="mb2 tac">This will restore your unsaved changes. Are you sure?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={handleRestoreChanges}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={() => setIsOpenModalRevert(false)}
        >
          Cancel
        </BasicButton>
      </BasicModal>
      {/* Restore defaults modal */}
      <BasicModal isOpen={isOpenModalDefaults} onClose={() => setIsOpenModalDefaults(false)}>
        <h2 className="mb2 tac">Are you sure you want to restore to Dobcon's recommended settings?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={restoreDefaults}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={() => setIsOpenModalDefaults(false)}
        >
          Cancel
        </BasicButton>
      </BasicModal>
    </>
  )
}

export default PrivilegesTable