import React, { useState } from 'react'
import './LoginForm.css'
import BasicInput from './BasicInput'
import Warning from '../alerts/Warning'
import BasicButton from '../buttons/BasicButton'
import NextField from '../functions/NextField'
import { useNavigate } from 'react-router-dom'
/* Amplify */
import { Auth } from 'aws-amplify'

const API = process.env.REACT_APP_LICENCES_API

const FirstTimeLoginForm = ({
  email,
  psswd,
  openTokenModal,
  checkDevice,
  updateStoredBrowserId,
}) => {
  let navigate = useNavigate()

  const [firstLoginData, setFirstLoginData] = useState({
    newUsername: '',
    newPassword: '',
    confirmPassword: '',
    emailToken: ''
  })

  const handleInputChangeFirstLogin = (field, value) => {
    setFirstLoginData({
      ...firstLoginData,
      [field]: value,
    })
  }

  // Errors
  const [error, setError] = useState('') 
  
  function handleConfirmationErrors(err) {
    console.log(err);
    switch (err.name) {
      case 'CodeMismatchException':
        setError('Please enter a valid email token')
        break;
      case 'ExpiredCodeException':
        setError('The email token has expired')
        break;
      case 'LimitExceededException':
        setError('Please wait to try again or contact your superuser to reset your account');
        break;
      case 'AliasExistsException':
        setError(`The username ${firstLoginData.newUsername} already exists, please choose another username`);
        break;
      default:
        setError('An unexpected error occurred');
        console.error(err)
    }
  }

  /* Token Expiration */
  const tokenExpiration = async () => {
    try {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        accept: 'application/json',
        body: JSON.stringify({
          "email": email
        })
      }
      const response = await fetch(`${API}/token`, requestOptions)
      const data = await response.json()
      return data
    } catch (error) {
      handleConfirmationErrors(error)
    }
  }

  /* Password change validations */
  const [passChecks, setPassChecks] = useState({
    eight: false,
    lowercase: false,
    uppercase: false,
    hasNumber: false,
    specialCharacter: false,
    passMatch: false,
  })
  const checkPass = (newPass) => {
    const checks = {
      eight: newPass.length > 7,
      lowercase: /[a-z]/.test(newPass),
      uppercase: /[A-Z]/.test(newPass),
      hasNumber: /\d/.test(newPass),
      specialCharacter: /[!@#$%^&*?¿{}></ºª|"'¬()=_~+`¡]/.test(newPass),
    }
    setPassChecks(checks)
  }
  const confirmPass = () => {
    if (firstLoginData.newPassword === firstLoginData.confirmPassword) {
      setPassChecks({
        ...passChecks,
        passMatch: true
      })
    } else {
      setPassChecks({
        ...passChecks,
        passMatch: false
      })
    }
  }

/* Updated username */
const updateUsername = async (user, newUsername) => {
  try {
    await Auth.updateUserAttributes(user, {
      'preferred_username': firstLoginData.newUsername || email
    })
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
      body: JSON.stringify({
        "email": email,
        "new_username": newUsername
      })
    }
    const response = await fetch(`${API}/users/username`, requestOptions)
    const data = await response.json()
    console.log(data)
  } catch (error) {
    handleConfirmationErrors(error)
  }
}
  const preventCopyPaste = (e) => {
    e.preventDefault()
  }

  /* Submit first login */
  const handleSubmitFirstLogin = async (e) => {
    e.preventDefault();

    if (!firstLoginData.newPassword) {
      setError("Please enter your new password");
      return;
    }

    const requiredChecks = {
      lowercase: "one lowercase character",
      uppercase: "one uppercase character",
      hasNumber: "one number",
      specialCharacter: "one special character",
      eight: "eight characters minimum"
    };

    if (!Object.values(passChecks).every(check => check)) {
      for (const [key, value] of Object.entries(passChecks)) {
        if (!value) {
          setError(`The password must have ${requiredChecks[key]}`);
        }
      }
      return;
    }

    if (firstLoginData.newPassword !== firstLoginData.confirmPassword) {
      setError("The passwords must match");
      return;
    }

    if (!firstLoginData.emailToken) {
      setError("Please enter your email token");
      return;
    }

    try {
      const tokenCheck = await tokenExpiration();
      if (tokenCheck.response === "token is expired") {
        setError("Token is expired");
        return;
      }
      await Auth.confirmSignUp(email, firstLoginData.emailToken)
      const user = await Auth.signIn(email, psswd);
      if (user.challengeName === "CUSTOM_CHALLENGE") {
        try {
          const deviceCheckResult = await checkDevice();
          await Auth.sendCustomChallengeAnswer(user, deviceCheckResult);
          updateStoredBrowserId()
          setError("");
          navigate(`/${btoa('user-home')}`);
        } catch (error) {
          setError("Wrong device");
        }
      }
      console.log('logged in successful')
      await Auth.changePassword(user, psswd, firstLoginData.newPassword)
      console.log('password changed')
      await updateUsername(user, firstLoginData.newUsername)
      console.log('username updated')
      setError("")
      navigate(`/${btoa('user-home')}`)
    } catch (error) {
      handleConfirmationErrors(error);
    }
  };

  return (
    <div>
      <div>
        <img
          src="/img/logo/logo-dobcon-small.png"
          alt="DevOps Business Control"
          className="col6Mobile mb2 hide showMobile"
        />
        <h1 className="mb1">First time here?</h1>
        <p className="mb2 fw500">Please set a new username and a new password before login</p>
      </div>
      {error && <div className="mb2"><Warning message={error} /></div>}
      <form>
        <div className="hide">
          <BasicInput />
        </div>
        <BasicInput
          label="New username (optional)"
          type="text"
          value={firstLoginData.newUsername}
          onChange={(value) => handleInputChangeFirstLogin('newUsername', value)}
          onKeyDown={NextField}
          placeholder="Type your new username"
        />
        <BasicInput
          onCopy={preventCopyPaste}
          onCut={preventCopyPaste}
          label="New password (Case sensitive)*"
          type="password"
          value={firstLoginData.newPassword}
          onChange={(value) => handleInputChangeFirstLogin('newPassword', value)}
          onKeyDown={NextField}
          onKeyUp={(e) => checkPass(e.target.value)}
          placeholder="Type your new password"
        />
        <BasicInput
          label="Confirm password (Case sensitive)*"
          type="password"
          value={firstLoginData.confirmPassword}
          onChange={(value) => handleInputChangeFirstLogin('confirmPassword', value)}
          onKeyDown={NextField}
          onKeyUp={confirmPass}
          onPaste={preventCopyPaste}
          placeholder="Confirm your password"
        />
        <BasicInput
          label="Email token (Only numbers)*"
          type="onlyNumbers"
          value={firstLoginData.emailToken}
          onChange={(value) => handleInputChangeFirstLogin('emailToken', value)}
          placeholder="Type your email token (Valid for 10min)"
        />
        <p className="lightGray tar mb1">
          Do you need a new email token?{' '}
          <u className="lighterGray cursorPointer" onClick={openTokenModal}>Click here</u>
          <br />
          <small>*Mandatory Fields</small>
        </p>
        <div className="flexContainer spaceBetween mb1 tal">
          <div className="col6">
            <div className="passCheckContainer">
              <span className={`passCheck ${passChecks.lowercase ? 'passCheckActive' : ''}`} />{' '}
              <span className="fw500">One lowecase character</span>
            </div>
            <div className="passCheckContainer">
              <span className={`passCheck ${passChecks.uppercase ? 'passCheckActive' : ''}`} />{' '}
              <span className="fw500">One uppercase character</span>
            </div>
            <div className="passCheckContainer">
              <span className={`passCheck ${passChecks.hasNumber ? 'passCheckActive' : ''}`} />{' '}
              <span className="fw500">One number</span>
            </div>
          </div>
          <div className="col6">
            <div className="passCheckContainer">
              <span className={`passCheck ${passChecks.specialCharacter ? 'passCheckActive' : ''}`} />{' '}
              <span className="fw500">One special character</span>
            </div>
            <div className="passCheckContainer">
              <span className={`passCheck ${passChecks.eight ? 'passCheckActive' : ''}`} />{' '}
              <span className="fw500">8 characters minimum</span>
            </div>
            <div className="passCheckContainer">
              <span className={`passCheck ${passChecks.passMatch ? 'passCheckActive' : ''}`} />{' '}
              <span className="fw500">Passwords must match</span>
            </div>
          </div>
        </div>
        <BasicButton
          as="button"
          color="btnRed"
          size="btnFull"
          onClick={handleSubmitFirstLogin}
        >
          Log In
        </BasicButton>
      </form>
    </div>
  )
}

export default FirstTimeLoginForm;