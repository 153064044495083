function generateMockEarlyDefects() {
    const daysBack = 30; // Number of days to generate data for
    let edd_by_date = [];
    let totalDetected = 0;
    let totalSolved = 0;

    for (let day = 0; day < daysBack; day++) {
        const date = new Date();
        date.setDate(date.getDate() - day);
        const dateString = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

        // Generate random numbers for detected and solved defects
        const detected = Math.floor(Math.random() * 11); // Random number from 0 to 10
        const solved = Math.floor(Math.random() * 11); // Random number from 0 to 10

        // Accumulate totals
        totalDetected += detected;
        totalSolved += solved;

        // Prepend to keep the most recent date last
        edd_by_date.unshift({
            detected: detected,
            solved: solved,
            timestamp: dateString
        });
    }

    return {
        All: {
            edd_by_date: edd_by_date,
            edd_by_timeframe: {
                detected: totalDetected,
                solved: totalSolved,
                timeframe: "lastMonth"
            }
        }
    };
}


export default generateMockEarlyDefects;

// Example usage:
