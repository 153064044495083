import React, { useState } from 'react'
import './DragAndDrop.css'
import Warning from '../alerts/Warning'
import Success from '../alerts/Success'

const API = process.env.REACT_APP_SETUP_API

const DragAndDrop = ({ sendLogo }) => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Uploading logo */
  const [dragging, setDragging] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  /* Uploading user logo with drag and drop */
  const handleDrop = (e) => {
    e.preventDefault()
    setDragging(false)

    const file = e.dataTransfer.files[0]
    const reader = new FileReader()

    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      reader.readAsDataURL(file)
      setError('')
      setSuccess('Your company logo has been updated')
      uploadLogo(file)
    } else {
      setSuccess('')
      setError('Please upload only a JPG or PNG image')
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    if (!dragging) {
      setDragging(true)
    }
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setDragging(false)
  }

  /* Uploading user logo with button */
  const uploadLogo = async (file) => {
    const formData = new FormData()
    formData.append('logo', file)
    const response = await fetch(`${API}/company-logo/${currentCompany}`, {
      method: 'POST',
      body: formData
    })
    const data = await response.json()
    console.log(data)
    sendLogo(true)
    setError('')
  }

  const setLogo = (e) => {
    setSuccess('Your company logo has been updated')
    uploadLogo(e.target.files[0])
  }

  return (
    <>
      {error && <div className="mb1 tac"><Warning message={error} /></div>}
      {success && <div className="mb1 tac"><Success message={success} /></div>}
      <div
        className={`dragAndDropBox basicBorder ${dragging ? 'dragging' : ''}`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <p className="bigText mt1">
          <i className="fa-solid fa-cloud-arrow-up lightGray" />{' '}
          Drag and drop your file here 
          <br/>
          <small className="lightGray">(Only JPG & PNG)</small>
        </p>
        <p className="mtHalf mb1">or</p>
        <input
          id="browseLogo"
          method="GET"
          type="file"
          accept="image/png, image/jpeg"
          className="hide"
          formEncType="multipart/form-data"
          name="logo"
          onChange={setLogo}
        />
        <label htmlFor="browseLogo" className="btn btnRed btnSmall cursorPointer">
          Browse File <i className="fas fa-search opacity5" />
        </label>
      </div>
    </>
  )
}

export default DragAndDrop