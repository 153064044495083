import React, { useEffect, useState } from 'react'
import './ToolchainCard.css'

const ToolchainCard = ({ stage, toolsData, selectedTools, onToolSelectionChange }) => {
  /* Tool image data */
  const [toolImages, setToolImages] = useState(toolsData)
  console.log(selectedTools)

  const paint = () => {
    const updatedToolImage = toolImages.map((tool) =>
      selectedTools.includes(tool.value) ? { ...tool, selected: true } : tool
    );
    setToolImages(updatedToolImage);
  }
  

  useEffect(() => {
    paint()
  }, [selectedTools])

  /* Handle image state and store image values */
  const handleImageClick = (value) => {
    const updatedToolImage = toolImages.map((tool) =>
      tool.value === value ? { ...tool, selected: !tool.selected } : tool
    )
    setToolImages(updatedToolImage)
    onToolSelectionChange(value)  
  }

  return (
    <div className="toolchainCard">
      <div className="toolchainCardHead">
        <p className="regularText">{stage}</p>
      </div>
      <ul>
        {toolImages.map((image) => (
          <li key={image.value} onClick={() => handleImageClick(image.value)}>
            <img src={image.selected ? image.srcSelected : image.src} alt={image.value} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ToolchainCard
