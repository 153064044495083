import React, { useState, useEffect } from 'react'
import './ServicesAndAppsTable.css'
import BasicButton from '../buttons/BasicButton'
import FormModal from '../modals/FormModal'
import BasicInput from '../forms/BasicInput'
import Warning from '../alerts/Warning'
import BasicModal from '../modals/BasicModal'
import ServAppsFileModal from '../modals/ServAppsFileModal'

const API = process.env.REACT_APP_SETUP_API

const ServicesAndAppsTable = ({ handleCompanyServApps }) => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Services and applications list */
  const [servApps, setServApps] = useState([])
  const [servAppsNames, setServAppsNames] = useState([])
  /* Fetch services and applications list */
  const getCompanyServApps = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    const response = await fetch(`${API}/servapps/${currentCompany}`, requestOptions)
    const data = await response.json()
    if(response.status === 200){
      setServApps(data)
      getServAppsNames(data)
    } else{
      console.error(data)
    }
  }

  const getServAppsNames = (data) => {
    let names_list = []
    for(let i = 0; i < data.length; i ++){
      names_list.push(data[i].name)
    }
    setServAppsNames(names_list)
  }

  useEffect(() => {
    getCompanyServApps()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Errors */
  const [error, setError] = useState('')

  /* Services and applications modal */
  const [isServAppsModal, setIsServAppsModal] = useState(false)
  const [selectedServApp, setSelectedServApp] = useState(null)

  const openServAppModal = () => {
    setIsServAppsModal(true)
  }

  const closeModal = () => {
    setAddServAppData({
      name: '',
      description: ''
    })
    setError('')
    setSelectedServApp(null)
    setIsServAppsModal(false)
  }

  /* Add new services or application */
  const [addServAppData, setAddServAppData] = useState({
    name: '',
    description: ''
  })

  const handleAddServAppInputChange = (field, value) => {
    setAddServAppData({
      ...addServAppData,
      [field]: value,
    })
  }

  

  const submitAddServApp = async (e) => {
    e.preventDefault()

    if (!addServAppData.name) {
      setError("Please enter the service or application name")
    } else {
      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({
            name: addServAppData.name,
            description: addServAppData.description
          })
        }
        const response = await fetch(
          `${API}/servapps/${currentCompany}/upload`, requestOptions
        )
        const data = await response.json()
        if(response.status === 200){
          setSelectedServApp(null)
          getCompanyServApps()
          setAddServAppData({
            name: '',
            description: ''
          })
          setError('')
          setIsServAppsModal(false)
        } else if(response.status === 409){
          setError("The service or application's name already exists, please choose another one")
        } else{
          console.error(data)
        }
        
      } catch (err) {
        console.error(err)
      }
    }
  }

  

  /* Edit an exists service or application */
  const [editServAppData, setEditServAppData] = useState({
    name: '',
    description: ''
  })

  const handleEditServAppInputChange = (field, value) => {
    setEditServAppData({
      ...editServAppData,
      [field]: value,
    })
  }

  const handleEditServApp = (servApp) => {
    setSelectedServApp(servApp)
    setEditServAppData({
      name: servApp.name,
      description: servApp.description
    })
    setIsServAppsModal(true)
  }

  const submitEditServApp = async (e) => {
    e.preventDefault()

    if (!editServAppData.name) {
      setError("Please enter the new service or application name")
    } else {
      try {
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({
            new_name: editServAppData.name,
            description: editServAppData.description
          })
        }
        const response = await fetch(
          `${API}/servapps/${currentCompany}/${selectedServApp.id}`, requestOptions
        )
        const data = await response.json()
        if(response.status === 200){
          setError('')
          setIsServAppsModal(false)
          getCompanyServApps()
          setSelectedServApp(null)
        } else if(response.status === 409){
          setError("The service or application's name already exists, please choose another one")
        }else {
          console.error(data)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }


  /* Delete an exists service or application */
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)

  const closeModalDelete = () => {
    setSelectedServApp(null)
    setIsOpenModalDelete(false)
  }

  const handleDeleteServApp = (servAppToDelete) => {
    setSelectedServApp(servAppToDelete)
    setIsOpenModalDelete(true)
  }

  const confirmDeleteServApp = async () => {
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      }
      const deleteResponse = await fetch(`${API}/servapps/${currentCompany}/${selectedServApp.id}`, requestOptions)
      const deleteResponseData = await deleteResponse.json()
      if(deleteResponse.status === 200){
        getCompanyServApps()
        closeModalDelete()
      }else{
        console.error(deleteResponseData)
      }
      
    } catch (err) {
      console.error(err)
    }
  }

  /* Servapps file */
  const [servAppsFileModal, setServAppsFileModal] = useState(false)

  /* Send financial variables */
  useEffect(() => {
    handleCompanyServApps(servApps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servApps])


  return (
    <>
      <div className="servicesAndAppsTable">
        <div className="servicesAndAppsTableBody">
          <table>
            <thead className="servicesAndAppsTableHead">
              <tr>
                <th>Service/Application Name</th>
                <th>Description</th>
                <th className="columnSmall tac">Edit</th>
                <th className="columnSmall tac">Delete</th>
              </tr>
            </thead>
            <tbody>
              {servApps.length !== 0 ?
                servApps.map((servApp) => (
                  <tr key={servApp.name}>
                    <td>{servApp.name}</td>
                    <td>{servApp?.description ? servApp.description : 'No description'}</td>
                    <td className="columnSmall tac">
                      <button onClick={() => handleEditServApp(servApp)}>
                        <i className="fa-solid fa-pen-to-square" />
                      </button>
                    </td>
                    <td className="columnSmall tac">
                      <button onClick={() => handleDeleteServApp(servApp)}>
                        <i className="fa-solid fa-trash" />
                      </button>
                    </td>
                  </tr>
                )) : <tr><td colSpan="6">No services or applications found</td></tr>
              }
            </tbody>
          </table>
        </div>
        {/* Footer table */}
        <div className="servicesAndAppsTableFooter">
          <BasicButton 
            color="btnRed" 
            size="btnSmall"
            onClick={() => setServAppsFileModal(true)}
          >
            <>Upload Services/Applications File <i className="fa-solid fa-upload opacity5" /></>
          </BasicButton>{' '}
          <BasicButton color="btnBlack" size="btnSmall" onClick={openServAppModal}>
            Add Service or Application Manually <i className="fa-solid fa-user-plus opacity5" />
          </BasicButton>
        </div>
      </div>
      {/* Add or edit service or applicatiion */}
      <FormModal isOpen={isServAppsModal} onClose={closeModal}>
        {selectedServApp ? (
          <>
            <h2 className="mb2">Edit service or application</h2>
            {error && <div className="mt-1 mb2"><Warning message={error} /></div>}
            <form>
              <BasicInput
                label="Name*"
                type="text"
                value={editServAppData.name}
                onChange={(value) => handleEditServAppInputChange('name', value)}
                placeholder="New service or application name"
              />
              <BasicInput
                label="Description (optional)"
                type="text"
                value={editServAppData.description}
                onChange={(value) => handleEditServAppInputChange('description', value)}
                placeholder="Service/Application description"
              />
              <p className="lightGray tal mt-1 mb1">
                <small>*Mandatory Fields</small>
              </p>
              <BasicButton
                color="btnRed"
                size="btnFull"
                onClick={submitEditServApp}
              >
                Edit service/application
              </BasicButton>
              <div className="mb1" />
              <BasicButton
                color="btnBlack"
                size="btnFull"
                onClick={closeModal}
              >
                Cancel
              </BasicButton>
            </form>
          </>
        ) : (
          <>
            <h2 className="mb2">Add service or application</h2>
            {error && <div className="mt-1 mb2"><Warning message={error} /></div>}
            <form>
              <BasicInput
                label="Name*"
                type="text"
                value={addServAppData.name}
                onChange={(value) => handleAddServAppInputChange('name', value)}
                placeholder="Service or application name"
              />
              <BasicInput
                label="Description"
                type="text"
                value={addServAppData.description}
                onChange={(value) => handleAddServAppInputChange('description', value)}
                placeholder="Service or application description"
              />
              <p className="lightGray tal mt-1 mb1">
                <small>*Mandatory Fields</small>
              </p>
              <BasicButton
                color="btnRed"
                size="btnFull"
                onClick={submitAddServApp}
              >
                Add service/application
              </BasicButton>
              <div className="mb1" />
              <BasicButton
                color="btnBlack"
                size="btnFull"
                onClick={closeModal}
              >
                Cancel
              </BasicButton>
            </form>
          </>
        )}
      </FormModal>
      {/* Prevent service or application delete modal */}
      <BasicModal isOpen={isOpenModalDelete} onClose={closeModalDelete}>
        <h2 className="mb2 tac">Are you sure that you want to delete this service or application?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={confirmDeleteServApp}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={closeModalDelete}
        >
          Cancel
        </BasicButton>
      </BasicModal>

      {/* Upload resources file */}
      <ServAppsFileModal
        existentServapps={servAppsNames}
        isOpen={servAppsFileModal}
        closeModal={() => {
          setServAppsFileModal(false)
          getCompanyServApps()
        }}
      />
    </>
  )
}

export default ServicesAndAppsTable