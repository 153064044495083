import { Link } from 'react-router-dom'
import './SettingsCard.css'

const SettingsCard = ({ cardTitle, cardLinkTo }) => {
  return (
      <Link to={`/${btoa(cardLinkTo)}`} className="settingsCard">
        <p className="bigText">{cardTitle}</p>
        <i className="fa-solid fa-arrow-right settingsCardIcon" />
      </Link>
  )
}

export default SettingsCard