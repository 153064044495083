import React, { createContext, useContext, useEffect, useState, useCallback, useMemo } from 'react'
import { Auth, Hub } from 'aws-amplify'
import BasicModal from '../components/modals/BasicModal'
import BasicButton from '../components/buttons/BasicButton'
import Header from '../components/nav/Header'
import UserLogo from '../components/resources/UserLogo'
import Sidebar from '../components/nav/Sidebar'
import FooterCopy from '../components/footer/FooterCopy'

const AuthContext = createContext()

// Inactivity time count
const TIME_LIMIT = 20 * 60 * 1000;
const MODAL_LIMIT = 30 * 1000;

export const useAuthContext = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  // Automatic Logout
  const [isOpenModalLogout, setIsOpenModalLogout] = useState(false)
  const [logoutMessage, setLogoutMessage] = useState('')
  const [modalTime, setModalTime] = useState(0)
  const [modalCounter, setModalCounter] = useState(30)

  const [lastActivityTime, setLastActivityTime] = useState(
    localStorage.getItem('lastActivityTime') ?
      parseInt(localStorage.getItem('lastActivityTime')) :
      Date.now()
  );

  const logout = useCallback(async () => {
    try {
      await Auth.signOut()
      setUser(null)
      setIsAuthenticated(false)
      setIsAdmin(false)
      localStorage.removeItem('currentUser')
      localStorage.removeItem('currentCompany')
      localStorage.removeItem('lastActivityTime')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }, [])

  const resetSessionTimer = () => {
    const activityTimestamp = Date.now()
    setLastActivityTime(activityTimestamp)
    localStorage.setItem('lastActivityTime', activityTimestamp)
    setIsOpenModalLogout(false)
    setLogoutMessage('')
  }

  const handleCloseModal = () => {
    setModalTime(0)
    resetSessionTimer()
  }

  const handleLogout = async () => {
    setModalTime(0)
    setModalCounter(30)
    setIsOpenModalLogout(false)
    setLogoutMessage('')
    await logout()
  }

  useEffect(() => {
    const handleUserActivity = () => {
      const activityTimestamp = Date.now()
      setLastActivityTime(activityTimestamp)
      localStorage.setItem('lastActivityTime', activityTimestamp)
    }

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [])

  // Check for inactivity time limit
  useEffect(() => {
    const checkSessionTime = async () => {
      const currentTime = Date.now();
      if (isAuthenticated && !isOpenModalLogout && currentTime - lastActivityTime >= TIME_LIMIT) {
        const modalTimeTriggered = currentTime + MODAL_LIMIT
        setModalTime(modalTimeTriggered)
        setLogoutMessage('Do you want to continue?')
        setIsOpenModalLogout(true)
      }
    };

    const sessionTimeCheck = setInterval(checkSessionTime, 1000); // Check every second
    checkSessionTime();
    return () => {
      clearInterval(sessionTimeCheck);
    };
  }, [isAuthenticated, lastActivityTime, isOpenModalLogout]);

  useEffect(() => {
    const checkModalTime = async () => {
      if (isOpenModalLogout) {
        const currentTime = Date.now()
        const timeRemaining = Math.max(0, Math.ceil((modalTime - currentTime) / 1000));
        setModalCounter(timeRemaining)

        if (timeRemaining <= 0) {
          handleLogout()
        }
      }
    }

    checkModalTime(); // Check immediately
    const modalTimeCheck = setInterval(checkModalTime, 1000); // Check every second
    return () => clearInterval(modalTimeCheck);
  }, [isOpenModalLogout, modalTime])

  useEffect(() => {
    if (!isAuthenticated) {
      // Reset modal-related state when user is logged out
      setIsOpenModalLogout(false);
      setLogoutMessage('');
      setModalTime(0);
      setModalCounter(30)
    }
  }, [isAuthenticated]);

  //
  useEffect(() => {
    const checkIfAuthenticated = async () => {
      try {
        const usr = await Auth.currentAuthenticatedUser()
        if (usr) {
          const attributes = usr.attributes
          setUser(attributes)
          setIsAuthenticated(true)
          const groups = usr.signInUserSession.accessToken.payload["cognito:groups"];
          if (groups && groups.includes('ADMIN')) {
            setIsAdmin(true)
          } else {
            setIsAdmin(false)
          }
          localStorage.setItem('currentUser', JSON.stringify(attributes))
          localStorage.setItem('currentCompany', attributes['custom:company'])
        }
      } catch (error) {
        console.error(error)
        setUser(null)
        setIsAuthenticated(false)
        localStorage.removeItem('currentUser')
        localStorage.removeItem('currentCompany')
      }
    }

    checkIfAuthenticated()
  }, [])

  // Auth listener
  useEffect(() => {
    const authListener = async (data) => {
      if (data.payload.event === 'signIn') {
        try {
          const usr = await Auth.currentAuthenticatedUser()
          if (usr) {
            const attributes = usr.attributes
            setUser(attributes)
            setIsAuthenticated(true)
            resetSessionTimer()
            localStorage.setItem('currentUser', JSON.stringify(attributes))
            localStorage.setItem('currentCompany', attributes['custom:company'])
            const groups = usr.signInUserSession.accessToken.payload["cognito:groups"];
            if (groups && groups.includes('ADMIN')) {
              setIsAdmin(true)
            } else {
              setIsAdmin(false)
            }
          }
        } catch (error) {
          console.error('Error fetching user:', error)
          setUser(null)
          setIsAuthenticated(null)
          localStorage.removeItem('currentUser')
          localStorage.removeItem('currentCompany')
        }
      } else if (data.payload.event === 'signOut') {
        setUser(null)
        setIsAuthenticated(false)
        localStorage.removeItem('currentUser')
        localStorage.removeItem('currentCompany')
        localStorage.removeItem('lastActivityTime')
      }
    }
    Hub.listen('auth', authListener)
  }, [])

  const authValue = useMemo(() => ({
    user,
    isAuthenticated,
    logout  
  }), [user, isAuthenticated, logout])

  return (
    <AuthContext.Provider value={authValue}>
      {isAuthenticated && (
        <>
          <Header />
          <UserLogo logoUpdated={false} />
          <Sidebar admin={isAdmin}/>
        </>
      )}
      {children}
      {/* {isAuthenticated && (
        <>
          <FooterCopy />
        </>
      )} */}
      <BasicModal isOpen={isOpenModalLogout}>
        <h2 className="mb2 tac">{logoutMessage}</h2>
        <p>You have {modalCounter} seconds</p>
        <BasicButton
          as="button"
          color="btnBlack"
          size="btnFull"
          onClick={handleCloseModal}
        >
          Continue
        </BasicButton>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={handleLogout} // close the tab
        >
          Logout
        </BasicButton>
        <div className="mb1" />

      </BasicModal>
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
