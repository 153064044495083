import React, { useState } from 'react'
import CurrentDate from '../../components/resources/CurrentDate'
import BusinessNavFilter from '../../components/nav/BusinessNavFilter'
import BusinessInitiativeFilter from '../../components/filters/BusinessInitiativeFilter'
import BusinessMetricsTable from '../../components/tables/BusinessMetricsTable'
import { useInitiativesContext } from './resources/BusinessInitiativesContext'
import InitiativePill from '../../components/pills/InitiativePill'
import DateInput from '../../components/forms/DateInput'
import BasicButton from '../../components/buttons/BasicButton'

const BusinessStartDate = () => {
  /* Initiatives */
  const initiatives = useInitiativesContext()

  /* Options for filter */
  const options = ['Equal to', 'Before to', 'After to', 'Is between']

  const handleFilter = (value) => {
    setFilter({ ...filter, type: value })
  }

  /* Filtering by date */
  const [filter, setFilter] = useState({ type: '', value: '' })

  const applyFilter = (type) => {
    switch (type) {
      case 'Equal to':
        return (item) => item.start_date === filter.value
      case 'Before to':
        return (item) => new Date(item.start_date) < new Date(filter.value)
      case 'After to':
        return (item) => new Date(item.start_date) > new Date(filter.value)
      case 'Is between':
        return (item) =>
          new Date(item.start_date) >= new Date(filter.value[0]) &&
          new Date(item.start_date) <= new Date(filter.value[1])
      default:
        return () => true
    }
  }

  const filteredInitiatives = initiatives.filter(applyFilter(filter.type))

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    const format = e.target.dataset.format
    let formattedValue = value
    if (format === 'date') {
      const inputDate = new Date(value)
      formattedValue = inputDate.toLocaleDateString('en-US', {
        timeZone: 'UTC',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      })
    }

    if (name === 'value') {
      setFilter((prev) => ({ ...prev, value: formattedValue }))
    } else if (name === 'startvalue') {
      setFilter((prev) => ({ ...prev, value: [formattedValue, prev.value[1]] }))
    } else if (name === 'endvalue') {
      setFilter((prev) => ({ ...prev, value: [prev.value[0], formattedValue] }))
    }
  }

  const clearFilters = () => {
    setFilter({ type: '', value: '' })
  }

  return (
    <div className="containerToRight">
      <h1>
        <span className="lightGray">DOBCO<span className="bigN-h1">n</span></span> Business per Date
      </h1>
      <p className="mediumText mt1 fw500">Filter All Your Initiatives by Date</p>
      <div className="tar">
        <CurrentDate />
        <hr className="grayLine mt1" />
      </div>
      <div className="flexContainer spaceBetween pt1">
        <div className="col2 col6Tablet mb1">
          <BusinessNavFilter
            links={[
              'Business Metrics',
              'Initiative Resources',
              'Initiative Status',
              'Location',
              'Total Initiative Cost'
            ]}
            activeSection="Initiative Start Date"
          />
        </div>
        <div className="col2 col6Tablet mb1">
          <BusinessInitiativeFilter
            options={options}
            onChange={handleFilter}
          />
        </div>
        <div className="col8 mb1">
          {
            filter.type === "" ? null :
              <>
                {filter.type === 'Is between' ? (
                  <div className="flexContainer alignCenter">
                    <div className="col1 mr1"><p>Start Date: </p></div>
                    <div className="col3 mr1">
                      <DateInput name="startvalue" onChange={handleFilterChange} />
                    </div>
                    <div className="col1 mr1"><p>End date:</p></div>
                    <div className="col3 mr1">
                      <DateInput name="endvalue" onChange={handleFilterChange} />
                    </div>
                    <div className="col2">
                      <BasicButton color="btnRed" size="btnMini" onClick={clearFilters}>
                        Clear Filter
                      </BasicButton>
                    </div>
                  </div>
                ) : (
                  <div className="flexContainer alignCenter">
                    <div className="col1 mr1"><p>{filter.type}: </p></div>
                    <div className="col3 mr1">
                      <DateInput name="value" onChange={handleFilterChange} />
                    </div>
                    <div className="col2">
                      <BasicButton color="btnRed" size="btnMini" onClick={clearFilters}>
                        Clear Filter
                      </BasicButton>
                    </div>
                  </div>
                )}
              </>
          }
        </div>
      </div>
      <hr className="grayLine" />
      <div className="flexContainer pt1">
        {filteredInitiatives.length !== 0 ? (
          filteredInitiatives.map((initiative) => (
            <InitiativePill
              key={initiative.name}
              pillTitle={initiative.name}
              pillLink={initiative.name}
            />
          ))
        ) : (
          <p className="fw500 mb1">No initiatives Found</p>
        )}
      </div>
      <hr className="grayLine mb2" />
      <BusinessMetricsTable initiatives={filteredInitiatives} />
    </div>
  )
}

export default BusinessStartDate