import React, { useState, useEffect, useRef } from 'react'
import './BasicIconFilter.css'

const BasicIconFilter = ({ icon, title, options, onChange, }) => {
  const [isOpen, setIsOpen] = useState(false)
  const filterRef = useRef(null)

  const handleSelect = (selectedValue) => {
    onChange(selectedValue)
    setIsOpen(false)
  }

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="iconFilter" ref={filterRef}>
      <div className="iconFilterContainer" onClick={handleToggle}>
        <div className="iconFilterSelected">
          <div>
            {icon ? <i className={`opacity5 ${icon}`} /> : null}
            <span className="fw500"> {title}</span>
          </div>
          <i className={`fa-solid fa-angle-down ${isOpen ? 'open' : ''}`} />
        </div>
        {isOpen && (
          <ul className="iconFilterOptions">
            {options.map((option) => (
              <li
                key={option}
                className="iconFilterOption fw500"
                onClick={() => handleSelect(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default BasicIconFilter