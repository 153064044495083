import React, { useEffect, useState } from 'react'
import './BusinessMetricsTable.css'

const BusinessMetricsTable = ({ initiatives }) => {
  /* Metrics */
  const [metrics, setMetrics] = useState()
  /* Calculate */
  const calculate = () => {
    if (!initiatives || initiatives.length === 0) {
      setMetrics({
        "roi": "0.00",
        "tco": "0.00",
        "total_cost": "0.00",
        "release_cost": "0.00",
        "se_automation": "0.00",
        "productivity": "0.00",
        "acceptance_rate": "0.00",
        "sla_breaches": "0.00",
        "change_efficiency": "0.00",
        "release_efficiency": "0.00",
        "adoption_progress": "0.00"
      })
      return
    }

    let sumRoi = 0
    let sumTco = 0
    let sumInitiativeCost = 0
    let sumReleaseCost = 0
    let sumSavingsExpenses = 0
    let sumStaffProductivity = 0
    let sumAcceptanceRate = 0
    let sumSlaBreaches = 0
    let sumChangeProcessEfficiency = 0
    let sumReleaseProcessEfficiency = 0
    let sumAdoptionProgress = 0

    for (let index = 0; index < initiatives?.length; index++) {
      sumRoi += initiatives[index].roi
      sumTco += initiatives[index].tco
      sumInitiativeCost += initiatives[index].total_cost
      sumReleaseCost += initiatives[index].release_cost
      sumSavingsExpenses += initiatives[index].se_automation
      sumStaffProductivity += initiatives[index].productivity
      sumAcceptanceRate += initiatives[index].acceptance_rate
      sumSlaBreaches += initiatives[index].sla_breaches
      sumChangeProcessEfficiency += initiatives[index].change_efficiency
      sumReleaseProcessEfficiency += initiatives[index].release_efficiency
      sumAdoptionProgress += initiatives[index].adoption_progress
    }

    const totalInitiatives = initiatives?.length

    setMetrics({
      "roi": (sumRoi / totalInitiatives).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      "tco": (sumTco / totalInitiatives).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      "total_cost": (sumInitiativeCost / totalInitiatives).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      "release_cost": (sumReleaseCost / totalInitiatives).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      "se_automation": (sumSavingsExpenses / totalInitiatives).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      "productivity": (sumStaffProductivity / totalInitiatives).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      "acceptance_rate": (sumAcceptanceRate / totalInitiatives).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      "sla_breaches": (sumSlaBreaches / totalInitiatives).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      "change_efficiency": (sumChangeProcessEfficiency / totalInitiatives).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      "release_efficiency": (sumReleaseProcessEfficiency / totalInitiatives).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      "adoption_progress": (sumAdoptionProgress / totalInitiatives).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    })
  }

  useEffect(() => {
    calculate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiatives])

  return (
    <div className="businessMetricsTable">
      <div className="businessMetricsTableBody flexContainer">
        <ul className="w50 grayLineRight">
          <p className="mediumText mb1">Financial Metrics</p>
          <li>
            <span>DevOps ROI</span>
            <span>
              {metrics?.roi ?? initiatives?.roi}%
            </span>
          </li>
          <li>
            <span>DevOps TCO</span>
            <span>
              {metrics?.tco ?? initiatives?.tco}%
            </span>
          </li>
          <li>
            <span>Total Initiative Cost</span>
            <span>
              ${metrics?.total_cost ?? initiatives?.total_cost}
            </span>
          </li>
          <li>
            <span>Total Release Cost</span>
            <span>
              ${metrics?.release_cost ?? initiatives?.release_cost}
            </span>
          </li>
          <li>
            <span>Savings and Expenses (%) from Automation</span>
            <span>
              {metrics?.se_automation ?? initiatives?.se_automation}%
            </span>
          </li>
        </ul>
        <ul className="w50">
          <p className="mediumText mb1">Performance Metrics</p>
          <li>
            <span>Staff Productivity</span>
            <span>
              {metrics?.productivity ?? initiatives?.productivity}%
            </span>
          </li>
          {/* <li>
            <span>DevOps customer acceptance rate</span>
            <span>
              {metrics?.acceptance_rate ?? initiatives?.acceptance_rate}%
            </span>
          </li> */}
          <li>
            <span>SLAs Breaches</span>
            <span>
              {metrics?.sla_breaches ?? initiatives?.sla_breaches}%
            </span>
          </li>
          <li>
            <span>DevOps Change Management Process Efficiency</span>
            <span>
              {metrics?.change_efficiency ?? initiatives?.change_efficiency}%
            </span>
          </li>
          <li>
            <span>DevOps Release Management Process Efficiency</span>
            <span>
              {metrics?.release_efficiency ?? initiatives?.release_efficiency}%
            </span>
          </li>
          <li>
            <span>DevOps Practices Adoption Progress</span>
            <span>
              {metrics?.adoption_progress ?? initiatives?.adoption_progress}%
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default BusinessMetricsTable