import React, { useState, useEffect } from 'react'
import './AdoptionCard.css'

const AdoptionCard = ({
  cardTitle,
  cardDescription,
  cardVideoSrc,
  videoEnded,
  onVideoEnded,
  videoProgress,
  onSaveVideoProgress,
}) => {
  /* Modal */
  const [openVideo, setOpenVideo] = useState(false)
  /* Video ended? */
  const [ended, setEnded] = useState(videoEnded)
  /* Save time */

  // Save the initial progress
  const [initialProgress, setInitialProgress] = useState(videoProgress)
  const [watchedPercentage, setWatchedPercentage] = useState(0)
  const [videoDuration, setVideoDuration] = useState(0)

  const closeModalAndSaveTime = () => {
    /* Get the video name */
    const videoElement = document.getElementById(cardTitle)
    /* Set the progress with the name and the current time */
    setInitialProgress(videoElement.currentTime)
    /* Send the name video and the current time to parent component */
    onSaveVideoProgress(cardTitle, videoElement.currentTime)
    setOpenVideo(false)
  }

  const handleLoadedMetadata = () => {
    const videoElement = document.getElementById(cardTitle);
    if (videoElement) {
      videoElement.currentTime = initialProgress;
      setVideoDuration(videoElement.duration)
      calculateWatchedPercentage(videoElement.duration, initialProgress);
    }
  };

  const calculateWatchedPercentage = (duration, progress) => {
    if (duration === 0) return;
    const percentage = (progress / duration) * 100;
    setWatchedPercentage(percentage);
  }
  
  useEffect(() => {
    const videoElement = document.getElementById(cardTitle);
    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, [cardTitle]);

  useEffect(() => {
    if (videoDuration > 0) {
      calculateWatchedPercentage(videoDuration, initialProgress);
    }
  }, [initialProgress, videoDuration]);


  return (
    <>
      <div className="adoptionCard">
        <div className="adoptionCardHead">
          <p className="mediumText">{cardTitle}</p>
        </div>
        <div className="adoptionCardBody">
          <p className="fw500">{cardDescription}</p>
        </div>
        <div className="adoptionCardFooter">
          <button
            onClick={() => setOpenVideo(true)}
            style={
              ended ? 
              { background: '#28283C' } : 
              ( videoProgress === 0 ? 
                {} : 
                { background: `linear-gradient(90deg, var(--dobcon-red) ${watchedPercentage}%, #28283C ${watchedPercentage}%)`}
              )
              
            }
            className="adoptionCards__btn"
          >
            {ended ? 'Watch Again' : (initialProgress > 0 ? 'Continue Watching' : 'Watch Video')}
          </button>
        </div>
      </div>
      {openVideo && (
        <div className={`videoModalBack flexContainer alignCenter justifyCenter
          ${openVideo ? 'modalActive' : 'modalNoActive'}`}>
          <div className='videoModalContent' onContextMenu={e => e.preventDefault()}>
            <i
              className="fas fa-times closeVideo"
              onClick={closeModalAndSaveTime}
            />
            <video
              controls
              autoPlay
              onEnded={() => {
                setEnded(true)
                onVideoEnded()
              }}
              controlsList="nodownload"
              id={cardTitle}
              onLoadedMetadata={handleLoadedMetadata}
            >
              <source src={cardVideoSrc} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </>
  )
}

export default AdoptionCard