import React, { useEffect, useState } from 'react';
import DarkSelectInput from '../../../components/forms/DarkSelectInput';
import './TotalProductionByDeveloper.css'

const TotalProductionByDeveloper = ({ data }) => {
    const [selectedOptioAuthor, setSelectedOptioAuthor] = useState('All');
    const [selectedOptioDate, setSelectedOptionDate] = useState("Last Month");
    const [selectedOptionInitiative, setSelectedOptionInitiative] = useState('All');
    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'ascending' });

    const optionsDateSelect = ['Today', 'Last Week', 'Last Two Weeks', 'Last Month'];
    const uniqueAuthors = [...new Set(data.map(item => item.author))];
    uniqueAuthors.unshift("All");

    const uniqueInitiatives = [...new Set(data.map(item => item.initiative))];
    uniqueInitiatives.unshift("All");

    const filterByTimeframe = (data, days) => {
        const today = new Date();
        const startDate = new Date(today.setDate(today.getDate() - days));
      
        return data.filter(item => {
          const itemDate = new Date(item.date);
          return itemDate >= startDate && itemDate <= new Date();
        });
    };
   
    const timeframeChange = (value, data) => {
        switch (value) {
          case 'Today':
            return filterByTimeframe(data, 1);
          case 'Last Week':
            return filterByTimeframe(data, 7);
          case 'Last Two Weeks':
            return filterByTimeframe(data, 14);
          case 'Last Month':
            return filterByTimeframe(data, 30);
          default:
            return data;
        }
    };

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        let filteredByDate = timeframeChange(selectedOptioDate, data);
        let filteredByInitiative = selectedOptionInitiative !== 'All' ? filteredByDate.filter(item => item.initiative === selectedOptionInitiative) : filteredByDate;
        let filteredData = selectedOptioAuthor !== 'All' ? filteredByInitiative.filter(item => item.author === selectedOptioAuthor) : filteredByInitiative;

        filteredData = sortTableData(filteredData, sortConfig);

        setTableData(filteredData);
    }, [data, selectedOptioAuthor, selectedOptioDate, selectedOptionInitiative, sortConfig]);

    const handleDataAuthorChange = (e) => {
        setSelectedOptioAuthor(e);
    };

    const handleDateChange = (e) => {
        setSelectedOptionDate(e);
    };

    const handleInitiativeChange = (e) => {
        setSelectedOptionInitiative(e);
    };

    const sortTableData = (data, config) => {
        return data.sort((a, b) => {
            if (a[config.key] < b[config.key]) {
                return config.direction === 'ascending' ? -1 : 1;
            }
            if (a[config.key] > b[config.key]) {
                return config.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortArrow = (column) => {
        if (sortConfig.key !== column) return ' ↑↓';
        return sortConfig.direction === 'ascending' ? ' ↑' : ' ↓';
    };

    return (
      <div className='developerModalContent'>
        <div className='filtersContainer'>
          <div className="col3">
            <div className="graphFilterLabel fw500">Filter by Author:</div>
            <DarkSelectInput
                className="w100 m0 mt0 tal"
                options={uniqueAuthors}
                value={selectedOptioAuthor}
                onChange={handleDataAuthorChange}
            />
          </div>
          <div className="col3">
            <div className="graphFilterLabel fw500">Filter by Timeframe:</div>
            <DarkSelectInput
                className="w100 m0 mt0 tal"
                options={optionsDateSelect}
                value={selectedOptioDate}
                onChange={handleDateChange}
            />
          </div>
          <div className="col3">
            <div className="graphFilterLabel fw500">Filter by Initiative:</div>
            <DarkSelectInput
                className="w100 m0 mt0 tal"
                options={uniqueInitiatives}
                value={selectedOptionInitiative}
                onChange={handleInitiativeChange}
            />
            </div>
          </div>
        <div className="graphTable">
          <div className='graphTableBody'>
            <table>
              <thead className="graphTableHead">
                  <tr>
                      <th onClick={() => handleSort('date')}>Date {getSortArrow('date')}</th>
                      <th onClick={() => handleSort('author')}>Author {getSortArrow('author')}</th>
                      <th onClick={() => handleSort('commits')}>Commits {getSortArrow('commits')}</th>
                      <th onClick={() => handleSort('initiative')}>Initiative {getSortArrow('initiative')}</th>
                  </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td>{row.date}</td>
                    <td>{row.author}</td>
                    <td>{row.commits}</td>
                    <td>{row.initiative}</td> 
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
};

export default TotalProductionByDeveloper;
