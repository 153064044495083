import React, { createContext, useContext, useEffect, useState } from 'react'
import { Auth, Hub } from 'aws-amplify'

const API = process.env.REACT_APP_LICENCES_API
const PrivilegeContext = createContext()

export const usePrivilegeContext = () => {
  return useContext(PrivilegeContext)
}

export const PrivilegeProvider = ({ children }) => {
  const [rolePrivileges, setRolePrivileges] = useState(localStorage.getItem('rolePrivileges') || '')
  

  const roleNames = {
    'CEO': 'ceo',
    'PM': 'pm',
    'VP/Director Business':'vp_director_business',
    'VP/Director Engineering': 'vp_director_engineering',
    'Business Manager': 'manager_business',
    'Engineering Manager': 'manager_engineering',
    'Engineer/Developer': 'engineer_dev',
    'Business Analyst': 'business_analyst'
  }


  useEffect(() => {
    // Listening auth events from Amplify Hub
    const authListener = async (data) => {
      if (data.payload.event === 'signIn') {
        // The user has logged, get and save the user
        try {
          const user = await Auth.currentAuthenticatedUser()
          const company = user.attributes['custom:company']
          const role = user.attributes['custom:role']
          const roleName = roleNames[role]
          
          const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
            accept: 'application/json',
          }
          const response = await fetch(`${API}/privileges/${company}/${roleName}`, requestOptions)
          if(response.status === 200){
            const priv = await response.json()
            if (rolePrivileges !== priv) {
              setRolePrivileges(priv)
              localStorage.setItem('rolePrivileges', priv)
            }
            
          } else {
            console.error('Failed to fetch privileges:', response.statusText)
          }
        } catch (error) {
          console.error('Error fetching user:', error)
        }
      }
    }
    // Sign in to listen auth evets
    Hub.listen('auth', authListener)
    // After unmount the component, be sure to delet the listener
    return () => {
      Hub.remove('auth', authListener)
    }
  }, [rolePrivileges])

  return (
    <PrivilegeContext.Provider value={rolePrivileges}>
      {children}
    </PrivilegeContext.Provider>
  )
}

export const usePrivilege = () => {
  return useContext(PrivilegeContext)
}
