import React, { useState } from 'react'
import './BusinessDashboard.css'
import CurrentDate from '../../components/resources/CurrentDate'
import BusinessNavFilter from '../../components/nav/BusinessNavFilter'
import BusinessInitiativeFilter from '../../components/filters/BusinessInitiativeFilter'
import BusinessFilterPill from '../../components/pills/BusinessFilterPill'
import BusinessMetricsTable from '../../components/tables/BusinessMetricsTable'
import { useInitiativesContext } from './resources/BusinessInitiativesContext'
import InitiativePill from '../../components/pills/InitiativePill'

const BusinessStatus = () => {
  /* Initiatives */
  const initiatives = useInitiativesContext()
  
  /* Options for filter */
  const [onlyOneStatus, setOnlyOneStatus] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState([])
  const options = ['Under Development', 'Paused', 'Released', 'Under Maintenance', 'Retired']

  const handleStatusSelect = (status) => {
    if (selectedStatus.includes(status)) {
      setSelectedStatus(selectedStatus.filter((item) => item !== status))
    } else {
      setSelectedStatus([...selectedStatus, status])
    }
  }

  const handleOneStatus = (status) => {
    if (onlyOneStatus === status) {
      setOnlyOneStatus(null)
    } else {
      setOnlyOneStatus(status)
    }
  }

  const handleStatusDelete = (status) => {
    setSelectedStatus(selectedStatus.filter((item) => item !== status))
    if(onlyOneStatus === status){
      setOnlyOneStatus(null)
    }
  }

  /* Filtering */
  const filteredInitiatives = initiatives.filter((initiative) => {
    /* Filtering by status */
    if (selectedStatus.length === 0) {
      return true
    }

    if (onlyOneStatus) {
      return initiative.status === onlyOneStatus
    }

    return selectedStatus.includes(initiative.status)
  })

  return (
    <div className="containerToRight">
      <h1>
        <span className="lightGray">DOBCO<span className="bigN-h1">n</span></span> Business per Status
      </h1>
      <p className="mediumText mt1 fw500">Filter All Your Initiatives by Status</p>
      <div className="tar">
        <CurrentDate />
        <hr className="grayLine mt1" />
      </div>
      <div className="flexContainer spaceBetween pt1">
        <div className="col2 col6Tablet mb1">
          <BusinessNavFilter
            links={[
              'Business Metrics',
              'Initiative Resources',
              'Initiative Start Date',
              'Location',
              'Total Initiative Cost'
            ]}
            activeSection="Initiative Status"
          />
        </div>
        <div className="col2 col6Tablet mb1">
          <BusinessInitiativeFilter
            options={options}
            onChange={handleStatusSelect}
          />
        </div>
        <div className="col8 flexContainer filterContainer">
          {selectedStatus.map((status) => (
            <div key={status}>
              <BusinessFilterPill
                pillTitle={status}
                pillDelete={() => handleStatusDelete(status)}
                pillSelected={() => handleOneStatus(status)}
                pillDisabled={onlyOneStatus && status !== onlyOneStatus}
                isPillSelected={status === onlyOneStatus}
              />
            </div>
          ))}
        </div>
      </div>
      <hr className="grayLine" />
      <div className="flexContainer pt1">
        {filteredInitiatives.length !== 0 ? (
          filteredInitiatives.map((initiative) => (
            <InitiativePill
              key={initiative.name}
              pillTitle={initiative.name}
              pillLink={initiative.name}
            />
          ))
        ) : (
          <p className="fw500 mb1">No initiatives Found</p>
        )}
      </div>
      <hr className="grayLine mb2" />
      <BusinessMetricsTable initiatives={filteredInitiatives} />
    </div>
  )
}

export default BusinessStatus