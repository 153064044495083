import './InitiativePill.css'
import { Link } from 'react-router-dom'

const InitiativePill = ({ pillTitle, pillLink }) => {
  return (
    <Link
      to={`/${btoa('initiative')}/${btoa(pillLink)}`}
      className="initiativePill"
    >
      {pillTitle}
    </Link>
  )
}

export default InitiativePill