import React, { useEffect, useState } from 'react'
import './SearchInitiativeLeader.css'
import SearchBar from '../resources/SearchBar'

const API = process.env.REACT_APP_SETUP_API

const SearchInitiativeLeader = ({ updateInitiativeLeader, currentInitiativeLeader }) => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Resources list */
  const [resources, setResources] = useState([])
  /* Fetch resources list */
  const getCompanyResources = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    const response = await fetch(`${API}/resources/${currentCompany}/sync`, requestOptions)
    const data = await response.json()
    setResources(data)
  }

  useEffect(() => {
    getCompanyResources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Filtering by searchBar */
  const [searchInitiativeLeader, setSearchInitiativeLeader] = useState('')
  const [filteredInitiativeLeader, setFilteredInitiativeLeader] = useState([])

  const handleSearch = (value) => {
    const searchInitiativeLeader = value
    setSearchInitiativeLeader(searchInitiativeLeader)

    const filtered = resources.filter(leader =>
      leader.name.toLowerCase().includes(searchInitiativeLeader.toLowerCase())
    )

    setFilteredInitiativeLeader(filtered)
  }

  const displayInitiativeLeaders = searchInitiativeLeader === '' ? resources : filteredInitiativeLeader

  /* Leader selected */
  const [selectedInitiativeLeader, setSelectedInitiativeLeader] = useState(currentInitiativeLeader)

  const handleSelectInitiativeLeader = (leader) => {
    // Verify if is selected
    const isSelected = selectedInitiativeLeader === leader.name

    if (isSelected) {
      // If is selected, delete it
      setSelectedInitiativeLeader('')
    } else {
      // If is not selected, add it
      setSelectedInitiativeLeader(leader.name)
    }
  }

  /* Send initiative leader */
  useEffect(() => {
    updateInitiativeLeader(selectedInitiativeLeader)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInitiativeLeader])

  return (
    <div className="searchInitiativeLeaderTable">
      <div className="searchInitiativeLeaderTableHead">
        <h2>Initiative Leader</h2>
        <SearchBar
          className="searchBarMedium"
          placeholder="Search initiative leader"
          onChange={handleSearch}
          value={searchInitiativeLeader}
        />
      </div>
      <div className="searchInitiativeLeaderTableBody">
        <table>
          <thead>
            <tr>
              <th className="columnSmall">Select</th>
              <th>Full Name</th>
              <th>Role</th>
              <th>Department</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {displayInitiativeLeaders.length === 0 ? (
              <tr><td colSpan="5">No resources found</td></tr>
            ) : (
              displayInitiativeLeaders.map((leader, index) => (
                <tr key={leader.id}>
                  <td className="tac">
                    <span
                      className={
                        `selectLeader ${currentInitiativeLeader === leader.name ? 'selectedLeader' : ''}`
                      }
                      onClick={() => handleSelectInitiativeLeader(leader)}
                    />
                  </td>
                  <td>{leader.name}</td>
                  <td>{leader.role}</td>
                  <td>{leader.department}</td>
                  <td>{leader.location}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SearchInitiativeLeader