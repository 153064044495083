import PasswordForm from '../../components/forms/PasswordForm'

const ChangePassword = () => {
  return (
    <div className="containerToRight">
      <div className="flexContainer justifyCenter">
        <PasswordForm type="change" />
      </div>
    </div>
  )
}

export default ChangePassword