const getBrowserName = () => {
  const userAgent = navigator.userAgent;
  let browser = 'Unknown';
  if (userAgent.match(/opr\//i)) {
    browser = 'Opera'
  }
  else if (userAgent.indexOf('Chrome') !== -1) {
    const b = isBrave()
    if (b) {
      browser = 'Brave'
    }
    else {
      browser = 'Chrome'
    }
  }
  else if (userAgent.match(/safari/i)) {
    browser = 'Safari'
  }
  else if (userAgent.match(/firefox|fxios/i)) {
    browser = 'Firefox'
  }
  else if (userAgent.indexOf('Edge')) {
    browser = 'Edge'
  }
  return browser
}


const isBrave = () => {
  let is_brave = false
  if (window.navigator.brave && window.navigator.brave.isBrave.name === "isBrave") {
    is_brave = true;
  }
  return is_brave;
}

export default getBrowserName