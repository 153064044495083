import React, { useState, useEffect } from 'react';
import './DeploymentsToProduction.css';
import './GraphStyles.css';

/* ChartJS */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import DarkSelectInput from '../../../components/forms/DarkSelectInput';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      right: 30,
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: 'white',
      },
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      titleFont: {
        size: 14, // Set font size for the tooltip title
      },
      bodyFont: {
        size: 14, // Set font size for the tooltip body text
      },
    },
  },
  scales: {
    x: {
      stacked: false,
      reverse: true,
      title: {
        display: true,
        text: 'Date',
        color: 'white',
        font: {
          size: 17,
        },
      },
      ticks: {
        autoSkip: false,
        color: 'white',
        maxRotation: 45,
      },
    },
    y: {
      stacked: false,
      beginAtZero: true,
      title: {
        display: true,
        text: 'Deployments',
        color: 'white',
        font: {
          size: 17,
        },
      },
      ticks: {
        stepSize: 1,
        color: 'white',
      },
      suggestedMax(context) {
        let maxValue = 0;
        context.chart.data.datasets.forEach((dataset) => {
          dataset.data.forEach((value) => {
            if (value > maxValue) maxValue = value;
          });
        });
        return maxValue + 1;
      },
    },
  },
};

// Hardcoded JSON data
const hardcodedDtpData = [
  {
    initiative: 'Initiative_1',
    dtp_per_date: [
      { timestamp: '2024/08/01', g: 5, r: 2 },
      { timestamp: '2024/08/02', g: 3, r: 1 },
      { timestamp: '2024/08/03', g: 4, r: 3 },
      { timestamp: '2024/08/04', g: 6, r: 1 },
    ],
    dtp_per_timeframe: [
      { timeframe: 'lastDay', g: 1, r: 0 },
      { timeframe: 'lastWeek', g: 10, r: 4 },
      { timeframe: 'lastTwoWeeks', g: 20, r: 7 },
      { timeframe: 'lastMonth', g: 30, r: 10 },
    ],
  },
  {
    initiative: 'Initiative_2',
    dtp_per_date: [
      { timestamp: '2024/08/01', g: 4, r: 2 },
      { timestamp: '2024/08/02', g: 5, r: 3 },
      { timestamp: '2024/08/03', g: 2, r: 2 },
      { timestamp: '2024/08/04', g: 4, r: 1 },
    ],
    dtp_per_timeframe: [
      { timeframe: 'lastDay', g: 2, r: 1 },
      { timeframe: 'lastWeek', g: 8, r: 5 },
      { timeframe: 'lastTwoWeeks', g: 15, r: 6 },
      { timeframe: 'lastMonth', g: 25, r: 12 },
    ],
  },
];

const DeploymentsToProduction = ({ dtp = hardcodedDtpData, initiative }) => {
  const [deploymentsToProd, setDeploymentsToProd] = useState([]);
  const [filteredDeployments, setFilteredDeployments] = useState([]);
  const [filteredTimeframeDtp, setFilteredTimeframeDtp] = useState({});
  const [selectedOption, setSelectedOption] = useState(
    "Last Month"
  );

  /* INITIATIVE FILTER */
  const [initiativeFilter, setInitiativeFilter] = useState(initiative);
  const initiativeFilterOptions = dtp.map((item) => item.initiative);
  const [initiativeDtp, setInitiativeDtp] = useState(
    dtp.find((item) => item.initiative === initiative)
  );

  const handleInitiativeFilterChange = (value) => {
    setInitiativeFilter(value);
    setInitiativeDtp(dtp.find((item) => item.initiative === value));
    handleTimeframeChange('Last month');
  };

  /* TIMEFRAME FILTER */
  const options = ['Today', 'Last Week', 'Last Two Weeks', 'Last Month'];
  const handleTimeframeChange = (value) => {
    setSelectedOption(value);
    let timeframeFilter = 'lastMonth';
    switch (value) {
      case 'Today':
        setFilteredDeployments(deploymentsToProd.slice(0));
        timeframeFilter = 'lastDay';
        break;
      case 'Last Week':
        setFilteredDeployments(deploymentsToProd.slice(0, 7));
        timeframeFilter = 'lastWeek';
        break;
      case 'Last Two Weeks':
        setFilteredDeployments(deploymentsToProd.slice(0, 14));
        timeframeFilter = 'lastTwoWeeks';
        break;
      case 'Last Month':
        setFilteredDeployments(deploymentsToProd);
        timeframeFilter = 'lastMonth';
        break;
      default:
        setFilteredDeployments(deploymentsToProd);
        break;
    }
    setFilteredTimeframeDtp(
      initiativeDtp?.dtp_per_timeframe.find((item) => item.timeframe === timeframeFilter)
    );
  };

  useEffect(() => {
    const deployments = initiativeDtp?.dtp_per_date !== undefined ? initiativeDtp.dtp_per_date : [];
    setDeploymentsToProd(deployments);
    setFilteredDeployments(deployments);

    const timeframeData = initiativeDtp?.dtp_per_timeframe !== undefined ? initiativeDtp.dtp_per_timeframe : [];
    setFilteredTimeframeDtp(timeframeData.find((item) => item.timeframe === 'lastMonth'));
  }, [dtp, initiativeDtp]);

  const formatTimestamp = (timestamp) => {
    const [month, day, year] = timestamp.split('/');
    return `${month}-${day}-${year.slice(-2)}`;
  };

  const data = {
    labels: filteredDeployments.map((item) => formatTimestamp(item.timestamp)),
    datasets: [
      {
        label: 'Success',
        data: filteredDeployments.map((item) => item.g),
        borderColor: '#8CD98C',
        backgroundColor: '#8CD98C',
        
      },
      {
        label: 'Failed',
        data: filteredDeployments.map((item) => item.r),
        borderColor: '#CE1C1C',
        backgroundColor: '#CE1C1C',
      },
    ],
  };

  const chartContainerStyle = {
    maxWidth: '100%',
    height: '380px',
    overflowX: 'auto',
  };

  const chartStyle = {
    minWidth: '1050px',
    height: '100%'
  }

  return (
    <div>
      <br />
      {selectedOption === 'Today' ? (
        <div className="graphTable">
          <div className="graphTableBody">
            <table>
              <thead className="graphTableHead">
                <tr>
                  <th>Status</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Success</td>
                  <td>{filteredTimeframeDtp?.g || 0}</td>
                </tr>
                <tr>
                  <td>Failed</td>
                  <td>{filteredTimeframeDtp?.r || 0}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div style={chartContainerStyle}>
          <div style={chartStyle}>
            <Line options={chartOptions} data={data} className="chartModal" />
          </div>
          
        </div>
      )}
      <div className="graphModalFooter">
        <div className="fw500 graphFilterLabel">Filter by Timeframe:</div>
        <div className="col3">
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={options}
            value={selectedOption}
            onChange={handleTimeframeChange}
          />
        </div>
      </div>
      <div className="graphModalFooter">
        <div className="graphFilterLabel fw500">Filter by Initiative:</div>
        <div className="col3">
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={initiativeFilterOptions}
            value={initiativeFilter}
            onChange={handleInitiativeFilterChange}
          />
        </div>
      </div>
    </div>
  );
};

export default DeploymentsToProduction;
