import React, { useState } from 'react'
import './AdminLoginForm.css'
import { useNavigate } from 'react-router-dom'
import Warning from '../alerts/Warning'
import BasicInput from './BasicInput'
import BasicButton from '../buttons/BasicButton'
import NextField from '../functions/NextField'
import BasicModal from '../modals/BasicModal'
/* Amplify */
import { Auth } from 'aws-amplify'

/* API url */
const API = process.env.REACT_APP_LICENCES_API

const AdminLoginForm = () => {
  /* Company */
  /* Redirection */
  let navigate = useNavigate()
  /* New user */
  const [isNewUser, setIsNewUser] = useState(false)
  /* Errors */
  const [error, setError] = useState('')
  const [errorForToken, setErrorForToken] = useState('')
  /* Set admin login data */
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    })
  }

  /* Admin login step 1 */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email) {
      setError("Please enter your email or username");
      return;
    }

    if (!formData.password) {
      setError("Please enter your password");
      return;
    }

    try {
      const user = await Auth.signIn(formData.email, formData.password);
      if (user.challengeName === "CUSTOM_CHALLENGE") {
        try {
          await Auth.sendCustomChallengeAnswer(user, 'deviceConfirmed');
          setError("");
          console.log()
          navigate(`/${btoa('admin-home')}`);
        } catch (error) {
          setError("Wrong device");
          return;
        }
        
      }
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      if (groups && groups.includes('ADMIN')) {
        console.log("ADMIN user ok")
        navigate(`/${btoa('admin-home')}`);
      } else{
        console.error("Not admitted")
        await Auth.signOut()
        navigate(`/${btoa('login')}`)
      }
      setError("");
    } catch (error) {
      if (error.name === "UserNotConfirmedException") {
        setError("");
        setIsNewUser(true);
      } else if (error.name === "NotAuthorizedException") {
        setError("Incorrect email or password");
      }
    }
  };


  /* Admin login step 2 */
  const [firstLoginData, setFirstLoginData] = useState({
    newUsername: '',
    newPassword: '',
    confirmPassword: '',
    emailToken: ''
  })

  const handleInputChangeFirstLogin = (field, value) => {
    setFirstLoginData({
      ...firstLoginData,
      [field]: value,
    })
  }

  /* Password validations */
  const [passChecks, setPassChecks] = useState({
    eight: false,
    lowercase: false,
    uppercase: false,
    hasNumber: false,
    specialCharacter: false,
    passMatch: false,
  })

  const checkPass = (newPass) => {
    const checks = {
      eight: newPass.length > 7,
      lowercase: /[a-z]/.test(newPass),
      uppercase: /[A-Z]/.test(newPass),
      hasNumber: /\d/.test(newPass),
      specialCharacter: /[!@#$%^&*?¿{}></ºª|"'¬()=_~+`¡]/.test(newPass),
    }
    setPassChecks(checks)
  }

  const confirmPass = () => {
    if (firstLoginData.newPassword === firstLoginData.confirmPassword) {
      setPassChecks({
        ...passChecks,
        passMatch: true
      })
    } else {
      setPassChecks({
        ...passChecks,
        passMatch: false
      })
    }
  }

  const preventCopyPaste = (e) => {
    e.preventDefault()
  }

  /* Submit first login */
  const handleSubmitFirstLogin = async (e) => {
    e.preventDefault()

    if (!firstLoginData.newPassword) {
      setError("Please enter your new password")
    } else if (passChecks.lowercase === false) {
      setError("The password must have one lowercase character")
    } else if (passChecks.uppercase === false) {
      setError("The password must have one uppercase character")
    } else if (passChecks.hasNumber === false) {
      setError("The password must have one number")
    } else if (passChecks.specialCharacter === false) {
      setError("The password must have one special character")
    } else if (passChecks.eight === false) {
      setError("The password must have eight characters minimum")
    } else if (firstLoginData.newPassword !== firstLoginData.confirmPassword) {
      setError("The passwords must match")
    } else if (!firstLoginData.emailToken) {
      setError("Please enter your email token")
    } else if (firstLoginData.emailToken !== '123456') {
      setError("Please enter a valid email token")
    } else {
      navigate(`/${btoa('admin-home')}`)
    }
  }

  /* Token Modal */
  const [isOpenTokenModal, setIsOpenTokenModal] = useState(false)
  const [emailForToken, setEmailForToken] = useState('')
  const [emailTokenSended, setEmailTokenSended] = useState(false)

  const openTokenModal = () => {
    setIsOpenTokenModal(true)
  }

  const closeTokenModal = () => {
    setErrorForToken('')
    setEmailForToken('')
    setIsOpenTokenModal(false)
  }

  /* Submit token */
  const handleSubmitForToken = async (e) => {
    e.preventDefault()
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i

    if (emailForToken === '') {
      setErrorForToken("Please enter your email")
    } else if (!regex.test(emailForToken)) {
      setErrorForToken("Please enter a valid email")
    } else {
      setEmailTokenSended(true)
    }
  }

  const backToLogin = () => {
    setFormData({
      email: '',
      password: ''
    })
    setIsNewUser(false)
    setFirstLoginData({
      savedEmail: '',
      newUsername: '',
      newPassword: '',
      confirmPassword: '',
      emailToken: '',
    })
    setPassChecks({
      eight: false,
      lowercase: false,
      uppercase: false,
      hasNumber: false,
      specialCharacter: false,
      passMatch: false,
    })
    setIsOpenTokenModal(false)
    setEmailForToken('')
    setEmailTokenSended(false)
    setError('')
    setErrorForToken('')
  }

  return (
    <>
      <div className={isNewUser ? 'pb2' : ''}>
        <div className="adminLoginForm tac basicBorder">
          <img
            src="/img/logo/logo-dobcon-small.png"
            alt="Logo Devops Business Controll"
            className="imageForm mb1"
          />
          <div>
            {
              !isNewUser ?
                <>
                  {error && <div className="mt1 mb2"><Warning message={error} /></div>}
                  <form className="mt2">
                    <BasicInput
                      label="Email address or username (Case sensitive)*"
                      type="text"
                      value={formData.email}
                      onChange={(value) => handleInputChange('email', value)}
                      onKeyDown={NextField}
                      placeholder="Type your email or username"
                    />
                    <BasicInput
                      label="Password (Case sensitive)*"
                      type="password"
                      value={formData.password}
                      onChange={(value) => handleInputChange('password', value)}
                      placeholder="Type your password"
                    />
                    <p className="lightGray tal mb2">
                      <small>*Mandatory Fields</small>
                    </p>
                    <BasicButton
                      as="button"
                      color="btnRed"
                      size="btnFull"
                      onClick={handleSubmit}
                    >
                      Log In
                    </BasicButton>
                  </form>
                </>
                :
                <>
                  <p className="mt1 mb1">Please set a new username and a new password before login</p>
                  {error && <div className="mb1"><Warning message={error} /></div>}
                  <form className="mt2">
                    <div className="hide">
                      <BasicInput />
                    </div>
                    <BasicInput
                      label="New username (optional)"
                      type="text"
                      value={firstLoginData.newUsername}
                      onChange={(value) => handleInputChangeFirstLogin('newUsername', value)}
                      onKeyDown={NextField}
                      placeholder="Type your new username"
                    />
                    <BasicInput
                      onCopy={preventCopyPaste}
                      onCut={preventCopyPaste}
                      label="New password (Case sensitive)*"
                      type="password"
                      value={firstLoginData.newPassword}
                      onChange={(value) => handleInputChangeFirstLogin('newPassword', value)}
                      onKeyDown={NextField}
                      onKeyUp={(e) => checkPass(e.target.value)}
                      placeholder="Type your new password"
                    />
                    <BasicInput
                      label="Confirm password (Case sensitive)*"
                      type="password"
                      value={firstLoginData.confirmPassword}
                      onChange={(value) => handleInputChangeFirstLogin('confirmPassword', value)}
                      onKeyDown={NextField}
                      onKeyUp={confirmPass}
                      onPaste={preventCopyPaste}
                      placeholder="Confirm your password"
                    />
                    <BasicInput
                      label="Email token (Only numbers)*"
                      type="onlyNumbers"
                      value={firstLoginData.emailToken}
                      onChange={(value) => handleInputChangeFirstLogin('emailToken', value)}
                      placeholder="Type your email token (Valid for 10min)"
                    />
                    <p className="lightGray tar mb1">
                      Do you need a new email token?{' '}
                      <u className="lighterGray cursorPointer" onClick={openTokenModal}>Click here</u>
                      <br />
                      <small>*Mandatory Fields</small>
                    </p>
                    <div className="flexContainer spaceBetween mb1 tal">
                      <div className="col6">
                        <div className="passCheckContainer">
                          <span className={`passCheck ${passChecks.lowercase ? 'passCheckActive' : ''}`} />{' '}
                          One lowecase character
                        </div>
                        <div className="passCheckContainer">
                          <span className={`passCheck ${passChecks.uppercase ? 'passCheckActive' : ''}`} />{' '}
                          One uppercase character
                        </div>
                        <div className="passCheckContainer">
                          <span className={`passCheck ${passChecks.hasNumber ? 'passCheckActive' : ''}`} />{' '}
                          One number
                        </div>
                      </div>
                      <div className="col6">
                        <div className="passCheckContainer">
                          <span className={`passCheck ${passChecks.specialCharacter ? 'passCheckActive' : ''}`} />{' '}
                          One special character
                        </div>
                        <div className="passCheckContainer">
                          <span className={`passCheck ${passChecks.eight ? 'passCheckActive' : ''}`} />{' '}
                          8 characters minimum
                        </div>
                        <div className="passCheckContainer">
                          <span className={`passCheck ${passChecks.passMatch ? 'passCheckActive' : ''}`} />{' '}
                          Passwords must match
                        </div>
                      </div>
                    </div>
                    <BasicButton
                      as="button"
                      color="btnRed"
                      size="btnFull"
                      onClick={handleSubmitFirstLogin}
                    >
                      Log In
                    </BasicButton>
                  </form>
                </>
            }
          </div>
        </div>
      </div>
      {/* Modal for token */}
      <BasicModal isOpen={isOpenTokenModal} onClose={closeTokenModal}>
        <div className="tac">
          {!emailTokenSended ?
            <>
              <h2 className="mb1">Get a new email token</h2>
              <p className="mb2 fw500">Please enter your email to get a new email token</p>
              {errorForToken && <div className="mb2"><Warning message={errorForToken} /></div>}
              <form>
                <BasicInput
                  label="Email (Case sensitive)*"
                  type="text"
                  value={emailForToken}
                  onChange={(value) => setEmailForToken(value)}
                  placeholder="Type your email"
                />
                <p className="lighterGray tal mt-1 mb1"><small>*Mandatory fiekds</small></p>
                <BasicButton
                  as="button"
                  color="btnRed"
                  size="btnFull"
                  onClick={handleSubmitForToken}
                >
                  Submit
                </BasicButton>
              </form>
              <div className="mb1" />
              <BasicButton
                as="button"
                color="btnBlack"
                size="btnFull"
                onClick={closeTokenModal}
              >
                Cancel
              </BasicButton>
            </> :
            <>
              <h2 className="mb1">The Email Token has sended succesfully please check your email.</h2>
              <BasicButton
                as="button"
                color="btnRed"
                size="btnFull"
                onClick={backToLogin}
              >
                Back to Login
              </BasicButton>
            </>
          }
        </div>
      </BasicModal>
    </>
  )
}

export default AdminLoginForm