import SettingsCard from '../../components/cards/SettingsCard'
import CurrentDate from '../../components/resources/CurrentDate'

const HomeSettings = () => {
  return (
    <div className="containerToRight">
      <h1>What would you like to do?</h1>
      <div className="tar">
        <CurrentDate />
      </div>
      <div className="flexContainer spaceBetween mt3">
        <div className="col3 flex mb1">
          <SettingsCard
            cardLinkTo="assign-licenses"
            cardTitle="Manage Users"
          />
        </div>
        <div className="col3 flex mb1">
          <SettingsCard
            cardLinkTo="only-company-setup"
            cardTitle="Update Your Company Info"
          />
        </div>
        <div className="col3 flex mb1">
          <SettingsCard
            cardLinkTo="company-initiatives"
            cardTitle="View Your Initiatives"
          />
        </div>
        <div className="col3 flex mb1">
          <SettingsCard
            cardLinkTo="roles-and-privileges"
            cardTitle="Manage Company Roles & Privileges"
          />
        </div>
      </div>
    </div>
  )
}

export default HomeSettings