import React, { useState } from 'react'
import CurrentDate from '../../components/resources/CurrentDate'
import BusinessNavFilter from '../../components/nav/BusinessNavFilter'
import BusinessInitiativeFilter from '../../components/filters/BusinessInitiativeFilter'
import InitiativePill from '../../components/pills/InitiativePill'
import { useInitiativesContext } from './resources/BusinessInitiativesContext'
import BusinessFilterPill from '../../components/pills/BusinessFilterPill'
import BasicIconFilter from '../../components/filters/BasicIconFilter'
import '../../components/tables/InitiativeResourcesTable.css'

const BusinessResources = () => {
  /* Initiatives */
  const initiatives = useInitiativesContext()

  /* Extract departments and resources and seting the filter options */
  const extractUniqueOptions = (initiatives, optionType) => {
    let foundOptions = false
    const options = initiatives.flatMap((initiative) =>
      initiative.departments.flatMap((department) => {
        if (Array.isArray(department[optionType])) {
          if (department[optionType].length > 0) {
            foundOptions = true
            return department[optionType].map((item) => item.name)
          }
        } else {
          if (department[optionType]) {
            foundOptions = true
            return [department[optionType]]
          }
        }
        return []
      })
    )
    const uniqueOptions = [...new Set(options.flat())]
    return foundOptions ? uniqueOptions : []
  }

  const departments = extractUniqueOptions(initiatives, "department")
  const responsibles = extractUniqueOptions(initiatives, "responsibles")

  /* Filtering hooks and cases */
  const [selectedOption, setSelectedOption] = useState(null)
  const options = ['Department', 'Responsible', 'Delayed']

  const handleFirstFilter = (value) => {
    setSelectedOption(value)
    if (value === 'Department') {
      setSelectedResponsibles([])
      setDelayedInitiatives(false)
    }

    if (value === 'Responsible') {
      setSelectedDepartments([])
      setDelayedInitiatives(false)
    }

    if (value === 'Delayed') {
      setDelayedInitiatives(true)
      setSelectedResponsibles([])
      setSelectedDepartments([])
    }
  }

  /* Filtering by departments */
  const [onlyOneDepartment, setOnlyOneDepartment] = useState(null)
  const [selectedDepartments, setSelectedDepartments] = useState([])

  const handleOneDepartment = (department) => {
    if (onlyOneDepartment === department) {
      setOnlyOneDepartment(null)
    } else {
      setOnlyOneDepartment(department)
    }
  }

  const handleDepartmentsSelect = (department) => {
    if (selectedDepartments.includes(department)) {
      setSelectedDepartments(selectedDepartments.filter((item) => item !== department))
    } else {
      setSelectedDepartments([...selectedDepartments, department])
    }
  }

  const handleDepartmentDelete = (department) => {
    setSelectedDepartments(selectedDepartments.filter((item) => item !== department))
    if(onlyOneDepartment === department){
      setOnlyOneDepartment(null)
    }
  }

  /* Filtering by Responsibles */
  const [onlyOneResponsible, setOnlyOneResponsible] = useState(null)
  const [selectedResponsibles, setSelectedResponsibles] = useState([])

  const handleOneResponsible = (responsible) => {
    if (onlyOneResponsible === responsible) {
      setOnlyOneResponsible(null)
    } else {
      setOnlyOneResponsible(responsible)
    }
  }

  const handleResponsiblesSelect = (responsible) => {
    if (selectedResponsibles.includes(responsible)) {
      setSelectedResponsibles(selectedResponsibles.filter((item) => item !== responsible))
    } else {
      setSelectedResponsibles([...selectedResponsibles, responsible])
    }
  }

  const handleResponsibleDelete = (responsible) => {
    setSelectedResponsibles(selectedResponsibles.filter((item) => item !== responsible))
    if(onlyOneResponsible === responsible){
      setOnlyOneResponsible(null)
    }
  }

  /* Filtering by delayed status */
  const [delayedInitiatives, setDelayedInitiatives] = useState(false)

  const filteredInitiativesDelayed = initiatives.filter((initiative) => {
    if (delayedInitiatives) {
      return initiative.delayed === 'Yes'
    } else {
      return true
    }
  })

  const filteredInitiatives = initiatives.filter((initiative) => {
    if (selectedDepartments.length === 0 && selectedResponsibles.length === 0) {
      return true
    }

    let departmentFilter = true
    let responsibleFilter = true

    if (onlyOneDepartment) {
      departmentFilter = initiative.departments.some((dep) => dep.department === onlyOneDepartment)
    } else if (selectedDepartments.length > 0) {
      departmentFilter = selectedDepartments.some((dep) =>
        initiative.departments.some((item) => item.department === dep)
      )
    }

    if (onlyOneResponsible) {
      responsibleFilter = initiative.departments.some((dep) => dep.responsibles.some((resp) => resp.name === onlyOneResponsible))
    } else if (selectedResponsibles.length > 0) {
      responsibleFilter = selectedResponsibles.some((resp) =>
        initiative.departments.some((dep) =>
          dep.responsibles.some((item) => item.name === resp)
        )
      )
    }

    return departmentFilter && responsibleFilter
  })

  const handleInitiativeResources = (item) => {
    return (
      <tr key={item.id}>
        {/* Initiative */}
        <td className="w15">
          <ul>
            <li>{item.name}</li>
          </ul>
        </td>
        {/* Departments */}
        <td className="w15">
          <ul>
            {item.departments && item.departments.length > 0 ? (
              item.departments.map((department) => (
                <li key={department.department}>
                  {department.department}
                </li>
              ))
            ) : (
              <li key="noDepartments">No departments found</li>
            )}
          </ul>
        </td>
        {/* Responsibles */}
        <td className="w30">
          <ul>
            {item.departments && item.departments.length > 0 ? (
              item.departments.map((department) => (
                <React.Fragment key={department.department}>
                  {department.responsibles && department.responsibles.length > 0 ? (
                    department.responsibles.map((responsible) => (
                      <li key={responsible.name}>
                        <strong>{responsible.name}</strong> / {responsible.role} / {responsible.location}
                      </li>
                    ))
                  ) : (
                    <li key={`noResponsibles_${department.department}`}>No responsibles found</li>
                  )}
                </React.Fragment>
              ))
            ) : (
              <li key="noDepartmentsResponsibles">No departments with responsibles found</li>
            )}
          </ul>
        </td>
        {/* Resources */}
        <td className="w30">
          <ul>
            {item.departments && item.departments.length > 0 ? (
              item.departments.map((department) => (
                <React.Fragment key={department.department}>
                  {department.resources && department.resources.length > 0 ? (
                    department.resources.map((resource) => (
                      <li key={resource.name}>
                        <strong>{resource.name}</strong> / {resource.role} / {resource.location}
                      </li>
                    ))
                  ) : (
                    <li key={`noResources_${department.department}`}>No resources found</li>
                  )}
                </React.Fragment>
              ))
            ) : (
              <li key="noDepartmentsResources">No departments with resources found</li>
            )}
          </ul>
        </td>
        {/* Delayed */}
        <td className="w10">
          <ul>
            <li key={`delayed_${item.id}`}>
              <strong className='redText'>000.00%</strong>
            </li>
          </ul>
        </td>
      </tr>
    )
  }

  const initiativePill = (initiatives) => {
    return (
      initiatives.length !== 0 ? (
        initiatives.map((initiative) => (
          <InitiativePill
            key={initiative.name}
            pillTitle={initiative.name}
            pillLink={initiative.name}
          />
        ))
      ) : (
        <p className="fw500 mb1">No initiatives Found</p>
      )
    )
  }

  return (
    <div className="containerToRight">
      <h1>
        <span className="lightGray">DOBCO<span className="bigN-h1">n</span></span> Business per Resources
      </h1>
      <p className="mediumText mt1 fw500">Filter All Your Initiatives by Resources</p>
      <div className="tar">
        <CurrentDate />
        <hr className="grayLine mt1" />
      </div>
      <div className="flexContainer spaceBetween pt1">
        <div className="col2">
          <BusinessNavFilter
            links={[
              'Business Metrics',
              'Initiative Start Date',
              'Initiative Status',
              'Location',
              'Total Initiative Cost'
            ]}
            activeSection="Initiative Resources"
          />
        </div>
        <div className="col2 mb1">
          <BusinessInitiativeFilter
            options={options}
            onChange={handleFirstFilter}
          />
        </div>
        <div className="col2 mb1">
          {selectedOption && (
            <div>
              {selectedOption === 'Department' && (
                <BasicIconFilter
                  title="Departments"
                  icon="fa-solid fa-layer-group"
                  options={departments}
                  onChange={handleDepartmentsSelect}
                />
              )}
              {selectedOption === 'Responsible' && (
                <BasicIconFilter
                  title="Responsibles"
                  icon="fa-solid fa-user-group"
                  options={responsibles}
                  onChange={handleResponsiblesSelect}
                />
              )}
              {selectedOption === 'Delayed' && (
                <BusinessFilterPill
                  pillTitle="Delayed"
                />
              )}
            </div>
          )}
        </div>
        <div className="col6 fullTablet flexContainer filterContainer">
          {/* Departments */}
          {selectedDepartments.map((department) => (
            <div key={department}>
              <BusinessFilterPill
                pillTitle={department}
                pillDelete={() => handleDepartmentDelete(department)}
                pillSelected={() => handleOneDepartment(department)}
                pillDisabled={onlyOneDepartment && department !== onlyOneDepartment}
                isPillSelected={department === onlyOneDepartment}
              />
            </div>
          ))}
          {/* Responsibles */}
          {selectedResponsibles.map((responsible) => (
            <div key={responsible}>
              <BusinessFilterPill
                pillTitle={responsible}
                pillDelete={() => handleResponsibleDelete(responsible)}
                pillSelected={() => handleOneResponsible(responsible)}
                pillDisabled={onlyOneResponsible && responsible !== onlyOneResponsible}
                isPillSelected={responsible === onlyOneResponsible}
              />
            </div>
          ))}
        </div>
      </div>
      <hr className="grayLine" />
      <div className="flexContainer pt1">
        {selectedOption === 'Delayed' ? (
          initiativePill(filteredInitiativesDelayed)
        ) : (
          initiativePill(filteredInitiatives)
        )}
      </div>
      <hr className="grayLine mb2" />

      <div className="initiativeResourcesTable mt2">
        <table>
          <thead className="initiativeResourcesTableHead">
            <tr>
              <th className="w15">Initiative</th>
              <th className="w15">Departments</th>
              <th className="w30">Responsibles</th>
              <th className="w30">Team / Roles / Location</th>
              <th className="w10">
                <span className="greenDot" /> On Time
                <br />
                <span className="redDot" /> Delay
              </th>
            </tr>
          </thead>
          <tbody className="initiativeResourcesTableBody">
            {filteredInitiatives.length !== 0 ? 
            (
              filteredInitiatives.map((item) => 
                handleInitiativeResources(item)
              )
            ) : (
              <tr className="w100" key="noInitiatives">
                <td colSpan="5">
                  <ul>
                    <li>No initiatives found</li>
                  </ul>
                </td>
              </tr>
            )}
          </tbody>
        </table>

      </div>
    </div>
  )
}

export default BusinessResources