import PasswordForm from '../../components/forms/PasswordForm'

const ForgotPassword = () => {
  return (
    <div className="container flexContainer spaceBetween alignCenter justifyCenter h100">
      <PasswordForm type="forgot"/>
    </div>
  )
}

export default ForgotPassword