import React, { useEffect, useState } from 'react'
import './PasswordForm.css'
import BasicInput from './BasicInput'
import BasicButton from '../buttons/BasicButton'
import Warning from '../alerts/Warning'
import NextField from '../functions/NextField'
import BasicModal from '../modals/BasicModal'
/* Amplify */
import { Auth } from 'aws-amplify'

/* API url */
const API = process.env.REACT_APP_LICENCES_API

const PasswordForm = ({ type }) => {
  const [currentEmail, setCurrentEmail] = useState('')
  /* User info */
  useEffect(() => {
    userInfo()
  }, [])

  const userInfo = async () => {
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        setCurrentEmail(user.attributes['email'])
      })
      .catch((err) => {
        console.error(err)
      })
  }

  /* Email for password */
  const [emailForPassword, setEmailForPassword] = useState('')
  const [errorForPassword, setErrorForPassword] = useState('')
  const [emailSended, setEmailSended] = useState(false)

  /* Fetch to token */
  const resetToken = async () => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
      body: JSON.stringify({
        "email": emailForPassword
      })
    }
    const response = await fetch(`${API}/token/reset`, requestOptions)
    if (response.status === 404) {
      return false
    }
    return true
  }

  const handleSubmitEmail = async (e) => {
    e.preventDefault()
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i

    if (emailForPassword === '') {
      setErrorForPassword("Please enter your email")
    } else if (!regex.test(emailForPassword)) {
      setErrorForPassword("Please enter a valid email")
    } else if (type === 'change' && emailForPassword !== currentEmail) {
      setErrorForPassword("The email doesn't correspond to the active account")
    } else {
      try {
        const userFound = await resetToken()
        if (userFound) {
          Auth.forgotPassword(emailForPassword)
          setErrorForPassword("")
          setEmailSended(true)
        } else {
          setErrorForPassword("User doesn't exists")
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  /* When email sended */
  const [successModal, setSuccessModal] = useState(false)
  const [formNewPassData, setFormNewPassData] = useState({
    newPassword: '',
    emailToken: ''
  })

  const handleInputChange = (field, value) => {
    setFormNewPassData({
      ...formNewPassData,
      [field]: value,
    })
  }

  const closeSuccessModal = () => {
    setSuccessModal(false)
  }

  const tokenExpiration = async () => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
      body: JSON.stringify({
        "email": emailForPassword
      })
    }
    const response = await fetch(`${API}/token`, requestOptions)
    const data = await response.json()
    return data
  }

  const handleNewPassSubmit = async (e) => {
    e.preventDefault()

    if (!formNewPassData.newPassword) {
      setErrorForPassword("Please enter your new password")
    } else if (!formNewPassData.emailToken) {
      setErrorForPassword("Please enter your email token")
    } else {
      const tokenCheck = await tokenExpiration()
      if (tokenCheck["response"] === "token is expired") {
        setErrorForPassword('Token is expired')
      } else if (tokenCheck["response"] === "user not found") {
        setErrorForPassword('User not found')
      } else {
        Auth.forgotPasswordSubmit(
          emailForPassword,
          formNewPassData.emailToken,
          formNewPassData.newPassword
        )
          .then((data) => { setSuccessModal(true); setErrorForPassword(null) })
          .catch((error) => {
            if (error.name === 'CodeMismatchException') {
              setErrorForPassword('Please enter a valid Email Token')
            }
          })
      }
    }
  }

  return (
    <div className="passwordForm tac basicBorder">
      {!emailSended ?
        <>
          <h1 className="mb1">
            {type === "forgot" ? "Forgot Password" : "Change Password"}
          </h1>
          <p className="mb2 fw500">
            Enter your  address below, and we'll send you an email to change your password.
          </p>
          {errorForPassword && <div className="mb2"><Warning message={errorForPassword} /></div>}
          <form>
            <BasicInput
              label="Email (Case sensitive)*"
              type="text"
              value={emailForPassword}
              onChange={(value) => setEmailForPassword(value)}
              placeholder="Type your email"
            />
            <p className="lightGray tal mt-1 mb1">
              <small>*Mandatory Fields</small>
            </p>
            <BasicButton
              as="button"
              color="btnRed"
              size="btnFull"
              onClick={handleSubmitEmail}
            >
              Send email confirmation
            </BasicButton>
            <div className="mb1" />
            <BasicButton
              as="link"
              color="btnBlack"
              size="btnFull"
              to={type === "forgot" ? "login" : "user-home"}
            >
              Cancel
            </BasicButton>
          </form>
        </> : <>
          <h3 className="mb2">Please set your new password and enter your email token</h3>
          {errorForPassword && <div className="mb2"><Warning message={errorForPassword} /></div>}
          <form>
            <BasicInput
              label="New password (Case sensitive)*"
              type="password"
              value={formNewPassData.newPassword}
              onChange={(value) => handleInputChange('newPassword', value)}
              onKeyDown={NextField}
              placeholder="Type your new password"
            />
            <BasicInput
              label="Email token (Case sensitive)*"
              type="onlyNumbers"
              value={formNewPassData.emailToken}
              onChange={(value) => handleInputChange('emailToken', value)}
              placeholder="Type your email token (Valid for 10min)"
            />
            <p className="lightGray tal mt-1 mb1">
              <small>*Mandatory Fields</small>
            </p>
            <BasicButton
              as="button"
              color="btnRed"
              size="btnFull"
              onClick={handleNewPassSubmit}
            >
              Confirm
            </BasicButton>
            <div className="mb1" />
            <BasicButton
              as="link"
              color="btnBlack"
              size="btnFull"
              to={type === "forgot" ? "login" : "user-home"}
            >
              Cancel
            </BasicButton>
          </form>
        </>
      }
      <BasicModal isOpen={successModal} onClose={closeSuccessModal}>
        <h2 className="mb1">Password change successfully!</h2>
        {type === "forgot" ?
          <BasicButton
            as="link"
            color="btnRed"
            size="btnFull"
            to="login"
          >
            Back to login
          </BasicButton> :
          <BasicButton
            as="link"
            color="btnRed"
            size="btnFull"
            to="user-home"
          >
            Back to home
          </BasicButton>}
      </BasicModal>
    </div>
  )
}

export default PasswordForm