import React, { useState, useEffect, createContext, useContext } from 'react'
import InitiativePill from '../../../components/pills/InitiativePill'
import { Auth, Hub } from 'aws-amplify'

const API = process.env.REACT_APP_SETUP_API

// Create initiative context
const InitiativesContext = createContext()

// Initiatives provider
const InitiativesProvider = ({ children }) => {
  const [initiatives, setInitiatives] = useState([])
  const currentCompany = localStorage.getItem('currentCompany')

  //Get initiatives with location and resources
  const getAdditionalInitiativesData = async() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    const response = await fetch(`${API}/resources/${currentCompany}/initiatives`, requestOptions)
    const additionalData = await response.json()
    return additionalData
  }

  //Get initiatives with status, date, and metrics
  const getInitiatives = async() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    const response = await fetch(`${API}/initiatives/${currentCompany}/metrics`, requestOptions)
    const data = await response.json()
    const additionalData = await getAdditionalInitiativesData()

    const updatedInitiatives = data.map((initiative) => {
      const additionalInfo = additionalData.find((item) => item.initiative === initiative.name)
      const location = additionalInfo?.departments?.[0]?.resources?.[0]?.location || ''
      const resources = additionalInfo?.departments?.[0]?.resources || []
      const departments = additionalInfo?.departments || []

      return {
        ...initiative,
        location,
        resources,
        departments,
      }
    })

    setInitiatives(updatedInitiatives)
  }

  /* User's session */
  const [isLogged, setIsLogged] = useState(false)

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await Auth.currentAuthenticatedUser()
        setIsLogged(true)
      } catch (error) {
        setIsLogged(false)
      }
    }

    Hub.listen('auth', (data) => {
      const { payload } = data
      if (payload.event === 'signIn') {
        setIsLogged(true)
      } else if (payload.event === 'signOut') {
        setIsLogged(false)
      }
    })

    checkAuth()

    return () => Hub.remove('auth')
  }, [])


  useEffect(() => {
    // Verify if the user is logged
    if (!isLogged) {
      return
    }
    
    // Rest of the code for logged users
    const storedInitiatives = JSON.parse(localStorage.getItem('initiatives')) || []
    if (storedInitiatives.length === 0) {
      getInitiatives()
    } else {
      getInitiatives()
      setInitiatives(storedInitiatives)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged])
  
  useEffect(() => {
    // Verify if the user is logged
    if (!isLogged) {
      return
    }
  
    // Rest of the code for logged users
    localStorage.setItem('initiatives', JSON.stringify(initiatives))
  }, [initiatives, isLogged])

  return <InitiativesContext.Provider value={initiatives}>{children}</InitiativesContext.Provider>
}

// Hook to use the initiative context
const useInitiativesContext = () => {
  return useContext(InitiativesContext)
}

// Component of business initiative
const BusinessInitiatives = () => {
  const initiatives = useInitiativesContext()

  return (
    <div>
      {initiatives.length !== 0 ? (
        initiatives.map((initiative) => (
          <InitiativePill
            key={initiative.name}
            pillTitle={initiative.name}
            pillLink={initiative.name}
          />
        ))
      ) : (
        <p className="fw500 mb1">No initiatives Found</p>
      )}
    </div>
  )
}

export { InitiativesProvider, useInitiativesContext, BusinessInitiatives }
