import React, { useState } from 'react'
import './CheckResourcesTable.css'
import BasicButton from '../buttons/BasicButton'
import BasicInput from '../forms/BasicInput'
import NextField from '../functions/NextField'
import FormModal from '../modals/FormModal'
import Warning from '../alerts/Warning'
import BasicModal from '../modals/BasicModal'
import StatusPill from '../pills/StatusPill'

const CheckServAppsTable = ({
  existingNames,
  repeated,
  servapps,
  handleEditRepeatedServApps,
  handleEditNewServApp,
  handleDeleteNewServApp,
  handleDeleteRepeatedServApps
  
}) => {
  /* Errors */
  const [error, setError] = useState('')
  /* Servapp modal  (name and description inputs)*/
  const [isOpenServAppsModal, setIsOpenServAppsModal] = useState(false)
  const [selectedSource, setSelectedSource] = useState(null)

  const closeModal = () => {
    setError('')
    setIsOpenServAppsModal(false)
  }

  /* Updated servapp data */
  const emptyServAppData = {
    index: null,
    name: '',
    description: ''
  }
  const [editServAppData, setEditServAppData] = useState(emptyServAppData)

  const handleEditServAppInputChange = (field, value) => {
    setEditServAppData({
      ...editServAppData,
      [field]: value,
    })
  }

  const handleEditServApp = (servapp, source, index) => {
    setIsOpenServAppsModal(true)
    setSelectedSource(source)
    setEditServAppData({
      index: index,
      name: servapp.name,
      description: servapp.description
    })
  }

  const resetFormState = () => {
    // Set hooks to their initial state
    setError('')
    setIsOpenModalDelete(false)
    setIsOpenServAppsModal(false)
    setSelectedSource(null)
    setEditServAppData(emptyServAppData)
  }

  const submitEditServApp = async (e) => {
    e.preventDefault()
    
    setError('')
    // Send data based on selectedSource
    switch (selectedSource) {
      case 'repeated':
        const nameAvailable = checkNameAvailable(editServAppData.name)
        if(nameAvailable){
          handleEditRepeatedServApps(editServAppData)
          resetFormState()
        }else{
          setError("Application/Service already exists")
        }
        break;
      default: // new
        handleEditNewServApp(editServAppData)
        resetFormState()
        break;
    }
    
  }

  const checkNameAvailable = (editedName) => {
    return !existingNames.includes(editedName)
  }

  /* Delete an exists resource */
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const [selectedServAppToDelete, setSelectedServAppToDelete] = useState(null)

  const closeModalDelete = () => {
    setSelectedServAppToDelete(null)
    setIsOpenModalDelete(false)
  }

  const handleDeleteServApp = (servapp) => {
    setSelectedServAppToDelete(servapp)
    setIsOpenModalDelete(true)
  }

  const confirmDeleteServApp = async () => {
    if (selectedServAppToDelete) {
      const { name } = selectedServAppToDelete
      if (selectedServAppToDelete.status === 'repeated') {
        handleDeleteRepeatedServApps(name)
      } else if (selectedServAppToDelete.status === 'new') {
        handleDeleteNewServApp(name)
      } 

      closeModalDelete()
    }
  }

  return (
    <div className="checkResourcesTable">
      <div className="checkResourcesTableBody">
        <table>
          <thead className="checkResourcesTableHead">
            <tr>
              <th className="columnMedium">Name</th>
              <th className="columnMedium tac">Status</th>
              <th className="columnMini tac">Edit</th>
              <th className="columnMini tac">Delete</th>
            </tr>
          </thead>
          <tbody>

            {repeated.length !== 0 ?
              repeated.map((servapp, index) => (
                <tr key={servapp.name}>
                  <td>{servapp.name}</td>
                  <td>
                    <StatusPill 
                      status="pending-mod" 
                      message="Repeated name" 
                    />
                  </td>
                  <td className="columnSmall tac">
                    <button
                      onClick={() => handleEditServApp(servapp, 'repeated', index)}
                    >
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                  </td>
                  <td className="columnSmall tac">
                    <button onClick={() => handleDeleteServApp({ ...servapp, status: 'repeated' })}>
                      <i className="fa-solid fa-trash" />
                    </button>
                  </td>
                </tr>
              )) : null
            }

            {servapps.length !== 0 ?
              servapps.map((servapp, index) => (
                <tr key={servapp.name}>
                  <td>{servapp.name}</td>
                  <td>
                    <StatusPill 
                      status="success" 
                      message="Ready to upload" 
                    />
                  </td>
                  <td className="columnSmall tac">
                    <button onClick={() => handleEditServApp(servapp, 'new', index)}>
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                  </td>
                  <td className="columnSmall tac">
                    <button onClick={() => handleDeleteServApp({ ...servapp, status: 'new' })}>
                      <i className="fa-solid fa-trash" />
                    </button>
                  </td>
                </tr>
              )) : null
            }
          </tbody>
        </table>
      </div>
      {/* Edit resources */}
      <FormModal isOpen={isOpenServAppsModal} onClose={closeModal}>
        <h2 className="mb2">Edit service/application</h2>
        {error && <div className="mt-1 mb2"><Warning message={error} /></div>}
        <form>
          <div className="flexContainer spaceBetween">
            <div className="col6">
              <BasicInput
                label="Name*"
                type="text"
                value={editServAppData.name}
                onChange={(value) => handleEditServAppInputChange('name', value)}
                onKeyDown={NextField}
                placeholder="New service/application's name"
              />
              <BasicInput
                label="Description"
                type="text"
                value={editServAppData.description}
                onChange={(value) => handleEditServAppInputChange('description', value)}
                onKeyDown={NextField}
                placeholder="New service/application's description"
              />
            </div>
          </div>
          <p className="lightGray tal mt-1 mb1">
            <small>*Mandatory Fields</small>
          </p>
          <BasicButton
            color="btnRed"
            size="btnFull"
            onClick={submitEditServApp}
          >
            Confirm
          </BasicButton>
          <div className="mb1" />
          <BasicButton
            color="btnBlack"
            size="btnFull"
            onClick={closeModal}
          >
            Cancel
          </BasicButton>
        </form>
      </FormModal>
      {/* Prevent resource delete modal */}
      <BasicModal isOpen={isOpenModalDelete} onClose={closeModalDelete}>
        <h2 className="mb2 tac">Are you sure that you want to delete this service/application?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={confirmDeleteServApp}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={closeModalDelete}
        >
          Cancel
        </BasicButton>
      </BasicModal>
    </div>
  )
}

export default CheckServAppsTable