import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const decodedSegment = atob(pathSegments[1] || '');
    let title;

    switch (decodedSegment) {
      case 'admin-login':
        title = 'Dobcon | Admin Login';
        break;
      case 'login':
      case 'login-nofp':
        title = 'Dobcon | Login';
        break;
      case 'forgot-password':
        title = 'Dobcon | Forgot Password';
        break;
      case 'admin-home':
        title = 'Dobcon | Admin Home';
        break;
      case 'companies-licenses':
        title = 'Dobcon | Companies';
        break;
      case 'admin-chart-preview':
        title = 'Dobcon | Chart Preview';
        break;
      case 'user-home':
        title = 'Dobcon | Home';
        break;
      case 'only-company-setup':
      case 'company-setup':
        title = 'Dobcon | Company Setup';
        break;
      case 'initiative-setup':
        title = 'Dobcon | Initiative Setup';
        break;
      case 'toolchain-setup':
        title = 'Dobcon | Toolchain Setup';
        break;
      case 'toolchain-config':
        title = 'Dobcon | Toolchain Config';
        break;
      case 'settings-home':
        title = 'Dobcon | Settings';
        break;
      case 'change-password':
        title = 'Dobcon | Change Password';
        break;
      case 'assign-licenses':
        title = 'Dobcon | Licenses';
        break;
      case 'company-initiatives':
        title = 'Dobcon | Initiatives';
        break;
      case 'roles-and-privileges':
        title = 'Dobcon | Roles & Privileges';
        break;
      case 'initiative-status':
      case 'initiative-start-date':
      case 'location':
      case 'initiative-resources':
      case 'roi':
      case 'total-initiative-cost':
      case 'initiative':
      case 'dobcon-business':
        title = 'Dobcon | Business';
        break;
      case 'dobcon-toolchain':
        title = 'Dobcon | Toolchain';
        break;
      case 'dobcon-adoption':
        title = 'Dobcon | Adoption';
        break;
      default:
        title = 'Dobcon';
    }

    document.title = title;
  }, [location]);

  return null;
};

export default TitleUpdater;
