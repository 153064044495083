import React, { useState, useEffect, useRef } from 'react'
import './SelectInput.css'

const SelectInput = ({ label, options, value, onChange, }) => {
  const [open, setOpen] = useState(false)
  const [openUpwards, setOpenUpwards] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    if (open && containerRef.current) {
      const dropdownHeight = 150
      const { bottom } = containerRef.current.getBoundingClientRect()
      const distanceToBottom = window.innerHeight - bottom

      setOpenUpwards(distanceToBottom < dropdownHeight)
    }
  }, [open])

  const handleSelect = (selectedValue) => {
    onChange(selectedValue)
    if (!selectedValue) {
      setOpen(false)
    }
  }

  const handleToggleOpen = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="selectInput">
      {label && <label className="selectInputLabel">{label}</label>}
      <div
        className="selectInputContainer"
        onClick={handleToggleOpen}
        ref={containerRef}
      >
        <div className="selectInputSelected">
          {value}
          <span>
          <i className={`fa-solid fa-angle-down ${open ? 'open' : ''}`} />
          </span>
        </div>
        {open && (
          <ul className={`selectInputOptions ${openUpwards ? 'open-upwards' : ''}`}>
            {options.map((option) => (
              <li
                key={option}
                className="selectInputOption"
                onClick={() => handleSelect(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default SelectInput