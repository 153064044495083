import React, { useEffect, useState } from 'react'
import './FinancialVariablesTable.css'
import NumberInput from '../forms/NumberInput'
import NextField from '../functions/NextField'

const API = process.env.REACT_APP_SETUP_API

const FinancialVariablesTable = ({ handleCompanyFinancialVariables }) => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')

  /* Format number tu us */
  const formatNumber = (number) => {
    const numericValue = parseFloat(number)
    if (!isNaN(numericValue)) {
      return numericValue.toLocaleString('en-US', {
        maximumFractionDigits: 2
      })
    } else {
      return number
    }
  }

  /* Get Financial Variables */
  const pullData = async () => {
    try {
      const resVariables = await fetch(`${API}/parameters/${currentCompany}`, {
        method: 'GET'
      })

      const dataVariables = await resVariables.json()

      setAddFinancialVariables({
        discountRate: formatNumber(dataVariables.discount_rate),
        capitalCost: formatNumber(dataVariables.capital_cost),
        taxRate: formatNumber(dataVariables.tax_rate),
        breakEven: formatNumber(dataVariables.break_even),
        cloud: formatNumber(dataVariables.cloud_cost),
        tools: formatNumber(dataVariables.tools_cost),
        hr: formatNumber(dataVariables.hr_cost),
        culturalAdoption: formatNumber(dataVariables.cultural_adoption)
      })
    } catch (error) {
      console.error(error)
    }
  }

  /* Financial variables */
  const [addFinancialVariables, setAddFinancialVariables] = useState({
    discountRate: '',
    capitalCost: '',
    taxRate: '',
    breakEven: '',
    cloud: '',
    tools: '',
    hr: '',
    culturalAdoption: ''
  })

  const handleVariablesChange = (field, value) => {
    setAddFinancialVariables((prevAddFinancialVariables) => ({
      ...prevAddFinancialVariables,
      [field]: value,
    }))
  }

  /* Send financial variables */
  useEffect(() => {
    handleCompanyFinancialVariables(addFinancialVariables)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFinancialVariables])
  
  useEffect(() => {
    pullData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="financialVariablesTable">
      <form className="financialVariablesTableBody flexContainer">
        <ul className="w50 grayLineRight">
          <p className="mediumText mb1">Variables:</p>
          <li>
            <p>
              Discount Rate
              <span className="variableTooltip">
                <i className="fa-solid fa-circle-info" />
                <span className="variableTooltipInfo">
                  The rate of return used to discount future cash flows back to their present value.
                </span>
              </span>
            </p>
            <NumberInput
              inputName="discountRate"
              className="numberInputMini"
              inputType="percentage"
              value={addFinancialVariables.discountRate}
              onChange={handleVariablesChange}
              onKeyPress={NextField}
            />
          </li>
          <li>
            <p>
              Capital Cost
              <span className="variableTooltip">
                <i className="fa-solid fa-circle-info" />
                <span className="variableTooltipInfo">
                  The expenses incurred on the acquisition, construction, or enhancement of significant fixed assets.
                </span>
              </span>
            </p>
            <NumberInput
              inputName="capitalCost"
              className="numberInputMini"
              inputType="percentage"
              value={addFinancialVariables.capitalCost}
              onChange={handleVariablesChange}
              onKeyPress={NextField}
            />
          </li>
          <li>
            <p>
              Tax Rate
              <span className="variableTooltip">
                <i className="fa-solid fa-circle-info" />
                <span className="variableTooltipInfo">
                  The percentage at which the company is taxed.
                </span>
              </span>
            </p>
            <NumberInput
              inputName="taxRate"
              className="numberInputMini"
              inputType="percentage"
              value={addFinancialVariables.taxRate}
              onChange={handleVariablesChange}
              onKeyPress={NextField}
            />
          </li>
          <li>
            <p>
              Break-Even
              <span className="variableTooltip">
                <i className="fa-solid fa-circle-info" />
                <span className="variableTooltipInfo">
                  The point at which total cost and total revenue are equal.
                </span>
              </span>
            </p>
            <NumberInput
              inputName="breakEven"
              className="numberInputMini"
              inputType="percentage"
              value={addFinancialVariables.breakEven}
              onChange={handleVariablesChange}
              onKeyPress={NextField}
            />
          </li>
        </ul>
        <ul className="w50">
          <p className="mediumText mb1">Costs:</p>
          <li>
            <p>
              Cloud
              <span className="variableTooltip">
                <i className="fa-solid fa-circle-info" />
                <span className="variableTooltipInfo">
                  All costs related to the Cloud and its components.
                </span>
              </span>
            </p>
            <NumberInput
              inputName="cloud"
              className="numberInputMedium"
              inputType="cost"
              value={addFinancialVariables.cloud}
              onChange={handleVariablesChange}
              onKeyPress={NextField}
            />
          </li>
          <li>
            <p>
              Tools
              <span className="variableTooltip">
                <i className="fa-solid fa-circle-info" />
                <span className="variableTooltipInfo">
                  All costs incurred in DevOps tools. This may include support from third parties and a financial cost of adoption.
                </span>
              </span>
            </p>
            <NumberInput
              inputName="tools"
              className="numberInputMedium"
              inputType="cost"
              value={addFinancialVariables.tools}
              onChange={handleVariablesChange}
              onKeyPress={NextField}
            />
          </li>
          <li>
            <p>
              HR
              <span className="variableTooltip">
                <i className="fa-solid fa-circle-info" />
                <span className="variableTooltipInfo">
                  All DevOps-related Human Resources costs. This may include employees (payroll and benefits) and contractors.
                </span>
              </span>
            </p>
            <NumberInput
              inputName="hr"
              className="numberInputMedium"
              inputType="cost"
              value={addFinancialVariables.hr}
              onChange={handleVariablesChange}
              onKeyPress={NextField}
            />
          </li>
          <li>
            <p>
              Cultural Adoption
              <span className="variableTooltip">
                <i className="fa-solid fa-circle-info" />
                <span className="variableTooltipInfo">
                  All costs related to the adoption of a DevOps culture. This may include training, events, rewards, and other elements.
                </span>
              </span>
            </p>
            <NumberInput
              inputName="culturalAdoption"
              className="numberInputMedium"
              inputType="cost"
              value={addFinancialVariables.culturalAdoption}
              onChange={handleVariablesChange}
              onKeyPress={NextField}
            />
          </li>
        </ul>
      </form>
    </div>
  )
}

export default FinancialVariablesTable