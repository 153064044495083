import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import DarkSelectInput from '../../../components/forms/DarkSelectInput';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

// Correct phase order
const phaseColors = {
  'Code': 'rgba(255, 99, 132, 0.5)',
  'Build': 'rgba(255, 206, 86, 0.5)',
  'Test': 'rgba(54, 162, 235, 0.5)',
  'Integration': 'rgba(75, 192, 192, 0.5)',
  'Deploy': 'rgba(153, 102, 255, 0.5)',
  'Operate': 'rgba(60, 179, 113, 0.5)',
  'Monitor': 'rgba(255, 159, 64, 0.5)',
};

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: 'white'
      }
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      titleFont: {
        size: 14, // Set font size for the tooltip title
      },
      bodyFont: {
        size: 14, // Set font size for the tooltip body text
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      title: {
        display: true,
        text: 'Date',
        color: 'white',
        font: {
          size: 14
        }
      },
      ticks: {
        autoSkip: false,
        color: 'white',
      },
    },
    y: {
      stacked: true,
      title: {
        display: true,
        text: 'Hours',
        color: 'white',
        font: {
          size: 14
        }
      },
      ticks: {
        autoSkip: false,
        color: 'white',
      },
    },
  },
};

const ReleaseAverageLeadTime = ({ dataset }) => {
  const [selectedPhase, setSelectedPhase] = useState('All');
  const [selectedOptionDate, setSelectedOptionDate] = useState("Last Month");
  const [selectedOptionContributor, setSelectedOptionContributor] = useState('All');
  const [selectedInitiative, setSelectedInitiative] = useState('All');
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const optionsSelect = ['Today', 'Last Week', 'Last Two Weeks', 'Last Month'];

  const getUniqueContributionTypes = (data) => {
    const uniqueTypes = new Set(data.map(item => item.contribution));
    return Array.from(uniqueTypes);
  };

  const getUniqueInitiatives = (data) => {
    const uniqueInitiatives = new Set(data.map(item => item.initiative));
    return Array.from(uniqueInitiatives);
  };

  const uniqueContributionTypes = getUniqueContributionTypes(dataset.contributions_release_avg_lead_time_per_day);
  const uniqueInitiatives = getUniqueInitiatives(dataset.contributions_release_avg_lead_time_per_day);
  uniqueContributionTypes.unshift("All");
  uniqueInitiatives.unshift("All");

  useEffect(() => {
    const contributions = dataset.contributions_release_avg_lead_time_per_day;

    const filterByContribution = (data, contributionType) => {
      if (contributionType === 'All') {
        return data;
      }
      return data.filter(item => item.contribution === contributionType);
    };

    const filterByInitiative = (data, initiative) => {
      if (initiative === 'All') {
        return data;
      }
      return data.filter(item => item.initiative === initiative);
    };

    const filteredbyContributions = filterByContribution(contributions, selectedOptionContributor);
    const filteredByInitiative = filterByInitiative(filteredbyContributions, selectedInitiative);

    const sliceChartData = (data, sliceIndex) => {
      const slicedData = {
        labels: data.labels.slice(sliceIndex),
        datasets: data.datasets.map(dataset => ({
          ...dataset,
          data: dataset.data.slice(sliceIndex),
        })),
      };
      return slicedData;
    };

    const timeframeChange = (value, chartData) => {
      switch (value) {
        case 'Today':
          chartData = sliceChartData(chartData, -1);
          break;
        case 'Last Week':
          chartData = sliceChartData(chartData, -7);
          break;
        case 'Last Two Weeks':
          chartData = sliceChartData(chartData, -14);
          break;
        case 'Last Month':
          break;
        default:
          break;
      }
      return chartData;
    };

    const transformData = (phase) => {
      const formatDateString = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        return `${month}-${day}-${year.slice(-2)}`;
      };

      const labels = [...new Set(filteredByInitiative.map(c => formatDateString(c.date)))].sort();

      const datasets = phase === 'All'
        ? Object.keys(phaseColors).map(p => ({
          label: p,
          backgroundColor: phaseColors[p],
          data: labels.map(label => filteredByInitiative.filter(c => formatDateString(c.date) === label).reduce((acc, curr) => acc + (curr[p.toLowerCase()] ?? 0), 0)),
        }))
        : [{
          label: phase,
          backgroundColor: phaseColors[phase],
          data: labels.map(label => filteredByInitiative.filter(c => formatDateString(c.date) === label).reduce((acc, curr) => acc + (curr[phase.toLowerCase()] ?? 0), 0)),
        }];

      return { labels, datasets };
    };

    const filterbyPhase = transformData(selectedPhase);
    const filterbyTimeRange = timeframeChange(selectedOptionDate, filterbyPhase);
    setChartData(filterbyTimeRange);
  }, [selectedPhase, selectedOptionDate, selectedOptionContributor, selectedInitiative, dataset]);

  const handlePhaseChange = (e) => {
    setSelectedPhase(e);
  };

  const handleDataFrameChange = (e) => {
    setSelectedOptionDate(e);
  };

  const handleDataContributorChange = (e) => {
    setSelectedOptionContributor(e);
  };

  const handleInitiativeChange = (e) => {
    setSelectedInitiative(e);
  };

  const selectOptions = Object.keys(phaseColors);
  selectOptions.unshift("All");

  const chartContainerStyle = {
    maxWidth: '100%',
    height: '350px',
    overflowX: 'auto',
  };

  const chartStyle = {
    minWidth: '1050px',
    height: '100%'
  }

  return (
    <div>
      <div style={chartContainerStyle}>
        <div style={chartStyle}>
          <Bar options={chartOptions} data={chartData} className="chartModal" />
        </div>
      </div>
      <div className="graphModalFooter">
        <div className="graphFilterLabel fw500">Filter by Contributor: </div>
        <div className="col3">
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={uniqueContributionTypes}
            value={selectedOptionContributor}
            onChange={handleDataContributorChange}
          />
        </div>
      </div>
      <div className="graphModalFooter">
        <div className="graphFilterLabel fw500">Filter by Phase: </div>
        <div className="col3">
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={selectOptions}
            value={selectedPhase}
            onChange={handlePhaseChange}
          />
        </div>
      </div>
      <div className="graphModalFooter">
        <div className="graphFilterLabel fw500">Filter by Timeframe: </div>
        <div className="col3">
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={optionsSelect}
            value={selectedOptionDate}
            onChange={handleDataFrameChange}
          />
        </div>
      </div>
      
      <div className="graphModalFooter">
        <div className="graphFilterLabel fw500">Filter by Initiative: </div>
        <div className="col3">
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={uniqueInitiatives}
            value={selectedInitiative}
            onChange={handleInitiativeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ReleaseAverageLeadTime;
