import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import SearchServsAppsTable from '../../components/tables/SearchServsAppsTable'
import SearchInitiativeLeader from '../../components/tables/SearchInitiativeLeader'
import DarkInput from '../../components/forms/DarkInput'
import DateInput from '../../components/forms/DateInput'
import DarkSelectInput from '../../components/forms/DarkSelectInput'
import NumberInput from '../../components/forms/NumberInput'
import NextField from '../../components/functions/NextField'
import BasicButton from '../../components/buttons/BasicButton'
import SearchPeopleInvolved from '../../components/tables/SearchPeopleInvolved'
import BasicModal from '../../components/modals/BasicModal'
import Warning from '../../components/alerts/Warning'
import CurrentDate from '../../components/resources/CurrentDate'

const API = process.env.REACT_APP_SETUP_API

const InitiativeSetup = () => {
  // Initiative reference from Initiatives table
  const location = useLocation()
  const { state } = location
  const currentInitiativeId = state?.initiative
  const mode = state?.mode // 'edit' if comming from initiatives table
  // Alerts
  const [error, setError] = useState('')
  //const [currentInitiativeName, setCurrentInitiativeName] = useState['']
  /* Redirection */
  let navigate = useNavigate()
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Options for status input */
  const [selectedOption, setSelectedOption] = useState("Please select the initiative's status")
  const options = ['Under Development', 'Paused', 'Released', 'Retired', 'Under Maintenance']

  /* Initiative info */
  const [addInitiativeInfo, setAddInitiativeInfo] = useState({
    name: '',
    description: '',
    startDate: '',
    status: '',
    releaseDate: '',
    forcastedTotalCost: '0.00'
  })
  

  const getCurrentInitiativeInfo = async () => {
    if(mode === 'edit'){
      try{
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
        }
        const initiativeInfoResponse = await fetch(`${API}/initiatives/${currentInitiativeId}`, requestOptions)
        const data = await initiativeInfoResponse.json()
        if (initiativeInfoResponse.status === 200) {
          setAddInitiativeInfo({
            name: data.initiative_name,
            description: data.description,
            startDate: data.start_date,  
            status: data.status,
            releaseDate: data.release_date,
            forcastedTotalCost: data.total_cost
          })
          setInitiativeLeader(data.leader)
          setSelectedOption(data.status)
          setServicesAndApplications(data.service_apps)
          setInitiativePeopleInvolved(data.involved_resources)
          setDepartmentResponsibles(data.department_responsibles)
        } else if (initiativeInfoResponse.status === 500) {
          console.error('Error getting initiative data')
        }
      } catch(e){
        console.log(e)
      }
    }
    
  }

  useEffect(() => {
    getCurrentInitiativeInfo()
  }, [])

  const handleAddInitiativeInfoInputChange = (field, value) => {
    setAddInitiativeInfo({
      ...addInitiativeInfo,
      [field]: value,
    })
  }

  const handleAddStatus = (value) => {
    setSelectedOption(value)
    handleAddInitiativeInfoInputChange('status', value)
  }

  const allFilled = Object.values(addInitiativeInfo).every(val => val !== '')

  /* Services and applications */
  const [servicesAndApplications, setServicesAndApplications] = useState([])

  const updateServApps = (servApp) => {
    const existingServApp = servicesAndApplications.find((selectedItem) => selectedItem.id === servApp.id)
    if (existingServApp) {
      setServicesAndApplications(servicesAndApplications.filter((selectedItem) => selectedItem.id !== servApp.id))
    } else {
      setServicesAndApplications(servicesAndApplications.concat(servApp))
    }
  }

  /* Initiative leader */
  const [initiativeLeader, setInitiativeLeader] = useState('')

  const updateInitiativeLeader = (leader) => {
    setInitiativeLeader(leader)
  }

  /* Initiative people involved */
  const [initiativePeopleInvolved, setInitiativePeopleInvolved] = useState([])

  const updatePeopleInvolved = (involved) => {
    const isSelected = initiativePeopleInvolved.includes(involved.name)
    if (isSelected) {
      setInitiativePeopleInvolved(initiativePeopleInvolved.filter((selectedItem) => selectedItem !== involved.name))
    } else {
      setInitiativePeopleInvolved(initiativePeopleInvolved.concat(involved.name))
    }
  }

  /* Initiative people involved */
  const [departmentResponsibles, setDepartmentResponsibles] = useState([])

  const updateDepartmentResponsibles = (responsible) => {
    const isSelected = departmentResponsibles.includes(responsible.name)
    if (isSelected){
      setDepartmentResponsibles(departmentResponsibles.filter((selectedItem) => selectedItem !== responsible.name))
    } else{
      setDepartmentResponsibles(departmentResponsibles.concat(responsible.name))
      if(!initiativePeopleInvolved.includes(responsible.name)){
        setInitiativePeopleInvolved(initiativePeopleInvolved.concat(responsible.name))
      }
    }
  }
  /* Error modal */
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const closeErrorModal = () => {
    setIsOpenErrorModal(false)
  }

  const checkInitiativeExists = async () => {
    // Check if initiative name doesn't exists
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
    }
    const initiativeExistsResponse = await fetch(`${API}/initiatives/${currentCompany}/${addInitiativeInfo.name}/name`, requestOptions)
    const data = await initiativeExistsResponse.json()
    if (initiativeExistsResponse.status === 200) {
      return data.exists
    } else if (initiativeExistsResponse.status === 500) {
      return data.Error
    }
  }

  /* Go to toolchain setup */
  const sendInitiativeSetup = async () => {
    const initiativeExists = await checkInitiativeExists()
    console.log(initiativeExists)
    if (initiativeExists === true){
      setIsOpenErrorModal(true)
      setErrorMessage('An initiative was found with the same name. Please define a new one')
    } else if (!allFilled) {
      setIsOpenErrorModal(true)
      setErrorMessage('Please complete the initiative info')
      const element = document.getElementById('initiativeInfo')
      element.scrollIntoView()
    } else if (servicesAndApplications.length === 0) {
      setIsOpenErrorModal(true)
      setErrorMessage('Please select one service or application at least')
      const element = document.getElementById('initiativeServApps')
      element.scrollIntoView()
    } else if (initiativeLeader.length === 0) {
      setIsOpenErrorModal(true)
      setErrorMessage('Please select the initiative leader')
      const element = document.getElementById('initiativeLeader')
      element.scrollIntoView()
    } else if (initiativePeopleInvolved.length === 0) {
      setIsOpenErrorModal(true)
      setErrorMessage('Please select one people involved at least')
      const element = document.getElementById('initiativePeopleInvolved')
      element.scrollIntoView()
    } else if (initiativeExists === false) {
      const newInitiativeData = {
        'company': currentCompany,
        'initiative_name': addInitiativeInfo.name,
        'description': addInitiativeInfo.description,
        'start_date': addInitiativeInfo.startDate,
        'release_date': addInitiativeInfo.releaseDate,
        'status': addInitiativeInfo.status,
        'total_cost': addInitiativeInfo.forcastedTotalCost,
        'service_apps': servicesAndApplications,
        'leader': initiativeLeader,
        'resources': initiativePeopleInvolved,
        'responsibles': departmentResponsibles
      }
      console.log(newInitiativeData)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify(newInitiativeData),
      }
      const response = await fetch(`${API}/initiatives`, requestOptions)
      const data = await response.json()
      if (response.status === 200) {
        const initiativeId = data.initiativeId
        navigate(`/${btoa('toolchain-setup')}`, { state: { initiative: initiativeId } })
      } else if (response.status === 500) {
        console.log(data['Error'])
      }
    }
  }

  const updateInitiativeSetup = async () => {
    if (!allFilled) {
      setIsOpenErrorModal(true)
      setErrorMessage('Please complete the initiative info')
      const element = document.getElementById('initiativeInfo')
      element.scrollIntoView()
    } else if (servicesAndApplications.length === 0) {
      setIsOpenErrorModal(true)
      setErrorMessage('Please select one service or application at least')
      const element = document.getElementById('initiativeServApps')
      element.scrollIntoView()
    } else if (initiativeLeader.length === 0) {
      setIsOpenErrorModal(true)
      setErrorMessage('Please select the initiative leader')
      const element = document.getElementById('initiativeLeader')
      element.scrollIntoView()
    } else if (initiativePeopleInvolved.length === 0) {
      setIsOpenErrorModal(true)
      setErrorMessage('Please select one people involved at least')
      const element = document.getElementById('initiativePeopleInvolved')
      element.scrollIntoView()
    } else {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          'company': currentCompany,
          'initiative_name': addInitiativeInfo.name,
          'description': addInitiativeInfo.description,
          'start_date': addInitiativeInfo.startDate,
          'release_date': addInitiativeInfo.releaseDate,
          'status': addInitiativeInfo.status,
          'total_cost': addInitiativeInfo.forcastedTotalCost,
          'service_apps': servicesAndApplications,
          'leader': initiativeLeader,
          'resources': initiativePeopleInvolved,
          'responsibles': departmentResponsibles
        }),
      }
      const response = await fetch(`${API}/initiatives/${currentInitiativeId}`, requestOptions)
      if (response.status === 200) {
        navigate(`/${btoa('company-initiatives')}`)
      } else if (response.status === 500) {
        setError('Something went wrong')
      }
    }
  }

  return (
    <div className="containerToRight">
      { mode === 'edit' &&
      <div>
        <BasicButton
          as="link"
          to="company-initiatives"
          color="btnBlack"
          size="btnSmall"
        >
          <i className="fa-solid fa-arrow-left" />
          {""} Back
        </BasicButton>
        <br/>
      </div>
      }
      <div className="tar">
        <CurrentDate />
      </div>
      
      <h1>Initiative Setup</h1>
      <div>
      {error && <div className="mt1 mb2"><Warning message={error} /></div>}
      </div>
      {/* Setp one */}
      <hr className="grayLine mt2" />
      <span className="stepTag" id="initiativeInfo">1</span>
      <div className="flexContainer spaceBetween mt2">
        <div className="col3 fullTablet fullLandscape mb1">
          <h2 className="bigText mb1">Initiative Info</h2>
          <p className="fw500 mb1">
            Please provide the information of this initiative
          </p>
          <small className="lightGray">*Mandatory Fields</small>
        </div>
        <form className="col9 flexContainer spaceBetween">
          <div className="col6">
            { mode !== 'edit' 
            ? 
            <DarkInput
              type="text"
              className="darkestInput"
              label="Initiative Name*"
              placeholder="Specify an initiative name"
              value={addInitiativeInfo.name}
              onChange={(value) => handleAddInitiativeInfoInputChange('name', value)}
              onKeyPress={NextField}
            />
            :
            <div>
              <h2>{addInitiativeInfo.name}</h2>
              <br/>
            </div>
            }
            <DarkInput
              textarea={true}
              className="darkestInput"
              label="Initiative Description*"
              placeholder="Type the initiative description"
              value={addInitiativeInfo.description}
              onChange={(value) => handleAddInitiativeInfoInputChange('description', value)}
              onKeyPress={NextField}
            />
          </div>
          <div className="col6">
            <DateInput
              label="Start Date*"
              value={addInitiativeInfo.startDate}
              onChange={(e) => handleAddInitiativeInfoInputChange('startDate', e.target.value)}
            />
            <DarkSelectInput
              label="Status*"
              options={options}
              value={selectedOption}
              onChange={handleAddStatus}
            />
            <DateInput
              label="Target/Planned Release Date*"
              value={addInitiativeInfo.releaseDate}
              onChange={(e) => handleAddInitiativeInfoInputChange('releaseDate', e.target.value)}
            />
            <NumberInput
              inputName="forcastedTotalCost"
              className="darkestInput mt1"
              inputType="cost"
              label="Forecasted Total Cost*"
              value={addInitiativeInfo.forcastedTotalCost}
              onChange={handleAddInitiativeInfoInputChange}
            />
          </div>
        </form>
      </div>

      {/* Setp two */}
      <hr className="grayLine mt2" />
      <span className="stepTag" id="initiativeServApps">2</span>
      <div className="flexContainer spaceBetween mt2">
        <div className="col3 fullTablet fullLandscape mb1">
          <h2 className="bigText mb1">Associated Services or Applications</h2>
          <p className="fw500 mb1">
            Please select the services or applications to which this initiative is associated.
          </p>
          <small className="lightGray">*Mandatory Fields</small>
        </div>
        <div className="col9">
          <SearchServsAppsTable updateServApps={updateServApps} currentServApps={servicesAndApplications}/>
        </div>
      </div>

      {/* Setp three */}
      <hr className="grayLine mt2" />
      <span className="stepTag" id="initiativeLeader">3</span>
      <h2 className="bigText mt1 lightGray">Associated Resources</h2>
      <div className="flexContainer spaceBetween mt1">
        <div className="col3 fullTablet fullLandscape mb1">
          <h2 className="bigText mb1">Initiative Leader</h2>
          <p className="fw500 mb1">
            Please select the person who will lead this initiative.
          </p>
          <small className="lightGray">*Mandatory Fields</small>
        </div>
        <div className="col9">
          <SearchInitiativeLeader updateInitiativeLeader={updateInitiativeLeader} currentInitiativeLeader={initiativeLeader}/>  
        </div>
      </div>
      <div className="flexContainer spaceBetween mt2" id="initiativePeopleInvolved">
        <div className="col3 fullTablet fullLandscape mb1">
          <h2 className="bigText mb1">People Involved</h2>
          <p className="fw500 mb1">
            Please select the people who will be involved in this initiative for each of the departments. And select the person who will be responsible for each department involved in the initiative.
          </p>
          <small className="lightGray">*Mandatory Fields</small>
        </div>
        <div className="col9">
          <SearchPeopleInvolved
            updatePeopleInvolved={updatePeopleInvolved}
            currentPeopleInvolved={initiativePeopleInvolved}
            updateDepartmentResponsibles={updateDepartmentResponsibles}
            currentDepartmentResponsibles={departmentResponsibles}
          />
        </div>
      </div>

      {/* Go to initiative setup FIRST TIME vs UPDATE */}
      { mode !== 'edit'
      ? 
      <div className="tac mt2">
        <BasicButton
          color="btnRed"
          size="btnSmall"
          onClick={sendInitiativeSetup}
        >
          Next
        </BasicButton>
      </div>
      :
      <div className="tac mt2">
        <BasicButton
          color="btnRed"
          size="btnSmall"
          onClick={updateInitiativeSetup}
        >
          Save and Exit
        </BasicButton>
      </div>
      }
      
      <BasicModal isOpen={isOpenErrorModal} onClose={closeErrorModal}>
        <div className="tac">
          <>
            <p className="mb2 bigText">{errorMessage}</p>
            <div className="mb1" />
            <BasicButton
              as="button"
              color="btnRed"
              size="btnFull"
              onClick={closeErrorModal}
            >
              Ok
            </BasicButton>
          </>
        </div>
      </BasicModal>
    </div>
  )
}

export default InitiativeSetup