import './IntelligentReportWidget.css';
import DarkInput from '../forms/DarkInput';
import { useState } from 'react';
import BasicButton from '../buttons/BasicButton';

const FeedbackModal = ({closeModal}) => {

  const [feedback, setFeedback] = useState({
    rating: 0,
    comment: ""
  });

  const handleComment = (value) => {
    setFeedback({
      ...feedback, 
      comment: value // Access the input value
    });
  };

  const handleStarClick = (index) => {
    setFeedback({
      ...feedback, 
      rating: index + 1  // Set rating based on clicked star
    });
  };

  const submitFeedback = () => {
    console.log(feedback);
    closeModal();
  };

  return (
    <div id="myModal" className="modal">
      <div className="feedback-modal-content">
        <h1>Feedback</h1>
        <p>How helpful was this report?</p>
        <p> Please rate:</p>
        <div className="star-rating">
          {Array.from({ length: 5 }).map((_, index) => (
            <span
              key={index}
              className={index < feedback.rating ? "selected" : ""}  // Use feedback.rating
              onClick={() => handleStarClick(index)}
            >
              ★
            </span>
          ))}
        </div>
        <form>
          <div className='feedback-comment'>
            <DarkInput
              textarea={true}
              className="darkestInput"
              type="text"
              placeholder="Add Comment (optional)"
              value={feedback.comment}  // Use feedback.comment
              onChange={(value) => handleComment(value)}  // Pass event to handleComment
            />
          </div>
        </form>
        {
          feedback.rating > 0 && 
          <div className='feedback-button-container'>
            <BasicButton
                color="btnGray"
                onClick={submitFeedback}
              >
                SUBMIT
            </BasicButton>
          </div>
        }
        
      </div>
    </div>
  );
};

export default FeedbackModal;
