import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import './UserLogo.css'

const API = process.env.REACT_APP_SETUP_API

const UserLogo = ({ logoUpdated }) => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Location */
  const location = useLocation()
  const currentPath = location.pathname
  /* Logo */
  const [logoData, setLogoData] = useState('')

  /* Fetch users list */
  const getUserLogo = useCallback(async () => {
    try {
      const response = await fetch(`${API}/company-logo/${currentCompany}`, {
        method: 'GET'
      })
      const data = await response.blob()
      const reader = new FileReader()
      reader.onloadend = () => {
        const dataUrl = reader.result
        setLogoData(dataUrl)
      }
      reader.readAsDataURL(data)
    } catch (error) {
      console.error(error)
    }
  }, [currentCompany])

  useEffect(() => {
    getUserLogo()
  }, [getUserLogo, logoUpdated])

  /* Classnames to hide nav items */
  const [isDobconAdmin, setIsDobconAdmin] = useState(false)

  useEffect(() => {
    if (currentPath === `/${btoa('admin-home')}`) {
      setIsDobconAdmin(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    !isDobconAdmin ?
      <img
        src={logoData ? logoData : "/img/logo/alternate-logo.png"}
        alt="Logo company"
        className="userLogo"
      /> : null
  )
}

export default UserLogo