export const attributes1 = [
  //'userAgent',
  'webDriver',
  //'colorDepth', (different for MAC Firefox)
  // 'deviceMemory, (different for Brave and undefined for Firefox)
  // 'memoryConcurrency, (different for Brave)
  //'screenResolution', (different for Brave)
  // 'availableScreenResolution, (depends on browser window sizing)
  'timezoneOffset', 
  'timezone', 
  'sessionStorage',
  'localStorage',
  'indexedDb',
  'addBehavior',
  'openDatabase',
  // 'cpuClass' (not available in most cases)
  'platform',
  // 'plugins' (depends on browser)
  // 'canvas' (string is too long and might depend on browser)
  //'webglVendorAndRenderer (different for Firefox and Safari)
  'hasLiedLanguages',
  'hasLiedResolution',
  'hasLiedOs',
  'hasLiedBrowser',
  'touchSupport' // its a list of int values
]

export const attributes2 = [
  //'applePay', (different for Safari) 
  'architecture', // --
  // 'audio' (different for most cases)
  'colorDepth', // (different for firefox in Mac)
  'colorGamut',
  'contrast',
  'cookiesEnabled',
  // 'cpuClass' (not available in most browsers)
  //'deviceMemory', (different for Brave and undefined for Firefox)
  // 'domBlockers', (not available in most browsers)
  // 'fontPreferences', (undefined for firefox in Windows)
  'forcedColors',
  //'hardwareConcurrency', (different for Brave in Windows)
  'hdr',
  'indexedDB',
  'localStorage', // --
  'monochrome', // --
  'openDatabase', // --
  // 'osCpu' (not available for some browsers)
  'pdfViewerEnabled', // --
  'platform', // --
  'reducedMotion', // --
  //'reducedTransparency', // --
  'sessionStorage', // --
  'timezone', // --
  //'touchSupport', // Its an object ********
  //'vendor', (different for Safari and Firefox)
  //'vendorFlavors', (different for Safari and Firefox)
  //'webGlBasics'(dfferent for Safari and Firefox)
]