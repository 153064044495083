import React, { useEffect, useState } from 'react'
import './Setup.css'
import { useNavigate, useLocation } from 'react-router-dom'
import ToolchainCard from '../../components/cards/ToolchainCard'
import code from './resources/toolsdata/code.json'
import build from './resources/toolsdata/build.json'
import test from './resources/toolsdata/test.json'
import integration from './resources/toolsdata/integration.json'
import deploy from './resources/toolsdata/deploy.json'
import operate from './resources/toolsdata/operate.json'
import monitor from './resources/toolsdata/monitor.json'
import ToolchainSlaCard from '../../components/cards/ToolchainSlaCard'
import SearchResponsibleTable from '../../components/tables/SearchResponsibleTable'
import BasicButton from '../../components/buttons/BasicButton'
import BasicModal from '../../components/modals/BasicModal'
import CurrentDate from '../../components/resources/CurrentDate'

const API = process.env.REACT_APP_SETUP_API

function getResponsibleName(toolchainResponsibles, key) {
  return toolchainResponsibles[key].responsible.name || "";
}

const ToolchainSetup = () => {
  /* State */
  const location = useLocation()
  const { state } = location
  const initiativeId = state?.initiative
  const mode = state?.mode
  /* Redirection */
  let navigate = useNavigate()

  /* Fetch involved resources list */
  const [involvedResources, setInvolvedResources] = useState([])
  const getInvolvedResources = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        accept: 'application/json',
      }
      const response = await fetch(
        `${API}/resources/${initiativeId}/involved`, requestOptions
      )
      const data = await response.json()
      setInvolvedResources(data)
    } catch (e) {
      console.error(e)
    }

  }
  /* Get toolchain setup (if edit mode) */
  const getToolchainSetup = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    const setupResponse = await fetch(`${API}/initiatives/${initiativeId}/toolchain`, requestOptions)
    const data = await setupResponse.json()
    if (setupResponse.status === 200) {
      console.log(data)
      let updatedResponsibles = { ...toolchainResponsibles }
      for (const stage in data) {
        if (data[stage] !== null) {
          setToolsValue((prevToolsValue) => ({
            ...prevToolsValue,
            [stage]: data[stage]['tools'],
          }))
          setAddSlaValue((prevAddSlaValue) => ({
            ...prevAddSlaValue,
            [stage]: { value: data[stage]['sla'], required: true },
          }))
          const responsibleInfo = involvedResources.find((person) => person.name === data[stage]['responsible'])
          if (responsibleInfo) {
            updatedResponsibles = {
              ...updatedResponsibles,
              [stage]: { responsible: responsibleInfo, required: true }
            }
          }

        }
        setToolchainResponsibles(updatedResponsibles)
      }
    } else if (setupResponse.status === 500) {
      console.log('Error')
    }
  }

  /* Tools selection by stage */
  const [toolsValue, setToolsValue] = useState({
    CODE: [],
    BUILD: [],
    TEST: [],
    INTEGRATION: [],
    DEPLOY: [],
    OPERATE: [],
    MONITOR: []
  })
  const handleToolChange = (stage, tool) => {
    const toolExists = toolsValue[stage].includes(tool);
    if (toolExists) {
      const stageTools = toolsValue[stage].filter(existingTool => existingTool !== tool)
      setToolsValue((prevToolsValue) => ({
        ...prevToolsValue,
        [stage]: stageTools
      }))
      setAddSlaValue((prevAddSlaValue) => ({
        ...prevAddSlaValue,
        [stage]: { value: '', required: stageTools.length !== 0 },
      }))
      setToolchainResponsibles((prevToolchainResponsibles) => ({
        ...prevToolchainResponsibles,
        [stage]: {
          ...prevToolchainResponsibles[stage],
          required: stageTools.length !== 0
        }
      }))
    } else {
      const stageTools = toolsValue[stage].concat(tool)
      setToolsValue(prevToolsValue => ({
        ...prevToolsValue,
        [stage]: stageTools
      }))
      setAddSlaValue((prevAddSlaValue) => ({
        ...prevAddSlaValue,
        [stage]: { value: '', required: true },
      }))
      setToolchainResponsibles((prevToolchainResponsibles) => ({
        ...prevToolchainResponsibles,
        [stage]: {
          ...prevToolchainResponsibles[stage],
          required: true
        }
      }))
    }
  }
  const validateTools = () => {
    const toolCategories = Object.keys(toolsValue)
    for (const category of toolCategories) {
      if (toolsValue[category].length > 0) {
        // One tool selected at least
        return null
      }
    }
    return 'Please select one tool at least'
  }
  const validationResult = validateTools()

  /* SLA's values */
  const [addSlaValue, setAddSlaValue] = useState({
    CODE: { value: '', required: false },
    BUILD: { value: '', required: false },
    TEST: { value: '', required: false },
    INTEGRATION: { value: '', required: false },
    DEPLOY: { value: '', required: false },
    OPERATE: { value: '', required: false },
    MONITOR: { value: '', required: false }
  })
  const handleSlaChange = (field, value) => {
    setAddSlaValue((prevAddSlaValue) => ({
      ...prevAddSlaValue,
      [field]: {
        ...prevAddSlaValue[field],
        value: value,
      },
    }))
  }
  const validateSlaFields = () => {
    const slaFields = Object.values(addSlaValue)
    for (const slaField of slaFields) {
      if (slaField.required && slaField.value === '') {
        return "Please complete the available SLA's fields"
      }
    }
    return null
  }
  const slaValidationResult = validateSlaFields()

  /* Toolchain responsibles */
  const [toolchainResponsibles, setToolchainResponsibles] = useState({
    CODE: { responsible: '', required: false },
    BUILD: { responsible: '', required: false },
    TEST: { responsible: '', required: false },
    INTEGRATION: { responsible: '', required: false },
    DEPLOY: { responsible: '', required: false },
    OPERATE: { responsible: '', required: false },
    MONITOR: { responsible: '', required: false }
  })

  const handleToolchanResponsibles = (responsibles) => {
    setToolchainResponsibles(responsibles)
  }

  /* Validate responsibles */
  const validateResponsibles = () => {
    const responsibleCategories = Object.keys(toolchainResponsibles)

    for (const category of responsibleCategories) {
      if (toolchainResponsibles[category].required && toolchainResponsibles[category].responsible === '') {
        return 'Please select the stages responsibles'
      }
    }

    return null
  }

  const responsiblesValidationResult = validateResponsibles()

  /* Error modal */
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const closeErrorModal = () => {
    setIsOpenErrorModal(false)
  }

  const buildInitiativeSetupData = () => {
    let toolchainConfiguration = {}; // Initialize an empty object

    for (const stage in toolsValue) {
      if (toolsValue[stage].length > 0) { // Check if there is at least one tool in the array
        toolchainConfiguration[stage] = {
          'tools': toolsValue[stage],
          'sla': addSlaValue[stage].value,
          'responsible': toolchainResponsibles[stage].responsible.name
        };
      }
    }
    return toolchainConfiguration
  }

  useEffect(() => {
    console.log(toolsValue)
  }, [toolsValue])

  /* Go to toolchain configuration */
  const sendToolchainSetup = async () => {
    const scrollToElement = id => {
      const element = document.getElementById(id);
      element && element.scrollIntoView();
    };

    const showErrorModal = errorMessage => {
      setIsOpenErrorModal(true);
      setErrorMessage(errorMessage);
    };

    if (validationResult) {
      showErrorModal(validationResult);
      scrollToElement('toolchain');
    } else if (slaValidationResult) {
      showErrorModal(slaValidationResult);
      scrollToElement('sla');
    } else if (responsiblesValidationResult) {
      showErrorModal(responsiblesValidationResult);
      scrollToElement('responsibles');
    } else {
      const initiative_setup = buildInitiativeSetupData()
      console.log(initiative_setup)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify(initiative_setup),
      };

      const response = await fetch(`${API}/initiatives/${initiativeId}/toolchain`, requestOptions);
      const data = await response.json();

      switch (response.status) {
        case 200:
          if (mode === 'edit') {
            navigate(`/${btoa('company-initiatives')}`);
          } else {
            navigate(`/${btoa('toolchain-config')}`, { state: { initiative: initiativeId } });
          }
          break;
        case 500:
          console.log(data.Error);
          break;
        case 404:
          console.log(data.message);
          break;
        default:
        // Handle other response statuses if needed
      }
    }
  };

  useEffect(() => {
    getInvolvedResources()
  }, [])

  useEffect(() => {
    if (mode === 'edit' && involvedResources) {
      getToolchainSetup()
    }
  }, [involvedResources])

  return (
    <div className="containerToRight">
      {mode === 'edit' &&
        <div>
          <BasicButton
            as="link"
            to="company-initiatives"
            color="btnBlack"
            size="btnSmall"
          >
            <i className="fa-solid fa-arrow-left" />
            {""} Back
          </BasicButton>
          <br />
        </div>
      }
      <div className="tar">
        <CurrentDate />
      </div>
      <h1>Toolchain Setup</h1>

      {/* Setp one */}
      <hr className="grayLine mt2" />
      <span className="stepTag" id="toolchain">1</span>
      <div className="mt2 mb2">
        <h2 className="bigText mb1">Toolchain</h2>
        <p className="fw500">
          Select the tools you want to use in your software development pipeline
        </p>
        <small className="lightGray">*Please seleact one tool at least</small>
      </div>
      <div className="toolchainContainer">
        <ToolchainCard
          stage="Code"
          toolsData={code}
          selectedTools={toolsValue.CODE}
          onToolSelectionChange={(value) => handleToolChange('CODE', value)}
        />
        <ToolchainCard
          stage="Build"
          toolsData={build}
          selectedTools={toolsValue.BUILD}
          onToolSelectionChange={(value) => handleToolChange('BUILD', value)}
        />
        <ToolchainCard
          stage="Test"
          toolsData={test}
          selectedTools={toolsValue.TEST}
          onToolSelectionChange={(value) => handleToolChange('TEST', value)}
        />
        <ToolchainCard
          stage="Integration"
          toolsData={integration}
          selectedTools={toolsValue.INTEGRATION}
          onToolSelectionChange={(value) => handleToolChange('INTEGRATION', value)}
        />
        <ToolchainCard
          stage="Deploy"
          toolsData={deploy}
          selectedTools={toolsValue.DEPLOY}
          onToolSelectionChange={(value) => handleToolChange('DEPLOY', value)}
        />
        <ToolchainCard
          stage="Operate"
          toolsData={operate}
          selectedTools={toolsValue.OPERATE}
          onToolSelectionChange={(value) => handleToolChange('OPERATE', value)}
        />
        <ToolchainCard
          stage="Monitor"
          toolsData={monitor}
          selectedTools={toolsValue.MONITOR}
          onToolSelectionChange={(value) => handleToolChange('MONITOR', value)}
        />
      </div>

      {/* Setp two */}
      <hr className="grayLine mt2" />
      <span className="stepTag" id="sla">2</span>
      <div className="mt2 mb2">
        <h2 className="bigText mb1">SLA's</h2>
        <p className="fw500">
          Indicate the SLA (success rate) you have agreed for each stage of your software development pipeline
        </p>
        <small className="lightGray">*Please fill only the available fields</small>
      </div>
      <div className="flexContainer spaceBetween">
        <ToolchainSlaCard
          inputName="CODE"
          title="Code"
          disabled={toolsValue.CODE.length !== 0 ? false : true}
          onChange={handleSlaChange}
          value={addSlaValue.CODE.value || ''}
        />
        <ToolchainSlaCard
          inputName="BUILD"
          title="Build"
          disabled={toolsValue.BUILD.length !== 0 ? false : true}
          onChange={handleSlaChange}
          value={addSlaValue.BUILD.value || ''}
        />
        <ToolchainSlaCard
          inputName="TEST"
          title="Test"
          disabled={toolsValue.TEST.length !== 0 ? false : true}
          onChange={handleSlaChange}
          value={addSlaValue.TEST.value || ''}
        />
        <ToolchainSlaCard
          inputName="INTEGRATION"
          title="Integration"
          disabled={toolsValue.INTEGRATION.length !== 0 ? false : true}
          onChange={handleSlaChange}
          value={addSlaValue.INTEGRATION.value || ''}
        />
        <ToolchainSlaCard
          inputName="DEPLOY"
          title="Deploy"
          disabled={toolsValue.DEPLOY.length !== 0 ? false : true}
          onChange={handleSlaChange}
          value={addSlaValue.DEPLOY.value || ''}
        />
        <ToolchainSlaCard
          inputName="OPERATE"
          title="Operate"
          disabled={toolsValue.OPERATE.length !== 0 ? false : true}
          onChange={handleSlaChange}
          value={addSlaValue.OPERATE.value || ''}
        />
        <ToolchainSlaCard
          inputName="MONITOR"
          title="Monitor"
          disabled={toolsValue.MONITOR.length !== 0 ? false : true}
          onChange={handleSlaChange}
          value={addSlaValue.MONITOR.value || ''}
        />
      </div>

      {/* Setp three */}
      <hr className="grayLine mt2" />
      <span className="stepTag" id="responsibles">3</span>
      <div className="mt2 mb2">
        <h2 className="bigText mb1">Responsibles</h2>
        <p className="fw500">
          Select the person responsible for each pipeline stage in this initiative
        </p>
        <small className="lightGray">*Please fill only the available fields</small>
      </div>
      <SearchResponsibleTable
        code={toolsValue.CODE}
        build={toolsValue.BUILD}
        test={toolsValue.TEST}
        integration={toolsValue.INTEGRATION}
        deploy={toolsValue.DEPLOY}
        operate={toolsValue.OPERATE}
        monitor={toolsValue.MONITOR}
        currentResponsibles={toolchainResponsibles}
        sendResponsibles={handleToolchanResponsibles}
        peopleInvolved={involvedResources}
      />
      {/* Go to initiative setup */}
      <div className="tac mt2">
        {mode !== 'edit' ?
          <BasicButton
            color="btnRed"
            size="btnSmall"
            onClick={sendToolchainSetup}
          >
            Next
          </BasicButton> :
          <BasicButton
            color="btnRed"
            size="btnSmall"
            onClick={sendToolchainSetup}
          >
            Save and Exit
          </BasicButton>
        }

      </div>
      <BasicModal isOpen={isOpenErrorModal} onClose={closeErrorModal}>
        <div className="tac">
          <>
            <p className="mb2 bigText">{errorMessage}</p>
            <div className="mb1" />
            <BasicButton
              as="button"
              color="btnRed"
              size="btnFull"
              onClick={closeErrorModal}
            >
              Ok
            </BasicButton>
          </>
        </div>
      </BasicModal>
    </div>
  )
}

export default ToolchainSetup