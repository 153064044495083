import SettingsCard from '../../components/cards/SettingsCard'
import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'

const AdminHome = () => {
  /* User info */
  const [username, setUsername] = useState('')
  const getUserInfo = async() => {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser()
      const userAttributes = authenticatedUser.attributes
      if(userAttributes.preferred_username){
        if(userAttributes.preferred_username === userAttributes.email){
          setUsername(userAttributes.name)
        } else {
          setUsername(userAttributes.preferred_username)
        }
      } else {
        setUsername(userAttributes.name)
      }
    } catch (error) {
      console.error('Error fetching user info:', error)
    }

  }

  useEffect(() => {
    getUserInfo()
  }, [])
  
  return (
    <div className="containerToRight">
      <h1 className="mb1">
      Welcome, {username}!
      </h1>
      <h2 className="mb1">What would you like to do today?</h2>
      <div className="flexContainer spaceBetween mt3">
        <div className="col6 flex mb1">
          <SettingsCard
            cardLinkTo="admin-companies"
            cardTitle="Manage Companies Licenses"
          />
        </div>
        <div className="col6 flex mb1">
          <SettingsCard
            cardLinkTo="admin-users"
            cardTitle="Manage Admin Users"
          />
        </div>
      </div>
    </div>
  )
}

export default AdminHome