function generateMockData() {
  const dtpPerDate = [];
  const today = new Date();
  const lastMonthDate = new Date(new Date().setDate(today.getDate() - 30));

  let day = new Date(lastMonthDate);

  while (day <= today) {
    dtpPerDate.push({
      g: Math.floor(Math.random() * 11), // Generates a random number between 0 and 10
      r: Math.floor(Math.random() * 11), // Generates a random number between 0 and 10
      timestamp: day.toISOString().split('T')[0]
    });
    
    // Increment the date by one day
    day.setDate(day.getDate() + 1);
  }

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  // Helper function to check if a date is within the last N days
  const isWithinLastDays = (dateString, days) => {
    const date = new Date(dateString);
    return ((today - date) / oneDay) <= days;
  };

  const dtpPerTimeframe = [
    {
      g: dtpPerDate.filter(item => isWithinLastDays(item.timestamp, 30)).reduce((acc, item) => acc + item.g, 0),
      r: dtpPerDate.filter(item => isWithinLastDays(item.timestamp, 30)).reduce((acc, item) => acc + item.r, 0),
      timeframe: "lastMonth"
    },
    {
      g: dtpPerDate.filter(item => isWithinLastDays(item.timestamp, 14)).reduce((acc, item) => acc + item.g, 0),
      r: dtpPerDate.filter(item => isWithinLastDays(item.timestamp, 14)).reduce((acc, item) => acc + item.r, 0),
      timeframe: "lastTwoWeeks"
    },
    {
      g: dtpPerDate.filter(item => isWithinLastDays(item.timestamp, 7)).reduce((acc, item) => acc + item.g, 0),
      r: dtpPerDate.filter(item => isWithinLastDays(item.timestamp, 7)).reduce((acc, item) => acc + item.r, 0),
      timeframe: "lastWeek"
    },
    {
      g: dtpPerDate.filter(item => isWithinLastDays(item.timestamp, 1)).reduce((acc, item) => acc + item.g, 0),
      r: dtpPerDate.filter(item => isWithinLastDays(item.timestamp, 1)).reduce((acc, item) => acc + item.r, 0),
      timeframe: "lastDay"
    }
  ];

  const totals = {
    g: dtpPerDate.reduce((acc, item) => acc + item.g, 0),
    r: dtpPerDate.reduce((acc, item) => acc + item.r, 0)
  };

  const mockedData = {
    dtp_per_date: dtpPerDate,
    dtp_per_timeframe: dtpPerTimeframe,
    initiative: "All",
    totals: totals
  };

  return mockedData;
}

export default generateMockData;
