import React from 'react'
import PrivilegesTable from '../../components/tables/PrivilegesTable'
import BasicButton from '../../components/buttons/BasicButton'
import CurrentDate from '../../components/resources/CurrentDate'

const RolesAndPrivileges = () => {
  
  const currentCompany = localStorage.getItem('currentCompany')
  
  return (
    <div className="containerToRight">
      <BasicButton
        as="link"
        to="settings-home"
        color="btnBlack"
        size="btnSmall"
      >
        <i className="fa-solid fa-arrow-left" />
        {""} Back
      </BasicButton>
      <div className="tar">
        <CurrentDate />
      </div>
      <br/>
      <h1>Roles and Privileges</h1>
      
      <div className="flexContainer spaceBetween mt2">
        <div className="col3">
          <h2>{currentCompany}</h2>
          <p className="fw500 mediumText">
            Assign or deny privileges as needed for each role within your company
          </p>
        </div>
        <div className="col9">
          {currentCompany !== null && ( // Check if companyPrivileges is not null
            <PrivilegesTable company={currentCompany}/>
          )}
        </div>
      </div>
    </div>
  )
}

export default RolesAndPrivileges