import BasicTab from '../../components/tabs/BasicTab'
import { useLocation } from 'react-router-dom'
import BasicButton from '../../components/buttons/BasicButton'
import CurrentDate from '../../components/resources/CurrentDate'

const ToolchainConfig = () => {

  const location = useLocation()
  const { state } = location
  const initiativeId = state?.initiative
  const mode = state?.mode

  return (
    <div className="containerToRight">
      { mode === 'edit' &&
      <div>
        <BasicButton
          as="link"
          to="company-initiatives"
          color="btnBlack"
          size="btnSmall"
        >
          <i className="fa-solid fa-arrow-left" />
          {""} Back
        </BasicButton>
        <br/>
      </div>
      }
      <div className="tar">
        <CurrentDate />
      </div>
      <h1 className="mb1">Toolchain Configuration</h1>
      <div className="flexContainer spaceBetween">
        <div className="col4">
          <h2 className="mb2">Please provide the necesary credentials for each tool of the initiative</h2>
          <p className="mediumText lightGray mb1">
            This is needed so that Dobcon can gather needed data for your Business metrics
          </p>
          
        </div>
        
        <div className="col8">
          <BasicTab initiative={initiativeId}/>
        </div>
      </div>
    </div>
  )
}

export default ToolchainConfig