import React, { useEffect, useState } from "react"
import DarkSelectInput from "../../components/forms/DarkSelectInput"
import ProgressSlaCard from "../../components/cards/ProgressSlaCard"
import GraphCard from "../../components/cards/GraphCard"
import DeploymentsToProduction from "./graphs/DeploymentToProduction"
import ReleaseAverageLeadTime from "./graphs/ReleaseAverageLeadTime"
import NumberOfEarlyDefects from "./graphs/NumberOfEarlyDefects"
import MeanTimeToRecovery from "./graphs/MeanTimeToRecovery"
import CurrentDate from "../../components/resources/CurrentDate"
import TotalProductionByDeveloper from "../../pages/toolchain/graphs/TotalProductionByDeveloper"
import generateMockTotalProductionByDeveloper from '../../test_metric_data/generateMockTotalProductionByDeveloper';
import ChangeFailureRate from '../../pages/toolchain/graphs/ChangeFailureRate';
import SoftwareFailures from '../../pages/toolchain/graphs/SoftwareFailures';
import './ToolchainDashboard.css'
import IntelligentReportWidget from "../../components/IntelligentReportWidget/IntelligentReportWidget";

import { fakeReleaseAvg, fakeMttr, sampleDatasetCFR, sampleDatasetS } from "./mock_data"

const API = process.env.REACT_APP_DATA_API

const ToolchainDashboard = () => {

  /* Company */
  //const currentCompany = localStorage.getItem('currentCompany')
  const mockTotalProductionByDeveloper = generateMockTotalProductionByDeveloper();
  const currentCompany = localStorage.getItem('currentCompany')
  /* Timeframe options temp */
  const [selectedTimeframe, setSelectedTimeframe] = useState('Select Timeframe')
  const [selectedTimeframeFilter, setSelectedTimeframeFilter] = useState('historic')
  const [uploadeddtotalProductionByDeveloper, setUploadedtotalProductionByDeveloper] = useState(mockTotalProductionByDeveloper);
  const timeframe = ['Today', 'Last Week', 'Last Two Weeks', 'Last Month']
  const timeframeNames = {
    'Today': 'lastDay',
    'Last Week': 'lastWeek',
    'Last Two Weeks': 'lastTwoWeeks',
    'Last Month': 'lastMonth'
  }

  const handleTimeframe = (value) => {
    setSelectedTimeframe(value)
    setSelectedTimeframeFilter(timeframeNames[value])
  }

  /* Initiatives options */
  const [selectedInitiative, setSelectedInitiative] = useState('All')
  const [initiatives, setInitiatives] = useState([])
  const [initiativeNames, setInitiativeNames] = useState([])

  const handleInitiatives = (value) => {
    setSelectedInitiative(value)
  }
  /* Fetch initiatives list */
  const getCompanyInitiatives = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    try{
      const response = await fetch(`${API}/company-toolchain/${currentCompany}`, requestOptions)
      const data = await response.json()
      if(response.status === 200){
        console.log(data)
        setInitiatives(data)

        let namesInitiatives = []
        data.forEach(obj => {
          if(obj.initiative !== 'All')
          {
            namesInitiatives.push(obj.initiative)
          }
          
        })
        setInitiativeNames(namesInitiatives)
      }
    } catch(e){
      console.error(e)
    }
    
  }

  /* Metric Cards */
  const [dtp, setDtp] = useState({})
  const [filteredDtp, setFilteredDtp] = useState({}) // Apply timeframe filter
  const [edd, setEdd] = useState({})
  const [releaseAvg, setReleaseAvg] = useState(fakeReleaseAvg)
  const [meanTimeToRecovery, setMeanTimeToRecovery] = useState(fakeMttr) 
  const [filteredEdd, setFilteredEdd] = useState({}) // Apply timeframe filter

  const handleTimeframeChange = (selectedOption, metric) => {
    if(metric === 'edd'){
      setFilteredEdd(edd.All.edd_by_timeframe.find(
        (item) => item.timeframe === selectedOption
      ))
    } else if(metric === 'dtp'){
      const dtpSummary = dtp.find(
        (item) => item.initiative === 'All'
      )
      setFilteredDtp(dtpSummary.dtp_per_timeframe.find(
        (item) => item.timeframe === selectedOption
      ))
    }
  }

  const getCompanyMetricCards = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }

    // Deployments to Productio
    try{
      const dtp_response = await fetch(`${API}/metric-cards/dtp-fake/company/${currentCompany}`, requestOptions)
      const dtp_data = await dtp_response.json()
      if(dtp_response.status === 200){
        setDtp(dtp_data)
        const dtpSummary = dtp_data.find(
          (item) => item.initiative === 'All'
        )
        setFilteredDtp(dtpSummary.totals)
      } else{
        console.error(dtp_data)
      }
    } catch (e){
      console.error(e)
    }

    // Earky Defects
    try{
      const edd_response = await fetch(`${API}/metric-cards/edd-fake/company/${currentCompany}`, requestOptions)
      const edd_data = await edd_response.json()
      if(edd_response.status === 200){
        setEdd(edd_data)
        setFilteredEdd(edd_data.All.edd_by_timeframe.find(
          (item) => item.timeframe === 'lastMonth'
        ))
      } else{
        console.error(edd_data)
      }
    } catch(e){
      console.error(e)
    }
    
  
  }

  useEffect(() => {
    getCompanyInitiatives()
    getCompanyMetricCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredInitiatives = selectedInitiative === "" ?
    initiatives : initiatives.filter(item => selectedInitiative.includes(item.initiative))

  /* Order of the stages */
  const stageOrder = ["CODE", "BUILD", "TEST", "INTEGRATION", "DEPLOY", "OPERATE", "MONITOR"]

  /* Format stages */
  function capitalizeWords(sentence) {
    return sentence
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const testDetails = (numberOfFails) => {
    const failArray = Array.from({ length: numberOfFails }, () => {
      const randomInitiative = initiativeNames[Math.floor(Math.random() * initiativeNames.length)];
      return {
          tool: "source_tool",
          event_id: "f59822b9345n2nv428",
          initiative: randomInitiative,
          detail: "More information about the event (from tool)"
      };
    });
    return failArray;
  }

  const handleDetailsPostition = (stage) => {
    let position = 'right'
    const lastStages = ['MONITOR', 'OPERATE']
    if(lastStages.includes(stage)){
      position = 'left'
    }
    return position
  }

  return (
    <div className="containerToRight">
      <h1 className="mb2">
        <span className="lightGray">DOBCO<span className="bigN-h1">n</span></span> Toolchain Dashboard
      </h1>
      <div className="tar">
        <CurrentDate />
      </div>

      <span className="stepTag">Software Delivery Pipeline Stages</span>
      <hr className="grayLine" />

      {/* Head */}
      <div className="flexContainer spaceBetween mt2">
        <div className="col3 fw500 mb1">
          <span className="mr1"><span className="greenDot" /> Successful</span>
          <span><span className="redDot" /> Failed</span>
        </div>

        <div className="col8 flexContainer spaceBetween">
          <div className="col6 flexContainer flexEnd mb1">
            <p className="fw500 mr1"><i className="fa-regular fa-folder" /> Initiative</p>
            <DarkSelectInput
              className="darkSelectInputMedium"
              options={initiatives && initiatives.map(item => item.initiative)}
              value={selectedInitiative}
              onChange={handleInitiatives}
              type="link"
              link="dobcon-toolchain"
            />
          </div>
          <div className="col6 flexContainer flexEnd mb1">
            <p className="fw500 mr1"><i className="fa-regular fa-clock" /> Timeframe</p>
            <DarkSelectInput
              className="darkSelectInputMedium"
              options={timeframe}
              value={selectedTimeframe}
              onChange={handleTimeframe}
            />
          </div>
        </div>
      </div>
      
      {/* Progress cards */}
      {
        initiatives.length !== 0 ? filteredInitiatives.map((item, index) => (
          <div key={item.initiative} className='toolchainProgressCardsContainer'>
            {item?.toolchain_data && Object.entries(item.toolchain_data).sort(([keyA], [keyB]) => stageOrder.indexOf(keyA) - stageOrder.indexOf(keyB))
              .map(([stage, stage_data]) => (
                 stage_data && stage_data.map((tf) =>
                  tf.timeframe === selectedTimeframeFilter ?
                    <ProgressSlaCard
                      key={`${item.initiative}-${stage}`}
                      all={true}
                      stage={capitalizeWords(stage)}
                      success={tf.g}
                      fail={tf.r}
                      failDetails={testDetails(tf.r)}
                      successDetails={testDetails(tf.g)}
                      detailsPosition={handleDetailsPostition(stage)}
                      agreed={tf.agreed_sla}
                      achieved={tf.achieved}
                    /> : null
                )
              ))}
          </div>
        )) :
          <div className="toolchainProgressCardsContainer">
            {stageOrder.map((stage) => (
              <ProgressSlaCard
                key={stage}
                all={true}
                stage={capitalizeWords(stage)}
                success="Loading..."
                fail="Loading..."
                failDetails={[]}
                successDetails={[]}
                failDetailsDirection='right'
                agreed="0"
                achieved="0"
              />
            ))}
          </div>
      }

      {/* Graph cards */}
      <div className="flexContainer spaceBetween mt1">
        <div className="col3 mb2">
          <GraphCard
            title="Release Average Lead Time (From First Commit)"
            graph={<><ReleaseAverageLeadTime dataset={releaseAvg}/></>}
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">0 hh 0 mm</p>
          </GraphCard>
        </div>
        <div className="col3 mb2">
          <GraphCard
            metric='dtp'
            title="Deployments to Production"
            graph={<><DeploymentsToProduction dtp={dtp} initiative='All'/></>}
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">
              Successful: { filteredDtp && filteredDtp?.g !== undefined ? filteredDtp.g : 0}
              <br />
              Failed: {filteredDtp && filteredDtp?.r !== undefined ? filteredDtp.r : 0}
            </p>
          </GraphCard>
        </div>
        <div className="col3 mb2">
          <GraphCard
            title="Mean Time to Recovery (MTTR)"
            graph={<><MeanTimeToRecovery dataset={meanTimeToRecovery}/></>}
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">0 hh 0 mm</p>
          </GraphCard>
        </div>
        <div className="col3 col6Tablet mb2">
          <GraphCard
            title="Total Production by Developer"
            graph={<><TotalProductionByDeveloper data={uploadeddtotalProductionByDeveloper} /></>}
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">1,230 Commits</p>
          </GraphCard>
        </div>
        <div className="col4 mb2">
          <GraphCard
            metric='edd'
            title="Number of Early Defects"
            graph={<NumberOfEarlyDefects edd={edd} initiative={'All'}/>} // pass edd data to graph
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">
              Detected: {filteredEdd && filteredEdd?.detected !== undefined ? filteredEdd.detected : 0}
              <br />
              Solved: {filteredEdd && filteredEdd?.solved !== undefined ? filteredEdd.solved : 0}
            </p>
          </GraphCard>
        </div>
        <div className="col4 mb2">
          <GraphCard
            title="Number of Software-Related Failures in Production"
            graph={<><SoftwareFailures dataset={sampleDatasetS} /></>}
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">
              Detected: 4
              <br />
              Solved: 3
            </p>
          </GraphCard>
        </div>
        <div className="col4">
          <GraphCard
            title="Change Failure Rate"
            graph={<><ChangeFailureRate dataset={sampleDatasetCFR} /></>}
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">
              Detected: 4
              <br />
              Solved: 3
            </p>
          </GraphCard>
        </div>
      </div>
      <IntelligentReportWidget />
    </div>
  )
}

export default ToolchainDashboard