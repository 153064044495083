import React, { useEffect, useState } from 'react'
import './Setup.css'
import { useNavigate, useLocation } from 'react-router-dom'
import DragAndDrop from '../../components/resources/DragAndDrop'
import CompanyResourcesTable from '../../components/tables/CompanyResourcesTable'
import FinancialVariablesTable from '../../components/tables/FinancialVariablesTable'
import ServicesAndAppsTable from '../../components/tables/ServicesAndAppsTable'
import BasicButton from '../../components/buttons/BasicButton'
import BasicModal from '../../components/modals/BasicModal'
import CurrentDate from '../../components/resources/CurrentDate'

const API = process.env.REACT_APP_SETUP_API

const CompanySetup = ({ sendFlagLogo }) => {
  /* Location */
  const location = useLocation()
  const currentPath = location?.pathname
  const [isOnlyCompanySetup, setIsOnlyCompanySetup] = useState(false)
  useEffect(() => {
    if (currentPath === `/${btoa('only-company-setup')}`) {
      setIsOnlyCompanySetup(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /* Redirection */
  let navigate = useNavigate()
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  const formattedCompany = currentCompany.replace(/_/g, ' ')
  /* Recieving logo */
  const [logo, setLogo] = useState(false)
  const sendLogo = (value) => {
    if (logo === false) {
      setLogo(value)
      sendFlagLogo(value)
    } else {
      setLogo(!value)
      sendFlagLogo(value)
    }
  }

  /* Resources */
  const [companyResources, setCompanyResources] = useState([])

  const handleCompanyResources = (resources) => {
    setCompanyResources(resources)
  }

  /* Financial variables */
  const [companyFinancialVariables, setCompanyFinancialVariables] = useState({})

  const handleCompanyFinancialVariables = (financialVariables) => {
    setCompanyFinancialVariables(financialVariables)
  }

  const allFilledVariables = Object.values(companyFinancialVariables).every(val => val !== '')

  /* Services and applications */
  const [companyServApps, setCompanyServApps] = useState([])

  const handleCompanyServApps = (servApps) => {
    setCompanyServApps(servApps)
  }

  /* Error modal */
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const closeErrorModal = () => {
    setIsOpenErrorModal(false)
  }

  /* Go to initiative setup */
  const sendCompanySetup = async () => {
    if (companyResources.length === 0) {
      setIsOpenErrorModal(true)
      setErrorMessage("Please add one resourse at least")
      const element = document.getElementById("resources")
      element.scrollIntoView()
    } else if (!allFilledVariables) {
      setIsOpenErrorModal(true)
      setErrorMessage("Please complete all the financial variables")
      const element = document.getElementById("financialVariables")
      element.scrollIntoView()
    } else if (companyServApps.length === 0) {
      setIsOpenErrorModal(true)
      setErrorMessage("Please add one service or application at least")
      const element = document.getElementById("servApps")
      element.scrollIntoView()
    } else {
      const variables = {
        "discount_rate": companyFinancialVariables.discountRate.replace(/,/g, ''),
        "capital_cost": companyFinancialVariables.capitalCost.replace(/,/g, ''),
        "tax_rate": companyFinancialVariables.taxRate.replace(/,/g, ''),
        "break_even": companyFinancialVariables.breakEven.replace(/,/g, ''),
        "cloud_cost": companyFinancialVariables.cloud.replace(/,/g, ''),
        "tools_cost": companyFinancialVariables.tools.replace(/,/g, ''),
        "hr_cost": companyFinancialVariables.hr.replace(/,/g, ''),
        "cultural_adoption": companyFinancialVariables.culturalAdoption.replace(/,/g, '')
      }
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          "variables": variables
        })
      }

      const response = await fetch(`${API}/parameters/${currentCompany}`, requestOptions)
      const data = await response.json()
      if (response.status === 200) {
        console.log(data['message'])
        if (!isOnlyCompanySetup) {
          navigate(`/${btoa('initiative-setup')}`)
        }
        else{
          navigate(`/${btoa('settings-home')}`)
        }
      } else if (response.status === 500) {
        console.log(data['Error'])
      }
    }
  }

  return (
    <div className="containerToRight">
      { isOnlyCompanySetup &&
      <div>
        <BasicButton
          as="link"
          to="settings-home"
          color="btnBlack"
          size="btnSmall"
        >
          <i className="fa-solid fa-arrow-left" />
          {""} Back
        </BasicButton>
        <br/>
      </div>
      }
      <div className="tar">
        <CurrentDate />
      </div>
      <h1>Company Setup <span className="lightGray">| {formattedCompany}</span></h1>

      {/* Setp one */}
      <hr className="grayLine mt2" />
      <span className="stepTag">1</span>
      <div className="flexContainer spaceBetween mt2">
        <div className="col3 fullTablet fullLandscape mb1">
          <h2 className="bigText mb1">Upload Your Company Logo</h2>
          <p className="fw500">
            · Recommended resolution (72 ppi) <br />
            · Horizontal logo format, preferably <br />
            · Accepted formats (preferably backgroundless png)
          </p>
        </div>
        <div className="col9">
          <DragAndDrop sendLogo={sendLogo} />
        </div>
      </div>

      {/* Step two */}
      <hr className="grayLine mt2" />
      <span className="stepTag" id="resources">2</span>
      <div className="flexContainer spaceBetween mt2">
        <div className="col3 fullTablet fullLandscape mb1">
          <h3 className="bigText mb1">Resources*</h3>
          <p className="fw500 mb1">
            Please enter the resources (people) who may participate in your software development initiatives. You may either enter data manually or allow the system to connect with your Human Resources system to import all data.
          </p>
          <small className="lightGray">*Mandatory Fields</small>
        </div>
        <div className="col9">
          <CompanyResourcesTable handleCompanyResources={handleCompanyResources} />
        </div>
      </div>

      {/* Setp three */}
      <hr className="grayLine mt2" />
      <span className="stepTag" id="financialVariables">3</span>
      <div className="flexContainer spaceBetween mt2">
        <div className="col3 fullTablet fullLandscape mb1">
          <h2 className="bigText mb1">Financial Variables*</h2>
          <p className="fw500 mb1">
            Please enter the following values. These variables may be different from one organization to another and will be used to show business indicators.
          </p>
          <small className="lightGray">*Mandatory Fields</small>
        </div>
        <div className="col9">
          <FinancialVariablesTable handleCompanyFinancialVariables={handleCompanyFinancialVariables} />
        </div>
      </div>

      {/* Setp four */}
      <hr className="grayLine mt2" />
      <span className="stepTag" id="servApps">3</span>
      <div className="flexContainer spaceBetween mt2">
        <div className="col3 fullTablet fullLandscape mb1">
          <h2 className="bigText mb1">Services and Applications*</h2>
          <p className="fw500 mb1">
            Please enter the IT services or applications that may be related to your software development initiatives.
          </p>
          <small className="lightGray">*Mandatory Fields</small>
        </div>
        <div className="col9">
          <ServicesAndAppsTable handleCompanyServApps={handleCompanyServApps} />
        </div>
      </div>

      {/* Go to initiative setup */}
      <div className="tac mt2">
        <BasicButton
          color="btnRed"
          size="btnSmall"
          onClick={sendCompanySetup}
        >
          {!isOnlyCompanySetup ? "Next" : "Save & Exit"}
        </BasicButton>
      </div>
      <BasicModal isOpen={isOpenErrorModal} onClose={closeErrorModal}>
        <div className="tac">
          <>
            <p className="mb2 bigText">{errorMessage}</p>
            <div className="mb1" />
            <BasicButton
              as="button"
              color="btnRed"
              size="btnFull"
              onClick={closeErrorModal}
            >
              Ok
            </BasicButton>
          </>
        </div>
      </BasicModal>
    </div>
  )
}

export default CompanySetup