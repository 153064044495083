import React, { useState } from 'react'
import './ProgressBar.css'

const ProgressBar = ({ progress, fail }) => {
  const [width, setWidth] = useState(0)

  useState(() => {
    setWidth(progress)
      
  }, [progress])

  return (
    <div className="progressBar">
      <div className={fail ? "progressFail" : "progress"} style={{ width: `${width}%` }} />
    </div>
  )
}

export default ProgressBar