import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import './BusinessNavFilter.css'

const BusinessNavFilter = ({ links, activeSection, initiativeName }) => {
  const [isOpen, setIsOpen] = useState(false)
  const navFilterRef = useRef(null)

  const generateDynamicRoute = (link) => {
    switch (link) {
      case 'Business Metrics':
        return `/${btoa('dobcon-business')}`
      case 'Initiative Metrics':
        return `/${btoa('initiative')}/${initiativeName}`
      case 'Costs':
        return `/${btoa('initiative')}/${initiativeName}/${btoa('costs')}`
      case 'Resources':
        return `/${btoa('initiative')}/${initiativeName}/${btoa('resources')}`
      default:
        return `/${btoa(link.toLowerCase().split(' ').join('-'))}`
    }
  }

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (event) => {
    if (navFilterRef.current && !navFilterRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="navFilter" ref={navFilterRef}>
      <div className="navFilterContent">
        <div className="navFilterSelected" onClick={handleToggle}>
          <div>
            <i className="fas fa-filter lightGray" />
            <span className="fw500">{activeSection}</span>
          </div>
          <i className={`fa-solid fa-angle-down ${isOpen ? 'open' : ''}`} />
        </div>
        {isOpen && (
          <div className="navFilterOptions">
            {links.map((link) => (
              <Link
                key={link}
                to={generateDynamicRoute(link)}
                className="navFilterOption fw500"
                onClick={() => setIsOpen(false)}
              >
                {link}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default BusinessNavFilter
