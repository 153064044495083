import InitiativesTable from '../../components/tables/InitiativesTable'
import BasicButton from '../../components/buttons/BasicButton'
import CurrentDate from '../../components/resources/CurrentDate'

const CompanyInitiatives = () => {
  return (
    <div className="containerToRight">
      <BasicButton 
        as="link"
        to="settings-home"
        color="btnBlack"
        size="btnSmall"
      >
        <i className="fa-solid fa-arrow-left" />
        {""} Back
      </BasicButton>
      <div className="tar">
        <CurrentDate />
      </div>
      <br/>
      <h1>Company Initiatives</h1>
      <div className="flexContainer spaceBetween mt2">
        <div className="col3">
          <h2 className="bigText mb1">Explore Your Business Initiatives</h2>
          <p className="fw500 mb1">
            Dive into the details of each business initiative. You have the flexibility to remove existing initiatives or create new ones
          </p>
        </div>
        <div className="col9">
          <InitiativesTable />
        </div>
      </div>
    </div>
  )
}

export default CompanyInitiatives