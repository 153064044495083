import './DateInput.css'

const DateInput = ({ name, value, onChange, label }) => {
  return (
    <div className="dateInput">
      {label && <label className="smallText">{label}</label>}
      <input
        className={label ? 'hasLabel' : ''}
        type="date"
        name={name}
        value={value}
        onChange={onChange}
        data-format="date"
      />
    </div>
  )
}

export default DateInput