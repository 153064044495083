import './BasicOptionsButton.css'

const BasicOptionsButton = ({ options, onChange, isOpen, onToggle }) => {
  const handleSelect = (selectedValue) => {
    onChange(selectedValue)
    onToggle()
  }

  const handleClick = (event) => {
    event.stopPropagation() // Evita que el clic se propague y cierre el menú inmediatamente
    onToggle()
  }

  return (
    <div className="basicOptions">
      <div className="basicOptionsContainer" onClick={handleClick}>
        <div className="basicOptionsSelected">
          <div>
            <p>...</p>
          </div>
        </div>
      </div>
      {isOpen && (
        <ul className="basicOptionsOptions">
          {options.map((option) => (
            <li
              key={option}
              className="basicOptionsOption"
              onClick={() => handleSelect(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default BasicOptionsButton