import { useParams } from 'react-router-dom'
import BasicButton from '../../../components/buttons/BasicButton'
import CurrentDate from '../../../components/resources/CurrentDate'
import BusinessNavFilter from '../../../components/nav/BusinessNavFilter'
import InitiativesInfo from './InitiativesInfo'
/* ChartJS */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js'

import { Line } from 'react-chartjs-2'
import DarkInput from '../../../components/forms/DarkInput'

const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    }
  },
}

const labels = ['DAY 1', 'DAY 2', 'DAY 3', 'DAY 4', 'DAY 5', 'DAY 6', 'DAY 7']

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => getRandomNumber(0, 300)),
      borderColor: '#CF1317',
      backgroundColor: '#CF1317',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => getRandomNumber(0, 300)),
      borderColor: '#38AA0A',
      backgroundColor: '#38AA0A',
    },
    {
      label: 'Dataset 3',
      data: labels.map(() => getRandomNumber(0, 300)),
      borderColor: '#5299FF',
      backgroundColor: '#5299FF',
    },
    {
      label: 'Dataset 4',
      data: labels.map(() => getRandomNumber(0, 300)),
      borderColor: '#FFF800',
      backgroundColor: '#FFF800',
    },
  ],
}

const InitiativeFinancial = () => {
  /* Initiative Params */
  let { initiativeName } = useParams()
  /* Navigation */
  const links = ['Initiative Metrics', 'Costs', 'Resources']
  const activeSection = 'Financial projections'

  return (
    <div className="containerToRight">
      <h1 className="mb1">
        <span className="lightGray">{window.atob(initiativeName)}</span> Financial projections
      </h1>
      <BasicButton color="btnBlack" size="btnSmall" as="link" to="dobcon-business">
        Back to Business Dashboard <i className="fa-solid fa-rotate-left" />
      </BasicButton>
      <div className="tar mt-1">
        <CurrentDate />
        <hr className="grayLine mt1" />
      </div>
      <div className="flexContainer spaceBetween pt1 pb1">
        <div className="col2">
          <BusinessNavFilter
            links={links}
            activeSection={activeSection}
            initiativeName={initiativeName}
          />
        </div>
      </div>

      <InitiativesInfo />

      <div className="businessMetricsTable mt2">
        <div className="businessMetricsTableBody flexContainer">
          <ul className="w35 grayLineRight">
            <p className="mediumText mb2">Variables</p>
            <div>
              <DarkInput
                type="text"
                className="darkestInput"
                label="Variable one"
                placeholder="Type the variable here"
                value=""
                onChange={(e) => console.log(e.target.value)}
              />
              <DarkInput
                type="text"
                className="darkestInput"
                label="Variable two"
                placeholder="Type the variable here"
                value=""
                onChange={(e) => console.log(e.target.value)}
              />
              <DarkInput
                type="text"
                className="darkestInput"
                label="Variable three"
                placeholder="Type the variable here"
                value=""
                onChange={(e) => console.log(e.target.value)}
              />
              <DarkInput
                type="text"
                className="darkestInput"
                label="Variable n"
                placeholder="Type the variable here"
                value=""
                onChange={(e) => console.log(e.target.value)}
              />
            </div>
          </ul>
          <ul className="w65">
            <p className="mediumText mb1">Projections</p>
            <Line options={options} data={data} className='chartModal' />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default InitiativeFinancial