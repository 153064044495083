import { Link } from 'react-router-dom'
import './Header.css'

const Header = () => {
  return (
    <div className="mainHeader">
      <div className="headerContainer containerToRight">
        <Link to={`/${btoa('user-home')}`}>
          <img
            src="/img/logo/logo-dobcon-small.png"
            alt="Logo DevOps Business Control"
            className="headerLogo"
          />
        </Link>
        <p className="mediumGray">
          v1.3
        </p>
      </div>
    </div>
  )
}

export default Header