import React, { useEffect, useState } from 'react'
import DarkSelectInput from '../forms/DarkSelectInput'
import './DetailsTable.css'

const DetailsTable = ({ details }) => {

  const [filteredDetails, setFilteredDetails] = useState(details)

  const [initiativeNames, setInitiativeNames] = useState([])
  const [selectedInitiative, setSelectedInitiative] = useState('All')
  const [toolsNames, setToolsNames] = useState([])
  const [selectedTool, setSelectedTool] = useState('All')

  const handleInitiativeFilter = (value) => {
    setSelectedInitiative(value)
    filterDetails(value, selectedTool)
  }

  const handleToolFilter = (value) => {
    setSelectedTool(value)
    filterDetails(selectedInitiative, value);
  }

  const filterDetails = (initiative, tool) => {
    let filteredData = details;

    // Apply initiative filter
    if (initiative !== 'All') {
      filteredData = filteredData.filter(obj => obj.initiative === initiative);
    }

    // Apply tool filter
    if (tool !== 'All') {
      filteredData = filteredData.filter(obj => obj.tool === tool);
    }

    // Update filteredDetails state
    setFilteredDetails(filteredData);
  };

  const handleFilters = () => {
    let initiatives = new Set()
    let tools = new Set()

    details.forEach(obj => {
      initiatives.add(obj.initiative)
      tools.add(obj.tool)
    })

    tools.add('All')
    initiatives.add('All')

    setInitiativeNames(Array.from(initiatives))
    setToolsNames(Array.from(tools))
  }

  useState(() => {
    handleFilters() 
  }, [details])

  return (
    <>
      <div className="detailsFilters">
        <div className="col3">
          <label>Filter by Initiative:</label>
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={initiativeNames || []}
            value={selectedInitiative}
            onChange={handleInitiativeFilter}
          />
        </div>
        <div className="col3">
          <label>Filter by Tool:</label>
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={toolsNames}
            value={selectedTool}
            onChange={handleToolFilter}
          />  
        </div>
      </div>

      <div className="detailsTable">
        <table>
          <thead className="detailsTableHead">
            <tr>
              <th>Event ID</th>
              <th>Initiative</th>
              <th>Tool</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {filteredDetails &&
              filteredDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.event_id}</td>
                  <td>{detail.initiative}</td>
                  <td>{detail.tool}</td>
                  <td>{detail.detail}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default DetailsTable