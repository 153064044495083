import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import DarkSelectInput from '../../../components/forms/DarkSelectInput';
import '../../../components/tables/GraphTable.css';
import './GraphStyles.css';

const SoftwareFailures = ({ dataset }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Last Month");
  const [selectedInitiative, setSelectedInitiative] = useState('All');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'ascending' });

  const options = ['Today', 'Last Week', 'Last Two Weeks', 'Last Month'];

  // Extract unique initiatives
  const uniqueInitiatives = [...new Set(dataset.softwareFailures.map(item => item.initiative))];
  uniqueInitiatives.unshift("All");

  // Function to generate a pastel color and map to failure types
  const colorMap = new Map();
  const getColor = (id) => {
    if (!colorMap.has(id)) {
      const hue = Math.floor(Math.random() * 360);
      const pastel = `hsl(${hue}, 100%, 80%)`;
      colorMap.set(id, pastel);
    }
    return colorMap.get(id);
  };

  const formatDateString = (dateStr) => {
    const [year, month, day] = dateStr.split('-');
    return `${month}-${day}-${year.slice(-2)}`;
  };

  useEffect(() => {
    if (dataset && dataset.softwareFailures) {
      let filteredFailures = selectedInitiative !== 'All' 
        ? dataset.softwareFailures.filter(item => item.initiative === selectedInitiative) 
        : dataset.softwareFailures;

      const failuresPerDay = {};
      const failureIds = new Set();

      filteredFailures.forEach(({ timestamp, failures, count }) => {
        if (!failuresPerDay[timestamp]) failuresPerDay[timestamp] = {};
        if (!failuresPerDay[timestamp][failures]) failuresPerDay[timestamp][failures] = 0;
        failuresPerDay[timestamp][failures] += count;
        failureIds.add(failures);
      });

      const sortedDates = Object.keys(failuresPerDay).sort((a, b) => new Date(a) - new Date(b));
      const formattedDates = sortedDates.map(formatDateString);
      const datasets = Array.from(failureIds).map((id) => {
        const backgroundColor = getColor(id);
        const borderColor = backgroundColor;
        const data = sortedDates.map(date => failuresPerDay[date][id] || 0);

        return {
          label: id,
          backgroundColor,
          borderColor,
          data,
          fill: false,
          tension: 0.4,
        };
      });

      setChartData({
        labels: formattedDates,
        datasets,
      });

      let initialTableData = sortedDates.map(date => ({
        date: formatDateString(date),
        totalFailures: Object.values(failuresPerDay[date]).reduce((acc, curr) => acc + curr, 0),
        initiative: filteredFailures.find(item => item.timestamp === date)?.initiative || 'Unknown',
      }));

      initialTableData = sortTableData(initialTableData, sortConfig);

      setFilteredData(initialTableData);
      setTableData(initialTableData);
    }
  }, [dataset, selectedInitiative, sortConfig]);

  const handleTimeframeChange = (value) => {
    setSelectedOption(value);
    let filtered = [];

    const today = new Date();
    const filterDate = (days) => {
      const filteredDate = new Date(today);
      filteredDate.setDate(today.getDate() - days);
      return filteredDate;
    };

    switch (value) {
      case 'Today':
        filtered = filteredData.filter(item => new Date(item.date) >= filterDate(1));
        break;
      case 'Last Week':
        filtered = filteredData.filter(item => new Date(item.date) >= filterDate(7));
        break;
      case 'Last Two Weeks':
        filtered = filteredData.filter(item => new Date(item.date) >= filterDate(14));
        break;
      case 'Last Month':
        filtered = filteredData.filter(item => new Date(item.date) >= filterDate(30));
        break;
      default:
        filtered = filteredData;
        break;
    }

    const newLabels = filtered.map(item => item.date);
    const newDatasets = chartData.datasets.map(dataset => ({
      ...dataset,
      data: newLabels.map(label => {
        const index = filteredData.findIndex(item => item.date === label);
        return index !== -1 ? dataset.data[index] : 0;
      }),
    }));

    setChartData({
      labels: newLabels,
      datasets: newDatasets,
    });
    setTableData(filtered);
  };

  const handleInitiativeChange = (value) => {
    setSelectedInitiative(value);
  };

  const sortTableData = (data, config) => {
    return data.sort((a, b) => {
      if (a[config.key] < b[config.key]) {
        return config.direction === 'ascending' ? -1 : 1;
      }
      if (a[config.key] > b[config.key]) {
        return config.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortArrow = (column) => {
    if (sortConfig.key !== column) return ' ↑↓';
    return sortConfig.direction === 'ascending' ? ' ↑' : ' ↓';
  };

  const optionsChart = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: 'white'
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        titleFont: {
          size: 14, 
        },
        bodyFont: {
          size: 14, 
        },
      },
      datalabels: {
        display: true,
        align: 'top',
        formatter: (value, context) => {
          return value > 0 ? value : '';
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
          color: 'white',
          font: {
            size: 14
          }
        },
        ticks: {
          autoSkip: false,
          color: 'white',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Failures',
          color: 'white',
          font: {
            size: 14
          }
        },
        ticks: {
          stepSize: 1,
          color: 'white',
        },
      },
    },
  };

  const chartContainerStyle = {
    maxWidth: '100%',
    height: '380px',
    overflowX: 'auto',
  };

  const chartStyle = {
    minWidth: `${filteredData.length * 60}px`,
    height: '100%'
  }

  return (
    <div>
      <div style={chartContainerStyle}>
        <div style={chartStyle}>
          <Line data={chartData} options={optionsChart} className="chartModal" />
        </div>
      </div>
      <div className="graphModalFooter">
        <div className="graphFilterLabel fw500">Filter by Timeframe:</div>
        <div className="col3">
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={options}
            value={selectedOption}
            onChange={handleTimeframeChange}
          />
        </div>
      </div>
      <div className="graphModalFooter">
        <div className="graphFilterLabel fw500">Filter by Initiative:</div>
        <div className="col3">
          <DarkSelectInput
            className="w100 m0 mt0 tal"
            options={uniqueInitiatives}
            value={selectedInitiative}
            onChange={handleInitiativeChange}
          />
        </div>
      </div>
      {tableData.length > 0 && (
        <div>
          <br />
          <h3>Number of Software-Related Failures in Production <i className="fa-solid fa-arrow-down"></i></h3>
          <br />
          <div className="graphTable">
            <div className="graphTableBody">
              <table>
                <thead className="graphTableHead">
                  <tr>
                    <th onClick={() => handleSort('date')}>Date {getSortArrow('date')}</th>
                    <th onClick={() => handleSort('totalFailures')}>Total Failures {getSortArrow('totalFailures')}</th>
                    <th onClick={() => handleSort('initiative')}>Initiative {getSortArrow('initiative')}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, index) => (
                    <tr key={index}>
                      <td>{row.date}</td>
                      <td>{row.totalFailures}</td>
                      <td>{row.initiative}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
              
          </div>
        </div>
      )}
    </div>
  );
};

export default SoftwareFailures;
