import React, { useState } from 'react'
import './BasicInput.css'

const BasicInput = ({
  label,
  type,
  value,
  onChange,
  placeholder,
  className,
  onKeyDown,
  onKeyUp,
  ...rest
}) => {

  /* Funtion if type is password to show and hide the characters */
  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev)
  }

  /* Function if type is onlyNumbers to allow only numbers */
  const handleKeyPress = (e) => {
    if (type === 'onlyNumbers' && e.key !== "Enter") {
      if (!/\d/.test(e.key)) {
        e.preventDefault()
      }
    }
  }

  const renderInput = () => {
    if (type === 'password') {
      return (
        <div className="passInput">
          <input
            type={showPassword ? 'text' : 'password'}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            onKeyDown={onKeyDown} {...rest}
            onKeyUp={onKeyUp}
          />
          <div
            type="button"
            onClick={handleTogglePassword}
            className="showPassword"
          >
            {showPassword ? <i className="fa-solid fa-eye-slash" /> : <i className="fa-solid fa-eye" />}
          </div>
        </div>
      )
    } else {
      return (
        <input
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          onKeyPress={handleKeyPress}
          onKeyDown={onKeyDown} {...rest}
        />
      )
    }
  }

  return (
    <div className={`basicInput ${className}`}>
      {label && <label className="smallText">{label}</label>}
      {renderInput()}
    </div>
  )
}

export default BasicInput