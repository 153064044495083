import './DetailsModal.css'
import DetailsTable from '../tables/DetailsTable'

const DetailsModal = ({ isOpen, details, onClose, detailsTitle }) => {
  if (!isOpen) return null

  return (
    <div
      className={
       `detailsModalBack flexContainer alignCenter justifyCenter 
       ${isOpen ? 'modalActive' : 'modalNoActive'}`
      }
    >
      <div className="detailsModalContent">
        <i className="fa-regular fa-circle-xmark bigText lightGray" onClick={onClose}/>
        <h2>{detailsTitle}: {details.length}</h2>
        <br/>
        <DetailsTable details={details}/>
      </div>
    </div>
  )
}

export default DetailsModal