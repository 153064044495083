import React, { useState, useEffect } from 'react'
import './ResourcesFileModal.css'
import BasicButton from '../buttons/BasicButton'
import Warning from '../alerts/Warning'
import Success from '../alerts/Success'
import CheckServAppsTable from '../tables/CheckServAppsTable'

const API = process.env.REACT_APP_SETUP_API

const ServAppsFileModal = ({ isOpen, closeModal, existentServapps }) => {
  const [existingServAppNames, setExistingServAppNames] = useState(existentServapps)
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Dragging state and errors */
  const [dragging, setDragging] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  /* Check resources hooks */
  const [repeatedServApps, setRepeatedServApps] = useState([])
  const [newServApps, setNewServApps] = useState({})
  const [checkServApps, setCheckServApps] = useState(false)
  /* Resources uploaded */
  const [servAppsUploaded, setServAppsUploaded] = useState(false)
  /* Counter to close resources modal */
  const [counter, setCounter] = useState(5)

  /* Upload with drag and drop */
  const handleDrop = async (e) => {
    e.preventDefault()
    setDragging(false)

    const file = e.dataTransfer.files[0]
    const reader = new FileReader()

    /* Validation for type files */
    const allowedExtensions = ['xls', 'xlsm', 'csv', 'xlsx']
    const fileExtension = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)

    if (allowedExtensions.includes(fileExtension.toLowerCase())) {
      reader.readAsDataURL(file)
      /* Fetch */
      
      const formData = new FormData()
      formData.append('servapps-file', file)
      const filePreviewResponse = await fetch(`${API}/servapps/${currentCompany}/file-preview`, {
        method: 'POST',
        body: formData
      })
      const data = await filePreviewResponse.json()
      if (filePreviewResponse.status === 200){
        setCheckServApps(true)
        setRepeatedServApps(data['repeated'])
        setNewServApps(data['new'])
        updateExistingServAppNames(data['new'])
        setError('')
        setSuccess('Your service/applications file has been updated')
      } else {
        console.error(data)
      }
    } else {
      setSuccess('')
      setError('Please upload only a XLS, XLSM, CSV or XLSX file')
    }
  }

  const updateExistingServAppNames = (data) => {
    let updatedNames = existentServapps

    data.forEach(item => {
      updatedNames.push(item.name)
    })
    console.log(updatedNames)
    setExistingServAppNames(updatedNames)

  }

  const handleDragOver = (e) => {
    e.preventDefault()
    if (!dragging) {
      setDragging(true)
    }
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setDragging(false)
  }

  const setServAppsFile = (e, file) => {
    setSuccess('Your service/applciations file has been updated')
    handleDrop({
      preventDefault: () => { },
      dataTransfer: {
        files: [file || e.target.files[0]],
      },
    })
  }


  const handleEditRepeatedServApp = (editedServApp) => {
    setRepeatedServApps(repeatedServApps.filter((servapp, i) => i !== editedServApp.index))
    setNewServApps([...newServApps, {name:editedServApp.name, description: editedServApp.description}])
    setExistingServAppNames([...existingServAppNames, editedServApp.name])
  }

  /* Edit new resources */
  const handleEditNewServApp = (editedServApp) => {
    setNewServApps((prev) => {
      const updatedList = prev.map((servapp, i) => {
        // Substitute older servapp with updated one
        if (i === editedServApp.index) {
          return editedServApp
        }
        return servapp
      })

      return updatedList
    })

    
  }

  useEffect(() => {
    console.log(existingServAppNames)
  }, [existingServAppNames])


  // Delete new servapp from check table
  const handleDeleteNewServApp = (name) => {
    const updatedNewServApps = newServApps.filter(
      (servapp) => servapp.name !== name
    )
    setNewServApps(updatedNewServApps)

    const updatedNames = existingServAppNames.filter(
      (servappName) => servappName !== name
    )
    setExistingServAppNames(updatedNames)
  }
  // Delete repeated servapp from check table
  const handleDeleteRepeatedServApp = (name) => {
    const updatedRepeatedServApps = repeatedServApps.filter(
      (servapp) => servapp.name !== name
    )
    setRepeatedServApps(updatedRepeatedServApps)
  }

  /* Upload file */
  const uploadServAppsFile = async () => {
    if(repeatedServApps.length !== 0){
      setError("Some service/applications are repeated in file")
    } else {
      const response = await fetch(`${API}/servapps/${currentCompany}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          'servapps': newServApps
        })
      })
      if (response.status === 500) {
        setError("Something went wrong")
      } else if (response.status === 200) {
        setServAppsUploaded(true)
        setCheckServApps(false)
      }
    }
  }

  /* Cancel upload resources file */
  const cancelUpload = () => {
    closeModal()
    setCheckServApps(false)
    setServAppsUploaded(false)
    setError(null)
    setSuccess(null)
    setCounter(5)
  }

  /* Interval to close modal after five seconds */
  useEffect(() => {
    let interval

    if (servAppsUploaded) {
      interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1)
      }, 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [servAppsUploaded])

  useEffect(() => {
    if (counter === 0) {
      cancelUpload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter])


  /* Open modal */
  if (!isOpen) return null

  return (
    <div
      className={
        `resourcesFileModalBack flexContainer alignCenter justifyCenter 
       ${isOpen ? 'modalActive' : 'modalNoActive'}`
      }
    >
      <div className="resourcesFileModalContent flexContainer spaceBetween">
        <div
          className="
            pr1
            grayLineRight noGrayLineRightMobile
            col3
            mb2
            fullTablet flexTablet flexWrap
            fullLandscape flexLandscape spaceBetween
          "
        >
          <div className="resourcesFileStep resourcesFileStepActive">
            <span><i className="fa-solid fa-cloud-arrow-up" /></span>
            <p>Upload Your File</p>
          </div>
          <div
            className={
              `resourcesFileStep ${checkServApps || servAppsUploaded ? "resourcesFileStepActive" : ""}`
            }
          >
            <span><i className="fa-solid fa-user-group" /></span>
            <p>Preview SVC/APP</p>
          </div>
          <div className={`resourcesFileStep ${servAppsUploaded ? "resourcesFileStepActive" : ""}`}>
            <span><i className="fa-solid fa-circle-check" /></span>
            <p>Upload SVC/APP</p>
          </div>
          <BasicButton
            color="btnBlack"
            size="btnFull"
            onClick={cancelUpload}
          >
            {servAppsUploaded ? "Close" : "Cancel"}
          </BasicButton>
        </div>
        {/* Step one */}
        {checkServApps || servAppsUploaded ? null :
          <div className="col9">
            <h2 className="mb1">Upload Your File Here</h2>
            <p className="regularText fw500 mb1">
              Please upload a single XLS, XLSM, or CSV file containing your company's resources. You can download the sample file below to see the structure that your file should have.
            </p>
            {error && <div className="mb1 tac"><Warning message={error} /></div>}
            {success && <div className="mb1 tac"><Success message={success} /></div>}
            <div
              className={`dragAndDropBox basicBorder mb1 ${dragging ? 'dragging' : ''}`}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
            >
              <p className="bigText mt1">
                <i className="fa-solid fa-cloud-arrow-up lightGray" />{' '}
                Drag and Drop Your File Here
                <br/>
                <small className="lightGray">(Only XLS, XLSM & CSV)</small>
              </p>
              <p className="mtHalf mb1">or</p>
              <input
                id="browseFile"
                method="GET"
                type="file"
                accept=".xls, .xlsm, .csv, .xlsx"
                className="hide"
                formEncType="multipart/form-data"
                name="resourcesFile"
                onChange={(e) => setServAppsFile(e)}
              />
              <label
                htmlFor="browseFile"
                className="btn btnRed btnSmall cursorPointer"
              >
                Browse file <i className="fas fa-search opacity5" />
              </label>
            </div>
            <a
              href="https://dobcon-files.s3.us-east-2.amazonaws.com/servappsSample.csv"
              className="btnGhost btnFull btn"
            >
              Download Sample File <i className="fa-solid fa-download" />
            </a>
          </div>
        }

        {/* Step two */}
        {!checkServApps ? null :
          <div className="col9">
            <h2 className="mb1">Check your services/applications file</h2>
            {(repeatedServApps.length > 0) &&
              <p className="regularText fw500 mb1">
                <strong>Repeated services/applications: {repeatedServApps.length}</strong><br />
                Please edit or delete the repeated services or applications.
              </p>
            }
            {repeatedServApps.length === 0 &&
              <p className="regularText fw500 mb1">
                No repeated services or applications detected. Please check data before upload it.
              </p>
            }
            {error && <div className="mt1 mb2"><Warning message={error} /></div>}
            <CheckServAppsTable
              existingNames={existingServAppNames}
              repeated={repeatedServApps}
              servapps={newServApps}
              handleEditNewServApp={handleEditNewServApp}
              handleEditRepeatedServApps={handleEditRepeatedServApp}
              handleDeleteNewServApp={handleDeleteNewServApp}
              handleDeleteRepeatedServApps={handleDeleteRepeatedServApp}
            />
            <BasicButton
              color="btnRed"
              size="btnFull"
              onClick={uploadServAppsFile}
            >
              Upload resources
            </BasicButton>
          </div>
        }


        {/* Step three */}
        {servAppsUploaded &&
          <div className="col9 tac flexContainer alignCenter justifyCenter">
            <div className="col10">
              <i className="fa-regular fa-circle-check green bigTitle" />
              <p className="smallTitle mt1 mb1">
                Your services/applications file was uploaded successfully. Please close this window or wait for {counter} seconds, and it will close automatically.
              </p>
              <BasicButton
                color="btnRed"
                size="btnFull"
                onClick={cancelUpload}
              >
                Close window
              </BasicButton>
            </div>
          </div>
        }

      </div>
    </div>
  )
}

export default ServAppsFileModal