import FP from '@fingerprintjs/fingerprintjs'
import fingerprint2 from 'fingerprintjs2'
import getBrowserName from './BrowserName'
import { attributes1, attributes2 } from './BrowserAttributes'
import getDeviceType from './DeviceType'

const DeviceIdentifier = async () => {

  const encodeAttributes = async (attrs1, attrs2) => {
    const attributesStringFull = attrs1 + attrs2
    return fingerprint2.x64hash128(attributesStringFull)
  }

  const getBrowser = async () => {
    // Browser Name
    const browserName = getBrowserName();

    // Fingerprinting 1
    let attributesString1 = "";
    fingerprint2.get((components) => {
      for (let component of components) {
        if (attributes1.includes(component.key)) {
          if (component.key === "screenResolution" || component.key === "touchSupport") {
            attributesString1 += component.value.join("");
          } else {
            attributesString1 += component.value.toString();
          }
        }
      }
    });

    // Fingerprint 2
    let browserId, deviceName, attributesString2;
    try {
      const fpPromise = FP.load();
      const fp = await fpPromise;
      const result = await fp.get({ extendedResult: true });
      browserId = result.visitorId;
      const browserComponents2 = result.components;
      deviceName = browserComponents2.platform.value.toString();
      attributesString2 = attributes2.map(attr => browserComponents2[attr].value.toString()).join("");
    } catch (err) {
      // Handle the error if needed
    }

    return {
      deviceName,
      browserName,
      browserId,
      attributesString1,
      attributesString2,
    };
  };


  const browser = await getBrowser()
  const deviceId = await encodeAttributes(browser.attributesString1, browser.attributesString2)
  const deviceType = await getDeviceType();

  const device = {
    'deviceType': deviceType,
    'browserName': browser.browserName,
    'browserId': browser.browserId,
    'deviceId': deviceId,
    'deviceName': browser.deviceName,
    'attributes1': browser.attributesString1,
    'attributes2': browser.attributesString2
  }

  return device
}

export default DeviceIdentifier