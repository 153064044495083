import React from 'react'
import './ToolchainSlaCard.css'
import NumberInput from '../forms/NumberInput'

const ToolchainSlaCard = ({ title, value, onChange, disabled, inputName }) => {
  return (
    <div className="toolchainSlaCard">
      <p className="regularText mbHalf">{title}</p>
      <NumberInput
        inputName={inputName}
        className="numberInputDarkest"
        inputType="percentage"
        placeholder={disabled ? "Select a tool first" : "Type sla value   "}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  )
}

export default ToolchainSlaCard