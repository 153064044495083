import React, { useState } from 'react'
import './GraphCard.css'
import DarkSelectInput from '../forms/DarkSelectInput'
import BasicButton from '../buttons/BasicButton'
import GraphModal from '../modals/GraphModal'

const GraphCard = ({
  metric,
  title,
  children,
  graph,
  onOptionChange
}) => {
  /* Options for timeframe */
  const [selectedOption, setSelectedOption] = useState(
    <div style={{fontSize: '15px'}}><i className="fa-regular fa-clock "/> Timeframe</div>
  )
  const options = ['Today', 'Last Week', 'Last Two Weeks', 'Last Month']

  const handleOption = (value) => {
    setSelectedOption(value)
    let timeframe
    switch (value){
      case 'Today':
        timeframe = 'lastDay'
        break;
      case 'Last Week':
        timeframe = 'lastWeek'
        break;
      case 'Last Two Weeks':
        timeframe = 'lastTwoWeeks'
        break;
      case 'Last Month':
        timeframe = 'lastMonth'
        break;
      default:
        timeframe = 'lastMonth'
        break;
    }
    onOptionChange(timeframe, metric)
  }

  /* Modal */
  const [openModal, setOpenModal] = useState(false)

  const closeOpenModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <div className="graphCard">
        <h2 className="graphCardHead regularText fw500">{title}</h2>
        <div className="graphCardBody">
          {children}
        </div>
        <div className="graphCardFooter">
          <div className="col6 relative">
            <DarkSelectInput
              className="darkSelectInputMini w100"
              options={options}
              value={selectedOption}
              onChange={handleOption}
            />
          </div>
          <div className="col6">
            <BasicButton
              onClick={() => setOpenModal(true)}
              color="btnRed"
              size="showGraph"
            >
              Show graph
            </BasicButton>
          </div>
        </div>
      </div>
      <GraphModal
        graphTitle={title}
        isOpen={openModal}
        onClose={closeOpenModal}
        graph={graph}
      />
    </>
  )
}

export default GraphCard