import './Settings.css'
import BasicButton from '../../components/buttons/BasicButton'
import LicensesTable from '../../components/tables/LicensesTable'
import CurrentDate from '../../components/resources/CurrentDate'

const AssignLicenses = () => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  const formattedCompany = currentCompany.replace(/_/g, ' ')
  /* User info */
  const userInfo = JSON.parse(localStorage.getItem('currentUser'))

  return (
    <div className="containerToRight mt1">
      
      <BasicButton
        as="link"
        to="settings-home"
        color="btnBlack"
        size="btnSmall"
      >
        <i className="fa-solid fa-arrow-left" />
        {""} Back
      </BasicButton>
      <div className="tar">
        <CurrentDate />
      </div>
      <br/>
      <div className="flexContainer spaceBetween">
        <div className="col2nHalf mb1 fullTablet fullLandscape fullMobile">
          
          <div className="userInfoCard">
            <div className="userInfoCardHead">
              <h1 className="bigText">About you</h1>
            </div>
            <div className="userInfoCardBody">
              <p className="mediumText mb2">
                <small className="lightGray">Company Name</small><br />
                {formattedCompany}
                <br /><br />
                <small className="lightGray">Username</small><br />
                {
                  userInfo.preferred_username === undefined
                    || userInfo.preferred_username === userInfo.email
                    ? userInfo.name
                    : userInfo.preferred_username
                }
              </p>
            </div>
          </div>
        </div>
        <div className="col9nHalf">
          <LicensesTable />
        </div>
      </div>
    </div>
  )
}

export default AssignLicenses