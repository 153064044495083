import React, { useState, useEffect, useRef } from 'react'
import './BusinessInitiativeFilter.css'

const BusinessInitiativeFilter = ({ options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const filterRef = useRef(null)

  const handleSelect = (selectedValue) => {
    onChange(selectedValue)
    setIsOpen(false)
  }

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="initiativeFilter" ref={filterRef}>
      <div className="initiativeFilterContainer" onClick={handleToggle}>
        <div className="initiativeFilterSelected">
          <div>
            <img
              src="/img/icons/filter-icon.png"
              alt="Filter Icon"
              className="initiativeFilterIcon"
            />
            <span className="fw500">Filter</span>
          </div>
          <i className={`fa-solid fa-angle-down ${isOpen ? 'open' : ''}`} />
        </div>
        {isOpen && (
          <ul className="initiativeFilterOptions">
            {options.map((option) => (
              <li
                key={option}
                className="initiativeFilterOption fw500"
                onClick={() => handleSelect(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default BusinessInitiativeFilter
