import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BasicButton from '../../../components/buttons/BasicButton'
import CurrentDate from '../../../components/resources/CurrentDate'
import BusinessNavFilter from '../../../components/nav/BusinessNavFilter'
import InitiativesInfo from './InitiativesInfo'

const API = process.env.REACT_APP_SETUP_API

const InitiativeMetrics = () => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Initiative Params */
  let { initiativeName } = useParams()
  /* Navigation */
  const links = ['Costs', 'Resources']
  const activeSection = 'Initiative Metrics'
  /* Get metrics */
  const [initiativeMetrics, setInitiativeMetrics] = useState()

  const fetchInitiativeMetrics = async () => {
    try {
      const response = await fetch(
        `${API}/initiatives/${currentCompany}/${window.atob(initiativeName)}/metrics`,
        {
          method: 'GET',
        }
      )
      const data = await response.json()
      setInitiativeMetrics(data)
    } catch (error) {
      console.error(error)
    }
  }

  const renderMetricItem = (label, value) => (
    <li>
      <span>{label}</span>
      <span>${value !== undefined ? value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}</span>
    </li>
  );

  useEffect(() => {
    fetchInitiativeMetrics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="containerToRight">
      <h1 className="mb1">
        <span className="lightGray">{window.atob(initiativeName)}</span> Metrics
      </h1>
      <BasicButton color="btnBlack" size="btnSmall" as="link" to="dobcon-business">
        Back to Business Dashboard <i className="fa-solid fa-rotate-left" />
      </BasicButton>
      <div className="tar mt-1">
        <CurrentDate />
        <hr className="grayLine mt1" />
      </div>
      <div className="flexContainer spaceBetween pt1 pb1">
        <div className="col2">
          <BusinessNavFilter
            links={links}
            activeSection={activeSection}
            initiativeName={initiativeName}
          />
        </div>
      </div>

      <InitiativesInfo />

      <div className="businessMetricsTable mt2">
        <div className="businessMetricsTableBody flexContainer">
          <ul className="w50 grayLineRight">
            <p className="mediumText mb1">Financial Metrics</p>
            {renderMetricItem("Initiative ROI", initiativeMetrics?.roi)}
            {renderMetricItem("Total Cost per Initiative", initiativeMetrics?.total_cost)}
            {renderMetricItem("Average Cost per Release", initiativeMetrics?.release_cost)}
            {renderMetricItem("Cost of Initiative SLAs Achievement", initiativeMetrics?.total_cost)}
            {/* <Link
              className="btn btnBlack btnSmall mt1"
              to={`/${btoa('initiative')}/${initiativeName}/${btoa('financial')}`}
            >
              Financial projections <i className="fa-solid fa-arrow-up-right-from-square" />
            </Link> */}
          </ul>
          <ul className="w50">
            <p className="mediumText mb1">Performance Metrics</p>
            {renderMetricItem("Staff Productivity", initiativeMetrics?.productivity)}
            {/* {renderMetricItem("DevOps customer acceptance rate", initiativeMetrics?.acceptance_rate)} */}
            {renderMetricItem("SLAs Breaches", initiativeMetrics?.sla_breaches)}
            {renderMetricItem("DevOps Change Management Process Efficiency", initiativeMetrics?.change_efficiency)}
            {renderMetricItem("DevOps Release Management Process Efficiency", initiativeMetrics?.release_efficiency)}
            {renderMetricItem("DevOps Practices Adoption Progress", initiativeMetrics?.adoption_progress)}
          </ul>
        </div>
      </div>
    </div>

  )
}

export default InitiativeMetrics;