import React, { useEffect, useState } from "react"
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import './Sidebar.css'

import { Auth, Hub } from 'aws-amplify'

const Sidebar = ({admin}) => {
  let navigate = useNavigate()
  /* Location */
  const location = useLocation()
  const currentPath = location.pathname

  /* Classnames to hide nav items */
  const [isDobconAdmin, setIsDobconAdmin] = useState(false)

  /* Amplify listener */
  const setAuthListener = async () => {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signOut'){
        navigate(`/${btoa('login')}`)
      }
    })  
  }

  const adminPaths = [
    `/${btoa('admin-home')}`,
    `/${btoa('admin-companies')}`,
    `/${btoa('admin-users')}`,
  ]

  useEffect(() => {
    setAuthListener()
    if (admin && adminPaths.includes(currentPath)) {
      setIsDobconAdmin(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="sidebar">
      <ul className="sidebarMenu">
        {!isDobconAdmin ?
          <>
            <li>
              <NavLink to={`/${btoa('user-home')}`} activeclassname="active">
                <img
                  loading="lazy"
                  className="sidebarIcons"
                  src={process.env.PUBLIC_URL + "/img/icons/sidebar/home-icon.png"}
                  alt="Dobcon Toolchain"
                  title="Dobcon Toolchain"
                />
                <h4>
                  Home
                </h4>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${btoa('dobcon-business')}`}>
                <img
                  loading="lazy"
                  className="sidebarIcons"
                  src={process.env.PUBLIC_URL + "/img/icons/sidebar/business-icon.png"}
                  alt="Dobcon Business"
                  title="Dobcon Business"
                />
                <h4>
                  DOBCO<span className="bigN">n</span> Business
                </h4>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${btoa('dobcon-toolchain')}`}>
                <img
                  loading="lazy"
                  className="sidebarIcons"
                  src={process.env.PUBLIC_URL + "/img/icons/sidebar/technical-icon.png"}
                  alt="Dobcon Toolchain"
                  title="Dobcon Toolchain"
                />
                <h4>
                  DOBCO<span className="bigN">n</span> Toolchain
                </h4>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${btoa('dobcon-adoption')}`}>
                <img
                  loading="lazy"
                  className="sidebarIcons"
                  src={process.env.PUBLIC_URL + "/img/icons/sidebar/adoption-icon.png"}
                  alt="Dobcon Adoption"
                  title="Dobcon Adoption"
                />
                <h4>
                  DOBCO<span className="bigN">n</span> Adoption
                </h4>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${btoa('settings-home')}`}>
                <img
                  loading="lazy"
                  className="sidebarIcons"
                  src={process.env.PUBLIC_URL + "/img/icons/sidebar/settings-icon.png"}
                  alt="Settings"
                  title="Settings"
                />
                <h4>Settings</h4>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${btoa('change-password')}`}>
                <img
                  loading="lazy"
                  className="sidebarIcons"
                  src={process.env.PUBLIC_URL + "/img/icons/sidebar/password-icon.png"}
                  alt="Change Password"
                  title="Change Password"
                />
                <h4>Change Password</h4>
              </NavLink>
            </li>
            <li>
              <button onClick={() => Auth.signOut()} className="sidebarbtn">
                <img
                  loading="lazy"
                  className="sidebarIcons"
                  src={process.env.PUBLIC_URL + "/img/icons/sidebar/exit-icon.png"}
                  alt="Log Out"
                  title="Log Out"
                />
                <h4>Log Out</h4>
              </button>
            </li>
          </>
          :
          <>
            <li>
              <NavLink to={`/${btoa('admin-home')}`}>
                <img
                  loading="lazy"
                  className="sidebarIcons"
                  src={process.env.PUBLIC_URL + "/img/icons/sidebar/home-icon.png"}
                  alt="Dobcon Toolchain"
                  title="Dobcon Toolchain"
                />
                <h4>
                  Home
                </h4>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${btoa('change-password')}`}>
                <img
                  loading="lazy"
                  className="sidebarIcons"
                  src={process.env.PUBLIC_URL + "/img/icons/sidebar/password-icon.png"}
                  alt="Change Password"
                  title="Change Password"
                />
                <h4>Change Password</h4>
              </NavLink>
            </li>
            <li>
              <button onClick={() => Auth.signOut()} className="sidebarbtn">
                <img
                  loading="lazy"
                  className="sidebarIcons"
                  src={process.env.PUBLIC_URL + "/img/icons/sidebar/exit-icon.png"}
                  alt="Log Out"
                  title="Log Out"
                />
                <h4>Log Out</h4>
              </button>
            </li>
          </>
        }
      </ul>
    </div>
  )
}

export default Sidebar