import React, { useState } from 'react';
import GraphCard from "../../components/cards/GraphCard"
import DeploymentToProduction from "../../pages/toolchain/graphs/DeploymentToProduction"
import DeploymentsToProductionStackedBars from "../../pages/toolchain/graphs/DeploymentsToProductionStackedBars"
import TotalProductionByDeveloper from "../../pages/toolchain/graphs/TotalProductionByDeveloper"
import ReleaseAverageLeadTime from "../../pages/toolchain/graphs/ReleaseAverageLeadTime"
import MeanTimeToRecovery from "../../pages/toolchain/graphs/MeanTimeToRecovery"
import NumberEarlyDefects from "../../pages/toolchain/graphs/NumberEarlyDefects"
import './AdminChartPreviewStyles.css'
import generateMockData from '../../test_metric_data/generateMockData';
import generateMockReleaseAvLeadTime from '../../test_metric_data/generateMockReleaseAvLeadTime';
import generateMockTotalProductionByDeveloper from '../../test_metric_data/generateMockTotalProductionByDeveloper';
import generateMockMTTR from '../../test_metric_data/generateMockMTTR';
import generateMockEarlyDefects from '../../test_metric_data/generateMockEarlyDefects';


const AdminChartPreview = () => {
    // State for managing the uploaded data
    const mockTotalProductionByDeveloper = generateMockTotalProductionByDeveloper();
    const mockDtpData = generateMockData();
    const mockGenerateMockReleaseAvLeadTime = generateMockReleaseAvLeadTime();
    const mockGenerateMockMTTR = generateMockMTTR()
    const mockGenerateMockEarlyDefects = generateMockEarlyDefects()

    const [uploadedMTTR, setUploadedMTTR] = useState(mockGenerateMockMTTR);
    const [uploadedDtpData, setUploadedDtpData] = useState(mockDtpData);
    const [uploadeddtotalProductionByDeveloper, setUploadedtotalProductionByDeveloper] = useState(mockTotalProductionByDeveloper);
    const [uploadedReleaseAvgLeadTimeData, setUploadedReleaseAvgLeadTimeData] = useState(mockGenerateMockReleaseAvLeadTime);
    
    // Combined download function for different test mocks
    const downloadJson = (data, filename) => {
        const blob = new Blob([JSON.stringify(data, null, 2)], {type: 'application/json'});
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Simplified handle file upload to update relevant state based on file content
    const handleFileUpload = (event, updateStateFunction) => {
        const fileReader = new FileReader();
        fileReader.readAsText(event.target.files[0], "UTF-8");
        fileReader.onload = e => {
            try {
                const jsonData = JSON.parse(e.target.result);
                console.log(jsonData);
                updateStateFunction(jsonData); 
            } catch (error) {
                console.error("Error reading or parsing the file: ", error);
            }
        };
    };

    return (
        <div className="containerToRightCharts">
            <h1 className="mb1">Dobcon charts catalog preview</h1>
            <h2 className="mb1">Dobcon chart overview preview</h2>
            <div className="flexContainer spaceAround">
                <div className="col3 mt2">
                    <GraphCard
                        title="Release Average Lead Time (From First Commit)"
                        graph="graph"
                    >
                        <p className="mediumText">1 hh 7 mm</p>
                    </GraphCard>
                </div>
                <div className="col3 mt2">
                    <GraphCard
                        title="Deployments to Production"
                        graph="chart"
                    >
                        <p className="mediumText">
                            Successful: {123}
                            <br />
                            Failed: {123}
                        </p>
                    </GraphCard>
                </div>
                <div className="col3 mt2">
                    <GraphCard
                        title="Mean Time to Recovery (MTTR)"
                        graph="graph"
                    >
                        <p className="mediumText">2 hh 56 mm</p>
                    </GraphCard>
                </div>
                <div className="col3 mt2">
                    <GraphCard
                        title="Total Production by Developer"
                        graph="graph"
                    >
                        <p className="mediumText">1,230 Commits</p>
                    </GraphCard>
                </div>
                <div className="col3 mt2">
                    <GraphCard
                        title="Number of Early Defects"
                        graph="graph"
                    >
                        <p className="mediumText">
                            Detected: 111
                            <br />
                            Solved: 222
                        </p>
                    </GraphCard>
                </div>
                <div className="col3 mt2">
                    <GraphCard
                        title="Number of Software-Related Failures in Production"
                        graph="graph"
                    >
                        <p className="mediumText">
                            Detected: 4
                            <br />
                            Solved: 3
                        </p>
                    </GraphCard>
                </div>
                <div className="col3 mt2">
                    <GraphCard
                        title="Change Failure Rate"
                        graph="graph"
                    >
                        <p className="mediumText">Graph</p>
                    </GraphCard>
                </div>
            </div>
            {/* here charts  */}
            <h2 className="mt1 mb1">Dobcon chart graph preview</h2>
            <div className="flexContainer spaceAround chartSpace">
                <div className="chart-card">
                    <h2 className="chart-title">Release Average Lead Time (From First Commit)</h2>
                    <div className="chart-container">
                        <ReleaseAverageLeadTime dataset={uploadedReleaseAvgLeadTimeData} />
                    </div>
                    <p className="chart-text">Release Average Lead Time (From First Commit), the X is the contributions list that could be PRs for initiative and Y is the number the days until finished</p>
                    <br />
                    <button
                        className="download-button"
                        onClick={() => downloadJson(uploadedReleaseAvgLeadTimeData, 'mockkReleaseAvgLeadTime.json')}
                    >
                        Download Data Template
                    </button>
                    <label htmlFor="file-upload-a" className="upload-label">
                        Upload Data File
                    </label>
                    <input
                        id="file-upload-a"
                        className="upload-input"
                        type="file"
                        onChange={(e) => handleFileUpload(e, setUploadedReleaseAvgLeadTimeData)}
                        accept=".json"
                    />
                </div>
                <div className="chart-card">
                    <h2 className="chart-title">Deployments to Production Chart</h2>
                    <div className="chart-container">
                        <DeploymentToProduction dtp={uploadedDtpData} />
                    </div>
                    <p className="chart-text">This graph displays the deployments to production ...</p>
                    <br />
                    <button
                        className="download-button"
                        onClick={() => downloadJson(uploadedDtpData, 'mockDtpData.json')}
                    >
                        Download Data Template
                    </button>
                    <label htmlFor="file-upload" className="upload-label">
                        Upload Data File
                    </label>
                    <input
                        id="file-upload"
                        className="upload-input"
                        type="file"
                        onChange={(e) => handleFileUpload(e, setUploadedDtpData)}
                        accept=".json"
                    />
                </div>

                <div className="chart-card">
                    <h2 className="chart-title">Deployments to Production Chart Stacked bars</h2>
                    <div className="chart-container">
                        <DeploymentsToProductionStackedBars dtp={uploadedDtpData} />
                    </div>
                    <p className="chart-text">This graph displays the deployments to production with an stacked bar...</p>
                    <br />
                    <button
                        className="download-button"
                        onClick={() => downloadJson(uploadedDtpData, 'mockDtpData.json')}
                    >
                        Download Data Template
                    </button>
                    <label htmlFor="file-upload" className="upload-label">
                        Upload Data File
                    </label>
                    <input
                        id="file-upload"
                        className="upload-input"
                        type="file"
                        onChange={(e) => handleFileUpload(e, setUploadedDtpData)}
                        accept=".json"
                    />
                </div>
                <div className="chart-card">
                    <h2 className="chart-title">Deployments to Production Chart</h2>
                    <div className="chart-container">
                        <TotalProductionByDeveloper data={uploadeddtotalProductionByDeveloper} />
                    </div>
                    <p className="chart-text">This graph displays the total production developer ...</p>
                    <br />
                    <button
                        className="download-button"
                        onClick={() => downloadJson(uploadeddtotalProductionByDeveloper, 'mockTotalProductionByDeveloper.json')}
                    >
                        Download Data Template
                    </button>
                    <label htmlFor="file-upload" className="upload-label">
                        Upload Data File
                    </label>
                    <input
                        id="file-upload"
                        className="upload-input"
                        type="file"
                        onChange={(e) => handleFileUpload(e, setUploadedtotalProductionByDeveloper)}
                        accept=".json"
                    />
                </div>
                <div className="chart-card">
                    <h2 className="chart-title">Mean Time to Recovery (MTTR)</h2>
                    <div className="chart-container">
                        <MeanTimeToRecovery data={uploadedMTTR} />
                    </div>
                    <p className="chart-text">This graph displays the MTTR ...</p>
                    <br />
                    <button
                        className="download-button"
                        onClick={() => downloadJson(uploadedMTTR, 'mockMTTR.json')}
                    >
                        Download Data Template
                    </button>
                    <label htmlFor="file-upload" className="upload-label">
                        Upload Data File
                    </label>
                    <input
                        id="file-upload"
                        className="upload-input"
                        type="file"
                        onChange={(e) => handleFileUpload(e, setUploadedMTTR)}
                        accept=".json"
                    />
                </div>
                <div className="chart-card">
                    <h2 className="chart-title">Early defects</h2>
                    <div className="chart-container">
                        <NumberEarlyDefects data={mockGenerateMockEarlyDefects} />
                    </div>
                    <p className="chart-text">This graph displays Early defects ...</p>
                    <br />
                    <button
                        className="download-button"
                        onClick={() => downloadJson(mockGenerateMockEarlyDefects, 'mockEarlyDefects.json')}
                    >
                        Download Data Template
                    </button>
                    <label htmlFor="file-upload" className="upload-label">
                        Upload Data File
                    </label>
                    <input
                        id="file-upload"
                        className="upload-input"
                        type="file"
                        onChange={(e) => handleFileUpload(e, setUploadedMTTR)}
                        accept=".json"
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminChartPreview