import HomeCard from '../components/cards/HomeCard'
import { useInitiativesContext } from './business/resources/BusinessInitiativesContext'
import CurrentDate from '../components/resources/CurrentDate'
import { useState, useEffect } from 'react'

const UserHome = () => {

  const [userLabel, setUserLabel] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('currentUser')) || null;
    if (user) {
      setUserLabel(user?.preferred_username && user.preferred_username !== user.email ? user.preferred_username : user.name)
    }
  }, []);

  const initiatives = useInitiativesContext()

  return (
    <div className="containerToRight">
      <h1>
        Welcome, {userLabel}!
      </h1>
      <div className="tar">
        <CurrentDate />
      </div>
      <h2 className="mb1">What would you like to do today?</h2>
      <p className="mediumText fw500">Feel free to switch between views at any time</p>
      <div className="flexContainer spaceBetween mt2">
        <div className="col3 flex mb1">
          <HomeCard
            dobconOnTitle={false}
            cardTitle="Create an Initiative"
            cardIconSrc="/img/icons/cards/initiatives-icon.png"
            cardIconAlt="Dobcon initiatives"
            cardDescription="Begin your DevOps journey with strategic initiatives"
            cardLinkTo={initiatives ? "initiative-setup" : "company-setup"}
            cardSize="small"
          />
        </div>
        <div className="col3 flex mb1">
          <HomeCard
            cardTitle="Business"
            cardIconSrc="/img/icons/cards/business-icon.png"
            cardIconAlt="Dobcon Business Dashboard"
            cardDescription="Analyze your DevOps metrics and track your team performance"
            cardLinkTo="dobcon-business"
            cardSize="small"
          />
        </div>
        <div className="col3 flex mb1">
          <HomeCard
            cardTitle="Toolchain"
            cardIconSrc="/img/icons/cards/toolchain-icon.png"
            cardIconAlt="Dobcon Toolchain Dashboard"
            cardDescription="Track the efficiency of your software delivery pipeline in real time"
            cardLinkTo="dobcon-toolchain"
            cardSize="small"
          />
        </div>
        <div className="col3 flex mb1">
          <HomeCard
            cardTitle="Adoption"
            cardIconSrc="/img/icons/cards/adoption-icon.png"
            cardIconAlt="Dobcon Adoption"
            cardDescription="Explore strategic approaches to increase your DevOps adoption"
            cardLinkTo="dobcon-adoption"
            cardSize="small"
          />
        </div>
      </div>
    </div>
  )
}

export default UserHome