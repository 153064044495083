import React from 'react'
import './SwitchToogle.css'

const SwitchToggle = ({ value, onChange }) => {
  const handleToggle = () => {
    const newValue = !value
    if(onChange){
      onChange(newValue)
    }
  }

  return (
    <div className={`switchToggle ${value ? 'active' : ''}`} onClick={handleToggle}>
      <div className="switchHandle" />
    </div>
  )
}

export default SwitchToggle
