import React, { useState, useEffect } from 'react';
import './GraphStyles.css';

/* ChartJS */
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

import DarkSelectInput from '../../../components/forms/DarkSelectInput';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
);

const chartOptions = {
    responsive: true,
    layout: {
    padding: {
      right: 30, // Adjust this value to add more space on the right side
    },
    },
    plugins: {
        legend: {
            position: 'bottom',
        },
        tooltip: {
            mode: 'index',
            intersect: false,
        },
    },
    interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
            beginAtZero: true,
        },
    },
};

const sortDataByDateDescending = (dataArray) => {
    return dataArray.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
};

const NumberEarlyDefects = ({ data }) => {
    const [defectData, setDefectData] = useState([]);
    const [selectedOption, setSelectedOption] = useState('Last Month');
    const options = ['Today', 'Last Week', 'Last Two Weeks', 'Last Month'];

    useEffect(() => {
        if (data && data.All) {
            const sortedData = sortDataByDateDescending(data.All.edd_by_date);
            setDefectData(sortedData);
        }
    }, [data]);

    const filterDataBasedOnTimeframe = (timeframe) => {
        const endDate = new Date();
        let startDate = new Date();
        switch (timeframe) {
            case 'Today':
                // Keep startDate as today
                break;
            case 'Last Week':
                startDate.setDate(endDate.getDate() - 7);
                break;
            case 'Last Two Weeks':
                startDate.setDate(endDate.getDate() - 14);
                break;
            case 'Last Month':
                startDate.setMonth(endDate.getMonth() - 1);
                break;
            default:
                startDate = new Date(Math.min(...defectData.map(d => new Date(d.timestamp))));
                break;
        }
        return defectData.filter(d => {
            const date = new Date(d.timestamp);
            return date >= startDate && date <= endDate;
        });
    };

    const handleTimeframeChange = (value) => {
        setSelectedOption(value);
        const filteredData = filterDataBasedOnTimeframe(value);
        setDefectData(filteredData);
    };

    const chartData = {
        labels: defectData.map(item => item.timestamp),
        datasets: [
            {
                label: 'Detected',
                data: defectData.map(item => item.detected),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
            {
                label: 'Solved',
                data: defectData.map(item => item.solved),
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className="mb1 mt1 ml-2 mr-2">
            <Bar options={chartOptions} data={chartData} className='chartModal' />

            <div className="graphModalFooter">
                <div className="col2 fw500">Select Timeframe</div>
                <div className="col3">
                    <DarkSelectInput
                        className="w100 m0 mt0 tal"
                        options={options}
                        value={selectedOption}
                        onChange={(e) => handleTimeframeChange(e.target.value)}
                    />
                </div>
            </div>
        </div>
    );
};

export default NumberEarlyDefects;
