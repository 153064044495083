import React, { useState, useEffect } from 'react'
import './CompanyResourcesTable.css'
import BasicButton from '../buttons/BasicButton'
import BasicInput from '../forms/BasicInput'
import NextField from '../functions/NextField'
import FormModal from '../modals/FormModal'
import Warning from '../alerts/Warning'
import BasicModal from '../modals/BasicModal'
import ResourcesFileModal from '../modals/ResourcesFileModal'

const API = process.env.REACT_APP_SETUP_API

const CompanyResourcesTable = ({ handleCompanyResources }) => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Resources list */
  const [resources, setResources] = useState([])
  /* Fetch resources list */
  const getCompanyResources = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    const response = await fetch(`${API}/resources/${currentCompany}/sync`, requestOptions)
    const data = await response.json()
    if(response.status === 200){
      setResources(data)
    } else{
      console.error(data)
    }
    
    
  }

  useEffect(() => {
    getCompanyResources()
  }, [])

  /* Errors */
  const [error, setError] = useState('')

  /* Resources modal */
  const [isOpenResourcesModal, setIsOpenResourcesModal] = useState(false)
  const [selectedResource, setSelectedResource] = useState(null)

  const openResourcesModal = () => {
    setIsOpenResourcesModal(true)
  }

  const closeModal = () => {
    setAddResourceData({
      name: '',
      role: '',
      department: '',
      location: '',
      email: ''
    })
    setError('')
    setSelectedResource(null)
    setIsOpenResourcesModal(false)
  }

  /* Add new resource */
  const [addResourceData, setAddResourceData] = useState({
    name: '',
    role: '',
    department: '',
    location: '',
    email: ''
  })

  const handleAddResourceInputChange = (field, value) => {
    setAddResourceData({
      ...addResourceData,
      [field]: value,
    })
  }

  const submitAddResource = async (e) => {
    e.preventDefault()
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i

    if (!addResourceData.name) {
      setError("Please enter the resource's name")
    } else if (!addResourceData.role) {
      setError("Please enter the resource's role")
    } else if (!addResourceData.department) {
      setError("Please enter the resource's department")
    } else if (!addResourceData.location) {
      setError("Please enter the resource's location")
    } else if (!addResourceData.email) {
      setError("Please enter the resource's email")
    } else if (!regex.test(addResourceData.email)) {
      setError("Please enter a valid email")
    } else {
      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({
            new_resource: {
              name: addResourceData.name,
              role: addResourceData.role,
              department: addResourceData.department,
              location: addResourceData.location,
              email: addResourceData.email
            }
          })
        }
        const response = await fetch(`${API}/resources/${currentCompany}/manual`, requestOptions)
        const data = await response.json()
        if (response.status === 409) {
          setError("The resource's email is already in use, please choose another one")
        } else if (response.status === 500) {
          setError(data['Error'])
        } else if (response.status === 200) {
          setSelectedResource('')
          getCompanyResources()
          setAddResourceData({
            name: '',
            role: '',
            department: '',
            location: '',
            email: ''
          })
          setError('')
          setIsOpenResourcesModal(false)
          console.log(data)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  /* Edit an exists resource */
  const [selectedResourceEmail, setSelectedResourceEmail] = useState(null)
  const [editResourceData, setEditResourceData] = useState({
    name: '',
    role: '',
    department: '',
    location: '',
    email: ''
  })

  const handleEditResourceInputChange = (field, value) => {
    setEditResourceData({
      ...editResourceData,
      [field]: value,
    })
  }

  const handleEditResource = (resource) => {
    setSelectedResource(resource)
    setEditResourceData({
      name: resource.name,
      role: resource.role,
      department: resource.department,
      location: resource.location,
      email: resource.email
    })
    setSelectedResourceEmail(resource.email)
    setIsOpenResourcesModal(true)
  }

  const submitEditResource = async (e) => {
    e.preventDefault()
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i

    if (!editResourceData.name) {
      setError("Please enter the new resource's name")
    } else if (!editResourceData.role) {
      setError("Please enter the new resource's role")
    } else if (!editResourceData.department) {
      setError("Please enter the new resource's department")
    } else if (!editResourceData.location) {
      setError("Please enter the new resource's location")
    } else if (!editResourceData.email) {
      setError("Please enter the new resource's email")
    } else if (!regex.test(editResourceData.email)) {
      setError("Please enter a valid email")
    } else {
      try {
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({
            new_info: {
              name: editResourceData.name,
              role: editResourceData.role,
              department: editResourceData.department,
              location: editResourceData.location,
              email: editResourceData.email
            },
            email: selectedResourceEmail
          })
        }
        const response = await fetch(`${API}/resources/${currentCompany}/manual`, requestOptions)
        const data = await response.json()
        if (response.status === 400) {
          setError("The resource's email is already in use, please choose another one")
          console.log(data['message'])
        } else if (response.status === 500) {
          setError(data['Error'])
        } else if (response.status === 200) {
          setError('')
          setIsOpenResourcesModal(false)
          getCompanyResources()
          setSelectedResource(null)
          console.log(data)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  /* Delete an exists resource */
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)

  const closeModalDelete = () => {
    setSelectedResource(null)
    setIsOpenModalDelete(false)
  }

  const handleDeleteResource = (email) => {
    setSelectedResource(email)
    setIsOpenModalDelete(true)
  }

  const confirmDeleteResource = async () => {
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          email: selectedResource,
        }),
      }
      const response = await fetch(`${API}/resources/${currentCompany}/manual`, requestOptions)
      const data = await response.json()
      getCompanyResources()
      closeModalDelete()
      console.log(data)
    } catch (err) {
      console.error(err)
    }
  }

  /* Send resources */
  useEffect(() => {
    handleCompanyResources(resources)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources])

  /* Resources modal */
  const [resourcesFileModal, setResourcesFileModal] = useState(false)

  return (
    <>
      <div className="companyResourcesTable">
        <div className="companyResourcesTableBody">
          <table>
            <thead className="companyResourcesTableHead">
              <tr>
                <th>Full Name</th>
                <th>Role</th>
                <th>Department</th>
                <th>Location</th>
                <th className="columnSmall tac">Edit</th>
                <th className="columnSmall tac">Delete</th>
              </tr>
            </thead>
            <tbody>
              {resources.length !== 0 ?
                resources.map((resource) => (
                  <tr key={resource.id}>
                    <td>{resource.name}</td>
                    <td>{resource.role}</td>
                    <td>{resource.department}</td>
                    <td>{resource.location}</td>
                    <td className="columnSmall tac">
                      <button onClick={() => handleEditResource(resource)}>
                        <i className="fa-solid fa-pen-to-square" />
                      </button>
                    </td>
                    <td className="columnSmall tac">
                      <button onClick={() => handleDeleteResource(resource.email)}>
                        <i className="fa-solid fa-trash" />
                      </button>
                    </td>
                  </tr>
                )) : <tr><td colSpan="6">No resources found</td></tr>
              }
            </tbody>
          </table>
        </div>
        {/* Footer table */}
        <div className="companyResourcesTableFooter">
          <BasicButton color="btnRed" size="btnSmall" onClick={() => setResourcesFileModal(true)}>
            <>Update Resources File <i className="fa-solid fa-rotate opacity5" /></>
          </BasicButton>{' '}
          <BasicButton color="btnBlack" size="btnSmall" onClick={openResourcesModal}>
            Add Resources Manually <i className="fa-solid fa-user-plus opacity5" />
          </BasicButton>
        </div>
      </div>
      {/* Add or edit resource */}
      <FormModal isOpen={isOpenResourcesModal} onClose={closeModal}>
        {selectedResource ? (
          <>
            <h2 className="mb2">Edit Resource</h2>
            {error && <div className="mt-1 mb2"><Warning message={error} /></div>}
            <form>
              <div className="flexContainer spaceBetween">
                <div className="col6">
                  <BasicInput
                    label="Name*"
                    type="text"
                    value={editResourceData.name}
                    onChange={(value) => handleEditResourceInputChange('name', value)}
                    onKeyDown={NextField}
                    placeholder="New resource's name"
                  />
                </div>
                <div className="col6">
                  <BasicInput
                    label="Role*"
                    type="text"
                    value={editResourceData.role}
                    onChange={(value) => handleEditResourceInputChange('role', value)}
                    onKeyDown={NextField}
                    placeholder="New resource's role"
                  />
                </div>
                <div className="col6">
                  <BasicInput
                    label="Department*"
                    type="text"
                    value={editResourceData.department}
                    onChange={(value) => handleEditResourceInputChange('department', value)}
                    onKeyDown={NextField}
                    placeholder="New resource's department"
                  />
                </div>
                <div className="col6">
                  <BasicInput
                    label="Location*"
                    type="text"
                    value={editResourceData.location}
                    onChange={(value) => handleEditResourceInputChange('location', value)}
                    onKeyDown={NextField}
                    placeholder="New resource's location"
                  />
                </div>
              </div>
              <BasicInput
                label="Email address (Case sensitive)*"
                type="text"
                value={editResourceData.email}
                onChange={(value) => handleEditResourceInputChange('email', value)}
                placeholder="New resource's email"
              />
              <p className="lightGray tal mt-1 mb1">
                <small>*Mandatory Fields</small>
              </p>
              <BasicButton
                color="btnRed"
                size="btnFull"
                onClick={submitEditResource}
              >
                Edit resource
              </BasicButton>
              <div className="mb1" />
              <BasicButton
                color="btnBlack"
                size="btnFull"
                onClick={closeModal}
              >
                Cancel
              </BasicButton>
            </form>
          </>
        ) : (
          <>
            <h2 className="mb2">Add Resource</h2>
            {error && <div className="mt-1 mb2"><Warning message={error} /></div>}
            <form>
              <div className="flexContainer spaceBetween">
                <div className="col6">
                  <BasicInput
                    label="Name*"
                    type="text"
                    value={addResourceData.name}
                    onChange={(value) => handleAddResourceInputChange('name', value)}
                    onKeyDown={NextField}
                    placeholder="Resource's name"
                  />
                </div>
                <div className="col6">
                  <BasicInput
                    label="Role*"
                    type="text"
                    value={addResourceData.role}
                    onChange={(value) => handleAddResourceInputChange('role', value)}
                    onKeyDown={NextField}
                    placeholder="Resource's role"
                  />
                </div>
                <div className="col6">
                  <BasicInput
                    label="Department*"
                    type="text"
                    value={addResourceData.department}
                    onChange={(value) => handleAddResourceInputChange('department', value)}
                    onKeyDown={NextField}
                    placeholder="Resource's department"
                  />
                </div>
                <div className="col6">
                  <BasicInput
                    label="Location*"
                    type="text"
                    value={addResourceData.location}
                    onChange={(value) => handleAddResourceInputChange('location', value)}
                    onKeyDown={NextField}
                    placeholder="Resource's location"
                  />
                </div>
              </div>
              <BasicInput
                label="Email address (Case sensitive)*"
                type="text"
                value={addResourceData.email}
                onChange={(value) => handleAddResourceInputChange('email', value)}
                placeholder="Resource's email"
              />
              <p className="lightGray tal mt-1 mb1">
                <small>*Mandatory Fields</small>
              </p>
              <BasicButton
                color="btnRed"
                size="btnFull"
                onClick={submitAddResource}
              >
                Add resource
              </BasicButton>
              <div className="mb1" />
              <BasicButton
                color="btnBlack"
                size="btnFull"
                onClick={closeModal}
              >
                Cancel
              </BasicButton>
            </form>
          </>
        )}
      </FormModal>
      {/* Prevent resource delete modal */}
      <BasicModal isOpen={isOpenModalDelete} onClose={closeModalDelete}>
        <h2 className="mb2 tac">Are you sure that you want to delete this resource?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={confirmDeleteResource}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={closeModalDelete}
        >
          Cancel
        </BasicButton>
      </BasicModal>
      {/* Upload resources file */}
      <ResourcesFileModal
        isOpen={resourcesFileModal}
        closeModal={() => {
          setResourcesFileModal(false)
          getCompanyResources()
        }}
      />
    </>
  )
}

export default CompanyResourcesTable