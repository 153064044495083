import React, { useState, useEffect, useRef } from 'react'
import './DarkSelectInput.css'
import { Link } from 'react-router-dom'

const DarkSelectInput = ({ label, options, value, onChange, className, type, link }) => {
  const [open, setOpen] = useState(false)
  const [openUpwards, setOpenUpwards] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    if (open && containerRef.current) {
      const dropdownHeight = 150
      const { bottom } = containerRef.current.getBoundingClientRect()
      const distanceToBottom = window.innerHeight - bottom

      setOpenUpwards(distanceToBottom < dropdownHeight)
    }
  }, [open])

  const handleSelect = (selectedValue) => {
    onChange(selectedValue)
    if (!selectedValue) {
      setOpen(false)
    }
  }

  const handleToggleOpen = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className={`darkSelectInput ${className}`}>
      {label && <label className="darkSelectInputLabel">{label}</label>}
      <div
        className="darkSelectInputContainer"
        onClick={handleToggleOpen}
        ref={containerRef}
      >
        <div className="darkSelectInputSelected fw500">
          {value}&nbsp;
          <span>
          <i className={`fa-solid fa-angle-down ${open ? 'open' : ''}`} />
          </span>
        </div>
        {open && (
          <ul className={`darkSelectInputOptions fw500 ${openUpwards ? 'open-upwards' : ''}`}>
            {options.map((option) => (
              type === 'link' ?
                <li
                  key={option}
                  className="darkSelectInputOption"
                  onClick={() => handleSelect(option)}
                >
                  {option === 'all' || option === 'All' ?
                    <Link className="darkSelectInputLink fw400" to={`/${btoa(link)}`}>
                      {option}
                    </Link> :
                    <Link className="darkSelectInputLink fw400" to={`/${btoa(link)}/${btoa(option)}`}>
                      {option}
                    </Link>
                  }
                </li> :
                <li
                  key={option}
                  className="darkSelectInputOption"
                  onClick={() => handleSelect(option)}
                >
                  {option}
                </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default DarkSelectInput
