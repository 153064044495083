import './BasicModal.css'

const BasicModal = ({ isOpen, children }) => {
  if (!isOpen) return null

  return (
    <div
      className={
       `basicModalBack flexContainer alignCenter justifyCenter 
       ${isOpen ? 'modalActive' : 'modalNoActive'}`
      }
    >
      <div className="basicModalContent">
        {children}
      </div>
    </div>
  )
}

export default BasicModal