import AdminLoginForm from '../../components/forms/AdminLoginForm'

const AdminLogin = () => {
  return (
    <div className="container flexContainer spaceBetween alignCenter justifyCenter h100">
      <AdminLoginForm />
    </div>
  )
}

export default AdminLogin