import React, { useState, useEffect } from 'react'
import '../BusinessDashboard.css'
import { useParams, useLocation } from 'react-router-dom'

const API = process.env.REACT_APP_SETUP_API

const InitiativesInfo = () => {
  /* Location */
  const location = useLocation()
  const { pathname } = location
  const isResourcesPath = pathname.endsWith(`${btoa('resources')}`)
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Initiative Params */
  let { initiativeName } = useParams()
  /* Initiative info */
  const [initiativeInfo, setInitiativeInfo] = useState(() => {
    // Try to get the data from local storage at the beginning
    const storedInitiativeInfo = localStorage.getItem(`initiativeInfo_${initiativeName}`)
    return storedInitiativeInfo ? JSON.parse(storedInitiativeInfo) : null
  })

  // Get initiatives info
  const fetchInitiativeInfo = async () => {
    try {
      const requestOptions = {method:'GET',}
      const response = await fetch(`${API}/initiatives/${currentCompany}/${window.atob(initiativeName)}/metrics`, requestOptions)
      const data = await response.json()
      if(response.status === 200){
        console.log(data)
        setInitiativeInfo(data)
        localStorage.setItem(`initiativeInfo_${initiativeName}`, JSON.stringify(data))
      } else {
        console.error(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const dateFormatter = (dateString) => {
    const dateObject = new Date(dateString);

    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const formattedDate = dateObject.toLocaleDateString('en-US', options);
     
    return formattedDate
  }

  useEffect(() => {
    fetchInitiativeInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, initiativeName])

  return (
    <>
      <div className="initiativesInfoTable flexContainer spaceBetween mt1">
        <ul className="w30">
          <li className="initiativesInfoHead">Name</li>
          <li className="initiativesInfoBody">
            {initiativeInfo?.name}
          </li>
        </ul>
        <ul className="w40">
          <li className="initiativesInfoHead initiativeLast">Description</li>
          <li className="initiativesInfoBody">
            {initiativeInfo?.description}
          </li>
        </ul>
        <ul className="w30">
          <li className="initiativesInfoHead">Status</li>
          <li className="initiativesInfoBody">
            {initiativeInfo?.status}
          </li>
        </ul>
        
        
      </div>

      <div className="initiativesInfoTable flexContainer spaceBetween mt1">
        <ul className="w30">
          <li className="initiativesInfoHead">Initiative Lead</li>
          <li className="initiativesInfoBody">
            {initiativeInfo?.leader}
          </li>
        </ul>
        <ul className="w20">
          <li className="initiativesInfoHead initiativeFirst">Start Date</li>
          <li className="initiativesInfoBody">
            {initiativeInfo?.start_date && dateFormatter(initiativeInfo.start_date)}
          </li>
        </ul>
        <ul className="w20">
          <li className="initiativesInfoHead">Last Updated</li>
          <li className="initiativesInfoBody">
            {initiativeInfo?.last_updated && dateFormatter(initiativeInfo.last_updated)}
          </li>
        </ul>
        <ul className="w30">
          <li className="initiativesInfoHead initiativeLast">Delayed</li>
          <li className="initiativesInfoBody">
            {initiativeInfo?.delayed}
          </li>
        </ul>
      </div>

      {isResourcesPath ? (
        <div className="initiativesInfoTable flexContainer spaceBetween mt1">
          <ul className="w20">
            <li className="initiativesInfoHead">Staff Productivity</li>
            <li className="initiativesInfoBody">
              {initiativeInfo && initiativeInfo.productivity !==
                undefined ? initiativeInfo.productivity.toFixed(2) : "0.00"}%
            </li>
          </ul>
          {/* <ul className="w80">
            <li className="initiativesInfoHead">DevOps customer acceptance rate</li>
            <li className="initiativesInfoBody">
              {initiativeInfo && initiativeInfo.acceptance_rate !==
                undefined ? initiativeInfo.acceptance_rate.toFixed(2) : "0.00"}%
            </li>
          </ul> */}
        </div>
      ) : (
        null
      )}
    </>
  )
}

export default InitiativesInfo