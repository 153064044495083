import BasicButton from '../../components/buttons/BasicButton'
import CurrentDate from '../../components/resources/CurrentDate'
import CompaniesTable from '../../components/tables/CompaniesTable'

const CompaniesLicenses = () => {
  return (
    <div className="containerToRight">
      <BasicButton 
        as="link"
        to="admin-home"
        color="btnBlack"
        size="btnSmall"
      >
        <i className="fa-solid fa-arrow-left" />
        {""} Back
      </BasicButton>
      <div className="tar">
        <CurrentDate />
      </div>
      <br/>
      <h1>Companies Licenses</h1>
      <div className="flexContainer spaceBetween mt2">
        <div className="col12">
          <CompaniesTable />
        </div>
      </div>
    </div>
  )
}

export default CompaniesLicenses