import React, { useEffect, useState } from 'react'
import './AdminUsersTable.css'
import BasicButton from '../buttons/BasicButton'
import BasicModal from '../modals/BasicModal'
import BasicOptionsButton from '../buttons/BasicOptionsButton'
import FormModal from '../modals/FormModal'
import BasicInput from '../forms/BasicInput'
import Warning from '../alerts/Warning'
import '../forms/BasicInput.css'

const LICENSESAPI = process.env.REACT_APP_LICENCES_API

const AdminUsersTable = () => {
  const [error, setError] = useState('')

  const [selectedAdmin, setSelectedAdmin] = useState(null)

  // Add Admin
  const [isOpenAddAdminModal, setIsOpenAddAdminModal] = useState(false)
	const [userExists, setUserExists] = useState(null)
	const [foundUser, setFoundUser] = useState(null)
	const [addAdminData, setAddAdminData] = useState({
		email: '',
		name: '',
		role: '',
		department: '',
	})
  const openAddAdminModal = () => {
    setIsOpenAddAdminModal(true)
  }
  const closeAddAdminModal = () => {
		setUserExists(null)
    setIsOpenAddAdminModal(false)
    setError('')
		setAddAdminData({
			email: '',
			name: '',
			role: ''
		})
  }
	const handleAddAdminInputChange = (field, value) => {
		if(field === 'email'){
			setFoundUser(null)
			setUserExists(null)
		}
		setAddAdminData({
      ...addAdminData,
      [field]: value,
    })
		setError('')
	}
	const checkIfAlreadyAdmin = () => {
		console.log(addAdminData.email)
		const found = admins.some(admin => admin['email'] === addAdminData.email)
		console.log(found)
		return found
	}
	const handleCheckUserExist = async(e) => {
		e.preventDefault()

		const isAlreadyAdmin = checkIfAlreadyAdmin()

		if(isAlreadyAdmin){
			setError('User with this email is already an admin user.')
		} else {
			const requestOptions = {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				mode: 'cors',
				accept: 'application/json',
				body: JSON.stringify({
					email: addAdminData.email
				})
			}
			try{
				const userExistResponse = await fetch(`${LICENSESAPI}/user`, requestOptions)
				const data = await userExistResponse.json()
				console.log(data)
				if (userExistResponse.status === 200) {
					if(!data){
						setFoundUser(null)
						setUserExists(false)
					} else{
						setFoundUser(data)
						setUserExists(true)
					}
				} else {
					console.error(data)
				}
			} catch(e){
				console.error(e)
			}

		}
		
	}

	const handleGiveAdminPermissions = async(e) => {
		e.preventDefault()
		const licenseId = foundUser.id
		const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    try{
      const response = await fetch(`${LICENSESAPI}/admins/${licenseId}`, requestOptions)
      const data = await response.json()
      if (response.status === 200) {
				getAdmins()
				closeAddAdminModal()
      } else {
				setError('Error while registering user to admin group')
        console.error(data)
      }
    } catch(e){
      console.error(e)
    }
	}


  // Actions Menu
  const [openMenuId, setOpenMenuId] = useState(null)
  const actionOptions = ['Delete User', 'Revoke Permissions']
  const handleSelectActionsOption = (value, user) => {
		setSelectedAdmin(user)
    if (value === 'Delete User') {
      setIsOpenModalDelete(true)
    } else if (value === 'Revoke Permissions') {
      setIsOpenModalRevoke(true)
    } 
  }
  const handleMenuToggle = (company) => {
    setOpenMenuId(openMenuId === company ? null : company)
  }
  const handleClickOutside = () => {
    setOpenMenuId(null)
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])
  /* Delete Admin */
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const closeModalDelete = () => {
    setIsOpenModalDelete(false)
    setError('')
  }
  const confirmDeleteAdmin = async() => {
    console.log("Delete Admin request here")
  }
  /* Revoke Admin Permissions */
  const [isOpenModalRevoke, setIsOpenModalRevoke] = useState(false)
  const closeModalRevoke = () => {
		setSelectedAdmin(null)
    setIsOpenModalRevoke(false)
    setError('')
  }
  const confirmRevoke = async(e) => {
    e.preventDefault()
		const licenseId = selectedAdmin.id
		const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    try{
      const response = await fetch(`${LICENSESAPI}/admins/${licenseId}`, requestOptions)
      const data = await response.json()
      if (response.status === 200) {
				getAdmins()
				closeModalRevoke()
      } else {
				setError('Error while registering user to admin group')
        console.error(data)
      }
    } catch(e){
      console.error(e)
    }
  }

  /* Get current admins */
  const [admins, setAdmins] = useState([])
  const getAdmins = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    try{
      const response = await fetch(`${LICENSESAPI}/admins`, requestOptions)
      const data = await response.json()
      if (response.status === 200) {
        setAdmins(data)
      } else {
        console.error(data)
      }
    } catch(e){
      console.error(e)
    }
    
  }
  useEffect(() => {
    getAdmins()
  }, [])
  

  return (
    <>
      <div className="adminUsersTable">
        <div className="adminUsersTableBody">
          <table>
            <thead className="adminUsersTableHead">
              <tr>
                <th>Name</th>
                <th className="columnMedium">Company</th>
                <th className="columnMedium">Email</th>
                <th className="columnMedium">Role</th>
                <th className="columnMedium tac">Actions</th>
              </tr>
            </thead>
            <tbody>
              {admins && admins.map((admin) => (
                <tr key={admin.name}>
                  <td>{admin.name}</td>
                  <td>{admin.company}</td>
                  <td>{admin.email}</td>
                  <td>{admin.role}</td>
                  <td>
                    <BasicOptionsButton
                      options={actionOptions}
                      onChange={(value) => handleSelectActionsOption(value, admin)}
                      isOpen={openMenuId === admin.name}
                      onToggle={() => handleMenuToggle(admin.name)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="adminUsersTableFooter">
          <BasicButton
            color="btnRed"
            size="btnSmall"
            onClick={openAddAdminModal}
          >
            Add New Administrator
          </BasicButton>
        </div>
      </div>
      {/* Add New Admin */}
      <FormModal isOpen={isOpenAddAdminModal} onClose={closeAddAdminModal}>
        <>
				  <h2 className="mb2">New Admin User</h2>
					{error && <div className="mt-1 mb2"><Warning message={error} /></div>}
					<form>
            <div className="flexContainer spaceBetween">
              <div className="col12">
                <BasicInput
                  label="Email*"
                  type="text"
                  value={addAdminData.email}
                  onChange={(value) => handleAddAdminInputChange('email', value)}
                  placeholder="Email"
                />
              </div>
            </div>
						{userExists === null &&
							<BasicButton
								color="btnRed"
								size="btnFull"
								onClick={handleCheckUserExist}
							>
								Check User
							</BasicButton>
						}
						{userExists === true && foundUser &&
							<>
								<p className="mb2">User already exists:</p>
								<p>Name: {foundUser.name}</p>
								<p>Username: {foundUser.username}</p>
								<p>Company: {foundUser.company}</p>
								<p>Role: {foundUser.role}</p>
								<p className='mb2'/>
								<p className="mb2">Would you like to give this user admin permissions?</p>
								
								<BasicButton
									color="btnRed"
									size="btnFull"
									onClick={handleGiveAdminPermissions}
								>
									Give Admin Permissions
								</BasicButton>
							</>
							
						}
						{userExists === false &&
							<>
							<p className="mb2">User does not exists. Please fill the next information if you want to create a new user.</p>
							<div className="flexContainer spaceBetween">
								<div className="col12">
									<BasicInput
										label="Name*"
										type="text"
										value={addAdminData.name}
										onChange={(value) => handleAddAdminInputChange('name', value)}
										placeholder="Name"
									/>
								</div>
								<div className="col6">
									<BasicInput
										label="Role*"
										type="text"
										value={addAdminData.role}
										onChange={(value) => handleAddAdminInputChange('role', value)}
										placeholder="Role"
									/>
								</div>
								<div className="col6">
									<BasicInput
										label="Department*"
										type="text"
										value={addAdminData.department}
										onChange={(value) => handleAddAdminInputChange('department', value)}
										placeholder="Department"
									/>
								</div>
            	</div>
							<BasicButton
								color="btnRed"
								size="btnFull"
								onClick={handleGiveAdminPermissions}
							>
								Create Admin User
							</BasicButton>
							</>
							
						}
            
            <div className="mb1" />
            <BasicButton
              color="btnBlack"
              size="btnFull"
              onClick={closeAddAdminModal}
            >
              Cancel
            </BasicButton>
          </form>
        </>

      </FormModal>

      {/* Prevent user delete */} 
      {selectedAdmin && 
      <BasicModal isOpen={isOpenModalDelete} onClose={closeModalDelete}>
        <h2 className="mb2 tac">Are you sure that you want to delete this user {selectedAdmin.name}?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={confirmDeleteAdmin}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={closeModalDelete}
        >
          Cancel
        </BasicButton>
      </BasicModal>}
      
      {/* Prevent revoke permissions*/}
      {selectedAdmin &&
      <BasicModal isOpen={isOpenModalRevoke} onClose={closeModalRevoke}>
        <h2 className="mb2 tac">Are you sure that you want to revoke admin permissions for {selectedAdmin.name}?</h2>
        <BasicButton
          color="btnRed"
          size="btnFull"
          onClick={confirmRevoke}
        >
          Confirm
        </BasicButton>
        <div className="mb1" />
        <BasicButton
          color="btnBlack"
          size="btnFull"
          onClick={closeModalRevoke}
        >
          Cancel
        </BasicButton>
      </BasicModal>
      }
      
      
    </>
  )
}

export default AdminUsersTable