import './NumberInput.css'

const NumberInput = ({
  label,
  inputType,
  value,
  defaultValue,
  inputName,
  onChange,
  placeholder,
  className,
  disabled,
  onKeyPress
}) => {

  const preventKey = (e) => {
    // Allow numbers, decimals and backspace key
    if (!/[\d.]|\b|Tab|Enter/.test(e.key)) {
      e.preventDefault()
    }
  }

  const numFixed = (e) => {
    e.target.value = e.target.value.replace(/[^\d.]/g, '').replace(/(\.\d{0,2})\d*$/g, '$1')
    const numericValue = parseFloat(e.target.value)
    if (inputType === 'percentage' && numericValue > 100) {
      e.target.value = 100
    }
  }

  const handleCost = (e) => {
    if (inputType === 'cost') {
      // Delete not allowed characters
      const sanitizedValue = e.target.value.replace(/[^\d.]/g, '')

      // Set the format value with comas between 3 digits and allow only 2 decimals
      const formattedValue = sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      // Update the input value
      e.target.value = formattedValue

      // Allow only two decimals
      const decimalParts = formattedValue.split('.')
      if (decimalParts[1] && decimalParts[1].length > 2) {
        e.target.value = `${decimalParts[0]}.${decimalParts[1].slice(0, 2)}`
      }

      // Set the max value to 999,999,999
      const maxValue = 999999999.99
      if (parseFloat(e.target.value.replace(/,/g, '')) > maxValue) {
        e.target.value = maxValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      }

      // Assign the numeric value to the onChage
      onChange(parseFloat(e.target.value.replace(/,/g, '')))
    }
  }

  return (
    <div
      className={
        `numberInput 
          ${className} ${inputType === 'cost' ? 'numberInputCost' : 'numberInputPercentage'}
          ${label ? "hasLabel" : ""}
        `
      }
    >
      {label ? <label className="smallText">{label}</label> : null}
      <span
        className={
          `numberInputSymbol
            ${inputType === "cost" ? 'cost' : "percentage"} 
            ${label ? "symbolWithLabel" : ""}
          `
        }
      >
        {inputType === "cost" ? '$' : "%"}
      </span>
      <input
        type="text"
        name={inputName}
        defaultValue={defaultValue}
        value={value}
        onKeyDown={preventKey}
        onKeyPress={onKeyPress}
        onChange={(e) => {
          numFixed(e)
          handleCost(e)
          onChange(inputName, e.target.value)
        }}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  )
}

export default NumberInput
