import React, { useState, useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Auth, Hub } from 'aws-amplify'

const PrivateRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await Auth.currentAuthenticatedUser()
        setIsAuthenticated(true)
      } catch (error) {
        setIsAuthenticated(false)
      }
      setIsLoading(false)
    }

    Hub.listen('auth', (data) => {
      const { payload } = data
      if (payload.event === 'signIn') {
        setIsAuthenticated(true)
      } else if (payload.event === 'signOut') {
        setIsAuthenticated(false)
      }
    })

    checkAuth()

    return () => Hub.remove('auth')
  }, [])

  if (isLoading) {
    return null // or loading indicator, etc...
  }

  const adminLoginPath = `/${btoa('admin-login')}`

  if (location.pathname === adminLoginPath) {
    return <Outlet />
  }

  return isAuthenticated ? <Outlet /> : <Navigate to={`/${btoa('login')}`} />
}

export default PrivateRoutes
