import React, { useEffect, useState } from 'react'
import './SearchPeopleInvolved.css'
import SearchBar from '../resources/SearchBar'

const API = process.env.REACT_APP_SETUP_API

const SearchPeopleInvolved = ({ updatePeopleInvolved, currentPeopleInvolved, updateDepartmentResponsibles, currentDepartmentResponsibles }) => {
  /* Company */
  const currentCompany = localStorage.getItem('currentCompany')
  /* Resources list */
  const [resources, setResources] = useState([])
  /* Fetch resources list */
  const getCompanyResources = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    const response = await fetch(`${API}/resources/${currentCompany}/sync`, requestOptions)
    const data = await response.json()
    setResources(data)
  }

  useEffect(() => {
    getCompanyResources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Filtering by searchBar */
  const [searchPeopleInvolved, setSearchPeopleInvolved] = useState('')
  const [filteredPeopleInvolved, setFilteredPeopleInvolved] = useState([])

  const handleSearch = (value) => {
    const searchResources = value
    setSearchPeopleInvolved(searchResources)

    const filtered = resources.filter(resource =>
      resource.name.toLowerCase().includes(searchResources.toLowerCase())
    )

    setFilteredPeopleInvolved(filtered)
  }

  const displayPeopleInvolved = searchPeopleInvolved === '' ? resources : filteredPeopleInvolved

  const handleSelectInvolved = (selectedInvolved) => {
    updatePeopleInvolved(selectedInvolved)
  }

  const handleSelectResponsible = (responsible) => {
    updateDepartmentResponsibles(responsible)
  }


  return (
    <div className="searchPeopleInvolvedTable">
      <div className="searchPeopleInvolvedTableHead">
        <h2>People Involved</h2>
        <SearchBar
          className="searchBarMedium"
          placeholder="Search people involved"
          onChange={handleSearch}
          value={searchPeopleInvolved}
        />
      </div>
      <div className="searchPeopleInvolvedTableBody">
        <table>
          <thead>
            <tr>
              <th className="columnSmall">Select</th>
              <th className="tac">Dept Responsible</th>
              <th>Full Name</th>
              <th>Role</th>
              <th>Location</th>
              <th>Department</th>
            </tr>
          </thead>
          <tbody>
            {displayPeopleInvolved.length === 0 ? (
              <tr><td colSpan="2">No resources found</td></tr>
            ) : (
              displayPeopleInvolved.map((resource, index) => (
                <tr key={resource.id}>
                  <td className="tac">
                    <span
                      className={
                        `selectServApp ${currentPeopleInvolved.includes(resource.name) ? 'selectedServApp' : ''}`
                      }
                      onClick={() => handleSelectInvolved(resource)}
                    />
                  </td>
                  <td className="tac">
                    <span
                      className={
                        `selectServApp ${currentDepartmentResponsibles.includes(resource.name) ? 'selectedServApp' : ''}`
                      }
                      onClick={() => handleSelectResponsible(resource)}
                    />
                  </td>
                  <td>{resource.name}</td>
                  <td>{resource.role}</td>
                  <td>{resource.location}</td>
                  <td>{resource.department}</td>
                </tr>
              ))
            )}

          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SearchPeopleInvolved