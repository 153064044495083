/* Dependencies */
import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from 'react-router-dom'

/* Styles */
import './index.css'

/* Components for routes */
/* Admin */
import AdminLogin from './pages/admin/AdminLogin'
import AdminHome from './pages/admin/AdminHome'
import CompaniesLicenses from './pages/admin/CompaniesLicenses'
import AdminChartPreview from './pages/admin/AdminChartPreview'
/* User */
import UserLogin from './pages/login/UserLogin'
import UserLoginFp from './pages/login/UserLoginFp' // FingerprintTests
import ForgotPassword from './pages/login/ForgotPassword'
import UserHome from './pages/UserHome'
import CompanySetup from './pages/setup/CompanySetup'
import InitiativeSetup from './pages/setup/InitiativeSetup'
import ToolchainSetup from './pages/setup/ToolchainSetup'
import ToolchainConfig from './pages/setup/ToolchainConfig'
import BusinessDashboard from './pages/business/BusinessDashboard'
import ToolchainDashboard from './pages/toolchain/ToolchainDashboard'
import DobconAdoption from './pages/adoption/DobconAdoption'
import TCOCharts from './pages/business/TCOCharts'
import HomeSettings from './pages/settings/HomeSettings'
import ChangePassword from './pages/settings/ChangePassword'
import AssignLicenses from './pages/settings/AssignLicenses'
import CompanyInitiatives from './pages/settings/CompanyInitiatives'
import PrivateRoutes from './pages/login/PrivateRoutes'
import BusinessStartDate from './pages/business/BusinessStartDate'
import BusinessStatus from './pages/business/BusinessStatus'
import BusinessLocation from './pages/business/BusinessLocation'
import BusinessResources from './pages/business/BusinessResources'
import BusinessRoi from './pages/business/BusinessRoi'
import TCOBusinessForm from './pages/business/TCOBusinessForm'
import DobconCostsDashboard from './pages/business/DobconCostsDashboard'
//import DobconCostsDashboard from './pages/business/DobconCostsDashboard'
import BusinessTotalCost from './pages/business/BusinessTotalCost'
import InitiativeMetrics from './pages/business/initiatives/InitiativeMetrics'
import InitiativeCosts from './pages/business/initiatives/InitiativeCosts'
import InitiativeFinancial from './pages/business/initiatives/InitiativeFinancial'
import InitiativeResources from './pages/business/initiatives/InitiativeResources'
import RolesAndPrivileges from './pages/settings/RolesAndPrivileges'
import ToolchainDashboardInitiative from './pages/toolchain/ToolchainDashboardInitiative'
import { InitiativesProvider } from './pages/business/resources/BusinessInitiativesContext'
import { PrivilegeProvider } from './auth/PrivilegeContext'
import { AuthProvider } from './auth/AuthContext'
import TitleUpdater from './components/title/TitleUpdater'
import AdminUsers from './pages/admin/AdminUsers'
import { Amplify } from 'aws-amplify'
import config from './auth/AmplifyConfig'

Amplify.configure(config)

function App() {
  /* Scroll top on each link or redirection */
  function ScrollToTop() {
    const { pathname } = useLocation()
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname])

    return null
  }

  /* flaglogo */
  const [flagLogo, setFlagLogo] = useState(false)
  const sendFlagLogo = (value) => {
    if (flagLogo === false) {
      setFlagLogo(value)
    } else {
      setFlagLogo(!value)
    }
  }

  return (
    <Router>
      <AuthProvider>
        <InitiativesProvider>
          <PrivilegeProvider>
          <ScrollToTop />
          <TitleUpdater />
          <Routes>
            {/* Admin */}
            <Route path={`/${btoa('admin-login')}`} element={<AdminLogin />} />
            {/* Login */}
            <Route path={`/${btoa('login-nofp')}`} element={<UserLogin />} />
            <Route path={`/${btoa('login')}`} element={<UserLoginFp />} />
            <Route path={`/${btoa('forgot-password')}`} element={<ForgotPassword />} />
            {/* Private */}
            <Route element={<PrivateRoutes />}>
              {/* Admin */}
              <Route path={`/${btoa('admin-home')}`} element={<AdminHome />} />
              <Route path={`/${btoa('admin-companies')}`} element={<CompaniesLicenses />} />
              <Route path={`/${btoa('admin-users')}`} element={<AdminUsers />} />
              <Route path={`/${btoa('admin-chart-preview')}`} element={<AdminChartPreview />} />
              {/* User */}
              <Route path="/" element={<UserHome />} />
              <Route path={`/${btoa('user-home')}`} element={<UserHome />} />
              {/* Setup */}
              <Route
                path={`/${btoa('only-company-setup')}`} element={<CompanySetup sendFlagLogo={sendFlagLogo} />}
              />
              <Route path={`/${btoa('company-setup')}`} element={<CompanySetup sendFlagLogo={sendFlagLogo} />} />
              <Route path={`/${btoa('initiative-setup')}`} element={<InitiativeSetup />} />
              <Route path={`/${btoa('toolchain-setup')}`} element={<ToolchainSetup />} />
              <Route path={`/${btoa('toolchain-config')}`} element={<ToolchainConfig />} />
              {/* Settings */}
              <Route path={`/${btoa('settings-home')}`} element={<HomeSettings />} />
              <Route path={`/${btoa('change-password')}`} element={<ChangePassword />} />
              <Route path={`/${btoa('assign-licenses')}`} element={<AssignLicenses />} />
              <Route path={`/${btoa('company-initiatives')}`} element={<CompanyInitiatives />} />
              <Route path={`/${btoa('roles-and-privileges')}`} element={<RolesAndPrivileges />} />
              {/* Business Dashboard */}
              <Route path={`/${btoa('dobcon-business')}`} element={<BusinessDashboard />} />
              <Route path={`/${btoa('initiative-start-date')}`} element={<BusinessStartDate />} />
              <Route path={`/${btoa('initiative-status')}`} element={<BusinessStatus />} />
              <Route path={`/${btoa('location')}`} element={<BusinessLocation />} />
              <Route path={`/${btoa('initiative-resources')}`} element={<BusinessResources />} />
              <Route path={`/${btoa('roi')}`} element={<BusinessRoi />} />
              <Route path={`/${btoa('tco-form')}`} element={<TCOBusinessForm />} />
              <Route path={`/${btoa('tco-charts')}`} element={<TCOCharts />} />
              <Route path={`/${btoa('dobcon-costs')}`} element={<DobconCostsDashboard />} />
              <Route path={`/${btoa('total-initiative-cost')}`} element={<BusinessTotalCost />} />

              <Route path={`/${btoa('initiative')}/:initiativeName`} element={<InitiativeMetrics />} />
              <Route
                path={`/${btoa('initiative')}/:initiativeName/${btoa('costs')}`} element={<InitiativeCosts />}
              />
              <Route
                path={`/${btoa('initiative')}/:initiativeName/${btoa('financial')}`}
                element={<InitiativeFinancial />}
              />
              <Route
                path={`/${btoa('initiative')}/:initiativeName/${btoa('resources')}`}
                element={<InitiativeResources />}
              />
              {/* Toolchain Dashboard */}
              <Route path={`/${btoa('dobcon-toolchain')}`} element={<ToolchainDashboard />} />
              <Route
                path={`/${btoa('dobcon-toolchain')}/:initiativeName`} element={<ToolchainDashboardInitiative />}
              />
              {/* Dobcon Adoption */}
              <Route path={`/${btoa('dobcon-adoption')}`} element={<DobconAdoption />} />
            </Route>
          </Routes>
        </PrivilegeProvider>
      </InitiativesProvider>
    </AuthProvider>
    </Router>
  )
}

export default App
