import './BusinessFilterPill.css'

const BusinessFilterPill = ({
  pillTitle,
  pillDelete,
  pillSelected,
  pillDisabled,
  isPillSelected
}) => {
  return (
    <div className={`businessFilterPill ${pillDisabled ? 'opacity5' : ''}`}>
      <span className={pillSelected && pillDelete ? '' : 'businessFilterPillTitle'}>
        {pillTitle}
      </span>
      <div className="businessFilterPillOptionsContainer">
        {pillSelected ?
          <button
            className={pillDisabled ? 'pillDisabled' : ''}
            title="Select filter"
            onClick={pillSelected}
            disabled={pillDisabled}
          >
            <div className={isPillSelected ? 'selectFilter selected' : 'selectFilter unselected'} />
          </button> : null
        }
        {pillDelete ?
          <button
            className={pillDisabled ? 'pillDisabled' : ''}
            title="Remove filter"
            onClick={pillDelete}
            disabled={pillDisabled}
          >
            <i className="fa-solid fa-trash" />
          </button> : null
        }
      </div>
    </div>
  )
}

export default BusinessFilterPill